import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { openModalAction } from '../../../actions/modal';
import { PageHeader } from '../../../components/PageHeader';
import { Button } from '../../../components/PageHeader/Button';
import { TextInput } from '../../../components/PageHeader/TextInput';
import { HEADER_BUTTON_BLUE, PAGE_LIMIT } from '../../../constants';
import Table, { Column } from '../../../containers/Table/TableWithPagination';
import { ADD_ORAGANIZATION, UPDATE_ORAGANIZATION } from '../../../modals/AddOrganizationModal/redux/constants';
import Strings from '../../../Strings';
import { actions } from './redux/actions';
import { showStatus } from '../../../utils';
import AdvancedFilters, { Filter } from '../../../components/AdvancedFilters/AdvancedFilters';
import { getFiltersDefinition } from '../../../components/AdvancedFilters/FiltersDefinition';
import AdvancedFiltersBar from '../../../components/AdvancedFilters/AdvancedFiltersBar';
import { getFiltersForRequest, urlInjected } from '../../../components/AdvancedFilters/helpers';

function Organization(props) {
  const pageRequest = React.useRef({ offset: 0, search: '' });
  const { filters } = props;

  React.useEffect(() => {
    if (!urlInjected(window.location.search)) {
      props.getOrganizations(pageRequest.current);
    }
  }, []);

  React.useEffect(() => {
    const newFiltersForRequest = getFiltersForRequest(filters);
    if (!pageRequest.current.filterBy && newFiltersForRequest?.length === 0) {
      return;
    }
    if (pageRequest.current.filterBy !== newFiltersForRequest) {
      onFiltersChange();
    }
  }, [filters]);

  const onSearchQueryChange = query => {
    pageRequest.current.offset = 0;
    pageRequest.current.search = query;
    setTimeout(() => {
      props.getOrganizations(pageRequest.current);
    }, 1000);
  };

  const getHeaderComponents = () => {
    const { onAddOrganization } = props;
    return (
      <React.Fragment>
        <TextInput class="search" placeholder={Strings.search} onChange={e => onSearchQueryChange(e.target.value)} />
        <AdvancedFilters>
          <Filter definition={getFiltersDefinition().org_administrative_state} />
          <Filter definition={getFiltersDefinition().org_business_state} />
          <Filter definition={getFiltersDefinition().org_operational_state} />
        </AdvancedFilters>
        <Button class={HEADER_BUTTON_BLUE} onClick={onAddOrganization} title={Strings.addOrganization} />
      </React.Fragment>
    );
  };

  const onOffsetChange = offset => {
    pageRequest.current.offset = offset;
    props.getOrganizations(pageRequest.current);
  };

  const onSortClick = ({ sortKey, direction }) => {
    pageRequest.current.offset = 0;
    pageRequest.current.sortColumn = sortKey;
    pageRequest.current.sortType = direction;
    props.getOrganizations(pageRequest.current);
  };

  const onFiltersChange = _.debounce(() => {
    pageRequest.current.offset = 0;
    const newFiltersForRequest = getFiltersForRequest(filters);
    pageRequest.current.filterBy = newFiltersForRequest;
    props.getOrganizations(pageRequest.current);
  }, 1000);

  const onOrganizationSelected = id => {
    const { organizations } = props;
    if (organizations && organizations[id].masked_id) {
      const data = organizations[id];
      const maskedId = encodeURIComponent(data.masked_id);
      props.onNavigate(`/organization/${maskedId}/${organizations[id].name}/orgnization-manager`);
    }
  };

  const onOrgEdit = id => {
    props.onEditOrganization(props.organizations[id]);
  };

  const onOrgDelete = (_, organization) => {
    const data = {
      title: (
        <span>
          {Strings.deleteOrgWarning} <b>{organization.name}</b>?
        </span>
      ),

      onConfirmAction: actions.deleteOrg(organization.masked_id),
      onCancelAction: null,
      confirmPostAction: actions.getOrganizations({
        ...pageRequest.current,
        limit: PAGE_LIMIT,
      }),
    };
    props.openConfirmModal(data);
  };

  const launchOrg = (_, organization) => {
    const data = {
      caption: `${Strings.launch} ${organization.name}`,
      title: (
        <span>
          {Strings.launchOrgWarning} <b>{organization.name}</b>?
        </span>
      ),

      onConfirmAction: actions.launchOrganization(organization.masked_id),
      onCancelAction: null,
      confirmPostAction: actions.getOrganizations({
        ...pageRequest.current,
        limit: PAGE_LIMIT,
      }),
      hideCaution: true,
    };
    props.openConfirmModal(data);
  };

  const unsuspendOrg = (_, organization) => {
    const data = {
      caption: `${Strings.unsuspend} ${organization.name}`,
      title: (
        <span>
          {Strings.unsuspendOrgWarning}?
        </span>
      ),
      onConfirmAction: actions.unsuspendOrganization(organization.masked_id, {}),
      confirmPostAction: actions.getOrganizations({
        ...pageRequest.current,
        limit: PAGE_LIMIT,
      }),
      onCancelAction: null,
      hideCaution: true,
    };
    props.openConfirmModal(data);
  };

  const { isLoading, organizations, pagination } = props;

  const buttons = [
    {
      icon: 'edit',
      onClick: onOrgEdit,
      disabled: () => {
        return false;
      },
      text: Strings.edit,
    },
    {
      icon: 'delete',
      onClick: onOrgDelete,
      disabled: () => {
        return false;
      },
      text: Strings.delete,
    },
    {
      icon: 'launch',
      onClick: launchOrg,
      disabled: (_, org) => {
        return !(org.state.administrative_state !== 'New' && org.state.administrative_state !== 'Deleted' && org.state.business_state === 'Proof-of-Service');
      },
      text: Strings.launch,
    },
    {
      icon: 'refresh',
      onClick: unsuspendOrg,
      disabled: (_, org) => {
        return org.state.administrative_state !== 'Suspended';
      },
      text: Strings.unsuspend,
    }
  ];

  return (
    <div>
      <PageHeader right={getHeaderComponents()} />
      <AdvancedFiltersBar />
      <Table
        isLoading={isLoading}
        name="organizations"
        data={organizations || []}
        onRowSelection={onOrganizationSelected}
        onSortClick={onSortClick}
        onOffsetChange={onOffsetChange}
        pagination={
          pagination || {
            offset: 0,
            total: 0,
          }
        }
        buttons={buttons}
      >
        <Column key="name" title={Strings.organizations} value={d => d.name} />
        <Column key="organization_managers" title={Strings.organizationManagers} value={d => d.organization_managers} />
        <Column key="cap_managers" title={Strings.capManagers} value={d => d.cap_managers} />
        <Column key="admins" title={Strings.admin} value={d => d.admins} />
        <Column key="patients" title={Strings.patients} value={d => d.patients} />
        <Column
          key="two_fa_enabled"
          title={Strings.twoFAEnabled}
          value={d => showStatus(d.two_fa_enabled ? 'enabled' : 'disabled')}
        />
        <Column
          key="administrative_state"
          title={Strings.administrativeState}
          value={d => d.state.administrative_state}
        />
        <Column key="business_state" title={Strings.businessState} value={d => d.state.business_state} />
        <Column key="operational_state" title={Strings.operationalState} value={d => d.state.operational_state} />
      </Table>
    </div>
  );
}

const mapStateToProps = state => {
  const { organizations } = state.superUser;

  return {
    organizations: organizations && organizations?.organization,
    isLoading: organizations && organizations?.isLoading,
    pagination: organizations && organizations?.pagination,
    filters: state.entities.advancedFilters.filters.items,
  };
};

const mapDispatchToProps = dispatch => ({
  onNavigate: path => dispatch(push(path)),
  getOrganizations: pageRequest => dispatch(actions.getOrganizations(pageRequest)),
  onAddOrganization: () =>
    dispatch(
      openModalAction('add-organization', {
        actionType: ADD_ORAGANIZATION,
      }),
    ),
  onEditOrganization: organization =>
    dispatch(
      openModalAction('edit-organization', {
        actionType: UPDATE_ORAGANIZATION,
        organization,
      }),
    ),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
  launchOrganization: id => dispatch(actions.launchOrganization(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Organization);
