import { mobileDataRangeTypes } from './Metrics';

function toDateRange(dateRangeType) {
  switch (dateRangeType) {
    case mobileDataRangeTypes.day:
      return { type: 'day', count: 1 };
    case mobileDataRangeTypes.week:
      return { type: 'day', count: 7 };
    case mobileDataRangeTypes.twoWeeks:
      return { type: 'day', count: 14 };
    case mobileDataRangeTypes.month:
      return { type: 'day', count: 31 };
    case mobileDataRangeTypes.sixMonths:
      return { type: 'day', count: 183 };
    case mobileDataRangeTypes.year:
      return { type: 'day', count: 366 };
    default:
      return {};
  }
}

export function toDateRangeForMove(dateRangeType) {
  switch (dateRangeType) {
    case mobileDataRangeTypes.day:
      return { type: 'day', count: 1 };
    case mobileDataRangeTypes.week:
      return { type: 'day', count: 7 };
    case mobileDataRangeTypes.twoWeeks:
      return { type: 'day', count: 14 };
    case mobileDataRangeTypes.month:
      return { type: 'day', count: 31, maxOverflow: 3 };
    case mobileDataRangeTypes.sixMonths:
      return { type: 'day', count: 183, maxOverflow: 4 };
    case mobileDataRangeTypes.year:
      return { type: 'day', count: 366, maxOverflow: 1 };
  }
}

export function toDateRangeForPickerUnit(dateRangeType) {
  switch (dateRangeType) {
    case mobileDataRangeTypes.day:
      return { type: 'day', count: 1 };
    case mobileDataRangeTypes.week:
      return { type: 'day', count: 7 };
    case mobileDataRangeTypes.twoWeeks:
      return { type: 'day', count: 14 };
    case mobileDataRangeTypes.month:
      return { type: 'day', count: 31 };
    case mobileDataRangeTypes.sixMonths:
      return { type: 'day', count: 183 };
    case mobileDataRangeTypes.year:
      return { type: 'day', count: 366 };
  }
}

export function calcTicksCount(dateRangeType, maxRange) {
  const dri = toDateRangeForPickerUnit(dateRangeType);
  let count = 0;
  for (let index = maxRange.start.clone(); index.unix() < maxRange.end.unix(); index = index.add(dri.count, dri.type)) {
    count++;
  }

  return count;
}

function alignToStartOfDateRange(date, dateRangeType) {
  switch (dateRangeType) {
    case mobileDataRangeTypes.day:
      return date.clone().startOf('day');
    case mobileDataRangeTypes.week:
      return date.clone().startOf('week');
    case mobileDataRangeTypes.twoWeeks:
      return date
        .clone()
        .add(-1, 'weeks')
        .startOf('week');
    case mobileDataRangeTypes.month:
      return date.clone().startOf('month');
    case mobileDataRangeTypes.sixMonths:
      return date
        .clone()
        .startOf('year')
        .add(date.month() < 6 ? 0 : 6, 'months');
    case mobileDataRangeTypes.year:
      return date.clone().startOf('year');
    default:
      return date;
  }
}

export function calcNewDateRangeAfterTypeChange(dateRange, type, maxRange) {
  const dateAligned = alignToStartOfDateRange(dateRange.end, type);
  return calcDateRangeAround(dateAligned, type, maxRange);
}

export function calcNewDateRangeAfterMoveLeft(dateRange, maxRange) {
  const dateRangeForMove = toDateRangeForMove(dateRange.type);
  return calcDateRangeAround(
    dateRange.end
      .clone()
      .subtract(dateRangeForMove.maxOverflow ? dateRangeForMove.maxOverflow : 1, 'day')
      .subtract(dateRangeForMove.count, `${dateRangeForMove.type}s`),
    dateRange.type,
    maxRange,
  );
}

export function calcNewDateRangeAfterMoveRight(dateRange, maxRange) {
  const dateRangeForMove = toDateRangeForMove(dateRange.type);
  return calcDateRangeAround(
    dateRange.start.clone().add(dateRangeForMove.count, `${dateRangeForMove.type}s`),
    dateRange.type,
    maxRange,
  );
}

export function calcDateRangeCustom(baseMoment, days, maxRange) {
  const newRange = {
    start: baseMoment
      .clone()
      .subtract(days, 'days')
      .startOf('day'),
    end: baseMoment
      .clone()
      .add(1, 'days')
      .startOf('day'),
  };

  if (maxRange !== undefined && newRange.start.unix() < maxRange.start.unix()) {
    newRange.start = maxRange.start.clone();
  }

  return newRange;
}

export function calcDateRangeAround(baseMoment, rangeType, maxRange) {
  let baseMomentWithinMaxRange = baseMoment.clone();
  if (maxRange) {
    if (baseMomentWithinMaxRange.unix() >= maxRange.end.unix())
      baseMomentWithinMaxRange = maxRange.end.clone().subtract(1, 'day');
    if (baseMomentWithinMaxRange.unix() < maxRange.start.unix()) baseMomentWithinMaxRange = maxRange.start.clone();
  }

  if (!rangeType) {
    return {
      type: rangeType,
      start: baseMomentWithinMaxRange
        .clone()
        .subtract(graphType.defaultDateRangeDays || DEFAULT_DATE_RANGE_DAYS, 'days')
        .startOf('day'),
      end: baseMomentWithinMaxRange
        .clone()
        .add(1, 'days')
        .startOf('day'),
    };
  }

  const dateRange = toDateRange(rangeType);
  const start = alignToStartOfDateRange(baseMomentWithinMaxRange, rangeType);
  const end = start
    .clone()
    .add(dateRange.count, `${dateRange.type}s`)
    .startOf(dateRange.type);

  const ret = fixRange(
    {
      type: rangeType,
      start,
      end,
    },
    maxRange,
  );

  return ret;
}

export function fixRange(dateRange, maxRange) {
  if (!maxRange) {
    //console.log('maxRange undefiend!');
    return dateRange;
  }

  const len = dateRange.end.diff(dateRange.start, 'days');
  const lenMax = maxRange.end.diff(maxRange.start, 'days');

  //console.log(len, lenMax);

  if (len >= lenMax) {
    return {
      type: dateRange.type,
      start: maxRange.start.clone(),
      end: maxRange.end.clone(),
    };
  }

  if (dateRange.end.unix() > maxRange.end.unix()) {
    const diff = dateRange.end.diff(dateRange.start, 'days');
    const fixed = {
      type: dateRange.type,
      start: maxRange.end.clone().subtract(diff, 'days'),
      end: maxRange.end.clone(),
    };
    //console.log('Fixed out of range', fixed);
    return fixed;
  }

  if (dateRange.start.unix() < maxRange.start.unix()) {
    const diff = dateRange.end.diff(dateRange.start, 'days');
    const fixed = {
      type: dateRange.type,
      start: maxRange.start.clone(),
      end: maxRange.start.clone().add(diff, 'days'),
    };
    // console.log('Fixed out of range', fixed);
    return fixed;
  }

  return dateRange;
}

export function zoomRight(prevDateRangeStart, prevDateRangeEnd, maxRangeStart, maxRangeEnd, minDays, maxDays) {
  const diffDays = prevDateRangeEnd.diff(prevDateRangeStart, 'd');
  const newDateRangeStart = prevDateRangeEnd.clone();
  const newDateRangeEnd = newDateRangeStart.clone().add(diffDays, 'd');

  const fixedZoom = fixZoom(newDateRangeStart, newDateRangeEnd, maxRangeStart, maxRangeEnd, minDays, maxDays);

  return fixedZoom;
}

export function zoomLeft(prevDateRangeStart, prevDateRangeEnd, maxRangeStart, maxRangeEnd, minDays, maxDays) {
  const diffDays = prevDateRangeEnd.diff(prevDateRangeStart, 'd');
  const newDateRangeEnd = prevDateRangeStart.clone();
  const newDateRangeStart = newDateRangeEnd.clone().add(-diffDays, 'd');

  const fixedZoom = fixZoom(newDateRangeStart, newDateRangeEnd, maxRangeStart, maxRangeEnd, minDays, maxDays);

  return fixedZoom;
}

export function zoomIn(ratio, prevDateRangeStart, prevDateRangeEnd, maxRangeStart, maxRangeEnd, minDays, maxDays) {
  const diffDays = prevDateRangeEnd.diff(prevDateRangeStart, 'd');
  if (diffDays <= minDays) return { start: prevDateRangeStart, end: prevDateRangeEnd };
  // console.log(diffDays);
  // console.log((diffDays * (1 - 1 / ratio)) / 2);
  // console.log(Math.round((diffDays * (1 - 1 / ratio)) / 2));
  const deltaStart = Math.round((diffDays * (1 - 1 / ratio)) / 2);
  let diffDaysNew = Math.round(diffDays / ratio);
  if (diffDaysNew === diffDays) diffDaysNew = diffDays - 1;
  if (diffDaysNew < minDays) diffDaysNew = minDays;

  const newDateRangeStart = prevDateRangeStart.clone().add(deltaStart, 'd');
  const newDateRangeEnd = newDateRangeStart.clone().add(diffDaysNew, 'd');

  const fixedZoom = fixZoom(newDateRangeStart, newDateRangeEnd, maxRangeStart, maxRangeEnd, minDays, maxDays);

  return fixedZoom;
}

export function zoomOut(ratio, prevDateRangeStart, prevDateRangeEnd, maxRangeStart, maxRangeEnd, minDays, maxDays) {
  const diffDays = prevDateRangeEnd.diff(prevDateRangeStart, 'd');
  if (diffDays > maxDays) return { start: prevDateRangeStart, end: prevDateRangeEnd };
  const deltaStart = -Math.round(diffDays * ((ratio - 1) / 2));
  let diffDaysNew = Math.round(diffDays * ratio);
  if (diffDaysNew === diffDays) diffDaysNew = diffDays + 1;
  if (diffDaysNew > maxDays) diffDaysNew = maxDays;

  const newDateRangeStart = prevDateRangeStart.clone().add(deltaStart, 'd');
  const newDateRangeEnd = newDateRangeStart.clone().add(diffDaysNew, 'd');

  const fixedZoom = fixZoom(newDateRangeStart, newDateRangeEnd, maxRangeStart, maxRangeEnd, minDays, maxDays);

  return fixedZoom;
}

export function fixZoom(dateRangeStart, dateRangeEnd, maxRangeStart, maxRangeEnd, minDays, maxDays) {
  let ret = { start: dateRangeStart.clone(), end: dateRangeEnd.clone() };

  const diffDays = dateRangeEnd.diff(dateRangeStart, 'd');

  // ensure within maxRange
  if (ret.start.unix() < maxRangeStart.unix()) {
    ret.start = maxRangeStart.clone();
    ret.end = maxRangeStart.clone().add(diffDays, 'd');
  }
  if (ret.end.unix() > maxRangeEnd.unix()) {
    ret.start = maxRangeEnd.clone().add(-diffDays, 'd');
    ret.end = maxRangeEnd.clone();
  }

  // ensure diff <= max
  if (diffDays > maxDays) {
    const reduceDays = diffDays - maxDays;
    const reduceDaysL = Math.floor(reduceDays / 2);
    const reduceDaysR = reduceDays - reduceDaysL;
    ret.start = ret.start.clone().add(reduceDaysL, 'd');
    ret.end = ret.end.clone().add(-reduceDaysR, 'd');
  }

  // ensure diff >= min
  if (diffDays < minDays) {
    const addDays = minDays - diffDays;
    const addDaysL = Math.floor(addDays / 2);
    const addDaysR = addDays - addDaysL;
    ret.start = ret.start.clone().add(-addDaysL, 'd');
    ret.end = ret.end.clone().add(addDaysR, 'd');
  }

  // ensure within maxRange (after expanding?)
  const diffDaysNew = ret.end.diff(ret.start, 'd');
  if (ret.start.unix() < maxRangeStart.unix()) {
    ret.start = maxRangeStart.clone();
    ret.end = maxRangeStart.clone().add(diffDaysNew, 'd');
  }
  if (ret.end.unix() > maxRangeEnd.unix()) {
    ret.start = maxRangeEnd.clone().add(-diffDaysNew, 'd');
    ret.end = maxRangeEnd.clone();
  }

  // console.log('Fixed', ret);

  return ret;
}

export function defaultOnDrag(state) {
  if (state.pinching) {
    return state.cancel();
  }
}

export function defaultOnDragEnd(
  state,
  ratio,
  prevDateRangeStart,
  prevDateRangeEnd,
  maxRangeStart,
  maxRangeEnd,
  minDays,
  maxDays,
  setDateRange,
) {
  // console.log('DragEnd');
  // console.log(state);
  const dx = state.movement[0];
  const dy = state.movement[1];
  const isHorizontal = Math.abs(dx) > Math.abs(dy);
  if (isHorizontal && dx < 0) {
    // console.log('GESTURE LEFT!!!');
    const newDateRange = zoomRight(prevDateRangeStart, prevDateRangeEnd, maxRangeStart, maxRangeEnd, minDays, maxDays);
    setDateRange(newDateRange);
  }
  if (isHorizontal && dx > 0) {
    // console.log('GESTURE RIGHT!!!');
    const newDateRange = zoomLeft(prevDateRangeStart, prevDateRangeEnd, maxRangeStart, maxRangeEnd, minDays, maxDays);
    setDateRange(newDateRange);
  }
  if (!isHorizontal && dy < 0) {
    // console.log('GESTURE UP!!!');
    const newDateRange = zoomOut(
      ratio,
      prevDateRangeStart,
      prevDateRangeEnd,
      maxRangeStart,
      maxRangeEnd,
      minDays,
      maxDays,
    );
    setDateRange(newDateRange);
  }
  if (!isHorizontal && dy > 0) {
    // console.log('GESTURE DOWN!!!');
    const newDateRange = zoomIn(
      ratio,
      prevDateRangeStart,
      prevDateRangeEnd,
      maxRangeStart,
      maxRangeEnd,
      minDays,
      maxDays,
    );
    setDateRange(newDateRange);
  }
}

// export function defaultOnDrag_Mobile(state, graphWidth, memo) {
//   //return defaultOnDrag_Mobile_Debouced(state, graphWidth, memo);
//   return _.debounce( () => defaultOnDrag_Mobile_Debouced(state, graphWidth, memo), 100);
// }

export const defaultOnDrag_Mobile =
  //_.debounce (
  (state, graphWidth, memo, setDateRange, smooth) => {
    if (state.pinching) {
      return state.cancel();
    }

    if (!graphWidth || !memo) return;

    const originalMemo = state.memo ? state.memo : memo;

    const isHorizontal = state.direction[0] !== 0;
    if (!isHorizontal) return;

    const movementX = state.movement[0];
    const partOfWidth = movementX / graphWidth;

    const daysInRange = memo.dateRange.start.diff(memo.dateRange.end, 'days');
    const deltaDays = daysInRange * partOfWidth;

    //console.log(state);
    // console.log('movementX', movementX, 'graphWidth', graphWidth, 'partOfWidth', partOfWidth, 'deltaDays', deltaDays);

    const deltaDaysRounded = Math.round(deltaDays);

    //const isPositive = deltaDays >= 0;
    const dD = Math.floor(deltaDays);
    const dH = Math.round((deltaDays - dD) * 24);
    const diff = !smooth ? { days: deltaDaysRounded } : { days: dD, hours: dH };

    if ((smooth && deltaDays !== 0) || (!smooth && deltaDaysRounded !== 0)) {
      const newDateRange = {
        start: originalMemo.dateRange.start.clone().add(diff),
        end: originalMemo.dateRange.end.clone().add(diff),
        type: originalMemo.dateRange.type,
      };
      const newDateRangeFixed = fixRange(newDateRange, originalMemo.maxRange);
      // console.log(newDateRangeFixed);
      setDateRange(newDateRangeFixed);
    }

    return originalMemo;
  }; //, 10);

export function defaultOnDragEnd_Mobile(state, dateRange, maxRange, setDateRange) {
  //console.log(state);
  const dx = state.movement[0];
  const dy = state.movement[1];
  const swipeX = state.swipe[0];
  const isHorizontal = Math.abs(dx) > Math.abs(dy);

  if (isHorizontal && swipeX !== 0) {
    const newDateRange =
      swipeX > 0
        ? calcNewDateRangeAfterMoveLeft(dateRange, maxRange)
        : calcNewDateRangeAfterMoveRight(dateRange, maxRange);
    // console.log('DragMobile', dateRange, newDateRange);
    setDateRange(newDateRange);
    return;
  }
}

export function fixDateRangeInBounds(dateRange, maxRange, setter) {
  let newRange;
  if (dateRange.start.unix() < maxRange.start.unix()) {
    newRange = {
      start: maxRange.start.clone(),
      end: maxRange.start.clone().add(dateRange.end.diff(dateRange.start, 'days'), 'days'),
    };
  }
  if (dateRange.end.unix() > maxRange.end.unix()) {
    newRange = {
      start: maxRange.end.clone().add(-dateRange.end.diff(dateRange.start, 'days'), 'days'),
      end: maxRange.end.clone(),
    };
  }
  if (dateRange.end.diff(dateRange.start, 'days') > maxRange.end.diff(maxRange.start, 'days')) {
    newRange = {
      start: maxRange.start.clone(),
      end: maxRange.end.clone(),
    };
  }
  if (newRange) setter(newRange);
}

export function fixMaxRange(maxRange, setter) {
  const maxStart = maxRange.end.clone().subtract(14, 'days');
  const minStart = maxRange.end
    .clone()
    .subtract(2, 'years')
    .startOf('year');
  if (maxRange.start.unix() > maxStart.unix()) {
    setter({
      start: maxStart,
      end: maxRange.end,
    });
  }
  if (maxRange.start.unix() < minStart.unix()) {
    setter({
      start: minStart,
      end: maxRange.end,
    });
  }
}
