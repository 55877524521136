import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment, { now } from 'moment-timezone';
import _ from 'lodash';

import DashboardLayout from './DashboardLayout';
import DeviceReadings from './Widgets/DeviceReadings';
import Devices from './Widgets/Devices';
import HubStats, { deviceStatsWidgetTypes, HubStatsPeriod } from './Widgets/HubStats';
import LocationOfYourKits from './Widgets/LocationOfYourKits';
import YourKits from './Widgets/YourKits';
import HubsWithoutReadings from './Widgets/HubsWithoutReadings';
import { actionsDashboard as actions } from './redux/actions';
import { actions as actionsWidgets } from './redux/actionsWidgets';
import { PageHeader } from '../../components/PageHeader';
import { Button } from '../../components/PageHeader/Button';
import Strings from '../../Strings';
import DashboardFilter from './DashboardFilter';
import HubMetrics from './Widgets/HubMetrics';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import DashboardEditModeButton from './DashboardEditModeButton';
import { PERMISSIONS } from '../../utils/userPermissions';
import { openModalAction } from '../../actions/modal';
import { DeviceTypes } from '../../utils/deviceTypes';

const deviceStatsWidgetConfig = {
  widgetType: {
    type: 'select',
    name: 'widgetType',
    label: 'Widget Type',
    defaultValue: deviceStatsWidgetTypes.numbers,
    options: [
      { label: 'Numbers', value: deviceStatsWidgetTypes.numbers },
      { label: 'Bar chart', value: deviceStatsWidgetTypes.barChart },
      { label: 'Pie chart', value: deviceStatsWidgetTypes.pieChart },
      { label: 'Line chart', value: deviceStatsWidgetTypes.lineChart },
      { label: 'Area chart', value: deviceStatsWidgetTypes.areaChart },
    ],
    canBeSetByDashboard: true,
  },
};

export const DASHBOARD_ID = 'DashboardDevices';
const yourKitsMetadata = {
  id: YourKits.widgetId,
  name: Strings.widgets.yourKitsChart,
  permission: PERMISSIONS.KITS_MANAGEMENT,
  defProps: { i: YourKits.widgetId, w: 4, h: 4, minW: 3, minH: 3 },
};
const devieReadingsMetadata = {
  id: DeviceReadings.widgetId,
  name: Strings.widgets.deviceReadingsChart,
  permission: '',
  defProps: { i: DeviceReadings.widgetId, w: 4, h: 4, minW: 3, minH: 3 },
};
const devicesMetadata = {
  id: Devices.widgetId,
  name: Strings.widgets.numberOfDevices,
  permission: '',
  defProps: { i: Devices.widgetId, w: 4, h: 2, minW: 2, minH: 2 },
};
const hubStatsMetadata = {
  id: HubStats.widgetId,
  name: Strings.widgets.numberOfHubsRealTime,
  permission: PERMISSIONS.DEVICE_MANAGEMENT_HUB,
  defProps: { i: HubStats.widgetId, w: 4, h: 2, minW: 2, minH: 4 },
  configuration: deviceStatsWidgetConfig,
};
const kitsLocationMetadata = {
  id: LocationOfYourKits.widgetId,
  name: Strings.widgets.locationsOfYourKits,
  permission: '',
  defProps: { i: LocationOfYourKits.widgetId, w: 6, h: 4, minW: 2, minH: 2 },
};
const hubsWithNoReadingsMetadata = {
  id: HubsWithoutReadings.widgetId,
  name: Strings.widgets.hubsWithoutReadings,
  permission: PERMISSIONS.DEVICE_MANAGEMENT_HUB,
  defProps: { i: HubsWithoutReadings.widgetId, w: 4, h: 2, minW: 2, minH: 2 },
};
const dailyHubStatsMetadata = {
  id: `${HubStats.widgetId}_daily`,
  name: Strings.widgets.numberOfHubsDaily,
  permission: PERMISSIONS.DEVICE_MANAGEMENT_HUB,
  defProps: { i: `${HubStats.widgetId}_daily`, w: 4, h: 2, minW: 2, minH: 4 },
  configuration: deviceStatsWidgetConfig,
};
const monthlyHubStatsMetadata = {
  id: `${HubStats.widgetId}_monthly`,
  name: Strings.widgets.numberOfHubsMonthly,
  permission: PERMISSIONS.DEVICE_MANAGEMENT_HUB,
  defProps: { i: `${HubStats.widgetId}_monthly`, w: 4, h: 2, minW: 2, minH: 4 },
  configuration: deviceStatsWidgetConfig,
};
const monthlyOperatingRateMetadata = {
  id: 'MonthlyOperatingRateWidget',
  name: Strings.widgets.monthlyOperatingRate,
  permission: PERMISSIONS.DEVICE_MANAGEMENT_HUB,
  defProps: { i: 'MonthlyOperatingRateWidget', w: 4, h: 2, minW: 2, minH: 2 },
};
const bleStatsMetadata = {
  id: 'BleStatsWidget',
  name: Strings.widgets.numberOfBleRealTime,
  permission: PERMISSIONS.DEVICE_MANAGEMENT_BLE,
  defProps: { i: 'BleStatsWidget', w: 4, h: 2, minW: 2, minH: 4 },
  configuration: deviceStatsWidgetConfig,
};
const lteStatsMetadata = {
  id: 'LteStatsWidget',
  name: Strings.widgets.numberOfLteRealTime,
  permission: PERMISSIONS.DEVICE_MANAGEMENT_LTE,
  defProps: { i: 'LteStatsWidget', w: 4, h: 2, minW: 2, minH: 4 },
  configuration: deviceStatsWidgetConfig,
};
const lscStatsMetadata = {
  id: 'LscStatsWidget',
  name: Strings.widgets.numberOfLscRealTime,
  permission: PERMISSIONS.DEVICE_MANAGEMENT_LSC,
  defProps: { i: 'LscStatsWidget', w: 4, h: 2, minW: 2, minH: 4 },
  configuration: deviceStatsWidgetConfig,
};

export const WidgetMetadata = [
  hubStatsMetadata,
  dailyHubStatsMetadata,
  monthlyHubStatsMetadata,
  devicesMetadata,
  hubsWithNoReadingsMetadata,
  kitsLocationMetadata,
  monthlyOperatingRateMetadata,
  bleStatsMetadata,
  lteStatsMetadata,
  lscStatsMetadata,
];

const l = [
  { ...hubStatsMetadata.defProps, x: 0, y: 0, w: 4, h: 5 },
  { ...devicesMetadata.defProps, x: 4, y: 6, w: 4, h: 2 },
  { ...hubsWithNoReadingsMetadata.defProps, x: 0, y: 6, w: 4, h: 2 },
  //{ ...yourKitsMetadata.defProps, x: 0, y: 2, w: 5, h: 4 },
  //{ ...devieReadingsMetadata.defProps, x: 5, y: 2, w: 7, h: 4 },
  { ...kitsLocationMetadata.defProps, x: 0, y: 13, w: 12, h: 6 },
  { ...monthlyOperatingRateMetadata.defProps, x: 8, y: 6, w: 4, h: 2 },
  { ...dailyHubStatsMetadata.defProps, x: 4, y: 0, w: 4, h: 5 },
  { ...monthlyHubStatsMetadata.defProps, x: 8, y: 0, w: 4, h: 5 },
  { ...bleStatsMetadata.defProps, x: 0, y: 8, w: 4, h: 5 },
  { ...lteStatsMetadata.defProps, x: 4, y: 8, w: 4, h: 5 },
  { ...lscStatsMetadata.defProps, x: 8, y: 8, w: 4, h: 5 },
];

const m = [
  { ...hubStatsMetadata.defProps, x: 0, y: 0, w: 4, h: 4 },
  { ...devicesMetadata.defProps, x: 4, y: 12, w: 4, h: 2 },
  { ...hubsWithNoReadingsMetadata.defProps, x: 2, y: 12, w: 2, h: 2 },
  //{ ...yourKitsMetadata.defProps, x: 0, y: 0, w: 4, h: 4 },
  //{ ...devieReadingsMetadata.defProps, x: 0, y: 4, w: 5, h: 4 },
  { ...kitsLocationMetadata.defProps, x: 0, y: 15, w: 8, h: 5 },
  { ...monthlyOperatingRateMetadata.defProps, x: 0, y: 12, w: 2, h: 2 },
  { ...dailyHubStatsMetadata.defProps, x: 0, y: 8, w: 4, h: 4 },
  { ...monthlyHubStatsMetadata.defProps, x: 4, y: 8, w: 4, h: 4 },
  { ...bleStatsMetadata.defProps, x: 4, y: 0, w: 4, h: 4 },
  { ...lteStatsMetadata.defProps, x: 0, y: 4, w: 4, h: 4 },
  { ...lscStatsMetadata.defProps, x: 4, y: 4, w: 4, h: 4 },
];

const s = [
  { ...hubStatsMetadata.defProps, x: 0, y: 0, w: 4, h: 4 },
  { ...devicesMetadata.defProps, x: 4, y: 12, w: 4, h: 2 },
  { ...hubsWithNoReadingsMetadata.defProps, x: 2, y: 12, w: 2, h: 2 },
  //{ ...yourKitsMetadata.defProps, x: 0, y: 0, w: 4, h: 4 },
  //{ ...devieReadingsMetadata.defProps, x: 0, y: 4, w: 5, h: 4 },
  { ...kitsLocationMetadata.defProps, x: 0, y: 15, w: 8, h: 5 },
  { ...monthlyOperatingRateMetadata.defProps, x: 0, y: 12, w: 2, h: 2 },
  { ...dailyHubStatsMetadata.defProps, x: 0, y: 8, w: 4, h: 4 },
  { ...monthlyHubStatsMetadata.defProps, x: 4, y: 8, w: 4, h: 4 },
  { ...bleStatsMetadata.defProps, x: 4, y: 0, w: 4, h: 4 },
  { ...lteStatsMetadata.defProps, x: 0, y: 4, w: 4, h: 4 },
  { ...lscStatsMetadata.defProps, x: 4, y: 4, w: 4, h: 4 },
];

export const DefaultLayouts = { l, m, s };

const startDate = moment()
  .tz('UTC')
  .subtract(1, 'month')
  .format();

function DevicesDashboard(props) {
  const [refreshEnable, setRefreshEnable] = useState(true);
  const [widgets, setWidgets] = useState([]);
  const [refreshTimestamp, setRefreshTimestamp] = useState(now());
  const [statsLastMonth, setStatsLastMonth] = useState();
  const [widgetDragging, setWidgetDragging] = useState(false);
  const scrollbarRef = React.useRef();

  useEffect(() => {
    props
      .getActivityStatistics({
        start_time: startDate,
        device_type: DeviceTypes.Datahub.id,
        type: 'monthly',
      })
      .then(resp => {
        setStatsLastMonth(resp.response);
      });
  }, [refreshTimestamp]);

  const onRefresh = () => {
    setRefreshEnable(false);
    setTimeout(() => {
      setRefreshEnable(true);
    }, 10000);
    setRefreshTimestamp(now());
  };

  const calcColorForMubMetric = (value, total, percent = null) => {
    if (!total && !percent) {
      return 'black';
    }
    const p = percent || value / total;
    if (p < 0.25) {
      return 'red';
    }
    if (p > 0.75) {
      return 'green';
    }
    return 'blue';
  };

  const initializeDashboard = () => {
    const widgetYK = {
      ...yourKitsMetadata,
      render: <YourKits refreshTimestamp={refreshTimestamp} />,
    };
    const widgetDR = {
      ...devieReadingsMetadata,
      render: <DeviceReadings refreshTimestamp={refreshTimestamp} />,
    };
    const widgetDE = {
      ...devicesMetadata,
      render: <Devices refreshTimestamp={refreshTimestamp} />,
      noPadding: true,
    };
    const widgetHS = {
      ...hubStatsMetadata,
      render: (
        <HubStats refreshTimestamp={refreshTimestamp} statsPeriod={HubStatsPeriod.realTime} dragging={widgetDragging} />
      ),
      noPadding: true,
    };
    const widgetLO = {
      ...kitsLocationMetadata,
      render: <LocationOfYourKits refreshTimestamp={refreshTimestamp} />,
      noPadding: true,
    };
    const widgetHW = {
      ...hubsWithNoReadingsMetadata,
      render: <HubsWithoutReadings refreshTimestamp={refreshTimestamp} />,
    };

    const widgetACR = {
      ...monthlyOperatingRateMetadata,
      render: (
        <HubMetrics
          refreshTimestamp={refreshTimestamp}
          value={isNaN(statsLastMonth?.operating_rate) ? '-' : `${statsLastMonth?.operating_rate}%`}
          label={Strings.widgets.monthlyOperatingRate}
          color={calcColorForMubMetric(null, null, statsLastMonth?.operating_rate / 100)}
          tooltip={Strings.widgetsTooltips.monthlyOperatingRate}
          dragging={widgetDragging}
        />
      ),
    };
    const widgetHSD = {
      ...dailyHubStatsMetadata,
      render: (
        <HubStats
          refreshTimestamp={refreshTimestamp}
          statsPeriod={HubStatsPeriod.yesterday}
          dragging={widgetDragging}
        />
      ),
      noPadding: true,
    };
    const widgetHSM = {
      ...monthlyHubStatsMetadata,
      render: (
        <HubStats refreshTimestamp={refreshTimestamp} statsPeriod={HubStatsPeriod.monthly} dragging={widgetDragging} />
      ),
      noPadding: true,
    };

    const widgetBS = {
      ...bleStatsMetadata,
      render: (
        <HubStats
          refreshTimestamp={refreshTimestamp}
          statsPeriod={HubStatsPeriod.realTime}
          deviceType={DeviceTypes['Bluetooth Cap']}
          dragging={widgetDragging}
        />
      ),
      noPadding: true,
    };
    const widgetLteS = {
      ...lteStatsMetadata,
      render: (
        <HubStats
          refreshTimestamp={refreshTimestamp}
          statsPeriod={HubStatsPeriod.realTime}
          deviceType={DeviceTypes['LTE Pillbox']}
          dragging={widgetDragging}
        />
      ),
      noPadding: true,
    };
    const widgetLscS = {
      ...lscStatsMetadata,
      render: (
        <HubStats
          refreshTimestamp={refreshTimestamp}
          statsPeriod={HubStatsPeriod.realTime}
          deviceType={DeviceTypes['LTE Smart Cap']}
          dragging={widgetDragging}
        />
      ),
      noPadding: true,
    };

    const w = [
      widgetHS,
      widgetDE,
      widgetHW,
      widgetLO,
      widgetACR,
      widgetHSD,
      widgetHSM,
      widgetBS,
      widgetLteS,
      widgetLscS,
    ];
    setWidgets(w);
  };

  useEffect(() => {
    initializeDashboard();
  }, [refreshTimestamp, statsLastMonth, widgetDragging]);

  return (
    <React.Fragment>
      <PageHeader
        isBlack
        left={`${Strings.welcome} ${props.userName}`}
        right={
          <React.Fragment>
            <DashboardEditModeButton dashboardId={DASHBOARD_ID} />
            {props.dashboardSettingsFeature && (
              <div className="settings-button">
                <Button class="settings-button-icon" onClick={props.configDashboard}></Button>
              </div>
            )}
            <DashboardFilter dashboardId={DASHBOARD_ID} />
            {/* <Button class="download" onClick={this.onDownload} /> */}
            <Button class="refresh" disabled={!refreshEnable} onClick={onRefresh} />
          </React.Fragment>
        }
      />
      {widgets.length > 0 && !_.isEmpty(DefaultLayouts) && (
        <OverlayScrollbarsComponent
          defer
          className="scrollbar-right-margin"
          options={{ scrollbars: { autoHide: 'leave', autoHideDelay: '100' } }}
          ref={scrollbarRef}
        >
          <DashboardLayout
            dashboardId={DASHBOARD_ID}
            widgets={widgets}
            defaultLayouts={DefaultLayouts}
            rowHeight={65}
            onDragStart={() => setWidgetDragging(true)}
            onDragStop={() => setWidgetDragging(false)}
            scrollbarRef={scrollbarRef}
          />
        </OverlayScrollbarsComponent>
      )}
    </React.Fragment>
  );
}

DevicesDashboard.propTypes = {
  userName: PropTypes.string,
  timezone: PropTypes.string,
  filters: PropTypes.array,
  initializeDashboard: PropTypes.func,
  getActivityStatistics: PropTypes.func,
};

const mapStateToProps = state => {
  const { profile } = state.auth;
  const { dashboardFilters } = state.dashboardState;
  const filters = dashboardFilters[DASHBOARD_ID];
  const labFeatures = state.labState.features;
  return {
    userName: profile ? (profile.first_name ? `${profile.first_name} ${profile.last_name}` : profile.username) : '',
    timezone: state.auth?.profile?.preferences?.timezone,
    filters,
    dashboardSettingsFeature: labFeatures.find(f => f.name === 'Dashboard configuration')?.selected,
  };
};

const mapDispatchToProps = dispatch => ({
  initializeDashboard: (dashboardId, defaultLayouts, widgets, force) =>
    dispatch(actions.initializeDashboard(dashboardId, defaultLayouts, widgets, force)),
  getActivityStatistics: request => dispatch(actionsWidgets.getActivityStatistics(request)),
  configDashboard: () =>
    dispatch(openModalAction('dashboard-configuration', { dashboardId: DASHBOARD_ID, additionalData: true })),
});

export default connect(mapStateToProps, mapDispatchToProps)(DevicesDashboard);
