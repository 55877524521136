import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import Strings from '../../../Strings';
import TableWithLocalPagination, { Column } from '../../../containers/Table/TableWithLocalPagination';
import { DATE_MONTH_DAY_YEAR, TIME_FORMAT_12_UPPERCASE } from '../../../constants';
import { actions } from '../../ShipperCaps/redux/actions';
import { PageHeader } from '../../../components/PageHeader';
import { Button, HEADER_BUTTON_DARK_BLUE } from '../../../components/PageHeader/Button';
import { CHANGE_DEVICE_STATUS_HISTORY } from '../../ShipperCaps/redux/constants';
import { openModalAction } from '../../../actions/modal';
import { StateTypes } from '../../../modals/ChangeStateModal';

function DeviceStatusHistory(props) {
  const [history, setHistory] = React.useState([]);
  const deviceId = props.match.params.deviceId || props.match.params.id;

  React.useEffect(() => {
    getData();
  }, [deviceId]);

  const getData = () => {
    props.getDeviceStatusHistory(deviceId).then(resp => {
      if (resp?.response) {
        setHistory(resp.response);
      }
    });
  };

  const onStatusChange = () => {
    const data = {
      id: deviceId,
      propertyForApi: 'status',
      action: actions.changeDeviceStatus,
      actionType: CHANGE_DEVICE_STATUS_HISTORY,
      title: Strings.changeDeviceState,
      state: StateTypes.device.id,
      onSuccess: getData,
    };
    props.changeDeviceStatus(data);
  };

  return (
    <React.Fragment>
      <PageHeader
        left={Strings.deviceStatusHistory}
        right={<Button onClick={onStatusChange} class={HEADER_BUTTON_DARK_BLUE} title={Strings.changeDeviceState} />}
      />
      <TableWithLocalPagination
        name="device-status-history"
        data={history}
        columns={[
          <Column
            key="change_date"
            title={Strings.date}
            value={e => {
              return e.change_time ? moment(e.change_time).format(DATE_MONTH_DAY_YEAR) : '-';
            }}
          />,
          <Column
            key="change_time"
            title={Strings.time}
            value={e => {
              return e.change_time ? moment(e.change_time).format(TIME_FORMAT_12_UPPERCASE) : '-';
            }}
          />,
          <Column key="old_status" title={Strings.oldStatus} value={e => e.old_status || '-'} />,
          <Column key="new_status" title={Strings.newStatus} value={e => e.new_status || '-'} />,
          <Column key="change_note" title={Strings.note} value={e => e.change_note || '-'} />,
          <Column
            key="valid_until"
            title={Strings.validUntil}
            value={e => (e.valid_until ? moment(e.valid_until).format(DATE_MONTH_DAY_YEAR) : '-')}
          />,
        ]}
      />
    </React.Fragment>
  );
}

DeviceStatusHistory.propTypes = {
  activeTab: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
  getDeviceStatusHistory: id => dispatch(actions.getDeviceStatusHistory(id)),
  changeDeviceStatus: data => dispatch(openModalAction('change-state', data)),
});

export default connect(null, mapDispatchToProps)(DeviceStatusHistory);
