import PropTypes from 'prop-types';
import moment from 'moment-timezone/moment-timezone';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Select from '../../../components/Select';
import { actions } from '../Organization/redux/actions';
import { HUB_DEVICES_TYPES_RESULT } from '../Organization/redux/constants';
import {
  BLOOD_PRESSURE,
  WEIGHT,
  CONNECTION,
  BATTERY,
  SLEEP_REPORT,
  HEART_RATE,
  MOVEMENT_REPORT,
  STEPS_REPORT,
  SPIROMETER,
  PULSEOX,
  AIR_QUALITY_MONITOR,
  HAILIE_INHALER,
  PTINR_MONITOR,
} from './constant';
import './HubDeviceEvents/HubDeviceEvents.scss';
import SleepReport from './HubDeviceEvents/Graph/SleepReport/SleepReport';
import StepsReport from './HubDeviceEvents/Graph/StepsReport/StepsReport';
import MovementReport from './HubDeviceEvents/Graph/MovementReport/MovementReport';
import BatteryConnectionTable from './HubDeviceEvents/Tables/BatteryConnectionTable';
import Spirometry from './HubDeviceEvents/Graph/Spirometry/Spirometry';
import HailieInhalerTable from './HubDeviceEvents/Tables/HailieInhalerTable';
import CompositeGraph from '../../../components/Graphs/CompositeGraph/CompositeGraph';
import { PageHeader } from '../../../components/PageHeader';
import { graphTypeEnum } from '../../../components/Graphs/CompositeGraph/GraphTypes';
import { DeviceClass, DeviceTypes } from '../../../utils/deviceTypes';

class HubDeviceEvents extends PureComponent {
  constructor(props) {
    super(props);

    this.deviceType = props.match.params.deviceType;
    this.deviceId = { deviceId: props.match.params.deviceId };

    this.state = {
      selectedEventType: undefined,
      plotStartDate: props.lastActivity ? 
          moment(props.lastActivity * 1000)
          .subtract(4, 'months')
          .startOf('month')
          .startOf('day')
          .format()
        : moment()
          .subtract(4, 'months')
          .startOf('month')
          .startOf('day')
          .format(),
      plotEndDate: props.lastActivity ?
        moment(props.lastActivity * 1000)
          .add(1, 'days')
          .startOf('day')
          .format()
        : moment()
          .add(1, 'days')
          .startOf('day')
          .format(),
    };
  }

  componentDidMount() {
    if (this.deviceType === DeviceTypes.Spirometer.id) {
      this.setState({ selectedEventType: SPIROMETER });
      return;
    }
    if (this.deviceType === DeviceTypes['Hailie Inhaler'].id) {
      this.setState({ selectedEventType: HAILIE_INHALER });
      return;
    }
    this.props.loadDeviceTypes(this.deviceId).then(response => {
      if (response.type === HUB_DEVICES_TYPES_RESULT) {
        this.setState({ selectedEventType: response.response.data[0].type });
      }
    });
  }

  componentWillUnmount() {
    this.props.clearData();
  }

  onEventTypeChange = option => {
    this.setState({ selectedEventType: option.value });
  };

  getContent = () => {
    const { selectedEventType, plotStartDate, plotEndDate } = this.state;
    switch (selectedEventType) {
      case SLEEP_REPORT:
        return (
          <SleepReport
            patientId={this.props.patientId}
            deviceId={this.deviceId.deviceId}
            hubId={this.props.hubId}
            kitId={this.props.kitId}
            startDate={plotStartDate}
            endDate={plotEndDate}
          />
        );
      case HEART_RATE:
        return (
          <CompositeGraph
            patientId={this.props.patientId}
            hubId={this.props.hubId}
            kitId={this.props.kitId}
            deviceId={this.deviceId.deviceId}
            graphType={graphTypeEnum.hr}
            hasBorder
          />
        );
      case BLOOD_PRESSURE:
        return (
          <CompositeGraph
            patientId={this.props.patientId}
            hubId={this.props.hubId}
            kitId={this.props.kitId}
            deviceId={this.deviceId.deviceId}
            graphType={graphTypeEnum.bp}
            hasBorder
          />
        );
      case WEIGHT:
        return (
          <CompositeGraph
            patientId={this.props.patientId}
            hubId={this.props.hubId}
            kitId={this.props.kitId}
            deviceId={this.deviceId.deviceId}
            graphType={graphTypeEnum.weight}
            hasBorder
          />
        );
      case 'glucometer':
        return (
          <CompositeGraph
            patientId={this.props.patientId}
            hubId={this.props.hubId}
            kitId={this.props.kitId}
            deviceId={this.deviceId.deviceId}
            graphType={graphTypeEnum.glucose}
            hasBorder
          />
        );
      case 'thermometer':
        return (
          <CompositeGraph
            patientId={this.props.patientId}
            hubId={this.props.hubId}
            kitId={this.props.kitId}
            deviceId={this.deviceId.deviceId}
            graphType={graphTypeEnum.temperature}
            hasBorder
          />
        );
      case STEPS_REPORT:
        return (
          <StepsReport
            patientId={this.props.patientId}
            deviceId={this.deviceId.deviceId}
            hubId={this.props.hubId}
            kitId={this.props.kitId}
            startDate={plotStartDate}
            endDate={plotEndDate}
          />
        );
      case MOVEMENT_REPORT:
        return (
          <MovementReport
            patientId={this.props.patientId}
            deviceId={this.deviceId.deviceId}
            hubId={this.props.hubId}
            kitId={this.props.kitId}
            startDate={plotStartDate}
            endDate={plotEndDate}
          />
        );
      case SPIROMETER:
        return (
          <Spirometry
            patientId={this.props.patientId}
            deviceId={this.deviceId.deviceId}
            hubId={this.props.hubId}
            kitId={this.props.kitId}
            startDate={plotStartDate}
            endDate={plotEndDate}
          />
        );
      case PULSEOX:
        return (
          <CompositeGraph
            patientId={this.props.patientId}
            hubId={this.props.hubId}
            kitId={this.props.kitId}
            deviceId={this.deviceId.deviceId}
            graphType={graphTypeEnum.pulseOx}
            hasBorder
          />
        );
      case CONNECTION:
      case BATTERY:
        return (
          <React.Fragment>
            <BatteryConnectionTable
              patientId={this.props.patientId}
              deviceId={this.deviceId.deviceId}
              hubId={this.props.hubId}
              kitId={this.props.kitId}
              startDate={plotStartDate}
              endDate={plotEndDate}
              eventType={selectedEventType}
            />
          </React.Fragment>
        );
      case AIR_QUALITY_MONITOR:
        return (
          <CompositeGraph
            patientId={this.props.patientId}
            hubId={this.props.hubId}
            kitId={this.props.kitId}
            deviceId={this.deviceId.deviceId}
            graphType={graphTypeEnum.airQuality}
            hasBorder
          />
        );
      case HAILIE_INHALER:
        return (
          <HailieInhalerTable
            patientId={this.props.patientId}
            deviceId={this.deviceId.deviceId}
            hubId={this.props.hubId}
            kitId={this.props.kitId}
            startDate={plotStartDate}
            endDate={plotEndDate}
          />
        );
      case PTINR_MONITOR:
        return (
          <CompositeGraph
            patientId={this.props.patientId}
            hubId={this.props.hubId}
            kitId={this.props.kitId}
            deviceId={this.deviceId.deviceId}
            graphType={graphTypeEnum.coagulation}
            hasBorder
          />
        );
      default:
        return <p>Not supported</p>;
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.state.selectedEventType !== SPIROMETER && this.state.selectedEventType !== HAILIE_INHALER && (
          <PageHeader
            right={
              <React.Fragment>
                <Select
                  data={this.props.eventTypes}
                  onChange={this.onEventTypeChange}
                  value={this.state.selectedEventType}
                />
              </React.Fragment>
            }
          />
        )}
        <div className="details-container">{this.getContent()}</div>
      </React.Fragment>
    );
  }
}

HubDeviceEvents.propTypes = {
  clearData: PropTypes.func,
  eventTypes: PropTypes.array,
  loadDeviceTypes: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      deviceId: PropTypes.string,
      deviceType: PropTypes.any,
      id: PropTypes.any,
      model: PropTypes.string,
      manufacturer: PropTypes.string,
    }),
    url: PropTypes.string,
  }),
  details: PropTypes.any,
  showBackButton: PropTypes.bool,
};

HubDeviceEvents.defaultProps = { showBackButton: true };

const mapStateToProps = state => {
  const { organizations } = state.superUser;

  const eventTypes = [];
  if (organizations && organizations?.deviceTypes) {
    organizations.deviceTypes.forEach(et => {
      eventTypes.push({
        value: et.type,
        label: et.value,
      });
    });
  }

  const urlParams = new URLSearchParams(window.location.search);

  return {
    eventTypes,
    details: state.shipper.capsDetails.data,
    lastActivity: urlParams.get('lastActivity'),
    patientId: urlParams.get('patientId'),
    hubId: urlParams.get('hubId'),
    kitId: urlParams.get('kitId'),
  };
};

const mapDispatchToProps = dispatch => ({
  loadDeviceTypes: pageRequest => dispatch(actions.getDeviceTypes(pageRequest)),
  clearData: () => dispatch(actions.clearData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HubDeviceEvents);

export const getUrlForDevice = (device, deviceClass) => {
  const hubId = device.hub_id ? device.hub_id : device.hubs?.length > 0 ? device.hubs[0] : '-';
  const deviceId = device.deviceId || device.device_id;
  let manufacturer = device.manufacturer;
  let model = device.model;
  if (model == '') {
    if (manufacturer.startsWith('gg-rpm')) {
      model = manufacturer.replace('gg-rpm ', '');
      manufacturer = 'gg-rpm';
    }
  }

  if (
    DeviceTypes[deviceClass].deviceClass === DeviceClass.hub ||
    DeviceTypes[deviceClass].deviceClass === DeviceClass.cap ||
    DeviceTypes[deviceClass].deviceClass === DeviceClass.ble
  ) {
    return `/devices/${DeviceTypes[deviceClass].id}/${deviceId}`;
  }
  if (DeviceTypes[deviceClass].deviceClass === DeviceClass.thirdParty) {
    return `/devices/${DeviceTypes[deviceClass].id}/${hubId}/devices/${deviceId}/${manufacturer}/${model}/events`;
  }
  return `/device-under-construction`;
};
