import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import TabView, { Tab } from '../../../components/TabView';
import './Devices.scss';
import ProvisioningTab from './ProvisioningTab';

import { getActiveTabNameFromUrl } from '../../../utils';
import { hasPermission } from '../../../utils/userPermissions';
import { DeviceClass, DeviceTypes } from '../../../utils/deviceTypes';

const mapStateToProps = state => {
  const { profile } = state.auth;
  const access = profile?.access;
  return { access };
};

const mapDispatchToProps = dispatch => ({ navigate: path => dispatch(push(path)) });

export const root = 'provisioning';

function Provisionings(props) {
  const activeTab = props.match.params.tab;
  const tabArray = [];

  Object.entries(DeviceTypes)
    .filter(([key, value]) => value.deviceClass === DeviceClass.cap || value.deviceClass === DeviceClass.hub)
    .forEach(([key, deviceType]) => {
      if (hasPermission(deviceType.permission)) {
        tabArray.push(
          <Tab name={deviceType.displayNamePlural} path={`/${root}/${key}`} key={key}>
            <ProvisioningTab type={key} />
          </Tab>,
        );
      }
    });

  return (
    <TabView
      key="tabs"
      activeTab={getActiveTabNameFromUrl(
        activeTab,
        tabArray.map(t => {
          return { urlId: t.key, DisplayName: t.props.name };
        }),
      )}
      routeMode
    >
      {tabArray}
    </TabView>
  );
}

Provisionings.propTypes = { activeTab: PropTypes.any };

export default connect(mapStateToProps, mapDispatchToProps)(Provisionings);
