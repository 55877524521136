import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _, { cloneDeep } from 'lodash';
import { closeModal } from 'actions/modal';

import Wizard from '../../containers/Modal/Wizard';
import { Errors } from '../../components/Login/Errors/Errors';
import Strings from '../../Strings';
import { actions } from '../../pages/SuperUser/Patients/redux/actions';
import { actions as cohortActions } from '../../pages/SuperUser/Patients/Cohorts/redux/actions';
import {
  lOAD_PATIENTS_RESULT,
  PATIENT_LIST_FOR_SUGGESTION_RESULT,
} from '../../pages/SuperUser/Patients/redux/constants';
import {
  ASSIGN_PATIENT_TO_COHORT_RESULT,
  ASSIGN_PATIENT_TO_COHORT_ERROR,
  UNASSIGN_PATIENT_FROM_COHORT_ERROR,
  UNASSIGN_PATIENT_FROM_COHORT_RESULT,
} from '../../pages/SuperUser/Patients/Cohorts/redux/constants';
import TableWithPagination, { Column } from '../../containers/Table/TableWithPagination';
import { makeValid } from '../../utils';
import { notificationActions } from '../../components/Notification/redux/actions';
import './EditCohortModal.scss';
import LoadingRenderer from '../../components/LoadingRenderer';
import { Input, SelectField } from '../../containers/Form';
import { EditCohortModalPages } from '../_ModalsMetadata/ModalsMetadata';
import { DOCTOR } from '../../utils/userRoles';
import userActions from '../../actions/user';

const patientStatus = {
  assigned: Strings.capPatient.patientStatusInCohort.assigned,
  toAssign: Strings.capPatient.patientStatusInCohort.toAssign,
  toRemove: Strings.capPatient.patientStatusInCohort.toRemove,
  nothingToDo: Strings.capPatient.patientStatusInCohort.nothingToDo,
  successAdd: Strings.capPatient.patientStatusInCohort.successAdd,
  successRemove: Strings.capPatient.patientStatusInCohort.successRemove,
  error: Strings.capPatient.patientStatusInCohort.error,
};

const EditCohortModal = props => {
  const [cohort, setCohort] = useState(props.data && props.data.cohort ? props.data.cohort : {});
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [patients, setPatients] = useState([]);
  const [patientsAdded, setPatientsAdded] = useState([]);
  const [patientsRemoved, setPatientsRemoved] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [cohortAdmins, setCohortAdmins] = useState([]);
  const [saveClicked, setSaveClicked] = useState(false);
  const [doneAddingPatients, setDoneAddingPatients] = useState(false);
  const [doneRemovingPatients, setDoneRemovingPatients] = useState(false);
  const [highlightInvalidFields, setHighlightMissingFields] = useState(false);

  useEffect(() => {
    setLoading(true);
    props.getFacilities();
    props.getConditions();

    const request = {
      offset: 0,
      search: '',
      limit: 1000,
      orgId: props.organizationId,
      role: DOCTOR,
    };
    props.getAdmins(request).then(resp => {
      if (resp && resp.response.data) {
        setAdmins(resp.response.data);
      }
    });
  }, []);

  useEffect(() => {
    let cohortPatients = [];
    let patientsForSugestion = [];

    const pageRequest = {
      offset: 0,
      search: '',
      cohortId: '',
    };
    if (cohort.facility_id) {
      pageRequest.facilityId = cohort.facility_id;
    } else {
      delete pageRequest.facilityId;
    }
    if (cohort.condition_id) {
      pageRequest.conditionId = cohort.condition_id;
    } else {
      delete pageRequest.conditionId;
    }
    props.getPatientsForSuggestion(pageRequest).then(resp => {
      if (resp.type !== PATIENT_LIST_FOR_SUGGESTION_RESULT) return resp;
      if (resp.response.data && resp.response.data.length > 0) {
        patientsForSugestion = resp.response.data;
        patientsForSugestion.forEach((p, i) => {
          patientsForSugestion[i].cohortStatus = patientStatus.nothingToDo;
        });
      }
      if (cohort && cohort.id) {
        props.getPatients({ cohortId: cohort.id }).then(resp1 => {
          if (resp1.type !== lOAD_PATIENTS_RESULT) return resp1;
          if (resp1.response.data && resp1.response.data.length > 0) {
            cohortPatients = resp1.response.data;
            cohortPatients.forEach((p, i) => {
              cohortPatients[i].cohortStatus = patientStatus.assigned;
            });
          }
          setPatients(patientsForSugestion.concat(cohortPatients));
          setLoading(false);
        });
      } else {
        setPatients(patientsForSugestion.concat(cohortPatients));
        setLoading(false);
      }
    });

    return () => {
      props.clearPatientsForSuggestion();
    };
  }, [cohort.facility_id, cohort.condition_id]);

  useEffect(() => {
    const adminsTmp = admins.length > 0 ? [...admins] : [];
    if (!adminsTmp.some(a => a.id === cohort.manager_id)) {
      const names = cohort && cohort.manager ? cohort.manager.split(' ') : null;
      if (names) {
        adminsTmp.push({
          id: cohort.manager_id,
          first_name: names.shift(),
          last_name: names.join(' '),
        });
      }
    }
    setCohortAdmins(adminsTmp);
  }, [admins]);

  useEffect(() => {
    const addedCount = patientsAdded.filter(p => p.cohortStatus === patientStatus.successAdd)?.length;
    const patientsAddedText = Strings.formatString(
      Strings.xObjectsAssignedtoY,
      addedCount,
      Strings.patients,
      Strings.cohort,
    );
    const removedCount = patientsRemoved.filter(p => p.cohortStatus === patientStatus.successRemove)?.length;
    const patientsRemovedText = Strings.formatString(
      Strings.xObjectsUnassignedfromY,
      removedCount,
      Strings.patients,
      Strings.cohort,
    );

    if (doneAddingPatients && doneRemovingPatients) {
      setPatients(pts =>
        pts.map(p => {
          const added = patientsAdded.find(pa => pa.id === p.id);
          const removed = patientsRemoved.find(pa => pa.id === p.id);
          if (added) {
            return { ...p, cohortStatus: added.cohortStatus, error: added.error };
          }
          if (removed) {
            return { ...p, cohortStatus: removed.cohortStatus, error: removed.error };
          }
          return p;
        }),
      );
      if (
        !patientsAdded.some(p => p.cohortStatus === patientStatus.error) &&
        !patientsRemoved.some(p => p.cohortStatus === patientStatus.error)
      ) {
        if (addedCount > 0 || removedCount > 0) {
          props.showNotification(
            `${addedCount > 0 ? `${patientsAddedText}.` : ''} ${removedCount > 0 ? patientsRemovedText : ''}`,
          );
        }
        props.closeModalWithNextAction();
      } else if (props.data.nextAction) {
        props.data.nextAction();
      }
    }
  }, [doneAddingPatients, doneRemovingPatients]);

  const onTextChange = event => {
    if (event.target.value != ' ') {
      const newCohort = cloneDeep(cohort);
      newCohort[event.target.getAttribute('name')] = event.target.value;
      setCohort(newCohort);
    }
  };

  const onFacilityChange = option => {
    const newCohort = cloneDeep(cohort);
    if (option) {
      newCohort.facility_id = option.value;
      setCohort(newCohort);
    }
  };

  const onConditionChange = option => {
    const newCohort = cloneDeep(cohort);
    if (option) {
      newCohort.condition_id = option.value;
      setCohort(newCohort);
    }
  };

  const onManagerChange = option => {
    const newCohort = cloneDeep(cohort);
    if (option) {
      newCohort.manager_id = option.value;
      newCohort.manager = option.label;
    }
    setCohort(newCohort);
  };

  const onSubmit = async () => {
    if (saveClicked) return;
    setSaveClicked(true);
    let cohortId = cohort.id;

    if (!_.isEqual(cohort, props.data?.cohort)) {
      await props.onSubmit(cohort).then(response => {
        if (response && response.type === `${props.data.actionType}/result`) {
          props.showNotification(Strings.formatString(Strings.itemSaved, Strings.cohort));
          if (response.response?.id) {
            const newCohort = cloneDeep(cohort);
            newCohort.id = response.response.id;
            cohortId = response.response.id;
            setCohort(newCohort);
          }
        }
        if (response && response.type === `${props.data.actionType}/error`) {
          if (response.response && response.response.data) {
            if (response.response.data.error.message) {
              props.showNotification(response.response.data.error.message, 5000, true);
            }
          }
        }
        setSaveClicked(false);
        return response;
      });
    }

    addPatients(cohortId);
  };

  const addPatients = cohortId => {
    const added = [];
    const patientsToAdd = patients.filter(p => p.cohortStatus === patientStatus.toAssign);
    const removed = [];
    const patientsToRemove = patients.filter(p => p.cohortStatus === patientStatus.toRemove);

    if (patientsToAdd.length === 0) {
      setDoneAddingPatients(true);
    }
    patientsToAdd.forEach(p => {
      props.assignPatientToCohort(p.id, cohortId).then(response => {
        if (response && response.type === ASSIGN_PATIENT_TO_COHORT_ERROR) {
          added.push({ ...p, cohortStatus: patientStatus.error, error: response.response?.data?.error?.message });
        }
        if (response && response.type === ASSIGN_PATIENT_TO_COHORT_RESULT) {
          added.push({ ...p, cohortStatus: patientStatus.successAdd });
        }
        if (added.length === patientsToAdd.length) {
          setPatientsAdded(added);
          setDoneAddingPatients(true);
        }
        return response;
      });
    });

    if (patientsToRemove.length === 0) {
      setDoneRemovingPatients(true);
    }
    patientsToRemove.forEach(p => {
      props.unassignPatientFromCohort(p.id, cohort.id).then(response => {
        if (response && response.type === UNASSIGN_PATIENT_FROM_COHORT_ERROR) {
          removed.push({ ...p, cohortStatus: patientStatus.error, error: response.response?.data?.error?.message });
        }
        if (response && response.type === UNASSIGN_PATIENT_FROM_COHORT_RESULT) {
          removed.push({ ...p, cohortStatus: patientStatus.successRemove });
        }
        if (removed.length === patientsToRemove.length) {
          setPatientsRemoved(removed);
          setDoneRemovingPatients(true);
        }
        return response;
      });
    });
  };

  const itemChecked = ({ target }, patient) => {
    let newStatus = '';

    if (target.checked === true) {
      if (patient.cohortStatus === patientStatus.nothingToDo) newStatus = patientStatus.toAssign;
      if (patient.cohortStatus === patientStatus.toRemove) newStatus = patientStatus.assigned;
    } else {
      if (patient.cohortStatus === patientStatus.toAssign) newStatus = patientStatus.nothingToDo;
      if (patient.cohortStatus === patientStatus.assigned) newStatus = patientStatus.toRemove;
    }

    setPatients(pts =>
      pts.map(p => {
        if (p.id === patient.id) {
          return { ...p, cohortStatus: newStatus };
        }
        return p;
      }),
    );
  };

  const getStatusValue = patient => {
    if (patient.error) {
      return <div className="patient-error-cell">{patient.error}</div>;
    }
    switch (patient.cohortStatus) {
      case patientStatus.toRemove:
        return <div className="patient-error-cell">{patient.cohortStatus}</div>;
      case patientStatus.successAdd:
      case patientStatus.successRemove:
      case patientStatus.toAssign:
        return <div className="patient-added-cell">{patient.cohortStatus}</div>;
      default:
        return patient.cohortStatus;
    }
  };

  const getColumns = () => {
    const columns = [];
    columns.push(
      <Column
        key="mrn"
        sortKey="mrn"
        title={Strings.capPatient.patientId}
        value={e => (
          <React.Fragment>
            <div className="cell-with-select">
              <div className="selector">
                <input
                  key={`checkbox_${e.id}`}
                  type="checkbox"
                  className="item-checkbox"
                  id={e.id}
                  onChange={evt => itemChecked(evt, e)}
                  checked={e.cohortStatus === patientStatus.toAssign || e.cohortStatus === patientStatus.assigned}
                />
              </div>
              <div className="selector-label">{e.mrn}</div>
            </div>
          </React.Fragment>
        )}
      />,
    );
    columns.push(
      <Column
        key="patientName"
        sortKey="patientName"
        title={Strings.capPatient.patientName}
        value={d => makeValid(`${d.firstName} ${d.lastName}`)}
      />,
    );
    columns.push(<Column key="status" title="Status" value={d => getStatusValue(d)} />);

    return columns;
  };

  const checkForNewPatients = () => {
    return !patients.some(
      p => p.cohortStatus === patientStatus.nothingToDo || p.cohortStatus === patientStatus.toAssign,
    );
  };

  const cohortDataPage = (
    <React.Fragment>
      <Input
        name="title"
        id="title"
        label={Strings.cohortName}
        className="dialog-form"
        type="text"
        value={cohort.title}
        onChange={onTextChange}
        isRequired
        highlightInvalid={highlightInvalidFields}
      />
      {cohortAdmins && cohortAdmins.length > 0 && (
        <SelectField
          name="manager"
          id="manager"
          label={Strings.primaryCohortManager}
          onChange={onManagerChange}
          value={cohort.manager_id}
          data={cohortAdmins.map(f => {
            return { label: `${f.first_name} ${f.last_name}`, value: f.id };
          })}
          placeholder={Strings.selectManager}
        />
      )}
      {props.conditions && props.conditions.length > 0 && (
        <SelectField
          name="condition"
          id="condition"
          label={Strings.capPatient.primaryCondition}
          onChange={onConditionChange}
          value={cohort.condition_id}
          data={props.conditions?.map(f => {
            return { label: f.title, value: f.id };
          })}
          placeholder={Strings.selectCondition}
        />
      )}
      {props.facilities && props.facilities.length > 0 && (
        <SelectField
          name="facility"
          id="facility"
          label={Strings.facility}
          onChange={onFacilityChange}
          value={cohort.facility_id}
          data={props.facilities?.map(f => {
            return { label: f.title, value: f.id };
          })}
          placeholder={Strings.selectFacility}
        />
      )}
    </React.Fragment>
  );

  const patientsPage = (
    <div className="cohort-patient-page">
      {patients && patients.length > 0 && (
        <TableWithPagination name="patientsToAdd" data={patients} infiniteScroll>
          {getColumns()}
        </TableWithPagination>
      )}
      {loading && <LoadingRenderer loading />}
      {checkForNewPatients() === true && !loading && <Errors errors={[Strings.capPatient.noNewPatients]} />}
      {errors && errors.length > 0 && <Errors errors={errors} />}
    </div>
  );

  const pages = [
    {
      id: EditCohortModalPages.data.id,
      title: EditCohortModalPages.data.name,
      content: cohortDataPage,
      emptyFieldsCount: cohort.title ? 0 : 1,
      canGoNext: cohort.title,
    },
    {
      id: EditCohortModalPages.patients.id,
      title: EditCohortModalPages.patients.name,
      content: patientsPage,
      emptyFieldsCount: 0,
      canGoNext: true,
      noScrollbar: true,
    },
  ];

  const getpagesToShow = () => {
    const ret =
      props.data.pages && props.data.pages.length > 0
        ? props.data.pages.map(p => pages.find(pg => pg.id === p.id))
        : pages;

    if (props.data.ignoreFilters || !props.pagesVisibility) {
      return ret;
    }
    return ret.filter(p => props.pagesVisibility?.some(pg => pg.id === p.id && pg.visible));
  };

  return (
    <Wizard
      name="edit-cohort"
      pages={getpagesToShow()}
      onNextButtonHover={e => setHighlightMissingFields(e)}
      onSubmit={onSubmit}
    />
  );
};

EditCohortModal.propTypes = {
  onOpen: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  data: PropTypes.shape({
    cohort: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      manager: PropTypes.string,
      condition: PropTypes.string,
    }),
    action: PropTypes.func,
    nextAction: PropTypes.func,
  }),
  facilities: PropTypes.array,
  assignPatientToCohort: PropTypes.func,
  unassignPatientFromCohort: PropTypes.func,
  admins: PropTypes.array,
  capManagers: PropTypes.array,
  conditions: PropTypes.array,
  getFacilities: PropTypes.func,
  getAdmins: PropTypes.func,
  getPatients: PropTypes.func,
  getConditions: PropTypes.func,
  getPatientsForSuggestion: PropTypes.func,
  clearPatientsForSuggestion: PropTypes.func,
  showNotification: PropTypes.func,
  pagesVisibility: PropTypes.array,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => dispatch(closeModal('edit-cohort')),
  closeModalWithNextAction: () => {
    dispatch(closeModal('edit-cohort'));
    if (ownProps.data.nextAction) dispatch(ownProps.data.nextAction);
  },
  onSubmit: cohort =>
    dispatch(
      ownProps.data.cohort?.id ? ownProps.data.action(ownProps.data.cohort?.id, cohort) : ownProps.data.action(cohort),
    ).then(response => {
      if (response && response.type === `${ownProps.data.actionType}/result`) {
        if (ownProps.data.nextAction) dispatch(ownProps.data.nextAction);
        if (ownProps.data.onSuccess) {
          ownProps.data.onSuccess();
        }
      }
      return response;
    }),
  getFacilities: () => dispatch(cohortActions.getFacilities()),
  assignPatientToCohort: (patientId, cohortId) => dispatch(cohortActions.assignPatientToCohort(patientId, cohortId)),
  unassignPatientFromCohort: (patientId, cohortId) =>
    dispatch(cohortActions.unassignPatientFromCohort(patientId, cohortId)),
  getAdmins: request => dispatch(userActions.list(request)),
  getPatients: pageRequest => dispatch(actions.loadPatients(pageRequest)),
  getConditions: () => dispatch(actions.getConditions()),
  getPatientsForSuggestion: pageRequest => dispatch(actions.getPatientsForSuggestion(pageRequest)),
  clearPatientsForSuggestion: () => dispatch(actions.clearPatientsForSuggestion()),
  showNotification: (message, timeout, isError) => dispatch(notificationActions.show(message, timeout, isError)),
});

const mapStateToProps = state => {
  return {
    facilities: state.superUser.cohorts?.facilities,
    conditions: state.superUser.patients?.conditions,
    pagesVisibility: state.modalsVisibility.pagesVisibility.find(m => m.id === 'edit-cohort')?.pages,
    organizationId: state.auth.organization?.masked_id,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCohortModal);
