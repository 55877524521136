import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { graphContainer } from './graphContainers';
import GenericCategoryGraph, { chartTypeEnum } from '../../../components/Graphs/GenericCategoryGraph';
import { ColorPalette } from '../DashboardColorPalettes';
import { actions } from '../../SuperUser/Patients/redux/actions';
import Strings from '../../../Strings';

function ConditionsDistribution(props) {
  const [data, setData] = React.useState();
  const { colorPalette }  = props;

  React.useEffect(() => {
    let request = {
      offset: 0,
      search: '',
    };
    const conditions = [];
    props.getPatients(request).then(resp => {
      resp.response?.data.forEach(patient => {
        Object.entries(patient.conditions).forEach(([id, value]) => {
          const existingCondition = conditions.find(c => c.id === id);
          if (existingCondition) {
            existingCondition.count += 1;
          } else {
            conditions.push({ id, name: value.title, count: 1 });
          }
        }
      );
    });
    const conditionsSorted = conditions.sort((a, b) => b.count - a.count);
    setData(conditionsSorted.slice(0, 10).concat({ name: 'Other', count: conditionsSorted.slice(10).reduce((acc, c) => acc + c.count, 0) }));
  });
  }, []);

  const dataSeries = [
    {
      name: 'Condition',
      color: colorPalette?.interpolate(0.5),
      selector: item => item.count,
    },
  ];

  return graphContainer(
    <GenericCategoryGraph
      graphData={data}
      hasBorder={true}
      forceCondensed
      xAxisTopPosition={false}
      yAxisWidth={40}
      yAxisUnit=""
      tooltipTitle={Strings.condition}
      dataSeries={dataSeries}
      categorySelector={item => item.name}
      hasReferenceAreas={true}
      graphType={chartTypeEnum.pie}
      colorPalette={colorPalette}
    />,
    'Conditions Distribution',
  );
}

ConditionsDistribution.widgetId = 'ConditionsDistributionWidget';

ConditionsDistribution.propTypes = {
  getProgramStatistics: PropTypes.func,
  onClick: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    colorPalette:
      ColorPalette[state.dashboardState.configuration?.allDashboards?.colorPalette] || Object.values(ColorPalette)[0],
  };
};


const mapDispatchToProps = dispatch => ({
  getPatients: request => dispatch(actions.getPatientsForSuggestion(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConditionsDistribution);
