export const SHIPPER_CAPS_DETAILS = 'shipper-caps-details';
export const SHIPPER_CAPS_DETAILS_RESULT = `${SHIPPER_CAPS_DETAILS}/result`;
export const SHIPPER_CAPS_DETAILS_CLEAR = `${SHIPPER_CAPS_DETAILS}/clear`;


export const GET_FILTER_DATA = 'get-filter-data';
export const GET_FILTER_DATA_RESULT = `${GET_FILTER_DATA}/result`;
export const GET_FILTER_DATA_ERROR = `${GET_FILTER_DATA}/error`;

export const SHIPPER_CAPS_SCHEDULE_DETAILS = 'shipper-caps-schedule-details';
export const SHIPPER_CAPS_SCHEDULE_DETAILS_RESULT = `${SHIPPER_CAPS_SCHEDULE_DETAILS}/result`;
export const SHIPPER_CAPS_SCHEDULE_DETAILS_ERROR = `${SHIPPER_CAPS_SCHEDULE_DETAILS}/error`;

export const SHIPPER_CAPS_PLOT_DATA = 'shipper-caps-plot-data';
export const SHIPPER_CAPS_PLOT_DATA_RESULT = `${SHIPPER_CAPS_PLOT_DATA}/result`;
export const SHIPPER_CAPS_PLOT_DATA_ERROR = `${SHIPPER_CAPS_PLOT_DATA}/error`;
