import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, ReferenceArea, ReferenceLine, ComposedChart } from 'recharts';
import _ from 'lodash';
import moment from 'moment-timezone';
import { connect } from 'react-redux';

import '../graph.scss';
import {
  DEFAULT_ALLOWED_MOBILE_DATE_RANGE_UNITS,
  DEFAULT_DATE_RANGE_DAYS,
  DEFAULT_MOBILE_DATE_RANGE_UNIT,
  getMetricsByReadings,
  graphMetrics,
} from './Metrics';
import { DataProviders } from './DataProviders';
import { RangePicker } from './RangePicker';
import {
  GRAPH_ROW_BK,
  GRAPH_ROW_BK_ALTERNATE,
  MINIMUM_PIXELS_PER_DAY,
  RANGE_PICKER_TOP_MARGIN,
} from '../GraphConstants';
import { PageHeader } from '../../PageHeader';
import Multiselect from '../../Select/Multiselect';
import { actions } from '../../../pages/SuperUser/Patients/redux/actions';
import {
  GET_MEDICATIONS_RESULT,
  PATIENT_DASHBOARD_INFO_RESULT,
} from '../../../pages/SuperUser/Patients/redux/constants';
import { CustomReferenceArea } from '../GraphComponents';
import { calcXAxisParams_Time, calcXAxisParams_TimeMobile, drawXAxis, drawYAxis, drawYAxisTakes } from './Axes';
import { drawTakesTooltip, drawTooltip } from './Tooltips';
import { drawLineSeries } from './DrawMetrics';
import { drawTakes } from './DrawTakes';
import {
  OVERLAY_CHART_HEIGHT,
  X_AXIS_CHART_HEIGHT,
  X_AXIS_CHART_HEIGHT_TINY,
  X_AXIS_PADDING,
  Y_AXIS_LEFT_WIDTH,
  Y_AXIS_RIGHT_WIDTH,
  Y_AXIS_WIDTH_TINY,
} from './Constants';
import { getMetricDataForDateRange } from './DataHelpers';
import CompositeGraphXAxis from './CompositeGraphXAxis';
import { drawWeekRefLines } from './Components';
import Strings from '../../../Strings';
import { GraphLegend, GraphLegendWithToggle } from './Legend';
import { Input } from '../../../containers/Form';
import { PERMISSIONS, hasPermission } from '../../../utils/userPermissions';
import TableWithLocalPagination, { Column } from '../../../containers/Table/TableWithLocalPagination';
import { DATE_MONTH_DAY_YEAR, TIME_FORMAT_12_UPPERCASE } from '../../../constants';
import LoadingRenderer from '../../LoadingRenderer';
import { useGesture } from '@use-gesture/react';
import { isDesktop, isMobile } from 'react-device-detect';
import {
  calcDateRangeAround,
  calcDateRangeCustom,
  defaultOnDrag,
  defaultOnDragEnd,
  defaultOnDragEnd_Mobile,
  defaultOnDrag_Mobile,
  fixDateRangeInBounds,
  fixMaxRange,
  fixRange,
  fixZoom,
  zoomRight,
} from './ZoomHelpers';
import { MobileRangePicker } from './MobileRangePicker';
import { graphTypeEnum, graphTypes } from './GraphTypes';

function CompositeGraph(props) {
  const zoomingEnabled = isDesktop;

  const graphType = graphTypes.find(gt => gt.id === props.graphType);
  const editable = graphType?.editAllowed;
  const withLegend = graphType?.withLegend;
  const withOverlay = hasPermission(PERMISSIONS.PATIENTS_RPM_PATIENT_OVERLAY_PLOTS) && graphType?.withOverlay;
  const tableViewEnabled = graphType?.tableViewEnabled;

  const [dateRange, setDateRange] = useState(
    isMobile
      ? calcDateRangeAround(moment(), graphType.defaultMobileDateRangeType || DEFAULT_MOBILE_DATE_RANGE_UNIT)
      : calcDateRangeCustom(moment(), graphType.defaultDateRangeDays || DEFAULT_DATE_RANGE_DAYS),
  );
  const [maxRange, setMaxRange] = useState({
    start: moment()
      .subtract(4, 'months')
      .startOf('month')
      .startOf('day'),
    end: moment()
      .add(1, 'days')
      .startOf('day'),
  });
  const [selectedMetrics, setSelectedMetrics] = useState(graphType.metrics);
  const [data, setData] = useState({});
  const [meds, setMeds] = useState([]);
  const [selectedMedIds, setSelectedMedIds] = useState([]);
  const [takes, setTakes] = useState({});
  const [metricsWithReadings, setMetricsWithReadings] = useState([]);
  const [showReminders, setShowReminders] = useState(true);
  const [showTable, setShowTable] = useState(false);
  const [loading, setLoading] = useState(false);

  const [zoomAreaLeft, setZoomAreaLeft] = useState(0);
  const [zoomAreaRight, setZoomAreaRight] = useState(0);
  const [graphSize, setGraphSize] = useState({ width: undefined, height: undefined });

  const rangePickerRef = React.useRef();
  const mobileRangePickerRef = React.useRef();
  const dateRangeSet = React.useRef(false);

  const graphContainerRef = React.useCallback(
    node => {
      if (node !== null) {
        setGraphSize({
          width: node.getBoundingClientRect().width,
          height: node.getBoundingClientRect().height,
        });
      }
    },
    [props.plotResizeTimestamp],
  );

  const ReadData = async forceReadAll => {
    setLoading(true);
    let dataRaw = forceReadAll ? {} : { ...data };
    //console.log(selectedMetrics);
    const metricsExt = selectedMetrics.map(m => graphMetrics.find(gm => gm.id === m));
    const dps = metricsExt.map(m => m.dataProvider);
    const dpsDistinct = _.uniq(dps);

    await Promise.all(
      dpsDistinct.map(async dp => {
        const anyDpMetric = graphMetrics.find(m => m.dataProvider === dp).id;
        if (!dataRaw[anyDpMetric] || forceReadAll) {
          const dateRangeForData = await DataProviders.getDateRange(
            dp,
            props.patientId,
            props.deviceId,
            props.hubId,
            props.kitId,
          );
          if (dateRangeForData?.startDate && dateRangeForData?.endDate) {
            const dataPart = await DataProviders.getData(
              dp,
              props.patientId,
              props.deviceId,
              props.hubId,
              props.kitId,
              dateRangeForData?.startDate || maxRange.start,
              dateRangeForData?.endDate || maxRange.end,
            );
            //console.log(dataRaw);
            //console.log("data part:", dataPart);
            dataRaw = { ...dataRaw, ...dataPart };
          } else {
            const dataPart = DataProviders.getEmptyData(dp);
            dataRaw = { ...dataRaw, ...dataPart };
          }
        }
      }),
    );
    // console.log(dataRaw);
    if (selectedMetrics.length > 0) {
      const firstEvent = selectedMetrics.filter(m => dataRaw[m].firstEvent).length > 0 ? selectedMetrics.filter(m => dataRaw[m].firstEvent).map(m => dataRaw[m].firstEvent).reduce((a, b) => (a < b ? a : b)) : moment();
      const lastEvent = selectedMetrics.filter(m => dataRaw[m].lastEvent).length > 0 ?  selectedMetrics.filter(m => dataRaw[m].lastEvent).map(m => dataRaw[m].lastEvent).reduce((a, b) => (a > b ? a : b)) : moment();
      let newMaxRange = {
        start: firstEvent.clone().startOf('day'),
        end: lastEvent
          .clone()
          .add(1, 'days')
          .startOf('day'),
      };
      fixMaxRange(newMaxRange, dt => {
        newMaxRange = dt;
      });
      // console.log('newMaxRange', newMaxRange);
      setMaxRange(newMaxRange);

      // scroll the chart to the point where there is any data
      if (lastEvent) {
        const baseMoment = !dateRangeSet.current ? lastEvent : dateRange.start;
        const newRange = isMobile
          ? calcDateRangeAround(
              baseMoment,
              dateRange.type || graphType.defaultMobileDateRangeType || DEFAULT_MOBILE_DATE_RANGE_UNIT,
              newMaxRange,
            )
          : calcDateRangeCustom(baseMoment, graphType.defaultDateRangeDays || DEFAULT_DATE_RANGE_DAYS, newMaxRange);

        // console.log('calc dateRange START');
        // console.log('lastEvent', lastEvent);

        let currentDateRange = dateRange;

        if (!dateRangeSet.current) {
          // console.log('adjusting dateRange to data', newRange);
          dateRangeSet.current = true;
          setDateRange(newRange);
          currentDateRange = newRange;
        }

        if (!isMobile) {
          fixDateRangeInBounds(
            currentDateRange,
            newMaxRange,
            dt => {
              // console.log('fix', currentDateRange, dt); 
              setDateRange(dt); 
            },
          );
        } else {
          setDateRange(fixRange(currentDateRange, newMaxRange));
        }

        // console.log('calc dateRange END');

      }
    }
    setData(dataRaw);

    let takesRaw = forceReadAll ? {} : { ...takes };
    if (props.patientId) {
      await Promise.all(
        selectedMedIds.map(async medId => {
          if (!takesRaw[medId.toString()]) {
            const med = meds.find(m => m.id === medId);
            const takesPart = await DataProviders.getTakes(props.patientId, med, maxRange.start, maxRange.end);
            takesRaw[medId.toString()] = takesPart;
          }
        }),
      );
    }
    //console.log(takesRaw);
    setTakes(takesRaw);
    setLoading(false);
  };

  useEffect(() => {
    const start = moment()
      .subtract(4, 'months')
      .startOf('month')
      .startOf('day');
    const end = moment()
      .add(1, 'days')
      .startOf('day');

    setMaxRange({
      start,
      end,
    });
  }, []);

  useEffect(() => {
    if (props.patientId) {
      props.getMedications(props.patientId).then(resp => {
        if (resp.type === GET_MEDICATIONS_RESULT) {
          setMeds(resp.response.medications.map(med => ({ ...med, label: med.medicationName, value: med.id })));
        }
      });
      props.getLastReadings(props.patientId).then(resp => {
        if (resp.type === PATIENT_DASHBOARD_INFO_RESULT) {
          const { response } = resp;
          let readings = [];
          if (response?.vitals) {
            Object.entries(response.vitals).forEach(([key, value]) => {
              if (
                value.Timestamp &&
                moment(value.Timestamp) < maxRange.end &&
                moment(value.Timestamp) > maxRange.start
              ) {
                readings = readings.concat(getMetricsByReadings(key));
              }
            });
            const metricsWithReadings = readings.filter(r => !!r);
            setMetricsWithReadings(metricsWithReadings);
            if (!selectedMetrics.length) {
              const metricToSelect = graphType.allowedMetrics.find(m => metricsWithReadings.includes(m.id));
              setSelectedMetrics(metricToSelect ? [metricToSelect?.id] : []);
            }
          }
        }
      });
    } else {
      setMetricsWithReadings(graphTypes.find(gt => gt.id === props.graphType).allowedMetrics.map(m => m.id));
    }
    // if we want metrics to be filtered by patient devices:
    // props.getPatientInfo(props.patientId).then(resp => {
    //   if (resp.type === PATIENT_DASHBOARD_INFO_RESULT) {
    //     const deviceCount = resp.response.devices;
    //     const pageRequest = {
    //       offset: 0,
    //       search: '',
    //       limit: deviceCount,
    //     };
    //     props.getDevices(pageRequest, props.patientId).then(response => {
    //       setPatientDevices(response.response?.data?.map(d => d.deviceType));
    //     });
    //   }
    // });
  }, [props.patientId]);

  useEffect(() => {
    const fetchData = async () => {
      await ReadData(false);
    };
    fetchData();
  }, [selectedMetrics, selectedMedIds]);

  const calcMaxSelectionDays = () => {
    return Math.floor(graphSize.width / MINIMUM_PIXELS_PER_DAY);
  };
  const calcMinSelectionDays = () => {
    return 1;
  };

  const calcValMinMax2 = items => {
    return {
      min: Math.min(...items),
      max: Math.max(...items),
    };
  };
  const calcValMinMax = metrics => {
    let result;
    metrics.forEach(key => {
      const dataInRange = data[key] && data[key].data ? getMetricDataForDateRange(data, dateRange, key) : undefined;
      // console.log(dataInRange);
      const localData = dataInRange ? dataInRange.data.map(i => i.value) : [];

      const statsMins = dataInRange
        ? dataInRange.data.map(i => i.statistics?.minValue).filter(i => i !== undefined)
        : [];
      const statsMaxs = dataInRange
        ? dataInRange.data.map(i => i.statistics?.maxValue).filter(i => i !== undefined)
        : [];
      localData.push(...statsMins);
      localData.push(...statsMaxs);

      if (dataInRange && dataInRange.data.some(i => i.distribution)) localData.push(0);

      if (data[key] && !isNaN(data[key].thresholdMin)) {
        localData.push(data[key].thresholdMin);
      }
      if (data[key] && !isNaN(data[key].thresholdMax)) {
        localData.push(data[key].thresholdMax);
      }

      if (localData.length > 0) {
        const localMinMax = calcValMinMax2(localData);
        if (result === undefined) {
          result = localMinMax;
        } else {
          if (result.min > localMinMax.min) result.min = localMinMax.min;
          if (result.max < localMinMax.max) result.max = localMinMax.max;
        }
      }
    });

    // console.log('minmax', result);
    return result;
  };

  const calcValMinMaxTakes = data => {
    const localData = data
      .filter(i => i.dt.unix() >= dateRange.start.unix() && i.dt.unix() <= dateRange.end.unix() + 24 * 60 * 60)
      .map(i => i.value);

    let result;
    if (localData.length > 0) {
      const localMinMax = calcValMinMax2(localData);
      if (result === undefined) {
        result = localMinMax;
      } else {
        if (result.min > localMinMax.min) result.min = localMinMax.min;
        if (result.max < localMinMax.max) result.max = localMinMax.max;
      }
    }

    return result;
  };

  const calcTickFromDelta = (valDelta, maxTicks) => {
    const tick =
      valDelta < maxTicks
        ? 1
        : valDelta < maxTicks * 2
        ? 2
        : valDelta < maxTicks * 5
        ? 5
        : valDelta < maxTicks * 10
        ? 10
        : valDelta < maxTicks * 20
        ? 20
        : valDelta < maxTicks * 50
        ? 50
        : valDelta < maxTicks * 100
        ? 100
        : valDelta < maxTicks * 200
        ? 200
        : valDelta < maxTicks * 500
        ? 500
        : 1000;

    return tick;
  };

  const calcXAxisParamsBackup = (dateMinDay, dateMaxDay, xAxisLabelsMinMaxOnly, graphWidth) => {
    if (xAxisLabelsMinMaxOnly) {
      return {
        unit: 'day',
        drawFirstValue: true,
        drawLastValue: true,
        min: dateMinDay.unix(),
        max: dateMaxDay.unix(),
        ticks: [dateMinDay.unix(), dateMaxDay.unix()],
      };
    }

    if (!graphWidth) {
      return {
        unit: 'day',
        drawFirstValue: true,
        drawLastValue: true,
        min: dateMinDay.unix(),
        max: dateMaxDay.unix(),
        ticks: [dateMinDay.unix(), dateMaxDay.unix()],
      };
    }

    const preferredTickWidth = 40; // + graphWidth / 1000;
    const preferredTicks = Math.floor(graphWidth / preferredTickWidth) + 1; // 30px per tick

    const diffDays = dateMaxDay.diff(dateMinDay, 'days');

    const tickHours = (diffDays * 24) / preferredTicks;

    //const unit = diffDays <= 4 ? 'hour' : diffDays <= 30 ? 'day' : diffDays <= 500 ? 'month' : 'year';
    let unit = 'hour';
    let diffMulti = 1;

    if (tickHours <= 1) {
      unit = 'hour';
      diffMulti = 1;
    } else if (tickHours <= 2) {
      unit = 'hour';
      diffMulti = 2;
    } else if (tickHours <= 4) {
      unit = 'hour';
      diffMulti = 4;
    }
    //else if(tickHours <= 12) { unit = 'hour'; diffMulti= 12; }
    else if (tickHours <= 1 * 24) {
      unit = 'day';
      diffMulti = 1;
    }
    //    else if(tickHours <= 2*24) { unit = 'day'; diffMulti= 2; }
    else if (tickHours <= 7 * 24) {
      unit = 'day';
      diffMulti = 7;
    } else if (tickHours <= 1 * 31 * 24) {
      unit = 'month';
      diffMulti = 1;
    }
    //    else if(tickHours <= 2*31*24) { unit = 'month'; diffMulti= 2; }
    else if (tickHours <= 3 * 31 * 24) {
      unit = 'month';
      diffMulti = 3;
    }
    //    else if(tickHours <= 6*31*24) { unit = 'month'; diffMulti= 6; }
    else if (tickHours <= 12 * 31 * 24) {
      unit = 'year';
      diffMulti = 1;
    }

    const units = `${unit}s`;

    let dateMin;
    let dateMax;
    let diff;

    let drawFirstValue = false;
    let firstValue;
    let drawLastValue = false;
    let lastValue;

    switch (unit) {
      case 'year': {
        dateMin = dateMinDay.clone().startOf('year');
        dateMax = dateMaxDay
          .clone()
          .endOf('year')
          .add(1, 'day')
          .startOf('year');
        diff = dateMax.diff(dateMin, 'years');

        // const diffFirstValueToFirstTick = dateMin.diff(dateMinDay, 'days');
        // if (diffFirstValueToFirstTick > 0) {
        //   drawFirstValue = true;
        //   firstValue = dateMinDay
        //     .clone()
        //     .endOf('month')
        //     .add(1, 'day')
        //     .startOf('month');
        // }

        break;
      }
      case 'month': {
        dateMin = dateMinDay
          .clone()
          .startOf('month')
          .add(-(dateMinDay.month() % diffMulti), 'months')
          .startOf('month');
        const dateMaxStartOfLastTick = dateMaxDay
          .clone()
          .startOf('month')
          .add(-(dateMinDay.month() % diffMulti), 'months')
          .startOf('month');
        dateMax = dateMaxStartOfLastTick.add(diffMulti, 'months').startOf('month');
        diff = dateMax.diff(dateMin, 'months') / diffMulti;

        // const diffFirstValueToFirstTick = dateMin.diff(dateMinDay, 'days');
        // if (diffFirstValueToFirstTick > 0) {
        //   drawFirstValue = true;
        //   firstValue = dateMinDay;
        // }

        break;
      }
      case 'hour': {
        dateMin = dateMinDay;
        dateMax = dateMaxDay;
        diff = (diffDays * 24) / diffMulti;
        break;
      }
      case 'day':
      default: {
        dateMin =
          diffMulti === 7 ? dateMinDay.clone().add(-((dateMinDay.day() - 1 + 7) % 7), 'days') : dateMinDay.clone();
        dateMax =
          diffMulti === 7
            ? dateMaxDay
                .clone()
                .add(-((dateMaxDay.day() - 1 + 7) % 7), 'days')
                .add(7, 'days')
            : dateMaxDay.clone();
        diff = dateMax.diff(dateMin, 'days') / diffMulti;
        break;
      }
    }

    // console.log(dateMinDay, dateMaxDay, diffMulti, units);
    let ticks = Array.from({ length: diff + 1 }, (_, i) => i).map(i => dateMin.clone().add(i * diffMulti, units));
    if (ticks[0].unix() < dateMinDay.unix()) ticks = ticks.slice(1, undefined);
    if (ticks[ticks.length - 1].unix() > dateMaxDay.unix()) ticks = ticks.slice(0, ticks.length - 1);

    // console.log(ticks);
    if (drawFirstValue) ticks.splice(0, 0, firstValue);
    if (drawLastValue) ticks.push(lastValue);

    const ret = {
      unit,
      drawFirstValue,
      drawLastValue,
      min: dateMinDay.unix(),
      max: dateMaxDay.unix(),
      ticks: ticks.map(x => x.unix()),
      diffMulti,
    };

    // console.log(ret);

    return ret;
  };

  const calcXAxisParamsBackup2 = (dateMinDay, dateMaxDay, xAxisLabelsMinMaxOnly, graphWidth) => {
    if (xAxisLabelsMinMaxOnly) {
      return {
        unit: 'day',
        drawFirstValue: true,
        drawLastValue: true,
        min: dateMinDay.unix(),
        max: dateMaxDay.unix(),
        ticks: [dateMinDay.unix(), dateMaxDay.unix()],
      };
    }

    // console.log('graphWidth :', graphWidth);
    const preferredTicks = Math.floor(graphWidth / 30); // 30px per tick

    const diffDays = dateMaxDay.diff(dateMinDay, 'days');
    const unit = diffDays <= 4 ? 'hour' : diffDays <= 30 ? 'day' : diffDays <= 500 ? 'month' : 'year';
    const units = `${unit}s`;

    let dateMin;
    let dateMax;
    let diff;
    let diffMulti = 1;

    let drawFirstValue = false;
    let firstValue;
    let drawLastValue = false;
    let lastValue;

    switch (unit) {
      case 'year': {
        dateMin = dateMinDay
          .clone()
          .endOf('year')
          .add(1, 'years')
          .startOf('year');
        dateMax = dateMaxDay.clone().startOf('year');
        diff = dateMax.diff(dateMin, 'years');

        const diffFirstValueToFirstTick = dateMin.diff(dateMinDay, 'days');

        if (diffFirstValueToFirstTick > 0) {
          drawFirstValue = true;
          firstValue = dateMinDay
            .clone()
            .endOf('month')
            .add(1, 'day')
            .startOf('month');
        }

        break;
      }
      case 'month': {
        dateMin = dateMinDay
          .clone()
          .endOf('month')
          .add(1, 'months')
          .startOf('month');
        dateMax = dateMaxDay.clone().startOf('month');
        diff = dateMax.diff(dateMin, 'months');

        const diffFirstValueToFirstTick = dateMin.diff(dateMinDay, 'days');

        if (diffFirstValueToFirstTick > 0) {
          drawFirstValue = true;
          firstValue = dateMinDay;
        }

        break;
      }
      case 'hour': {
        dateMin = dateMinDay;
        dateMax = dateMaxDay;
        diffMulti = diffDays === 1 ? 1 : diffDays === 2 ? 2 : 4;
        diff = (diffDays * 24) / diffMulti;
        break;
      }
      case 'day':
      default: {
        dateMin = dateMinDay;
        dateMax = dateMaxDay;
        diff = diffDays;
        break;
      }
    }

    const ticks = Array.from({ length: diff + 1 }, (_, i) => i).map(i => dateMin.clone().add(i * diffMulti, units));
    if (drawFirstValue) ticks.splice(0, 0, firstValue);
    if (drawLastValue) ticks.push(lastValue);

    const ret = {
      unit,
      drawFirstValue,
      drawLastValue,
      min: dateMinDay.unix(),
      max: dateMaxDay.unix(),
      ticks: ticks.map(x => x.unix()),
      diffMulti,
    };

    return ret;
  };

  const calcYAxisGroups = () => {
    const height = graphSize.height;
    const preferredTickHeight = 30;
    const maxTicks = height ? height / preferredTickHeight : 8;
    const selectedMetricsExt = selectedMetrics.map(metric => graphMetrics.find(gm => gm.id === metric));
    const uniqueGroups = _.uniq(selectedMetricsExt.map(metric => metric.yAxis));
    const yAxisGroups = uniqueGroups.map(group => ({
      metricsExt: selectedMetricsExt.filter(gm => gm.yAxis === group),
    }));

    yAxisGroups.forEach((yAG, index) => {
      yAG.valMinMax = calcValMinMax(yAG.metricsExt.map(m => m.id));
      yAG.hasData = yAG.valMinMax !== undefined;

      if (yAG.hasData) {
        const valDelta = yAG.valMinMax.max - yAG.valMinMax.min;
        yAG.tick = calcTickFromDelta(valDelta, maxTicks);
        yAG.type = yAG.metricsExt[0]?.yAxis;

        yAG.yMin =
          yAG.valMinMax.min === 0
            ? 0
            : yAG.valMinMax.min -
              (yAG.valMinMax.min % yAG.tick) -
              (yAG.valMinMax.min % yAG.tick < yAG.tick / 10 ? yAG.tick : 0);
        yAG.yMax =
          yAG.valMinMax.max -
          (yAG.valMinMax.max % yAG.tick) +
          yAG.tick +
          (yAG.valMinMax.max % yAG.tick > (yAG.tick * 9) / 10 ? yAG.tick : 0);
        yAG.yTicksCount = (yAG.yMax - yAG.yMin) / yAG.tick + 1;
        yAG.yTicks = Array.from({ length: yAG.yTicksCount }, (_, i) => yAG.yMin + i * yAG.tick);
        yAG.yMinReal = yAG.yMin - yAG.tick / 2;
        yAG.yMaxReal = yAG.yMax + yAG.tick / 2;
      }

      yAG.axisId = index === 0 ? 'yAxis1' : index === 1 ? 'yAxis2' : '';
    });

    return yAxisGroups;
  };

  const calcTickFromAbsMax = max => {
    const twoThirds = (max * 3) / 4;

    const tick =
      twoThirds < 1.25
        ? 1
        : twoThirds < 1.75
        ? 1.5
        : twoThirds < 2.25
        ? 2
        : twoThirds < 2.75
        ? 2.5
        : Math.round(twoThirds);

    return tick;
  };

  const calcYAxisTakes = data => {
    const ya = {};
    ya.valMinMax = calcValMinMaxTakes(data);
    ya.hasData = ya.valMinMax !== undefined;

    if (ya.hasData) {
      const valAbsMax = Math.max(Math.abs(ya.valMinMax.max), Math.abs(ya.valMinMax.min));
      ya.tick = calcTickFromAbsMax(valAbsMax, 4);

      ya.yMin = Math.min(ya.valMinMax.min, -ya.tick) * 1.2;
      ya.yMax = Math.max(ya.valMinMax.max, ya.tick) * 1.2;
      ya.yTicksCount = 3;
      ya.yTicks = [-ya.tick, 0, ya.tick];
    }

    ya.axisId = 'yAxis1';

    return ya;
  };

  const { hasBorder } = props;

  const yAxisGroups = calcYAxisGroups();
  const yAxisGroupLeft = yAxisGroups.find(yAG => yAG.axisId === 'yAxis1');
  const yAxisGroupRight = yAxisGroups.find(yAG => yAG.axisId === 'yAxis2');
  const yAxisGroupNone = yAxisGroups.find(yAG => !yAG.axisId);

  const xAxisParams = isMobile
    ? calcXAxisParams_TimeMobile(dateRange)
    : calcXAxisParams_Time(
        dateRange.start,
        dateRange.end,
        false,
        graphSize.width - (yAxisGroupLeft ? Y_AXIS_LEFT_WIDTH : 0) - (yAxisGroupRight ? Y_AXIS_RIGHT_WIDTH : 0),
      );

  const dateRangeForRangePicker = {
    start: dateRange.start,
    end: dateRange.end.clone().startOf('day'),
  };
  const maxRangeForRangePicker = isMobile
    ? //{
      //start: calcDateRangeAround(maxRange.start, dateRange.type, maxRange).start,
      //end: calcDateRangeAround(maxRange.end, dateRange.type, maxRange).end,
      //}
      maxRange
    : maxRange;
  const resolutionForRangePicker = { type: 'day', count: 1 }; //isMobile ? toDateRangeForPickerUnit(dateRange.type) : { type: 'day', count: 1 };
  const ticksForRangePicker =
    // isMobile
    // ? calcTicksCount(dateRange.type, maxRange)
    // :
    Math.round((maxRange.end.unix() - maxRange.start.unix()) / (24 * 60 * 60));

  // console.log(dateRangeForRangePicker);
  // console.log(maxRangeForRangePicker);

  // console.log('graphSize :', graphSize);
  // console.log(xAxisParams);

  /* reference areas to mimic alternate rows */
  const drawAlternateRows = (yAxisParams, dontExtend) => {
    if (!yAxisParams) {
      return;
    }
    const axisWidth = dontExtend ? Y_AXIS_WIDTH_TINY : Y_AXIS_LEFT_WIDTH;
    //console.log(yAxisParams);
    return Array.from({ length: yAxisParams.yTicksCount }, (_, i) => i).map(i => (
      <ReferenceArea
        yAxisId={yAxisParams.axisId}
        y1={i === 0 ? yAxisParams.yMin : yAxisParams.yMin + yAxisParams.tick / 2 + (i - 1) * yAxisParams.tick}
        y2={yAxisParams.yMin + yAxisParams.tick / 2 + i * yAxisParams.tick}
        stroke={i % 2 === 0 ? GRAPH_ROW_BK : GRAPH_ROW_BK_ALTERNATE}
        fill={i % 2 === 0 ? GRAPH_ROW_BK : GRAPH_ROW_BK_ALTERNATE}
        key={`alternateArea_${i}`}
        shape={<CustomReferenceArea dontExtend={dontExtend} padding={X_AXIS_PADDING} />}
      />
    ));
  };

  const onBrushChange = (startTimestamp, endTimestamp) => {
    const start = moment(startTimestamp * 1000);
    const end = moment(endTimestamp * 1000);
    if (start.isValid() && end.isValid()) {
      //console.log('NEW RANGE: ', start.format(DATE_FORMAT_YEAR_MONTH_DAY), end.format(DATE_FORMAT_YEAR_MONTH_DAY));
      const range = {
        start: start,
        end: isMobile ? end : end.add(1, 'day').startOf('day'),
        type: dateRange.type,
      };
      setDateRange(range);
    }
  };

  const onBrushRight = () => {
    const newDateRange = zoomRight(
      dateRange.start,
      dateRange.end,
      maxRange.start,
      maxRange.end,
      calcMinSelectionDays(),
      calcMaxSelectionDays(),
    );
    setDateRange(newDateRange);
  };

  const zoomAreaStart = e => {
    if (zoomingEnabled && e && e.activeLabel) setZoomAreaLeft(+e.activeLabel);
  };

  const zoomAreaMove = (e, mouseEvent) => {
    if (zoomingEnabled && zoomAreaLeft && e) {
      const lbDown = (mouseEvent.buttons & 1) === 1;
      if (lbDown) {
        setZoomAreaRight(+e.activeLabel);
      } else {
        setZoomAreaLeft(0);
        setZoomAreaRight(0);
      }
    }
  };

  const zoomArea = () => {
    if (!zoomingEnabled) return;
    // console.log('mouse up');

    if (zoomAreaLeft === zoomAreaRight || zoomAreaRight === 0) {
      setZoomAreaLeft(0);
      setZoomAreaRight(0);
      return;
    }

    let zoomL = moment(zoomAreaLeft * 1000);
    let zoomR = moment(zoomAreaRight * 1000);

    if (!zoomL.isValid() || !zoomR.isValid()) return;

    if (zoomL.unix() > zoomR.unix()) [zoomL, zoomR] = [zoomR, zoomL];

    zoomL = zoomL.startOf('day');
    zoomR = zoomR.add(1, 'day').startOf('day');

    const fixedZoom = fixZoom(
      zoomL,
      zoomR,
      maxRange.start,
      maxRange.end,
      calcMinSelectionDays(),
      calcMaxSelectionDays(),
    );

    //if (zoomL.unix() < maxRange.start.unix()) zoomL = maxRange.start.clone();
    //if (zoomR.unix() > maxRange.end.unix()) zoomR = maxRange.end.clone();

    // console.log('setting new range');

    setDateRange({ start: fixedZoom.start, end: fixedZoom.end });
    setZoomAreaLeft(0);
    setZoomAreaRight(0);
  };

  const groupBy = function(array, key) {
    const keys = _.uniq(array.map(i => i[key]));
    const ret = [];
    array
      .filter(i => !i[key])
      .forEach(item => {
        ret.push({
          ...item,
          label: item.label,
        });
      });
    keys
      .filter(k => !!k)
      .forEach(k => {
        ret.push({
          label: k,
          options: array.filter(i => i[key] === k),
        });
      });

    return ret;
  };

  const metricsGrouped = useMemo(() => {
    return groupBy(
      // uncomment if we want to filter out metrincs by patient devices
      //filterMetricsByDevices(graphType.allowedMetrics, patientDevices).map(m => ({
      graphTypes
        .find(gt => gt.id === props.graphType)
        .allowedMetrics.filter(am => metricsWithReadings.includes(am.id))
        .map(m => ({
          ...m,
          value: m.id,
          isDisabled:
            !showTable &&
            !(
              !yAxisGroupLeft?.type ||
              yAxisGroupLeft.type === m.yAxis ||
              !yAxisGroupRight?.type ||
              yAxisGroupRight.type === m.yAxis
            ),
        })),
      'group',
    );
  }, [metricsWithReadings, selectedMetrics, yAxisGroupLeft, yAxisGroupRight, showTable]);

  const getSyncedTooltip = (_tooltipTicks, emittingChartData) => {
    const closest = (arr, n) => arr.sort((a, b) => Math.abs(a - n) - Math.abs(b - n))[0];

    const closestValue = closest(
      _tooltipTicks.map(d => d.value),
      Number(emittingChartData.activeLabel),
    );

    return _tooltipTicks.findIndex(t => t.value === closestValue);
  };

  const toggleTableView = () => {
    if (showTable) {
      setSelectedMetrics(graphType.metrics);
    }
    setShowTable(s => !s);
  };

  const useTinyAxes = isMobile;
  const extraRightMargin = useTinyAxes && !yAxisGroupRight ? Y_AXIS_WIDTH_TINY : 0;

  const header = () =>
    (editable || withLegend || withOverlay || tableViewEnabled) &&
    !isMobile && (
      <PageHeader
        right={
          <React.Fragment>
            {tableViewEnabled && isDesktop && (
              <Input
                type="checkbox"
                name="table-chart-switch"
                id="table-chart-switch"
                label="Show as table"
                defaultChecked={showTable}
                onChange={toggleTableView}
              />
            )}
            {editable && !isMobile && (
              <Multiselect
                data={metricsGrouped}
                value={selectedMetrics}
                placeholder={`${Strings.select} ${Strings.readings}`}
                onChange={e => setSelectedMetrics(e.map(o => o.value))}
                checkboxFirst
                closeMenuOnSelect={false}
                menuPositionFixed={false}
              />
            )}
            {withOverlay && (
              <Multiselect
                data={meds}
                value={selectedMedIds}
                placeholder={`${Strings.select} ${Strings.medications}`}
                onChange={e => setSelectedMedIds(e.map(o => o.value))}
                checkboxFirst
                menuPositionFixed={false}
              />
            )}
            {selectedMedIds?.length > 0 && (
              <Input
                type="checkbox"
                name="show-reminders"
                id="show-reminders"
                label={Strings.showReminders}
                checked={showReminders}
                onChange={() => setShowReminders(!showReminders)}
              />
            )}
          </React.Fragment>
        }
        left={
          withLegend &&
          !showTable && (
            <GraphLegend
              metricsLeft={yAxisGroupLeft}
              metricsRight={yAxisGroupRight}
              metricsNoAxis={yAxisGroupNone}
              showReminders={showReminders && selectedMedIds?.length > 0}
            />
          )
        }
        style={{ marginRight: extraRightMargin, marginLeft: extraRightMargin }}
      />
    );

  const getDataForTable = () => {
    let ret = [];
    selectedMetrics.forEach(m => {
      const arr = data[m]?.data?.map(d => {
        return { dt: d.dt, [m]: d.value };
      });
      ret = _(ret) // start sequence
        .keyBy('dt') // create a dictionary of the 1st array
        .merge(_.keyBy(arr, 'dt')) // create a dictionary of the 2nd array, and merge it to the 1st
        .values() // turn the combined dictionary to array
        .value() // get the value (array) out of the sequence
        .sort((a, b) => {
          // sort descending by date
          return moment(b.dt).diff(a.dt);
        });
    });

    return ret;
  };

  const getColumns = () => {
    const metricsExt = selectedMetrics.map(m => graphMetrics.find(gm => gm.id === m));
    const ret = [
      <Column key="dt" title={Strings.date} value={e => e.dt.format(DATE_MONTH_DAY_YEAR)} />,
      <Column key="dateTime" title={Strings.time} value={e => e.dt.format(TIME_FORMAT_12_UPPERCASE)} />,
    ];
    metricsExt.forEach(m => {
      ret.push(
        <Column
          key={m.id}
          title={
            m.labelWithUnit
              ? m.labelWithUnit(m.unit.trim())
              : typeof m.label === 'string'
              ? `${m.label}${m.unit ? ` [${m.unit.trim()}]` : ''}`
              : m.label
          }
          value={e => e[m.id]}
        />,
      );
    });
    return ret;
  };

  // const doPinch = ({ offset: [s, a] }) => {
  //   const msg = 'Pinch scale: ' + s;
  //   if (s) {
  //     alert(msg);
  //     return state.cancel();
  //   }
  // };
  // const doPinchEnd = state => {
  //   const msg = 'PinchEnd scale: ' + state.offset[0];
  //   console.log(msg);
  //   alert(msg);
  //   return state.cancel();
  // };

  //const refGestures = React.useRef < HTMLDivElement > null;

  const bindGestures = isDesktop
    ? () => {}
    : useGesture(
        {
          onDrag: state =>
            !isMobile
              ? defaultOnDrag(state)
              : defaultOnDrag_Mobile(
                  state,
                  graphSize.width - Y_AXIS_WIDTH_TINY - X_AXIS_PADDING * 2,
                  { dateRange, maxRange },
                  newDateRange => setDateRange(newDateRange),
                  true,
                ),
          onDragEnd: state =>
            !isMobile
              ? defaultOnDragEnd(
                  state,
                  1.8,
                  dateRange.start,
                  dateRange.end,
                  maxRange.start,
                  maxRange.end,
                  calcMinSelectionDays(),
                  calcMaxSelectionDays(),
                  newDateRange => setDateRange(newDateRange),
                )
              : defaultOnDragEnd_Mobile(state, dateRange, maxRange, newDateRange => setDateRange(newDateRange)),
        },
        {
          drag: { threshold: 5 },
        },
      );

  const mobileMetricsPicker = (
    <GraphLegendWithToggle
      metrics={graphTypes
        .find(gt => gt.id === props.graphType)
        .allowedMetrics.filter(am => metricsWithReadings.includes(am.id))
        .map(m => {
          return {
            ...m,
            isSelected: selectedMetrics.includes(m.id),
            isDisabled: !(
              !yAxisGroupLeft?.type ||
              yAxisGroupLeft.type === m.yAxis ||
              !yAxisGroupRight?.type ||
              yAxisGroupRight.type === m.yAxis
            ),
            axisId: yAxisGroupLeft?.metricsExt?.some(gm => gm.id === m.id)
              ? 'left'
              : yAxisGroupRight?.metricsExt?.some(gm => gm.id === m.id)
              ? 'right'
              : '',
          };
        })}
      toggleMetric={id =>
        setSelectedMetrics(
          selectedMetrics.includes(id) ? selectedMetrics.filter(m => m !== id) : [...selectedMetrics, id],
        )
      }
    />
  );

  const rangePickerHeight =
    mobileRangePickerRef.current?.clientHeight || rangePickerRef.current?.current?.clientHeight || null;
  return (
    <React.Fragment>
      {header()}
      <LoadingRenderer loading={loading && !dateRangeSet.current}>
        {!showTable && metricsWithReadings?.length > 0 && (
          <div
            className={`graphDiv compositeGraph ${hasBorder ? 'withBorder' : ''}`}
            // {...bindDrag()}
            // {...bindPinch()}
            //ref={refGestures}
          >
            {isDesktop && (
              <div
                className="vertical-shadow"
                style={{
                  left: useTinyAxes ? Y_AXIS_WIDTH_TINY : Y_AXIS_LEFT_WIDTH,
                  height: `calc(100% - ${rangePickerHeight + RANGE_PICKER_TOP_MARGIN + 1}px)`,
                }}
              />
            )}
            {isDesktop && yAxisGroupRight && (
              <div
                className="vertical-shadow"
                style={{
                  right: useTinyAxes ? Y_AXIS_WIDTH_TINY : Y_AXIS_LEFT_WIDTH,
                  height: `calc(100% - ${rangePickerHeight + RANGE_PICKER_TOP_MARGIN + 1}px)`,
                }}
              />
            )}
            {isMobile && (
              <MobileRangePicker
                dateRange={dateRange}
                maxRange={maxRange}
                allowedRangeTypes={graphType.allowedMobileDateRangeTypes || DEFAULT_ALLOWED_MOBILE_DATE_RANGE_UNITS}
                onChange={newRange => setDateRange(newRange)}
                metricsToggles={mobileMetricsPicker}
                ref={mobileRangePickerRef}
              />
            )}
            {isDesktop && yAxisGroupRight && (
              <div
                className="y-axis-extension"
                style={{
                  right: Y_AXIS_RIGHT_WIDTH - 1,
                  height: `calc(100% - ${rangePickerHeight + RANGE_PICKER_TOP_MARGIN + 1}px)`,
                }}
              />
            )}
            {!isNaN(rangePickerHeight) && (
              <div
                style={{
                  userSelect: 'none',
                  touchAction: 'none',
                  WebkitUserSelect: 'none',
                  height: `calc(100% - ${rangePickerHeight}px)`,
                  //height: `calc(100% - ${useTinyAxes ? RANGE_PICKER_HEIGHT_MOBILE_WITH_TOGGLES : 0}px)`,
                  position: 'relative',
                }}
                // {...bindDrag()}
                // {...bindPinch()}
                //ref={refGestures}
                {...bindGestures()}
              >
                {useTinyAxes && (
                  <React.Fragment>
                    <div
                      style={{
                        position: 'absolute',
                        top: '-1px',
                        height: 1,
                        width: '100%',
                        borderTop: 'solid 1px #D9D9D9',
                      }}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        bottom: X_AXIS_CHART_HEIGHT_TINY,
                        height: 1,
                        width: '100%',
                        borderTop: 'solid 1px #D9D9D9',
                      }}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        right: Y_AXIS_WIDTH_TINY - 1,
                        height: `calc(100% - ${X_AXIS_CHART_HEIGHT_TINY}px)`,
                        width: 1,
                        borderRight: 'solid 1px #D9D9D9',
                      }}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        left: Y_AXIS_WIDTH_TINY - 1,
                        height: `calc(100% - ${X_AXIS_CHART_HEIGHT_TINY}px)`,
                        width: 1,
                        borderRight: 'solid 1px #D9D9D9',
                      }}
                    />
                  </React.Fragment>
                )}
                {!isMobile && (
                  <CompositeGraphXAxis
                    dateRange={dateRange}
                    xAxisParams={xAxisParams}
                    withRightAxis={!!yAxisGroupRight}
                    useTinyAxes={useTinyAxes}
                  />
                )}

                {selectedMedIds.map(td => {
                  const medTakes = takes[td];
                  if (!medTakes) return <></>;

                  const yAxisTakes = calcYAxisTakes(medTakes);
                  const filteredTakes = medTakes.filter(
                    i => i.dt.unix() >= dateRange.start.unix() && i.dt.unix() <= dateRange.end.unix() + 24 * 60 * 60,
                  );

                  // console.log(dateRange);
                  // console.log(medTakes);
                  // console.log(filteredTakes);

                  return (
                    <div
                      key={`overlay_${td}`}
                      style={{
                        marginRight: extraRightMargin,
                      }}
                    >
                      <ResponsiveContainer width="100%" height={OVERLAY_CHART_HEIGHT}>
                        <ComposedChart
                          defaultShowTooltip={false}
                          margin={{
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                          }}
                          syncId="anyId"
                          syncMethod={getSyncedTooltip}
                        >
                          {drawWeekRefLines(xAxisParams, maxRange, 'yAxis1')}

                          {drawXAxis(xAxisParams, 2, 'bottom', null, true)}
                          {drawYAxisTakes(yAxisTakes, true, true, useTinyAxes ? Y_AXIS_WIDTH_TINY : 0)}
                          {yAxisGroupRight &&
                            drawYAxisTakes(yAxisTakes, false, true, useTinyAxes ? Y_AXIS_WIDTH_TINY : 0)}

                          <ReferenceLine y={0} yAxisId="yAxis1" />
                          <ReferenceLine y={-yAxisTakes.tick} yAxisId="yAxis1" strokeDasharray="1 3" />
                          <ReferenceLine y={yAxisTakes.tick} yAxisId="yAxis1" strokeDasharray="1 3" />

                          {drawTakes(filteredTakes, -yAxisTakes.tick, showReminders)}

                          {drawTakesTooltip(takes, td)}
                        </ComposedChart>
                      </ResponsiveContainer>
                    </div>
                  );
                })}

                <div
                  style={{
                    height: `calc(100% - ${(useTinyAxes ? X_AXIS_CHART_HEIGHT_TINY : X_AXIS_CHART_HEIGHT) +
                      OVERLAY_CHART_HEIGHT * selectedMedIds.length}px)`,
                    minHeight: 100,
                    // userSelect: 'none',
                    // touchAction: 'none',
                    // WebkitUserSelect: 'none',
                    paddingRight: extraRightMargin,
                  }}
                  ref={graphContainerRef}
                  //{...bindGestures()}
                  className={useTinyAxes ? 'graph-surface-background' : undefined}
                >
                  {graphSize.width && graphSize.height && (
                    <ResponsiveContainer width="100%" height="100%" className={isDesktop ? 'with-shadow' : ''}>
                      <ComposedChart
                        defaultShowTooltip={false}
                        margin={{
                          top: 0,
                          right: 0,
                          bottom: RANGE_PICKER_TOP_MARGIN,
                          left: 0,
                        }}
                        onMouseDown={zoomAreaStart}
                        onMouseMove={zoomAreaMove}
                        onMouseUp={zoomArea}
                        syncId="anyId"
                        syncMethod={getSyncedTooltip}
                        data={[
                          { x: 1705230000, y: 201.5 },
                          { x: 1706785200, y: 209 },
                          { x: 1706871600, y: 208.78 },
                          { x: 1706958000, y: 202.83 },
                        ]}
                      >
                        {drawAlternateRows(yAxisGroupLeft, useTinyAxes)}
                        {drawXAxis(xAxisParams, 1, 'bottom', null, useTinyAxes)}
                        {yAxisGroupLeft && drawYAxis(yAxisGroupLeft, true, useTinyAxes ? Y_AXIS_WIDTH_TINY : 0)}
                        {yAxisGroupRight && drawYAxis(yAxisGroupRight, true, useTinyAxes ? Y_AXIS_WIDTH_TINY : 0)}
                        {drawTooltip(data, dateRange, selectedMetrics)}
                        {selectedMetrics?.length > 0 && drawWeekRefLines(xAxisParams, maxRange, 'yAxis1')}
                        {selectedMetrics?.length > 0 && drawLineSeries(selectedMetrics, yAxisGroups, data, dateRange)}
                        {zoomAreaRight && zoomAreaLeft && (
                          <ReferenceArea yAxisId="yAxis1" x1={zoomAreaLeft} x2={zoomAreaRight} strokeOpacity={0.3} />
                        )}
                      </ComposedChart>
                    </ResponsiveContainer>
                  )}
                </div>
                {isMobile && (
                  <CompositeGraphXAxis
                    dateRange={dateRange}
                    xAxisParams={xAxisParams}
                    withRightAxis={!!yAxisGroupRight}
                    useTinyAxes={useTinyAxes}
                  />
                )}
              </div>
            )}
            {!showTable && !isMobile && (
              <RangePicker
                startTimestamp={dateRange.start.unix()}
                endTimestamp={dateRange.end
                  .clone()
                  .subtract(1, 'days')
                  .startOf('day')
                  .unix()}
                dataStartTimestamp={maxRange.start.unix()}
                resolution="days"
                ticks={Math.round((maxRange.end.unix() - maxRange.start.unix()) / (24 * 60 * 60))}
                width="100%"
                leftPadding={useTinyAxes ? Y_AXIS_WIDTH_TINY : Y_AXIS_LEFT_WIDTH + 1}
                rightPadding={useTinyAxes ? Y_AXIS_WIDTH_TINY : Y_AXIS_RIGHT_WIDTH}
                onChange={onBrushChange}
                metric={selectedMetrics[0]}
                yAxisGroups={yAxisGroups}
                data={data}
                dateRange={maxRange}
                minSelectionTicks={calcMinSelectionDays()}
                renderMiniVersion={isMobile}
                ref={!isMobile ? rangePickerRef : undefined}
              />
            )}
            {/* {!showTable && isMobile && (
            <RangePicker
              startTimestamp={dateRangeForRangePicker.start.unix()}
              endTimestamp={dateRangeForRangePicker.end.unix()}
              dataStartTimestamp={maxRangeForRangePicker.start.unix()}
              resolution={resolutionForRangePicker.type}
              resolutionMulti={resolutionForRangePicker.count}
              ticks={ticksForRangePicker}
              width="100%"
              leftPadding={useTinyAxes ? Y_AXIS_WIDTH_TINY : Y_AXIS_LEFT_WIDTH + 1}
              rightPadding={useTinyAxes ? Y_AXIS_WIDTH_TINY : Y_AXIS_RIGHT_WIDTH}
              onChange={onBrushChange}
              metric={selectedMetrics[0]}
              yAxisGroups={yAxisGroups}
              data={data}
              dateRange={maxRangeForRangePicker}
              minSelectionTicks={calcMinSelectionDays()}
              renderMiniVersion={isMobile}
            />
          )} */}
          </div>
        )}

        {showTable && (
          <TableWithLocalPagination
            name="dashboard-table"
            data={getDataForTable()}
            columns={getColumns()}
            infiniteScroll
          />
        )}
      </LoadingRenderer>
    </React.Fragment>
  );
}

const mapDispatchToProps = dispatch => ({
  getMedications: patientId => dispatch(actions.getMedications(patientId)),
  getPatientInfo: patientId => dispatch(actions.getPatientDahsboardInfo(patientId)),
  getDevices: (pageRequest, patientId) => dispatch(actions.getPatientDevices(pageRequest, patientId)),
  getLastReadings: patientId => dispatch(actions.getPatientLastReadings(patientId)),
});

export default connect(null, mapDispatchToProps)(CompositeGraph);

CompositeGraph.propTypes = {
  patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  graphType: PropTypes.string,
  getMedications: PropTypes.func,

  hasBorder: PropTypes.bool,
  useGestures: PropTypes.bool,
};

CompositeGraph.defaultProps = { graphType: graphTypeEnum.weight, useGestures: false };
