import Strings from '../Strings';
import { PERMISSIONS } from './userPermissions';

export const DeviceClass = {
  cap: 'cap',
  ble: 'ble', // to be removed when LTE.jsx is merged with BLE.jsx
  hub: 'hub',
  thirdParty: 'third_party',
  kit: 'kit',
};

export const DeviceTypes = {
  Kit: {
    id: 'Kit',
    displayName: Strings.devicesEnum.Kit,
    displayNamePlural: Strings.devicePluralsEnum.Kit,
    deviceClass: DeviceClass.kit,
    key: 'kit',
    permission: PERMISSIONS.KITS_MANAGEMENT,
  },
  Datahub: {
    id: 'Datahub',
    displayName: Strings.devicesEnum.Datahub,
    displayNamePlural: Strings.devicePluralsEnum.Datahub,
    deviceClass: DeviceClass.hub,
    key: 'hub',
    permission: PERMISSIONS.DEVICE_MANAGEMENT_HUB,
  },
  'Bluetooth Cap': {
    id: 'Bluetooth Cap',
    displayName: Strings.devicesEnum['Bluetooth Cap'],
    displayNamePlural: Strings.devicePluralsEnum['Bluetooth Cap'],
    deviceClass: DeviceClass.ble,
    key: 'ble',
    permission: PERMISSIONS.DEVICE_MANAGEMENT_BLE,
  },
  'LTE Pillbox': {
    id: 'LTE Pillbox',
    displayName: Strings.devicesEnum['LTE Pillbox'],
    displayNamePlural: Strings.devicePluralsEnum['LTE Pillbox'],
    deviceClass: DeviceClass.cap,
    key: 'lte',
    permission: PERMISSIONS.DEVICE_MANAGEMENT_LTE,
  },
  'LTE Smart Cap': {
    id: 'LTE Smart Cap',
    displayName: Strings.devicesEnum['LTE Smart Cap'],
    displayNamePlural: Strings.devicePluralsEnum['LTE Smart Cap'],
    deviceClass: DeviceClass.cap,
    key: 'lsc',
    permission: PERMISSIONS.DEVICE_MANAGEMENT_LSC,
  },
  'Injectable Medication Tracker': {
    id: 'Injectable Medication Tracker',
    displayName: Strings.devicesEnum['Injectable Medication Tracker'],
    displayNamePlural: Strings.devicePluralsEnum['Injectable Medication Tracker'],
    deviceClass: DeviceClass.cap,
    key: 'injectable-tracker',
    permission: PERMISSIONS.DEVICE_MANAGEMENT_INJECTABLE_TRACKER,
  },
  'MDP Dispenser': {
    id: 'MDP Dispenser',
    displayName: Strings.devicesEnum['MDP Dispenser'],
    displayNamePlural: Strings.devicePluralsEnum['MDP Dispenser'],
    deviceClass: DeviceClass.cap,
    key: 'mdp',
    permission: PERMISSIONS.DEVICE_MANAGEMENT_MDP,
  },
  'Blood Pressure Monitor': {
    id: 'Blood Pressure Monitor',
    displayName: Strings.devicesEnum['Blood Pressure Monitor'],
    displayNamePlural: Strings.devicePluralsEnum['Blood Pressure Monitor'],
    deviceClass: DeviceClass.thirdParty,
    tableUuid: '54a8a5ef-ee1e-40a3-add8-0302ff5d6c97',
    key: 'bpm',
    permission: PERMISSIONS.DEVICE_MANAGEMENT_BPM,
  },
  'Weighing Machine': {
    id: 'Weighing Machine',
    displayName: Strings.devicesEnum['Weighing Machine'],
    displayNamePlural: Strings.devicePluralsEnum['Weighing Machine'],
    deviceClass: DeviceClass.thirdParty,
    tableUuid: '52896208-7f60-48cf-99c5-15c0e2d896a9',
    key: 'weight-scales',
    permission: PERMISSIONS.DEVICE_MANAGEMENT_WEIGHTING_MACHINE,
  },
  'Wrist Band': {
    id: 'Wrist Band',
    displayName: Strings.devicesEnum['Wrist Band'],
    displayNamePlural: Strings.devicePluralsEnum['Wrist Band'],
    deviceClass: DeviceClass.thirdParty,
    tableUuid: '27c1bb0f-37ba-4ab3-9b2e-a8b337fd4c16',
    key: 'wb',
    permission: PERMISSIONS.DEVICE_MANAGEMENT_WB,
  },
  Glucometer: {
    id: 'Glucometer',
    displayName: Strings.devicesEnum.Glucometer,
    displayNamePlural: Strings.devicePluralsEnum.Glucometer,
    deviceClass: DeviceClass.thirdParty,
    tableeUuid: '9cceff59-bca7-47a1-ba2c-9112f3e38ced',
    key: 'glucometer',
    permission: PERMISSIONS.DEVICE_MANAGEMENT_GLUCO,
  },
  Thermometer: {
    id: 'Thermometer',
    displayName: Strings.devicesEnum.Thermometer,
    displayNamePlural: Strings.devicePluralsEnum.Thermometer,
    deviceClass: DeviceClass.thirdParty,
    tableUuid: 'f6c6e2e5-6b3d-4b5b-8a5e-0f3d1f4f4e5d',
    key: 'thermometer',
    permission: PERMISSIONS.DEVICE_MANAGEMENT_THERMOMETER,
  },
  Inhaler: {
    id: 'Inhaler',
    displayName: Strings.devicesEnum.Inhaler,
    displayNamePlural: Strings.devicePluralsEnum.Inhaler,
    deviceClass: DeviceClass.thirdParty,
    tableUuid: 'd55d8cf6-6913-4856-85ef-ea0bd379b23d',
    key: 'inhaler',
    permission: PERMISSIONS.DEVICE_MANAGEMENT_INHALER,
  },
  Spirometer: {
    id: 'Spirometer',
    displayName: Strings.devicesEnum.Spirometer,
    displayNamePlural: Strings.devicePluralsEnum.Spirometer,
    deviceClass: DeviceClass.thirdParty,
    tableUuid: 'd55d8cf6-6913-4856-85ef-ea0bd379b23d',
    key: 'spirometer',
    permission: PERMISSIONS.DEVICE_MANAGEMENT_SPIROMETER,
  },
  'Pulse Oximeter': {
    id: 'Pulse Oximeter',
    displayName: Strings.devicesEnum['Pulse Oximeter'],
    displayNamePlural: Strings.devicePluralsEnum['Pulse Oximeter'],
    deviceClass: DeviceClass.thirdParty,
    tableUiid: 'e871ae4c-6fa5-4de4-980e-47bdd01da5c9',
    key: 'po',
    permission: PERMISSIONS.DEVICE_MANAGEMENT_PULSE_OXIMETER,
  },
  'Air Quality Monitor': {
    id: 'Air Quality Monitor',
    displayName: Strings.devicesEnum['Air Quality Monitor'],
    displayNamePlural: Strings.devicePluralsEnum['Air Quality Monitor'],
    deviceClass: DeviceClass.thirdParty,
    tableUuid: '9e1e1823-7275-469f-907e-09efa1462015',
    key: 'air-quality-monitor',
    permission: PERMISSIONS.DEVICE_MANAGEMENT_AIR_QUALITY_MONITOR,
  },
  'Hailie Inhaler': {
    id: 'Hailie Inhaler',
    displayName: Strings.devicesEnum['Hailie Inhaler'],
    displayNamePlural: Strings.devicePluralsEnum['Hailie Inhaler'],
    deviceClass: DeviceClass.thirdParty,
    tableUuid: 'c9ab3b6e-fc43-426e-8102-e031231694dd',
    key: 'hailie-inhaler',
    permission: PERMISSIONS.DEVICE_MANAGEMENT_HAILIE_INHALER,
  },
  'PT/INR Monitor': {
    id: 'PT/INR Monitor',
    displayName: Strings.devicesEnum['PT/INR Monitor'],
    displayNamePlural: Strings.devicePluralsEnum['PT/INR Monitor'],
    deviceClass: DeviceClass.thirdParty,
    tableUuid: 'a74fbc03-bd41-4846-a101-17560bed1832',
    key: 'pt_inr',
    permission: PERMISSIONS.DEVICE_MANAGEMENT_PTINR_MONITOR,
  },
};
