import React from 'react';

const spanish = {
  yes: 'Sí',
  no: 'No',
  none: 'None',
  all: 'Todo',
  writePrivileges: 'Permiso de edición',
  orderList: 'Lista de órdenes',
  appEmail: 'support@rxcap.com',
  downloadData: 'Descargar datos',
  downloadAllData: 'Descargar todos los datos',
  downloadResultsData: 'Descargar datos de resultados',
  downloadHistory: 'Descargar historial',
  downloadAllMeds: 'Descargar lista de medicamentos',
  downloadEvents: 'Download Events',
  downloadSummary: 'Download Summary',
  addPatient: 'Agregar paciente',
  addEnrollAssignMed: 'Add & Enroll Patient, Assign Medication',
  removePatient: 'Remove Patient',
  resetPatient: 'Reset Patient',
  addNickName: 'Editar el nombre',
  sessionTimeout: 'Extender la sesión',
  nickName: 'Nombre de la tapa',
  editName: 'Editar el nombre',
  editCapName: 'Editar el nombre de la tapa',
  addPatients: 'Agregar pacientes',
  addRemovePatients: 'Add / Remove Patients',
  fullName: 'Nombre completo',
  date: 'Fecha',
  time: 'Hora',
  editInfo: 'Editar información',
  reloadGraph: 'Volver a cargar gráfico',
  showSelectedItems: 'Mostrar los campos seleccionados',
  showSelectedWidgets: ' Mostrar los widgets seleccionados',
  resetToDefaults: 'Restablecer configuración original',
  deleteWarning: '¿Seguro de que quiere borrar el {0}?',
  deletePatientWarning: '¿Seguro de que quiere borrar el paciente',
  updateStatus: '¿Seguro de que quiere actualizar el estado a',
  deleteCapManagerWarning: '¿Seguro de que quiere borrar el administrador de la tapa',
  deleteDoctorWarning: '¿Seguro de que quiere borrar el doctor',
  deleteOrderWarning: '¿Seguro de que quiere borrar la orden',
  deleteOrgWarning: '¿Seguro de que quiere borrar la organización',
  launchOrgWarning: 'Are you sure you want to launch organization',
  unsuspendOrgWarning: 'Are you sure you want to unsuspend organization',
  deleteScheduleWarning: '¿Seguro de que quiere borrar la programación',
  deleteTaksHistoryWarning: '¿Seguro de que quiere borrar el historial de tareas',
  deleteMedicationWarning: '¿Seguro de que quiere borrar el medicamento',
  deleteScheduleCapWarning: '¿Seguro de que quiere borrar la tapa',
  deleteOrgManagerWarning: '¿Seguro de que quiere borrar al gerente de la organización',
  deleteOrgAdminWarning: '¿Seguro de que quiere borrar al admin de la organización',
  deleteOrgCapsManagerWarning: '¿Seguro de que quiere borrar al gerente de tapas de la organización',
  deleteOrgOutboundAPIWarning: '¿Seguro de que quiere borrar el API saliente de la organización?',
  deleteCapWarning: '¿Seguro de que quiere borrar el dispositivo? ',
  capSendSecurityCode: 'Enviar código de seguridad del dispositivo',
  capSendSecurityCodeSent: 'Se envió el código de seguridad del dispositivo',
  deleteCapWarning: '¿Seguro de que quiere eliminar la tapa',
  deleteHubDeviceWarning: '¿Seguro de que quiere borrar el dispositivo',
  deleteCohortWarning: 'Are you sure you want to delete cohort',
  unassignPatientWarning: 'Are you sure you want to unassign patient from cohort',
  assignCapToScheduleCaption: 'Cap {0} will be assigned to schedule {1}',
  unassignCapToScheduleCaption: 'Cap {0} will be unassigned from schedule {1}',
  deletePrefabReply: 'Are you sure you want to delete prefab response',
  cancelEnrollmentWarning: 'Are you sure you want to cancel enrollment?',
  invitePatientWarning: 'Are you sure you want to invite patient {0} to portal?',
  resetBleWarning: 'Are you sure you want to reset BLE chip for selected hub(s)?',
  startEnrollmentWarning: 'Are you sure you want to activate patient {0}?',
  startEnrollmentMedsWarning:
    'The following schedules are linked to the program and have start date in the future. Please select the schedules you want to start now.',
  resetPatientStatusWarning:
    'Are you sure you want to reset patient {0} status? Patient will be moved to Onboarded/Registered state',
  resetPatientStatusSchedulesWarning:
    'The following schedules have cap assigned. Please select the schedules from which you want to unassign cap.',
  resetPatientStatusActiveAlertsWarning:
    'Some schedules have active alerts. You have to clear them to be able to unassign cap from them and to remove kit from patient. To clear alerts go to',
  patientDashboardTxt: 'Patient Dashboard',
  resetPatientStatusKitWarning:
    'Do you want to remove the kit from the patient? We recommend removing the kit only if the patient will be getting new one.',
  cancelEnrollmentWarning: 'Are you sure you want to cancel enrollment for patient {0}?',
  remindersChangeRequiredCaption: 'Reminders change required',
  remindersChangeRequired:
    'Patient has reminders defined. They will NOT be automatically translated to the newly set language. To change the reminders, please navigate to Medications page and edit each medication that has remiders set to change them. Would you like to be navigated to Medications page?',
  previous: 'Anterior',
  nextWeek: 'Semana próxima',
  downloadPatientData: 'Descargar datos del paciente',
  downloadPatientsEvents: 'Download Patients Events',
  caregiver: 'Cuidador',
  caregivers: 'Cuidadores',
  addCaregiver: 'Add Caregiver',
  editCaregiverList: 'Edit Caregiver List',
  addACaregiver: 'Agregar un cuidador',
  editCaregiver: 'Edit Caregiver',
  numberOfAdministrators: 'Número de administradores',
  addAdmin: 'Invitar Admin',
  rxcapCopyRightText: 'RXCap 2020 – Todos los derechos reservados',
  showingDataFrom: 'Mostrando datos de ',
  trend: 'Tendencia',
  missed: 'Omitido',
  miss: 'Miss',
  complied: 'Cumplido',
  skipped: 'Saltado',
  noData: 'No hay datos',
  last30days: 'Últimos 30 días',
  thisMonth: 'Este mes',
  lastMonth: 'Último mes',
  last6Months: 'Últimos 6 meses',
  thisYear: 'Este año',
  last12Months: 'Últimos 12 meses',
  last7DaysAdherence: 'Adherencia últimos 7 días',
  noDescription: 'No hay descripción',
  noDataAvailable: 'No hay datos',
  totalAdherence: 'Adherencia Total',
  weeklyTotalAdherence: 'Total | Weekly Adherence',
  totalAccuracy: 'Exactitud Total',
  accuracy: 'Exactitud',
  appOnly: 'Solo App',
  smartCap: 'SmartCap',
  pillbox: 'Pillbox',
  smartCapUsage: 'Uso de Smart Cap',
  accepted: 'Aceptado',
  accept: 'Aceptar',
  pending: 'Pendiente',
  createOrder: 'Crear Orden',
  acceptedPendingInvites: 'Invitaciones Aceptadas/Pendientes',
  medication: 'Medicación',
  medication: 'Medicación',
  noMedicationsTaken: 'Ninguna medicación tomada',
  averageTimedelta: 'Delta de Tiempo promedio',
  taken: 'Tomado',
  takes: 'Toma',
  nextMed: 'Next Med',
  unscheduled: 'no programado',
  takeTypes: {
    scheduled: 'Scheduled take',
    unscheduled: 'Unscheduled take',
    missed: 'Missed take',
  },
  delta: 'delta',
  na: 'N/D',
  name: 'Nombre',
  verificationCode: 'Ingresar Código de Verificación',
  send: 'Enviar',
  sendVerificationCode: 'Enviar Código de Verificación',
  reSendVerificationCode: 'Reenviar Código de Verificación',
  sendVerificationLink: 'Enviar Enlace de Verificación',
  reSendVerificationLink: 'Reenviar Enlace de Verificación',
  total: 'Total',
  verified: 'Verificado',
  invalidType: 'Tipo no válido',
  dashboard: 'Panel',
  loading: 'Cargando',
  notificationSettings: 'Configuración de Notificaciones',
  mobilePhone: 'Teléfono Móvil',
  phoneNo: 'No. de teléfono',
  contactNo: 'No. de Contacto',
  hardwareId: 'ID de Hardware ',
  notVerified: 'No verificado',
  emailAddress: 'Dirección de Email',
  inProcess: 'En proceso...',
  saveChanges: 'Guardar Cambios',
  save: 'Guardar',
  update: 'Actualizar',
  firstName: 'Nombre',
  yourName: 'Su Nombre',
  lastName: 'Apellido',
  userName: 'Nombre de usuario',
  patient: 'Paciente',
  selectGender: 'Please select gender',
  gender: 'Género',
  genders: {
    male: 'Masculino',
    female: 'Femenino',
    other: 'Otro',
  },
  userNameEmail: 'Nombre de usuario o Email',
  usingSmartCap: 'Usando Smart Cap',
  studies: 'Estudios',
  patients: 'Pacientes',
  myPatients: 'My Patients',
  enrollAndMonitor: 'Enroll & Monitor',
  alerts: 'Alertas',
  admin: 'Admins',
  caps: 'Tapas',
  signOut: 'Salir',
  today: 'Hoy',
  daily: 'Daily',
  monthly: 'Monthly',
  medications: 'Medicaciones',
  timeDelta: 'Time Delta',
  info: 'Información',
  personalSettings: 'Configuraciones Personales',
  settings: 'Configuraciones',
  support: 'Soporte',
  systemConfiguration: 'Configuración del Sistema',
  supportHeader: '¿Cómo podemos ayudar? (Solo apoyo técnico)',
  howToUseYourSmartCap: 'Cómo usar su smart cap',
  chatWithUs: 'Converse con nosotros por chat',
  callUs: 'Call us',
  chatText: `In a hurry and would prefer to speak with someone directly? Then, we're here to help, just give us a call on the number below and we'll see what we can do.`,
  helpCenter: 'Centro de ayuda',
  helpCenterText:
    'Before contacting customer support have a look around our help center to see if the answer you are looking for is there.',
  smartCapTitle: 'How to use your smart cap',
  emailUs: 'Email',
  emailText: `If you have a query or specific question, we'd be happy to hear from you. Our customer support team are here to help and can be reached at:`,
  supportText:
    'Si tiene una consulta o pregunta específica, con gusto la recibimos. Nuestro equipo de apoyo al cliente está aquí para ayudarle y puede encontrarlos en ',
  supportChatWithUsText:
    'Obténga respuestas sin dejar el sito; envíe un mensaje a nuestro chat de apoyo al cliente usando el ícono abajo a la derecha de la pantalla y alguien se pondrá en contacto a la brevedad.',
  supportHelpCenterText:
    'Antes de ponerse en contacto con apoyo al cliente busque en nuestro centro de ayuda para ver si la respuesta a lo que busca ya está ahí.',
  supportEmail: 'contact@rxcap.com',
  supportLidSyncEmail: 'support@lidsync.com',
  supportButton: 'Envíenos un email',
  technicalSupport: 'Solo Apoyo Técnico',
  supportTechnicalModal:
    'Tome en cuenta que nuestra página de apoyo es únicamente para apoyo técnico del hardware o software. No comparta información médica con nuestros representantes de apoyo al cliente.',
  callUsButton: 'O llámenos al:',
  phone: '+1(818) 618 6375',
  phoneLidSync: '+1(888)-618-0463',
  emailSubject: 'Asistencia Portal Web de RxCap',
  emailLidSyncSubject: 'Asistencia Portal Web de LidSync',
  donwload: 'Descargar',
  calendar: 'Calendario',
  findOutMore: 'Más información',
  cancel: 'Cancelar',
  done: 'Listo',
  confirm: 'Confirmar',
  careGiverInvalidLink: 'El enlace no es válido. Por favor generar un nuevo enlace.',
  takeSimulation: 'Realizar simulación',
  careGiverHeading: 'Actuar como Cuidador RxCap',
  careGiverSubHeading:
    'By entering your details you are accepting to receive txt message notifications from RxCap for the purposes of the CareGiver feature only…',
  invite: 'Invite',
  inviteToPortal: 'Invite to Potrtal',
  invitationSuccess: 'Invitation sent successfully',
  reInviteToPortal: 'Resend Potrtal Invitation',
  upload: 'Upload',
  uploading: 'Uploading',
  uploadSignedForm: 'Upload Signed Form',
  submit: 'Submit',
  close: 'Close',
  skipAndClose: 'Skip & Close',
  studyName: 'Study Name',
  daysOfWeek: 'Days of Week',
  description: 'Description',
  defaultEnabled: 'Default enabled',
  enabled: 'Enabled',
  disabled: 'Disabled',
  blacklisted: 'Blacklisted',
  masterConfiguration: 'Master Configuration',
  organizationConfiguration: 'Organization Configuration',
  backToHours: 'Back to Hours',
  add: 'Add',
  addPills: 'Add (Pills)',
  totalPills: 'Total (Pills)',
  back: 'Back',
  status: 'Status',
  oldStatus: 'Old Status',
  newStatus: 'New Status',
  usageState: 'Usage State',
  administrativeState: 'Administrative State',
  operationalState: 'Operational State',
  businessState: 'Business State',
  changedStatus: 'Changed Status',
  patientUserName: 'Patient Username',
  alertBySMS: 'Alert by SMS',
  alertByEmail: 'Alert by Email',
  alertByPhone: 'Alert by Phone',
  showAll: 'Show All',
  login: 'Login',
  loginTitle: 'Login to RxCap',
  loginTo: 'Login to',
  securityCode: 'Security Code',
  securityCodeIsRequired: 'Security Code is required, check your email or sms message',
  passwordChangeFailedCheckYourSecurityCode: 'Password change failed, check your security code.',
  show: 'Show',
  reset: 'Reset',
  inviteFirstAdmin: 'Invite your first administrator',
  inviteFirstPatient: 'Invite your first patient',
  inviteAnAdmin: 'Invite an Admin',
  sendInvite: 'Send Invite',
  inviteAdmin: 'Invite Admin',
  personalDetails: 'Personal Details',
  userDetails: 'User Details',
  phoneNumber: 'Phone Number',
  phone: 'Phone',
  company: 'Company',
  email: 'Email',
  dob: 'Fecha de nacimiento',
  monthYear: 'Mes-Año',
  age: 'Edad',
  startDate: 'Fecha Inicio',
  endDate: 'Fecha Final',
  endsAt: 'Ends at',
  regimenDetails: 'Detalles del Régimen',
  regimenStartDate: ' Fecha Inicio del Régimen',
  regimenEndDate: ' Fecha Final Régimen',
  regimenLength: 'Duración del Régimen',
  activeFor: 'Activo por',
  usesSmartCap: 'Usa Smart Cap',
  notAvailable: 'no disponible',
  adminAppPrevileges: 'Privilegios Admin del App',
  adminWritePrevileges: 'Permiso Admin de edición',
  adminTimerRights: 'Triggers Timer',
  adminAccess: 'Acceso del admin',
  manageMultipleStudiesText: 'Puede crear y manejar varios estudios a la misma vez.',
  invitePatientMessage: 'Puede invitar pacientes a usar RXCap para monitorear su adhesion a la medicación.',
  medicationAdherenceMessage:
    'Los datos de adhesion a la Medicación se agregan automáticamente en su Portal del Proveedor.',
  showingDataFromAllPatients: 'Mostrando los datos de todos sus pacientes y estudios',
  downloadApp: 'Descargar el App',
  createYourFirstStudy_p1: 'Puede',
  createYourFirstStudy_p2:
    ', ver o editar cualquiera de sus estudios en la pestaña estudios. Con acceso rápido a las cifras de adhesión y tendencias de los pacientes. O profundice más para ver los datos de los pacientes y registros de tiempo de la medicación.',
  addAPatient_p1: 'Puede ',
  addAPatient_p2:
    ', ver y manejar los pacientes desde la pestaña pacientes. Cuando haya creado un ‘estudio’, podrá asignar pacientes directamente a un estudio cuando los agregue al portal.',
  addAPatient_2_p1: 'Agregó un estudió, pero no asignó ningun ',
  addAPatient_2_p2: '. Puede ',
  addAPatient_2_p3:
    ' y manejar todos sus pacientes desde la pestaña pacientes. Este panel se actualizará con datos analíticos y gráficos cuando se haya recolectado suficientes datos de los pacientes asignados.',
  inviteAnAdmin_p1: 'En la pestaña admin puede ',
  inviteAnAdmin_p2: ' y manejar privilegios de administración, y derechos de acceso para médicos e investigadores.',
  dashboardNoDataYet: 'Las funcionalidades del panel se desbloquearán cuando haya más datos disponibles',
  medicationsMap: ' Mapa de Medicaciones ',
  signIn: 'Ingresar',
  signUp: 'Registrarse',
  patientAddedSuccessfully: 'Paciente agregado correctamente',
  patientSavedSuccessfully: ' Paciente guardado correctamente',
  welcomeToRxCap: 'Bienvenido a RxCap',
  welcomeToApp: 'Welcome al Portal para Proveedores de RXCap ',
  welcome: ' Bienvenido',
  loadingFhirSessions: 'Cargando  sesión de FHIR...',
  password: 'Contraseña',
  confirmPassword: 'Confirmar Contraseña',
  passwordChangeFailedCheckYourSercuityCode: 'Password change failed, check your security code.',
  forgotPassword: '¿Olvidó su contraseña?',
  createAccount: 'Crear Cuenta',
  createRxCapAccount: 'Crear Cuenta RXCap',
  create: 'Crear',
  addAPatient: 'Agregar un Paciente',
  appTitle: 'La Plataforma de RXCap ',
  loginToRxCap: 'Ingresar a RX-Cap',
  loginToLidSync: 'Ingresar a Lid Sync',
  loginTo: 'Ingresar a ',
  lidSync: 'Lid Sync',
  setPasswordToRxCap: 'Configurar contraseña',
  invitePatient: 'Invitar Paciente',
  addMedicine: 'Agregar Medicamento',
  editMedicine: 'Edit Medicine',
  summary: 'Resumen',
  youAreAllDone: "You're All Done",
  youAreAllDoneName: 'Add next patient(s)/medication',
  youFinishedSetup: "You've finished the set up for {0}",
  finishedRpmSetup: "You've finished the set up for your organization",
  mobileVersionNotSupported: 'Versión móvil no tiene soporte',
  editProfile: 'Editar Perfil',
  editPatientInfo: 'Editar Info del Paciente ',
  changePassword: 'Cambiar la Contraseña',
  adherence: 'Adherencia',
  adherenceForPeriod: 'Adherence for period',
  accuracy: 'Exactitud',
  study: 'Estudio',
  search: 'Búsqueda',
  searchLog: 'Search Log',
  leave: 'Salir',
  patientCount: 'Cantidad de Pacientes',
  createStudy: 'Crear un Estudio',
  editStudy: 'Editar un Estudio',
  country: 'País',
  introduction: 'Introducción',
  editPersonalSettings: 'Editar su configuración personal',
  dateOfBirth: 'Fecha de nacimiento',
  city: 'Ciudad',
  mobileNumber: 'Número de Móvil',
  confirmPassword: 'Confirmar Contraseña',
  confirmDelete: 'Confirmar Borrar',
  confirmAssignment: 'Confirm Assignment',
  newPassword: 'Nueva Contraseña',
  showPassword: 'Mostrar Contraseña',
  hidePassword: 'Ocultar Contraseña',
  oldPassword: 'Contraseña Anterior',
  resetPassword: 'Restablecer Contraseña',
  byEmail: 'Por Email',
  bySMS: 'Por SMS',
  next: 'Siguiente',
  later: 'Más tarde',
  watchNow: 'Mirar Ahora',
  allDay: 'Todo el día',
  yourAllDone: 'Listo!',
  videoInst:
    'Genial, su dispositivo está configurado y listo para usar. Antes de comenzar a usarlo, recomendamos que mire un tutorial rápido sobre cómo usarlo.',
  esiDoctorInviteText:
    'Está invitado a ser admin en RxCap. Para completar el proceso de verificación, ingrese su número de móvil, y luego el código de verificación.',
  esiDoctorCodeText:
    'Le enviamos un código de verificación de 6 dígitos. Ingréselo abajo para verificar su cuenta. El código vencerá en: ',
  esiDoctorAlmostDone: 'Casi listo; use las credenciales que se le enviaron por email para ingresar a su cuenta.',
  resetByEmail: 'Restablecer por Email',
  resetBySMS: 'Restablecer por SMS',
  resend: 'Reenviar',
  withYourAccount: 'con su cuenta',
  weSentYouEmail: 'Le enviamos un Email',
  ok: 'Ok',
  enterNewPasswordMessage: 'Ingrese la nueva contraseña que desea usar para',
  weSentVerificationCode: ' Le enviamos un código de verificación',
  yourRxCapAccountBelow: 'su cuenta de RXCap abajo',
  congratulations: 'Felicitaciones',
  enterBelowToContinue: 'Ingréselo abajo para continuar',
  thisOptionIsrequired: 'Esta opción es obligatoria',
  notFound: 'No se encontró',
  hi: 'Hola',
  lblResetPassword: 'Ingrese la dirección de email asociada con',
  lblResetPasswordRx: 'la siguiente cuenta de RX-CAP:',
  enterEmailAddressAssociated: 'Ingrese el email asociado',
  enterPhoneNumberAssociated: 'Ingrese el número de móvil asociado con su cuenta.',
  enterPhoneNumber2nd: ' Si tiene problemas, contacte:',
  resetPasswordMessage: ' Si olvidó su contraseña, elija uno de estos métodos para restablecerla:',
  appSubtitle:
    'Monitoree el uso de su medicación, o si es médico, obtenga información valiosa sobre la adherencia de su paciente a la medicación con nuestra app móvil y la solución SmartCap.',
  requestDeleteText: 'Usted solicitó borrar su cuenta RXCap. ',
  requestConfirmText:
    'Confirme que comprende que esta operación es <span class="color-brand-red"><strong>irreversible</strong></span> ',
  requestToContinue: 'y presione el botón para continuar.',
  iAmAwareText: 'Comprendo que si continúo ',
  irreversiblyText: 'se borrarán de forma irreversible todos los datos',
  aboutMeText: 'almacenados sobre mí en RXCap.',
  deleteBtn: 'Borrar la cuenta',
  verificationCompleteText: 'Verificación completada ',
  reviewThePatientText:
    'Revise la información del perfil del paciente, luego presione el botón siguiente para revisar sus medicaciones actuales.',
  nextBtnText: 'Siguiente',
  weAreSorryText:
    'Lo lamentamos, pero el punto final de su variable clínica FHIR  no parece coincidir con el punto final de la orden de medicamento.Contacte a su administrator EHR para obtener más información.',
  reviewYourPatient: 'Revise la medicación de su paciente para seleccionar las que quiere asociar con su cuenta RXCap.',
  sendInviteText: 'Enviar Invitación',
  patientNameText: 'Nombre del Paciente',
  inviteBySMSText: 'Invitar por SMS',
  toFinishTheSetupText:
    'Para terminar el proceso de configuración, agregue el número de teléfono del paciente y haga clic',
  invitationSentText: 'Invitación enviada',
  adminInviteSent: 'Invitación de Admin enviada',
  youHaveBeenInvitedText: 'Su médico le invita a usar RXCap para cumplir',
  trackOfYourMedicineText: ' el regimen de su medicación. Ingrese la información solicitada por',
  toVerifyYourAccountText: ' para verificar su cuenta.',
  verifyAccountText: 'Verifique su cuenta',
  yourDSAccountActivatedText:
    'Su cuenta RXCap ya está activa; puede ingresar en el app de RXCap. Busque en la casilla de correos de su teléfono el enlace de Descarga para bajar el app en su teléfono iPhone o Android',
  yourDSAccActivatedText: 'Su cuenta RXCap ya está activa.',
  checkYourInbox: 'Busque en su casilla de correos o haga clic en el link correspondiente abajo para descargar el app.',
  loadingFHIRSession: 'Cargando sesión de FHIR...',
  enterPhoneNumberThatCanBeUsedToVerifyYourIdentity:
    'Ingrese un número de teléfono que se pueda usar para verificar su identidad con un mensaje de texto.',
  noSMSReminderText: '¿Sin recordatorios por SMS?',
  weHaveSentCodeText: 'Enviamos un código de verificación temporal a:',
  enterTheCodeText: '. Ingrese el código a continuación para verificar el número.',
  didnText: '¿No',
  tReceiveACode: ' recibió el código?',
  digitVerificationCode: 'Código de verificación de 6 dígitos',
  yourText: 'Su ',
  verifiedText: ' se ha verificado correctamente.',
  editNotifcationSettingsText: 'Editar configuración de notificaciónes',
  chagneSettingForText: 'Cambiar configuración para',
  dsUsesSmartText:
    'RXCap usa notificaciones inteligentes que irán intensificandose para enviarle recordatorios de medicación, via email, mensajes texto SMS, o llamadas telefónicas. Podremos enviarle también información sobre actualizaciones importantes.',
  selectWhichNotificationText: 'Seleccionar las notificaciones que quiere recibir de RXCap',
  emailsText: 'Emails',
  smsText: 'SMS',
  voiceText: 'Voz',
  takenAt: 'Tomado A',
  dailyDataText: 'Los datos diarios comienzan a las 6 am',
  clickToTakeText: '¡Haz clic para tomarlo ahora!',
  dateText: 'Fecha',
  timeDueText: 'Hora a la que se debe tomar',
  medicationNameText: 'Nombre de la Medicación',
  frequencyText: 'Frecuencia',
  areYouSure: '¿Está seguro?',
  areYouSureText: '¿Seguro de que quiere borrar ',
  addMedicationText: 'Agregar Medicación',
  dosageText: 'Dosis - ',
  daysText: 'Días - ',
  timesText: 'Horas -',
  dosage: 'Dosis',
  times: 'Horas',
  delete: 'Borrar',
  edit: 'Editar',
  launch: 'Launch',
  unsuspend: 'Unsuspend',
  createYourFirstStudy: 'Crear su primer estudio',
  whenToTakeMedicine: '¿Cuándo tomar el medicamento?',
  frequency: 'Frecuencia',
  asNeeded: 'Cuando se necesite',
  everyDay: 'Todos los días',
  specificDays: 'Días específicos',
  interval: 'Intervalo',
  howLongToTakeMedicine: '¿Cuánto tiempo tomar el medicamento?',
  days: 'Días',
  weeks: 'Semanas',
  daysOfweek: 'Días de la semana',
  afterHowManyDays: '¿Después de cuántos días?',
  monday: 'Lunes',
  tuesday: 'Martes',
  wednesday: 'Miércoles',
  thursday: 'Jueves',
  friday: 'Viernes',
  saturday: 'Sábado',
  sunday: 'Domingo',
  timeForTakingMedicine: 'Hora de tomar el medicamento',
  administrationWindow: 'Ventana administrativa',
  medicineName: 'Nombre del medicamento',
  dose: 'Dosis',
  attach: 'Adjuntar',
  review: 'Revisar',
  capName: 'Nombre de la tapa',
  capId: 'ID de la tapa',
  hubId: 'ID del Hub',
  devicesPlural: {
    hub: 'Hubs',
    ble: 'BLE Caps',
    lte: 'LTE Caps',
    lsc: 'LSC Caps',
  },
  devicesEnum: {
    Kit: 'Kit',
    Datahub: 'Datahub',
    'Bluetooth Cap': 'Bluetooth Cap',
    'LTE Pillbox': 'LTE Pillbox',
    'LTE Smart Cap': 'LTE Smart Cap',
    'Injectable Medication Tracker': 'Injectable Medication Tracker',
    'MDP Dispenser': 'MDP Dispenser',
    'Blood Pressure Monitor': 'Blood Pressure Monitor',
    'Weighing Machine': 'Weighing Machine',
    'Wrist Band': 'Wrist Band',
    Glucometer: 'Glucometer',
    Thermometer: 'Thermometer',
    Inhaler: 'Inhaler',
    Spirometer: 'Spirometer',
    'Pulse Oximeter': 'Pulse Oximeter',
    'Air Quality Monitor': 'Air Quality Monitor',
    'Hailie Inhaler': 'Hailie Inhaler',
    'PT/INR Monitor': 'PT/INR Monitor',
  },
  devicePluralsEnum: {
    Kit: 'Kits',
    Datahub: 'Datahubs',
    'Bluetooth Cap': 'Bluetooth Caps',
    'LTE Pillbox': 'LTE Pillboxes',
    'LTE Smart Cap': 'LTE Smart Caps',
    'Injectable Medication Tracker': 'Injectable Medication Trackers',
    'MDP Dispenser': 'MDP Dispensers',
    'Blood Pressure Monitor': 'Blood Pressure Monitors',
    'Weighing Machine': 'Weighing Machines',
    'Wrist Band': 'Wrist Bands',
    Glucometer: 'Glucometers',
    Thermometer: 'Thermometers',
    Inhaler: 'Inhalers',
    Spirometer: 'Spirometers',
    'Pulse Oximeter': 'Pulse Oximeters',
    'Air Quality Monitor': 'Air Quality Monitors',
    'Hailie Inhaler': 'Hailie Inhalers',
    'PT/INR Monitor': 'PT/INR Monitors',
  },
  id: 'ID',
  hubName: 'Nombre del Hub',
  or: 'O',
  capsAdmin: 'Administrador de las tapas',
  searchNewCap: 'Buscar una tapa nueva',
  addCapsAdmin: 'Agregar un administrador de las tapas',
  addDoctor: 'Agregar médico',
  doctors: 'Médicos',
  attachCapsToAdmin: 'Adjuntar tapas al Administrador',
  attachCap: 'Adjuntar tapa',
  attachCap: 'Adjuntar tapa',
  attachDevice: 'Adjuntar tapa',
  devicesToAttach: 'Devices to attach',
  SendDeviceSecurityCode: 'Enviar código de seguridad del dispositivo',
  AddEditHardware: 'Ingresar la ID del dispositivo',
  enter6DigitCode: 'Ingresar el código de 6 caracteres o el ID de hardware impreso en la tapa',
  eventTimestamp: 'Fecha del evento',
  lastEventTimestamp: 'Fecha del ultimo evento',
  eventTime: 'Hora del evento',
  eventDate: 'Fecha del evento',
  eventType: 'Tipo de evento',
  physioParams: 'Parámetros fisiológicos',
  max: 'Max',
  min: 'Min',
  condition: 'Condición',
  conditions: 'Conditions',
  attachCapToAdmin: 'Adjuntar tapa al Admin',
  locations: 'Lugares',
  location: 'Lugar',
  state: 'Estado',
  organizationName: 'Nombre de la organización',
  devices: 'Dispositivos',
  deletedDevices: 'Deleted Devices',
  temperature: 'Temperatura',
  charge: `Cargo', or ‘Cargar`,
  action: 'Acción',
  eventList: 'Lista de eventos',
  version: 'Versión ',
  timezone: 'Zona horaria',
  selectStartAdministrativeWindow: 'Seleccionar la ventana administrativa de inicio',
  selectEndAdministrativeWindow: 'Seleccionar la ventana administrativa de fin',
  idleSessionMessage: 'Su sesión está por vencer. Presione el botón "Extender", para evitar que se cierre.',
  extend: 'Extender',
  recoverPassword: 'Recuperar la contraseña',
  showingXOf: 'Showing {0} of {1} {2}',
  showingX: 'Showing {0} {1}',
  showingXHubs: {
    showing: 'Mostrando',
    of: 'de',
    hubs: 'Hubs',
  },
  showingXDevices: 'Mostrando {0} de {1} Dispositivos',
  showingXPatients: 'Mostrando {0} de {1} Patients',
  showingXCohorts: 'Showing {0} of {1} Cohorts',
  devicesInThisArea: 'Dispositivos en el área',
  activeHubs: 'Hubs activos',
  pendingHubs: 'Hubs pendientes',
  hubs: 'Hubs',
  scales: 'Balanza',
  bpMonitors: 'Monitores BP',
  bp: 'BP',
  pendingCalls: 'Pending Calls',
  pendingMessages: 'Pending Messages',
  enrolledPatients: 'Enrolled Patients',
  pendingEnrollment: 'Pending Enrollment',
  totalKits: 'Total Kits',
  inLast: 'En el/los ultimo/s',
  noReadings: 'No hay lecturas',
  showingWait: 'Mostrando...',
  deviceReadings: 'Lecturas del dispositivo',
  yourKits: 'Tus equipos',
  kits: 'Equipos',
  widgets: {
    yourKitsChart: 'Gráfico de tus equipos',
    deviceReadingsChart: 'Gráfico de lecturas de tu/s dispositivo/s',
    numberOfDevices: 'Cantidad de dispositivos',
    numberOfHubs: 'Cantidad de Hubs',
    numberOfHubsRealTime: 'Number of Hubs (real-time)',
    numberOfHubsDaily: 'Number of Hubs (daily)',
    numberOfHubsMonthly: 'Number of Hubs (monthly)',
    locationsOfYourKits: 'Ubicaciones de tus equipos',
    hubsWithoutReadings: 'Hubs sin lecturas',
    hubsActivedLastMonth: 'Hubs Activated Last Month',
    monthlyActiveHubs: 'Monthly Active Hubs',
    monthlyOnlineHubs: 'Monthly Online Hubs',
    dailyOnlineHubs: 'Daily Online Hubs',
    dailyActiveHubs: 'Daily Active Hubs',
    monthlyOperatingRate: 'Monthly Operating Rate',
    hubsActivated24h: 'Hubs activated in last 24h',
    totalHubs: 'Total Hubs',
    patientActions: 'Patient Actions',
    unreadMessages: 'Unread Messages',
    patientsEnrollment: 'Patients Enrollment',
    totalKits: 'Total Kits',
    patientsWithNoReadings: 'Activated Patients with no readings',
    patientsWithNewNotes: 'Patients with new notes',
    patientsToCall: 'Patients to Call',
    details: 'Details',
    people: 'People',
    hubsActivatedToday: 'Hubs Activated Today',
    daysComplied: 'Days Complied',
    devicesActivatedToday: '{0} Activated Today',
    numberOfBleRealTime: 'Number of BLE caps (real-time)',
    numberOfLteRealTime: 'Number of LTE caps (real-time)',
    numberOfLscRealTime: 'Number of LSC caps (real-time)',
    programDistribution: 'Program Distribution',
    patientStatus: 'Patient Status',
  },
  widgetsTooltips: {
    active_number_real_time: 'Number of {0} which sent at least one measurement in the last 24 hours',
    new_number_real_time: 'Number of {0} that have never sent a reading since they were assigned to the organization',
    offline_number_real_time: "Number of {0} which didn't connect to the network in the last 7 days",
    online_number_real_time: 'Number of {0} connected to the network in the last 7 days',
    total_number_real_time: 'Number of all {0} assigned to the organization',
    activated_number_real_time: 'Number of {0} activated today',
    active_number_daily: 'Number of {0} which sent at least one measurement yesterday',
    new_number_daily:
      'Number of {0} that have never sent a reading since they were assigned to the organization as of yesterday',
    offline_number_daily: "Number of {0} which didn't connect to the network yesterday",
    online_number_daily: 'Number of {0} connected to the network yesterday',
    total_number_daily: 'Number of all {0} assigned to the organization as of yesterday',
    activated_number_daily: 'Number of {0} activated yesterday',
    active_number_monthly: 'Number of {0} which sent at least one measurement in the previous month',
    new_number_monthly:
      'Number of {0} that have never sent a reading since they were assigned to the organization as of previous month',
    offline_number_monthly: "Number of {0} which didn't connect to the network in the previous month",
    online_number_monthly: 'Number of {0} connected to the network in the previous month',
    total_number_monthly: 'Number of all {0} assigned to the organization as of previous month',
    activated_number_monthly: 'Number of {0} activated in the previous month',
    monthlyOperatingRate:
      'Average of connected rate (Days having a connection at least once / eligible days in the previous month) of all activated hubs',
    activated_number_24h: 'Number of {0} activated in the last 24 hours',
    numberOfDaysComplied: 'Number of days complied since {0} (for code {1})',
    inTheLastXDays: 'In the last {0} days',
  },
  errors: {
    requiredStudyNameAndDescription: 'Su estudio requiere un nombre y una descripción',
    longStudyName:
      'Los nombres largos de los estudios pueden no mostrarse completos, dependiendo de la resolución de pantalla en la que se vea.',
    invalidCapId: 'Id de tapa inválida',
    invalidDeviceId: 'Invalid Device id',
    enterAnyOne: 'Ingresar la id de cualquier tapa',
    endTime: 'Hora final debe ser mayor que la hora de inicio',
    timeInTheFuture: 'Start time and end time should not be a future date',
    dateInTheFuture: 'Date should not be a future date',
    dateInThePast: 'Date should be a future date',
    emptyOrgId: 'Seleccionar la organización',
    emptyCapId: 'Se debe ingresar un id de tapa válido',
    emptyDeviceId: 'Valid device id is required',
    emptyMedicineName: 'Se debe ingresar el nombre del medicamento',
    emptyHowLongToTakeMedicine: 'Se debe ingresar la cantidad de días',
    emptyHowLongToTakeMedicineWeek: 'Se debe ingresar la cantidad de semanas',
    validHowLongToTakeMedicine: 'Ingresar una cantidad válida de días/semanas',
    validHowLongToTakeMedicineDays: 'Ingresar una cantidad válida de días (menos de 999)',
    validHowLongToTakeMedicineWeeks: 'Ingresar una cantidad válida de semanas (menos de 999)',
    scheduleTypeRequired: 'Seleccione cuándo tomar el medicamento',
    invalidDays: 'Ingresar días válidos',
    duplicateTimeForTake: 'La hora de tomar el medicamento está duplicada',
    administrativeWindowRequiredForMultipleTakes: 'Ingresar la ventana administrativa para tomar el medicamento',
    timeBetweenMedicineTooShort: 'El tiempo entre medicamentos es muy corto',
    emptyValidHowManydays: 'Ingresar una cantidad válida de días',
    emptyTakenTime: 'Ingresar la hora para tomar el medicamento',
    emptyDaysOfWeek: 'Seleccionar los días de la semana',
    connectionError: 'Revise su conexión a internet e intente nuevamente',
    internalError: 'Algo salió mal. Intente nuevamente',
    unknownError: 'Error desconocido. Intente nuevamente',
    invalidEmail: 'Ingrese una dirección de email válida',
    emptyEmailAddress: 'Ingresar dirección de email',
    emptyNickName: 'Ingresar el sobrenombre',
    emptyFirstName: 'Ingresar el nombre',
    emptyOrgName: 'Ingresar el nombre de la organización',
    emptyIsRequired: 'Ingresar',
    emptyOldPassword: 'Ingresar la contraseña anterior',
    emptyConfirmPassword: 'Confirmar la contraseña',
    emptyNewPassword: 'Ingresar la nueva contraseña',
    passwordDoesNotMatched: 'Las contraseñas no coinciden',
    emptyLastName: 'Ingresar el apellido',
    emptyCode: 'Ingresar el código',
    emptyPhoneNumber: 'Ingresar el número de teléfono',
    emptyName: 'Ingresar el nombre',
    emptyDosage: 'Ingresar la dosis.',
    emptyPatient: 'Ingresar el paciente.',
    selectOneDay: 'Seleccionar al menos un día.',
    addMedicationTime: 'Agregar al menos un horario de medicación.',
    invalidPhoneNumber: 'El número de teléfono no es válido',
    passwordNotMatched: 'Error en el nombre de usuario o la contraseña',
    pleaseTryAgain: 'Intente nuevamente.',
    invalidDOB: 'Ingrese una fecha de nacimiento válida.',
    enterValidText: 'Ingresar válido',
    invalidPassword:
      'Su contraseña debe tener al menos 8 caracteres de largo e incluir 1 número, 1 símbolo (~!*^@#$%&), 1 mayúscula y 1 minúscula.',
    invalidActivationCode: 'El código de activación no es válido.',
    noPhoneNoEmail: 'Debe ingresar su email o número de teléfono',
    phone: {
      notValid: 'El teléfono no es válido',
      invalidCountryCode: 'El código de país no es válido',
      notANumber: 'No es un número',
      tooLong: 'El número es demasiado largo',
      tooShort: ' El número es demasiado corto',
    },
    unrecognisedNumber: 'No se reconoce el número',
    unrecognisedNumberText:
      'El número de teléfono que ingresó no coincide con el que tenemos para usted en nuestro sistema.',
    unrecognisedNumberEsiPatientText: [
      'Vuelva a ingresar el número de teléfono o llámenos para obtener asistencia. ',
      <a href="mailto:support@lidsync.com">support@lidsync.com</a>,
      ' or ',
      <a href="tel:+1 888-618-0463">+1 888-618-0463</a>,
    ],
    invalidCode: 'Código inválido',
    invalidCodeText:
      'El código que ingresó no es válido, intente nuevamente, o presione reenviar para recibir un código nuevo.',
    invalidCodeEsiPatientText: [
      'El código que ingresó no es válido, intente nuevamente o pida ayuda en ',
      <a href="mailto:support@lidsync.com">support@lidsync.com</a>,
      ' or ',
      <a href="tel:+1 888-618-0463">+1 888-618-0463</a>,
    ],
    invalidName: 'Ingresó un nombre inválido',
    invalidFirstName: 'Ingresó un nombre inválido',
    invalidLastName: '‘Ingresó un apellido inválido',
    invalidOrgName: 'Ingresó un nombre de organización inválido',
    nothingToChange: 'There is nothing to change',
    deviceIdError: 'Device id shall be shorter than 100 characters',
    settingLimitsError: 'Setting limits error',
    maxValueExceeded: 'Maximum allowed value is {0}',
    startDateCantBeInThePast: 'Start date can not be in the past',
    endDateMustBeAfterStartDate: 'End date must be after start date',
    endDateMustBeTomorrow: 'End date must be tomorrow or later',
    endDateMustBeBeforeEnrollmentEndDate: 'End date must be before enrollment end date',
  },
  warnigs: {
    cantBeUndone: 'Cuidado: esta acción no puede deshacerse.',
    deleteCaregiver: '¿Seguro de que quiere borrar el cuidador',
    adminAppPrevilegesWarning: '¿Seguro de que quiere cambiar los privilegios de Admin del App para este médico?',
    adminDelete: 'Esta acción no puede deshacerse. ¿Está seguro de que quiere borrar al siguiente administrador?',
    adminResetPassword: '¿Está seguro de que quiere restablecer la contraseña para el siguiente usuario?:',
    resendInvite: 'Are you sure you want to resend the invitation to the following user:',
    inviteFirstAdminWarning: ' Para invitar a su primer administrador ingrese la dirección de email.',
    inviteFirstPatientWarning: ' Para invitar a su primer paciente ingrese la dirección de email.',
    longStudyName:
      'Los nombres largos de los estudios pueden no mostrarse completos, dependiendo de la resolución de pantalla en que la que se vea.',
    deletePatientWarning:
      'When patient is deleted, all their Medication schedules and notifications will be stopped and all active alerts will be cleared.',
  },
  success: {
    deleteAccountSuccess: 'La cuenta se ha borrado correctamente.',
    dataSavedSuccessfully: 'Los datos se han guardado correctamente.',
    passwordResetSuccess:
      'Su contraseña de RXCap se ha restablecido, presione ingresar a continuación para acceder a su cuenta con sus nuevas credenciales',
    invitationConfirmedSuccessfully: 'Su invitación se ha confirmado con éxito.',
    medicationSaved: 'Medication saved successfully',
  },
  placeholder: {
    phoneNumber: 'Ingresar número de teléfono',
    emailAddress: 'Ingresar dirección de email',
    medicationName: 'Ingresar nombre de la medicación',
    dosageName: 'Ingresar dosis aquí',
    newTimeZone: 'Agregar nueva hora',
    userName: 'Nombre de usuario',
    password: 'Contraseña',
    confirmPassword: 'Confirmar contraseña',
    code: 'Código',
    cgPhoneNumber: 'Número de teléfono',
    notesArea: 'Notas',
  },
  battery: {
    level: 'Nivel de batería',
    battery: 'Batería',
    high: 'Bueno',
    medium: 'Medio',
    low: 'Bajo',
    noData: 'No disponible',
  },
  esiPatientStatus: {
    capInUse: 'La tapa está en uso',
    pending: 'Pendiente',
    shipping: 'En proceso de envío',
    inProgress: 'En progreso',
    ready: 'Listo',
    active: 'Activo',
  },
  patientDashboard: {
    upcomingReminders: 'Próximos recordatorios',
    activeMedications: 'Medicaciones activas',
    stockManagement: 'Pastillas disponibles',
    seeAll: 'Ver todo',
    seeDetails: 'Ver detalles',
    calendar: 'Calendario',
    viewHistory: 'Ver historial',
    lastWeek: 'Semana pasada',
    lastMonth: 'Mes pasado',
    allMeds: 'Todos los medicamentos',
    all: 'Todo',
    allOrg: 'Todas las organizaciones',
    noOrg: 'Ninguna organización',
    selectOrg: 'Seleccionar organización',
    doses: 'Pastillas',
    dose: 'Pastilla',
  },
  esiPatientInfo: {
    street: 'Calle',
    appartment: 'Apartmento/Suite',
    zip: 'ZIP',
    city: 'Ciudad',
    state: 'Estado',
    country: 'País',
    schedule: 'Horario',
    device: 'Dispositivo',
    time: 'Hora',
    activeFrom: 'Activo desde',
  },
  patient_medications: {
    medication: 'Medicamento',
    activeMedications: ' Medicamentos activos',
    stockDoses: 'Stock (dosis)',
    active: 'Activo',
    inActive: 'Inactivo',
    schedule: 'Horario',
    name: 'Nombre',
    frequency: 'Frecuencia',
    timesPerDay: 'veces por día',
    startDate: 'Fecha de inicio',
    endDate: 'Fecha final',
    lastTaken: 'última vez Tomado',
    nextReminder: 'Próximo recordatorio',
    stock: 'Stock',
    numberOfPill: 'Cantidad de pastillas',
    dosesInStock: 'Dosis disponibles',
    pillsInHand: 'Pastillas disponibles',
    tooMayPillsWaring: 'Se agregaron demasiadas pastillas. Se redujo la cantidad de pastillas a ',
    willLastFor: 'Alcanzan para ',
    expiresOn: 'Expira el',
    edit: 'Editar',
    hardwareId: 'ID de hardware',
    device: 'Dispositivo',
    battery: 'Batería',
    activationDate: 'Fecha de activación',
    learnMore: 'Más información',
    refill: 'Reabastecer',
    time: 'Hora',
    times: 'Time(s)',
    am: 'AM',
    pm: 'PM',
    addPills: 'Agregar (Pastillas)',
    totalPills: 'Total (Pastillas)',
    zero: '0',
    doseRemaining: 'pastillas restantes',
    thisWillLastFor: 'Esto alcanzará hasta el',
    downloadFullMedlist: 'Descargar lista completa de medicamentos',
    downloadFilteredMedList: 'Descargar lista filtrada de medicamentos',
    last2Months: 'Últimos 2 meses',
    last6Months: 'Last 6 Months',
    lastWeek: 'Semana pasada',
    lastMonth: 'Mes pasado',
    assignedCap: 'Assigned cap',
    reminders: {
      early: 'Early Reminder',
      on_time: 'On-Time Reminder',
      late: 'Late Reminder',
      missed_doses: 'Missed Doses Reminder',
    },
    remindersTypes: {
      early: 'Early',
      on_time: 'On-Time',
      late: 'Late',
    },
    numberOfDoses: '# of doses',
    defaultDoses: '2 doses',
    tagsForReminders: 'Tags to be used in reminders messages:',
    reminderCharLimit: 'Messages must be less than 320 charecters long',
    enter3Chars: 'Enter at least 3 characters to search',
  },
  endUserLicenseAgreement: 'Acuerdo de licencia de usuario final',
  privacyPolicy: 'POLÍTICA DE PRIVACIDAD EN INTERNET',
  privacyPolicyTrust: 'Política de privacidad',
  termsOfUse: 'Condiciones de uso',
  termsAndConditions: 'Terms and Conditions',
  esiPatientLoginStep1: 'Para acceder a su cuenta Lid Sync, ingrese su número de teléfono móvil a continuación.',
  esiPatientLoginStep2:
    'Le enviamos un código de verificación de 6 dígitos. Ingréselo a continuación para verificar su cuenta. El código vencerá en: ',
  yourTrustIsImportantToUs: 'Su confianza es importante para nosotros',

  toProceedYouNeedToAcceptTheFollowing: 'Para continuar, debe aceptar lo siguiente',
  capsRequiredList: {
    '38mm': 'Se necesitan tapas LS002',
    '42mm': 'Se necesitan tapas LS001',
  },
  capsTitles: {
    '38': 'Tapas 38 mm',
    '42': 'Tapas 42 mm',
  },
  updateOrder: 'Actualizar la orden',
  updateCap: 'Actualizar la tapa',
  updateCap: 'Actualizar la tapa',
  registerCap: 'Registrar la tapa',
  hardwareIdUpdatedSuccessfully: 'Se actualizó correctamente el ID de Hardware',
  registerYourSmartCap: 'Registre su Tapa Inteligente',
  activateYourDeviceTitle: 'Active su dispositivo',
  activateYourDevice:
    'Active su dispositivo por SMS. Su código de activación único de 6 dígitos está impreso en la etiqueta adherida a su dispositivo. Envíe un texto con el código a:',
  setYourTimezone: 'Configure su zona horaria',
  setTimezoneInst:
    'Por último, seleccione su zona horaria para que podamos asegurar que los horarios de sus medicamentos sean correctos. Puede cambiar esto más adelante en la configuración.',
  smartCapInst:
    'Encuentre el ID de la tapa con 6 dígitos que está impreso encima del empaque de su tapa (y en la parte de atrás del folleto de instrucciones), e ingrese el número a continuación para registrarla.',
  findTheSmartCapID:
    'Encuentre el ID de la tapa inteligente impreso afuera del empaque de su tapa, e ingrese el número a continuación para registrarla.',
  packageTrackingID: 'ID para rastrear el paquete',
  trackingStatus: 'Estado del envío',
  fieldIsRequired: 'Este campo es necesario',
  fieldIsBelowZero: 'El campo tiene que ser mayor a cero',
  orderStatuses: {
    created: 'Creado',
    inTransit: 'En tránsito',
    shipped: 'Despachado',
    delivered: 'Entregado',
  },
  orderID: 'ID del pedido',
  orderDate: 'Fecha del pedido',
  trackingID: 'ID de rastreo',
  capsRequired: 'Tapas requeridas',
  twoFAFormCodeText: 'El código de una contraseña se envió a su teléfono móvil. Este código vencerá en: ',
  editSchedule: 'Editar horario',
  editStock: 'Editar pastillas disponibles',
  myDetails: 'Mis datos',
  myAddress: 'Mi dirección',
  myMedications: 'Mis medicamentos',
  editAddress: 'Editar dirección',
  apartmentSuiteOptional: 'Apartmento/Suite (opcional)',
  streetAddress: 'Dirección física',
  editDetails: 'Editar detalles',
  scheduledRemindersAreTurningOff:
    'No se recomienda realizar esta acción que detendrá todos los recordatorios para todos sus medicamentos activos actualmente.',
  scheduledRemindersAreTurningOn: 'Recibirá todos los recordatorios para todos sus medicamentos activos actualmente.',
  medicationReminders: 'Recordatorios de medicación',
  receiveWeeklyReports: 'Recibir informes semanales',
  addressUpdatedSuccessfully: 'dirección Actualizada correctamente',
  codeHasBeenResent: 'Código reenviado. Vencerá en: ',
  profileUpdated: 'perfil Actualizado',
  selectTimezone: 'Seleccionar zona horaria',
  history: 'Historial',
  stateHistory: 'State History',
  timeTaken: 'Hora que se tomó',
  scheduledDate: 'Scheduled Date',
  downloadLast24Months: 'Descargar los últimos 24 meses',
  downloadFilteredHistory: 'Descargar el historial filtrado',
  addFilter: 'Agregar el filtro',
  refresh: 'Refrescar',
  imei: 'IMEI',
  deviceImei: 'Device IMEI',
  capShortName: 'Cap Short Name',
  lastActivity: 'Last Activity',
  lastReading: 'Last Reading',
  pairingStatus: 'Pairing Status',
  pairingTime: 'Pairing Time',
  updated_at: 'Updated At',
  reminders: 'Reminders',
  reminder: 'Reminder',
  showReminders: 'Show Reminders',
  factoryMode: 'Factory mode',
  factory: 'Factory',
  customerMode: 'Customer mode',
  customer: 'Customer',
  sleepMode: 'Sleep mode',
  pairingMode: 'Pairing mode',
  on: 'On',
  off: 'Off',
  fota: 'FOTA',
  pins: 'Pins',
  readings: 'Lecturas',
  signalStrength: 'Intensidad de la señal',
  signalQualityEnum: {
    Excellent: 'Excellent',
    Good: 'Good',
    Medium: 'Medium',
    Weak: 'Weak',
  },
  problems: 'Problem(s)',
  signal: 'Señal',
  activity: 'Actividad',
  activityType: 'Tipo de actividad',
  value: 'Valor',
  qrCode: 'Código QR',
  faults: 'Faults',
  passwordInfo:
    'Su contraseña debe tener 8 caracteres y por lo menos 1 número, 1 símbolo (~!*^@#$%&), 1 mayúscula y 1 minúscula.',
  AGN: 'AGN',
  enrollmentDate: 'Fecha de inscripción',
  registeredDate: 'Fecha de registro',
  lastKnownBattery: 'Última batería conocida',
  lastKnownTemperature: 'Última temperatura conocida',
  lastKnownSignalStrength: 'Última intensidad de señal conocida',
  lowSignal: 'Señal baja',
  moderateSignal: ' Señal moderada',
  highSignal: 'Señal alta',
  lastKnownLocation: 'Último lugar conocido',
  valueHwCode: 'Valor (Código de HW)',
  current: 'Actual',
  remove: 'Eliminar',
  dosesRemaining: 'pastillas restantes',
  thisWillLastUntil: 'Esto durará hasta ',
  great: 'Genial ',
  youAreAllDone: 'Listo',
  requestDownloadData: 'Solicitó descargar sus datos. ',
  pleasePressTheButton: 'Presione el botón a la derecha para guardar el archivo en su disco duro.',
  scheduled: 'Programado',
  allTimes: 'Todos los horarios',
  allMedications: 'Todos los medicamentos',
  statusAll: 'Estado - Todo',
  statusMissed: 'Estado - Omitidos',
  statusUpcoming: 'Estado - Próximos',
  statusTaken: 'Estado - Tomados',
  readyOnly: 'Solo lectura',
  readAndWrite: 'Leer y escribir',
  enterPhoneNumber: 'Ingresar número de teléfono',
  learnMoreAboutInfo:
    'Más información sobre este medicamento, incluyendo las instrucciones de uso, efectos secundarios, interacciones medicamentosas, precauciones y conservación. Con tecnología de ',
  medicationC: 'Medication©',
  day: ' Día',
  daysStreak: ' Racha',
  errorEnterCapId: 'Ingresar el Id de la tapa',
  errorEnterDeviceId: 'Ingresar el Id del dispositivo',
  errorPaitentMedication: 'Error al guardar los datos de medicación del paciente',
  errorSavingPatient: 'Error al guardar los datos del paciente',
  allDays: ['LUNES', 'MARTES', 'MIÉRCOLES', 'JUEVES', 'VIERNES', 'SÁBADO', 'DOMINGO'],
  orderUpdatedSuccessfully: 'orden Actualizada correctamente',
  downloadCalendar: 'Descargar el calendario',
  download: 'Descargar',
  left: 'restantes',
  yourSuccessPreviousMonth: 'Su precisión durante el mes pasado',
  yourSuccessMonthly: 'Su precisión este mes',
  yourSuccessWeekly: 'Su precisión esta semana',
  good: 'Bien',
  average: 'Aceptable',
  bad: 'Mal',
  getTheApp: 'Obtener el App',
  tutorialTexts: {
    skipBtn: 'Saltar',
    dashTitle: 'Panel',
    dashboardInfo:
      'El panel brinda un resumen rápido de cómo está cumpliendo su regimen, los próximos recordatorios, los medicamentos activos y las alertas de stock.',
    medicationsTitle: 'Medicamentos',
    medicationsInfo:
      'Aquí puede ver y editar sus medicamentos activos e inactivos, ver sus horarios y ver de un vistazo los medicamentos que ha tomado u omitido.',
    calendarTitle: 'Calendario',
    calendarInfo:
      'El calendario es la forma más rápida de ver los medicamentos que tiene para tomar cualquier día específico, y para ver qué días tiene agendados en todo el mes.',
    historyTitle: 'Historial',
    historyInfo:
      'Si necesita confirmar si tomó u omitió un medicamento un día anterior, o revisar o descargar cualquier período de tiempo vaya al Historial. ',
    settingsTitle: 'Configuración',
    settignsInfo:
      'Configuración le permite revisar sus datos personales (y editar algunos), y cambiar su idioma o zona horaria,  y repasar los recordatorios.',
    supportTitle: 'Soporte',
    supportInfo:
      'Si necesita asistencia técnica para su tapa, el app o el sitio web, estamos aquí para ayudarlo en el chat, el centro de ayuda, o por email o teléfono.',
  },
  adminDashboard: {
    numberOfParticipants: 'Participantes',
    numberOfActivatedCaps: 'Tapas activadas',
    missed2ConsecutiveDoses: 'omitidas 2 dosis consecutivas',
    missed5ConsecutiveDoses: 'omitidas 5 dosis consecutivas',
    medicationTaken: 'Tomado',
    medicationMissed: 'Omitido',
    top5LocationByEvents: '5 lugares principales (según los eventos)',
    evolutionOfCapActivations: 'Evolución de las activaciones de tapas',
    adherenceStats: 'Adherence Statistics',
  },
  organization: 'Organización',
  reason: 'Razón',
  organizations: 'Organizaciones',
  provisioning: 'Suministro',
  testDevice: 'Probar el dispositivo',
  apiURL: 'URL del API',
  fotaURL: ' URL de FOTA ',
  features: 'Funcionalidades',
  deviceManagement: 'Dispositivos',
  bluetooth: 'BLE Cap',
  weightScales: 'Balanzas',
  lte: 'Pillbox',
  lsc: 'LTE Cap',
  injectableTracker: 'Injectable Medication Tracker',
  mdp: 'Smart MDP Dispenser',
  bloodPressureMonitor: 'Blood Pressure Monitor',
  hub: 'Datahub',
  otherDevices: 'Otros dispositivos',
  report: 'Reporte',
  addOrganization: 'Agregar organización',
  editOrganization: 'Editar organización',
  organizationName: 'Nombre de la organización',
  enterOrganizationName: 'Ingresar nombre de la organización',
  organizationManagers: 'Gerentes de la organización',
  organizationContact: 'Organization Contact',
  capManagers: 'Administrador de las tapas',
  configuration: 'Configuración',
  dates: 'Fechas',
  startImmediately: 'Comenzar inmediatamente',
  date: 'Fecha',
  twoFAEnabled: '2FA',
  twoFAEnabledStatus: '2FA habilitadas',
  twoFADisabledStatus: '2FA deshabilitadas',
  capManager: 'Administrador de la tapa',
  adminTab: 'Admin',
  admins: 'Admins',
  devicesTab: 'Dispositivos',
  outboundAPITab: 'API saliente',
  externalApiOauthTab: 'API externo OAuth',
  externalApiAccessControlTab: 'Control de acceso a API externo',
  externalApiLogsTab: 'Registros de API externo',
  masterLog: 'Registro maestro',
  addOrganizationManager: 'Agregar gerente de la organización',
  editOrganizationManager: 'Editar gerente de la organización',
  active: 'Activo',
  urls: 'URLs',
  key: 'Clave',
  dateOfCreation: 'Fecha de creación (días)',
  addOutboundAPI: 'Agregar API saliente',
  editOutboundAPI: 'Editar API saliente',
  secretKey: 'Clave secreta',
  host: 'Anfitrión',
  url: 'URL',
  ip: 'IP',
  capActivityDetails: 'Detalles de actividad de la tapa',
  hubActivityDetails: 'Detalles de actividad del Hub',
  selectOrganization: 'Seleccionar',
  deviceId: 'ID del dispositivo',
  deviceName: 'Nombre del dispositivo',
  systolicPressure: 'Presión sistólica',
  diastolicPressure: 'Presión diástolica',
  pulseRate: 'Frecuencia cardíaca',
  meanArterialPressure: 'Promedio de presión arterial',
  deviceType: 'Tipo de dispositivo',
  deviceModel: `Modelo de dispositivo`,
  weight: 'Peso',
  pulse: 'Pulso',
  saturation: 'Saturación',
  oxygenSaturation: 'Oxygen Saturation',
  heartRate: 'Heart Rate',
  airQuality: 'Air Quality',
  movementReport: 'Movement report',
  stepsLowerCase: 'steps',
  steps: 'Steps',
  viewDetails: 'Ver detalles',
  hubDetails: 'Detalles del Hub',
  bloodPressure: 'Presión arterial',
  weeklyAvgBloodPressure: 'Weekly Avg. Blood Pressure',
  avgBloodPressure: 'Avg. Blood Pressure',
  pulseRateAndBloodPressure: 'Frecuencia cardíaca y presión',
  schedules: {
    schedules: 'Horarios',
    scheduleName: 'Nombre del horario',
    patient: 'Paciente',
    scheduleType: 'Tipo de horario',
    time: 'Hora',
    accuracy: 'Precisión',
    medication: 'Medicación',
    startDate: 'Fecha de inicio',
    endDate: 'Fecha final',
    addSchedule: 'Agregar horario',
    addCap: 'Agregar tapa',
    medication: 'Medicación',
    capId: 'ID de tapa',
    capName: 'Nombre de tapa',
    timeFor: 'Tiempo desde la última toma',
    lastActivityDate: 'Fecha de la última actividad',
    lastActivityTime: 'Hora de la última actividad',
    battery: 'Batería',
    addName: 'Agregar nombre',
    enterName: 'Ingresar nombre',
    search: 'Buscar',
    title1: 'Agregar horario a:',
    title2: 'Seleccionar medicamento',
    title3: 'Fijar horario',
    title4: 'Fechas',
    title5: 'Revisar',
    enableEndDate: 'Habilitar fecha final',
    disableEndDate: 'Deshabilitar fecha final',
    errorScheduleName: 'Ingresar nombre del horario',
    errorMedicationName: 'Seleccionar nombre del medicamento',
    errorSelectTime: 'Seleccionar hora',
    errorSelectTimezone: 'Seleccionar zona horaria',
    errorSelectStartDate: 'Seleccionar fecha de inicio',
    timezone: 'Zona horaria',
  },
  bpMonitor: 'Monitor de presión',
  scale: 'Balanza',
  ble: 'Bluetooth',
  billing: {
    billing: 'Facturación',
    lte: 'LTE',
    ble: 'Bluetooth',
    hub: 'HUB',
    ID: 'ID',
    nickname: 'Sobrenombre',
    costMonth: 'Costo/mes',
    added: 'Agregado',
    device: 'Tipo',
    tasks: 'Tareas',
    deviceEvents: 'Eventos del dispositivo',
  },
  capPatient: {
    watchedBy: 'Watched by',
    patientName: 'Nombre del paciente',
    enterPatientName: 'Ingresar nombre del paciente',
    enterFirstName: 'Ingresar nombre',
    enterLastName: 'Ingresar apellido',
    enrollment: 'Inscripción',
    enrollmentDate: 'Fecha de inscripción',
    organType: 'Tipo de órgano',
    downloadPatientData: 'Descargar datos del paciente',
    downloadNotesAndActions: 'Download Notes and Actions',
    mobileNumber: 'Mobile Number',
    phoneNumber: 'Número de teléfono',
    textPhoneNumber: 'Número de teléfono para mensajes de texto',
    primaryPhoneNumber: 'Número de teléfono primario',
    email: 'Email',
    address: 'Dirección',
    insurance: 'Seguro',
    insuranceName: 'Nombre del seguro',
    enterInsuranceName: 'Ingresar nombre del seguro',
    policyId: 'ID de póliza',
    enterPolicyId: 'Ingresar ID de póliza',
    groupNumber: 'Número de grupo',
    enterGroupNumber: 'Ingresar número de grupo',
    centerDetails: 'Detalles del centro',
    centerPracticeName: 'Nombre del centro/consultorio',
    enterCenterPracticeName: 'Ingresar nombre del centro/consultorio',
    orderingProvider: 'Proveedor que solicita',
    enterOrderingProvider: 'Ingresar proveedor que solicita',
    clinicContact: ' Contacto de la clínica',
    enterClinicContact: 'Ingresar contacto de la clínica',
    medicalHistory: ' Historia médica',
    primaryCondition: 'Diagnóstico primario',
    enterPrimaryCondition: 'Ingresar diagnóstico primario',
    secondaryCondition: 'Diagnóstico secondario',
    enterSecondaryCondition: 'Ingresar diagnóstico secundario',
    noConditionsSelected: 'No conditions selected',
    addMedicalHistory: 'Agregar historia médica',
    transplantDetails: ' Detalles del transplante ',
    transplantOrganType: 'Tipo de órgano del transplante',
    enterTransplantOrganType: 'Ingresar tipo de órgano del transplante',
    task: 'Tarea',
    addOrgan: 'Agregar órgano',
    transplantDate: 'Fecha del transplante',
    taskName: 'Nombre de la tarea',
    taskNotes: 'Notas',
    status: 'Estado',
    taskTime: 'Hora de la tarea',
    startTime: 'Hora de inicio',
    endTime: 'Hora de finalización',
    deviceId: 'ID del dispositivo',
    deviceName: 'Nombre del dispositivo',
    deviceType: 'Tipo del dispositivo',
    lastEventType: 'Último evento',
    registeredDate: 'Fecha registrada',
    activationDate: 'Fecha de activación',
    battery: 'Batería',
    startDate: 'Fecha de inicio',
    endDate: 'Fecha de finalización',
    startTime: 'Hora de inicio',
    endTime: 'Hora de finalización',
    dose: 'Dosis',
    logTime: 'Hora de registro',
    viewLogs: 'Ver registros',
    loggedThisMonth: 'Registrado este mes',
    goal: 'Meta',
    goalThisMonth: 'Goal this month',
    startTimer: 'Start Timer',
    stopTimer: 'Stop Timer',
    patientId: 'Patient ID',
    mrn: 'MRN',
    dob: 'DOB/fecha de nacimiento',
    patientDetails: 'Detalles del paciente ',
    summary: 'Resumen',
    dateOfBirth: 'Fecha de nacimiento',
    firstName: 'Nombre',
    lastName: 'Apellido',
    errorReadingFile: 'Error while reading file',
    errorInvalidFile: 'Invalid file selected, valid file format:',
    errorPatientName: 'Ingresar nombre del paciente',
    errorFirstName: 'Ingresar nombre',
    errorLastName: 'Ingresar apellido',
    errorDOB: 'Seleccionar fecha de nacimiento',
    errorTextPhoneNumber: 'Ingresar número de teléfono para mensajes de texto',
    errorPrimaryPhoneNumber: 'Ingresar número de teléfono primario',
    errorEmail: 'Ingresar email',
    errorTimezone: 'Seleccionar zona horaria',
    errorAddress: 'Ingresar dirección',
    errorInsuranceName: 'Ingresar nombre del seguro',
    errorPolicyId: 'Ingresar id de póliza',
    errorGroupNumber: 'Ingresar número de grupo',
    errorFacility: 'Please select Facility',
    errorCenterName: 'Ingresar nombre del centro',
    errorProvider: 'Ingresar proveedor',
    errorClinicContact: 'Ingresar contacto de la clínica',
    errorPrimaryCondition: 'Ingresar diagnóstico primario',
    errorSecondaryCondition: 'Ingresar diagnóstico secundario',
    errorOrganType: 'Ingresar tipo de órgano',
    errorTransplantDate: 'Ingresar fecha del transplante',
    addInsurance: 'Agregar seguro',
    addCenterDetails: 'Agregar detalles del centro',
    providerCenterDetails: 'Provider/Center Details',
    month: 'Mes',
    numberOfTask: 'Cantidad de tareas',
    numberOfDays: 'Días con evento',
    tcClaims: 'ReclamacionesTC',
    date: 'Fecha',
    dateOfService: 'Fecha del servicio',
    reviewerName: 'Nombre del revisor',
    type: 'Tipo',
    note: 'Nota',
    tcId: ' ID de TC',
    billableDate: 'Fecha facturable:',
    billableTime: 'Billable time',
    updateTask: 'Actualizar tarea',
    totalTime: 'Tiempo total',
    cptCode: 'Códigos CPT',
    diagnosticsCode: 'Código de diagnóstico',
    data: 'Datos',
    defaultTimeZone: 'America/New_York',
    cycleStartDate: 'Fecha de inicio',
    cycleEndDate: 'Fecha de finalización',
    fileWithXRecords: '{0} with {1} records(s)',
    errorRequiredFields: 'Required fields missing',
    errorFile: 'Unable to read file ',
    errorFiles: 'File errors encountered',
    missingFieldFormat: 'Field missing: {0}',
    incorrectFieldFormat: 'Incorrect format',
    wrongFieldLengthFormat: 'Field too long. Maximum length is {0}',
    incorrectFieldValue: 'Incorrect value: {0}',
    missingTransplantDetails: 'No transplant details',
    missingInsurance: 'No insurance',
    missingCenterDetails: 'No center details',
    missingMedicalHistory: 'No medical history',
    invalidRecords: 'invalid records(s)',
    patientNotAdded: 'patient(s) not added',
    uploadSucceeded: 'Upload succeeded',
    patientAlreadyExists: 'Patient already exists',
    readyForUpload: 'Ready for upload',
    noNewPatients: 'No patients found to be assigned to cohort.',
    patientStatusInCohort: {
      assigned: 'Already in cohort',
      toAssign: 'Will be assigned to cohort',
      toRemove: 'Will be removed from cohort',
      nothingToDo: '-',
      successAdd: 'Sucessfully added to cohort',
      successRemove: 'Sucessfully removed from cohort',
      error: 'Error',
    },
    assignCohort: 'Assign Cohort',
    unassignFromCohort: 'Unassign from Cohort',
    status: {
      enrolled: 'Enrolled',
      onboarded: 'Onboarded',
      verified: 'Registered',
      activated: 'Activated',
      ended: 'Disenrolled',
    },
    contactInformation: 'Contact Information',
    patientSaved: 'Patient Details for {0} have been saved',
    assignSmartCap: 'Assign Smart Cap',
    linkWithProgram: 'Link with Program',
    noProgram: 'No Program',
    whereIsKitId: "Where's the kit Id?",
    welcomeMessage: 'Welcome Message',
    enableMessage: 'Enable Message',
    addConditionsFacilitiesCohorts:
      'Please setup your organization (add conditions, facilities and cohorts) before adding your first patient',
    medicationsTooltip:
      'Please enter the medications the patient is currently taking. Each medication should be entered in new line.',
    patientAgreed: 'Patient agreed',
    patientNotConsented: 'Patient not consented',
    patientConsentIsRequired: 'Patient consent is required',
  },
  fieldsRequired: 'fields required',
  messages: {
    archived: 'Archivado',
    messages: 'Mensajes',
    new: 'New',
    inbox: 'Casilla de entrada',
    number: 'Número',
    name: 'Nombre',
    conversation: 'Conversación',
    date: 'Fecha',
    time: 'Hora',
    newMessage: 'Nuevo mensaje',
    displaySystemMessages: 'Mostrar mensajes del sistema',
    typeMessage: 'Tipo de mensaje',
    archive: 'Archivar',
    unarchive: 'Desarchivar',
    read: 'Leído',
    unread: 'Marcar como no leído',
    markAs: 'Mark as',
    prefabReplies: 'Prefab Responses',
    prefabReply: 'Prefab Response',
    shortcut: 'Shortcut',
    author: 'Author',
    shared: 'Shared',
    private: 'Private',
    shareWithCollegues: 'Share prefab with colleagues',
    addToPrefabs: 'Add to prefab responses',
    addAsPrefab: 'Add reply as a prefab',
    addReply: 'Add Reply',
    createNewPrefab: 'Create new prefab response',
    editPrefab: 'Edit prefab response',
    recipients: 'Recipients',
  },
  esi: {
    active: 'Activo',
    pending: 'Pendiente',
    termed: 'Terminado',
    activatedDate: 'Fecha de activación',
    termedDate: 'Fecha terminado',
  },
  textDays: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
  },
  settingPage: {
    details: 'Details',
  },
  timer: 'Timer:',
  alltime: 'All-time',
  selectTimezone: 'Please select timezone',
  patientInfo: 'Patient Info',
  frequecyType: {
    asNeeded: 'As Needed',
    everyday: 'Every day',
    specificDays: 'Specific days',
    interval: 'Interval',
    qd: 'q.d. - Every day',
    bid: 'b.i.d. - Twice a day',
    tid: 't.i.d. - Three times a day',
    qid: 'q.i.d. - Four times a day',
    q4h: 'q.4h - Every 4 hours',
  },
  searchConversation: 'Buscar conversación',
  editCapManager: 'Editar administrador de la tapa',
  capManagerUpdated: 'Se actualizó el administrador de la tapa',
  activateYourDeviceBySMSText: `Active su dispositivo vía SMS. Puede encontrar su código único de activación de 6 dígitos impreso en la etiqueta adherida a su dispositivo. Envíe el código a:`,
  refreshTheDataAL: 'Actualice los datos"',
  pleaseEnterCodeAL: 'Ingrese el código ',
  charachterAL: 'carácter',
  selectMedicineAL: 'Seleccione el medicamento de la lista',
  timepickerAL: 'Seleccione la zona horaria en el selector',
  medicationScreenAL: 'navegue a la pantalla de medicamentos',
  refreshDataAL: 'Actualice los datos',
  navigateToScreenAL: {
    navigateTo: 'navegue a',
    screen: 'pantalla',
  },
  refreshAL: 'Refresh the data',
  chooseStartDateAL: 'Choose start Date',
  chooseEndDateAL: 'Choose end Date',
  addFilterPlus: 'Add filter',
  clear: 'Clear',
  unsupportedDevice: 'This device type is not fully supported yet. Please contact customer support.',
  rem: `REM`,
  deepSleep: 'Deep',
  lightSleep: 'Light',
  awake: 'Awake',
  opportunity: 'Opportunity',
  percentChange: '% Change',
  ended: 'Ended',
  length: 'Lenght',
  sleepAt: 'Sleep at',
  wakeUpAt: 'Wake-up at',
  unit_minutes: 'mins',
  selectedTimezone: ' selected timezone',
  billingStatus: {
    pending: 'Pending',
    submitted: 'Submitted',
    onhold: 'On-Hold',
  },
  reading: 'Reading',
  custom: 'Custom',
  calories: 'Calories',
  distance: 'Distance',
  exerciseTime: 'Exercise time',
  exerciseIntensity: 'Exercise intensity',
  restHR: 'Rest HR',
  alertsEnum: {
    bloodPressure: 'Blood Pressure Alerts',
    oxygenSaturation: 'Saturation Alerts',
    weight: 'Weight Alerts',
    glucose: 'Glucose Concentration Alerts',
    spirometry: 'Spirometry Alerts',
    temperature: 'Temperature Alerts',
    heartRate: 'Heart rate Alerts',
    pm25: 'PM 2.5 Alerts',
    coagulation: 'Coagulation Alerts',
  },
  unit: 'Unit',
  lowWeight: 'Low Weight',
  highWeight: 'High Weight',
  lowSystolic: 'Low Systolic',
  highSystolic: 'High Systolic',
  lowDiastolic: 'Low Diastolic',
  highDiastolic: 'High Diastolic',
  lowGlucose: 'Low Glucose',
  highGlucose: 'High Glucose',
  lowFev1: 'Low FEV1',
  highFev1: 'High FEV1',
  lowFvc: 'Low FVC',
  highFvc: 'High FVC',
  change: 'Change',
  changeSinceLastReading: 'Change since last reading',
  latestReadings: 'Latest Readings',
  patientLog: 'Patient Log',
  time: 'Time',
  pulseOx: 'Pulse Ox',
  language: 'Language',
  new: 'New',
  documents: 'Documents',
  pulseMeasures: 'Pulse measures',
  stepsBy: 'Accumulated steps by',
  chooseFile: 'Choose file',
  dropOr: 'Drag and drop file, or',
  fileUploadedSuccessfully: 'File uploaded successfully',
  manualUploadProcessInfo:
    'As this is a manual patient upload process, please communicate to the to the coordinator that data has been uploaded.',
  fileUploadFail: 'File upload failed',
  fileExceedMaximumSizeOf: 'File exceed maximum size of',
  documentsGeneralProblem: 'Problem with getting access to documents',
  refreshAL: 'Actualice los datos',
  chooseStartDateAL: 'Elija la fecha de inicio',
  chooseEndDateAL: 'Elija la fecha de finalización',
  allFacilities: 'All Facilities',
  english: 'inglés',
  spanish: 'español',
  languagesEnum: {
    en: 'English',
    es: 'Spanish',
    ar: 'Arabic',
  },
  actions: 'Actions',
  addNote: 'Add Note',
  editNote: 'Edit Note',
  addOpenAction: 'Add Open Action',
  editAction: 'Edit Action',
  emailPatient: 'Email Patient',
  title: 'Title',
  note: 'Note',
  description: 'Description',
  completedAction: 'Completed Action',
  openAction: 'Open Action',
  created: 'Created',
  edited: 'Edited',
  complete: 'Complete',
  open: 'Open',
  completed: 'Completed',
  reOpen: 'Re-Open',
  actionStatus: 'Action Status',
  logitemType: 'Log Item Type',
  downloadTemplateCsv: 'Download template cvs file',
  downloadTemplateXslx: 'Download template xlsx file',
  andXmore: '... and {0} more',
  createKit: 'Create Kit',
  kit: 'Kit',
  kitId: 'Kit ID',
  kitStatus: 'Kit Status',
  selectKit: 'Select Kit',
  attachKit: 'Attach Kit',
  reassignKit: 'Reassign Kit',
  removeKit: 'Remove Kit',
  attachDeviceToKit: 'Attach Device to a Kit',
  attachDevicesToKit: 'Attach Devices to a Kit',
  kitName: 'Kit Name',
  activeSince: 'Active Since',
  deleteKit: 'Delete Kit',
  deleteKitWarning: 'Are you sure you want to delete this kit?',
  editKit: 'Edit Kit',
  myAlerts: 'My Alerts',
  showingXPendingAlerts: 'Showing {0} Pending Alerts',
  showingXofXPendingAlerts: 'Showing {0} of {1} Pending Alerts',
  showingXAlerts: 'Showing {0} Alerts',
  showingXofXAlerts: 'Showing {0} of {1} Alerts',
  priority: 'Priority',
  type: 'Type',
  low: 'Low',
  medium: 'Medium',
  high: 'High',
  measurments: 'Measurements',
  missedDoses: 'Missed Doses',
  characters: 'characters',
  vitals: 'Vitals',
  cohorts: 'Cohorts',
  cohort: 'Cohort',
  activePatients: 'Active Patients',
  inactivePatients: 'Inactive Patients',
  assignedTo: 'Assigned to',
  facilities: 'Facilities',
  facility: 'Facility',
  providerFacility: 'Provider / Facility',
  selectFacility: 'Select Provider / Facility',
  selectManager: 'Select Manager',
  selectCohort: 'Select Cohort',
  select: 'Select',
  noOptionSelected: 'No option selected',
  createCohort: 'Create Cohort',
  editCohort: 'Edit Cohort',
  cohortName: 'Cohort Name',
  cohortId: 'Cohort Id',
  primaryCohortManager: 'Primary Cohort Manager',
  primaryManager: 'Primary Manager',
  secondaryManager: 'Secondary Manager',
  numberOfPatients: 'Number of Patients',
  createdBy: 'Created by',
  skip: 'Skip',
  flowVolumeCurve: 'Flow Volume Curve',
  volumeTimeCurve: 'Volume Time Curve',
  clearAlert: 'Clear alert',
  clearAlertError: 'Clear alert error',
  setReminder: 'Set reminder',
  spirometry: 'Spirometry',
  program: 'Program',
  confirmations: 'Confirmations',
  programs: {
    rtm: 'RTM',
    ccm: 'CCM',
    rpm: 'RPM',
    pcm: 'PCM',
  },
  subprograms: {
    rtm: {
      musculoskeletal: 'Musculoskeletal',
      respiratory: 'Respiratory',
      behavioralHealth: 'Behavioral Health',
    },
    ccm: {
      basic: 'Basic',
      complex: 'Complex',
    },
  },
  defaultProgram: 'Default Program',
  otherPrograms: 'Other Programs',
  writtenConsentGiven: 'Written consent given',
  verbalConsentGiven: 'Verbal consent given',
  selectProgram: 'Select Program',
  selectCondition: 'Select Condition',
  selectPrograms: 'Select Programs',
  patientWillBeAssigned: 'Patient will be assigned to:',
  searchKit: 'Search Kit',
  scanBarcodeOrEnterId: 'Scan barcode on the box, or enter id',
  redings: 'Readings',
  deviceTypes: {
    bpm: 'BP Monitor',
    scales: 'Scales',
    glucometer: 'Glucometer',
    wristBand: 'Wrist Band',
    spirometer: 'Spirometer',
    hailieInhaler: 'Hailie Inhaler',
    thermometers: 'Thermometers',
    pulseOximeter: 'Pulse Oximeter',
    bluetoothCap: 'Bluetooth cap',
    hub: 'Hub',
    lte: 'LTE',
    lsc: 'LSC',
    inhaler: 'Inhaler',
    airQualityMonitor: 'Air Quality Monitor',
    ptInrMonitor: 'PT/INR Monitor',
  },
  readingStatus: {
    allReadings: 'All Readings',
    newReadings: 'New Readings',
    archivedReadings: 'Archived Readings',
  },
  normal: 'Normal',
  abnormal: 'Abnormal',
  batteryReading: 'Battery reading',
  localization: 'Localization',
  firmwareVersion: 'Firmware version',
  signalQuality: 'Signal quality',
  sleep: 'Sleep',
  pillsTaken: 'Pills taken',
  inhalationTaken: 'Inhalation taken',
  zephyRxDashboard: 'ZephyRx Dashboard',
  editConditionList: 'Edit Condition List',
  editFacilityList: 'Edit Provider / Facility List',
  patientAlerts: 'Patient Alerts',
  thermometer: 'Thermometer',
  lowTemperature: 'Low Temperature',
  highTemperature: 'High Temperature',
  lowSaturation: 'Low Saturation',
  lowHR: 'Low Heart rate',
  highHR: 'High Heart rate',
  highPm25: 'High PM 2.5',
  lowPt: 'Low PT',
  highPt: 'High PT',
  lowInr: 'Low INR',
  highInr: 'High INR',
  missedDosesAlerts: 'Missed doses Alerts',
  reviewBy: 'Review By',
  last2days: 'Last 48 hours',
  hour: 'hour',
  hours: 'hours',
  saveWithNoKit: 'Save with no kit',
  xObjectsAssignedtoY: '{0} {1} assigned to {2}',
  xObjectsUnassignedfromY: '{0} {1} unassigned from {2}',
  enroll: 'Enroll',
  continueEnrollment: 'Continue Enrollment',
  cancelEnrollment: 'Cancel Enrollment',
  setEnrollmentEndDate: 'Set enrollment end date',
  disenroll: 'Disenroll',
  activateNow: 'Activate now',
  register: 'Register',
  pharmacyNotesToProvider: 'Pharmacy notes to Provider',
  changeEnrollmentEndDate: 'Change enrollment end date',
  enrollmentFor: 'Enrollment for',
  enrollmentCondition: 'Enrollment Condition',
  enrollmentProgram: 'Enrollment Program',
  enrollmentConsent: 'Enrollment Consent',
  enrollmentDateTimezoneTooltip: '{0} - {1} timezone',
  agreementToSpeakWithProvider: 'Agreement to speak with Provider',
  consent: 'Consent',
  outreachStatus: 'Outreach Status',
  outreachStatusEnum: {
    pending: 'Pending',
    set: 'Patient consented',
    retry: 'Retry',
    not_eligible: 'Not Eligible',
    declined: 'Declined',
  },
  activate: 'Activate',
  assignKit: 'Assign Kit',
  enrollmentKit: 'Enrollment Kit',
  consentUploadInstruction: 'Signed consent can be uploaded via "Documents"',
  patientEnrolledInfo: '{0} has been enrolled to the system',
  patientActivatedInfo: 'Patient {0} has been activated',
  welcomeSmsSent: 'Welcome SMS was scheduled to be sent to patient {0}',
  kitAssignedInfo: 'Kit {0} has been assigned to patient {1}',
  wheresKitIdText:
    'You will find a 20 digit alphanumeric code printed here on the kit packaging. You should only need to enter the first few letters.',
  noCapAvailable: 'No cap available',
  avgTimeDelta: 'Avg. Time Delta',
  tutorial: 'Tutorial',
  onboarding: 'Onboarding',
  enrollment: 'Enrollment',
  monitoring: 'Monitoring',
  sendTo: 'Send to',
  from: 'From',
  message: 'Message',
  SMSSent: 'SMS sent successfully',
  SMSFailed: 'Unable to send SMS',
  sendSMS: 'Send SMS',
  uploadPatientList: 'Upload Patient List',
  patientListFiles: 'Patient List Files',
  perfusionIndex: 'Perfusion Index',
  maxAndRemainingChars: 'Max characters: {0} ({1} remaining)',
  maxAndOverflowChars: 'Max characters: {0} (too long by {1})',
  remainingChar: '{0} character remaining',
  remainingChars: '{0} characters remaining',
  selectTheme: 'Please select theme',
  theme: {
    blue: 'Blue',
    red: 'Red',
    petrol: 'Petrol',
    white: 'White (esi)',
  },
  selectBrand: 'Please select brand',
  lteUserInstructions: 'LTE User Instructions',
  rpmQuickGuide: 'RPM Quick Guide',
  rxcapPortal: 'RxCap Portal',
  smartCapPatientInstVideo: 'Smart Cap Patient Instruction Video',
  role: 'Select role',
  orgConfigUpdated: 'Org config updated',
  contactUs: 'Contact Us',
  videos: 'Videos',
  gettingStart: 'Getting started using the portal',
  gettingStartDesc:
    'Just joined? Watch here to cover all the key areas you will need to learn to successfully manage your patients.',
  onboardingDesc: 'Watch this if you need a quick review of how to onboard new patients to the portal.',
  enrollementDesc: 'Watch this if you need a quick review of how to enroll new patients to the portal.',
  monitoringDesc: 'Watch this if you need a quick review of how to monitor your patients metrics.',
  billingDesc: 'Watch this if you need a quick review of how to manage your billing for your billing clinician.',
  smartCapRTMDesc:
    'You can send this video to your patients, so they can quickly learn about how to use their smart cap',
  SmartCapRTM: 'Smart Cap for RTM',
  network: 'Network',
  networkTime: 'Last network time',
  rxcapLaunchOutlineExternal: 'RxCap Launch Outline',
  setDefault: 'Set default',
  authenticationHeader: 'Authentication Header',
  authHeader: 'Auth Header',
  pauseAPI: 'Pause API',
  successfieldRequired: 'Success Field Required',
  maxTimeWithoutEvents: 'Maximum Time Without Event',
  maxBatchSize: 'Max Batch Size',
  errorMaxBatchSize: 'Must be greater than 0',
  successFieldRequired: 'Success Field',
  required: 'Required',
  notRequired: 'Not Required',
  apiStatus: {
    active: 'Active',
    paused: 'Paused',
  },
  missedDoses: 'Missed doses',
  alertCleared: 'Alert successfully cleared',
  size: 'Size',
  online: 'Online',
  offline: 'Offline',
  blocked: 'Blocked',
  suspended: 'Suspended',
  unassigned: 'Unassigned',
  manufactured: 'Manufactured',
  deleted: 'Deleted',
  itemSaved: '{0} saved successfully',
  manage: 'Manage',
  atmocubeValues: {
    tvoc: 'TVOC',
    vocIndex: 'VOC Index',
    noxIndex: 'NOx Index',
    relativeHumidity: 'Relative Humidity',
    atmosphericPressure: 'Atmospheric Pressure',
    noiseLevels: 'Noise Levels',
    lightIntensity: 'Light Intensity',
    formaldehyde: 'Formaldehyde',
    occupancy: 'Occupancy',
  },
  hailieEventSubTypes: {
    inhalation: 'Inhalation',
    shakeDetection: 'Shake Detection',
    respirationFlow: 'Respiration Flow',
    respirationTiming: 'Respiration Timing',
  },
  passwordTexts: {
    yourPasswordShouldBe: 'Su contraseña debe tener',
    eightTo32Characters: 'de 8 a 32 caracteres',
    atLeastOneDigit: 'lo menos uno número',
    atLeastOneUpperCase: 'lo menos uno mayúscula',
    atLeastOneLowerCase: 'lo menos uno minúscula',
    atLeastOneSpecialCharacter: 'lo menos uno símbolo (~!*^@#$%&)',
    matchWithConfirmField: 'coincidir con el campo de confirmación',
    oldPasswordIsRequired: 'Se requiere contraseña antigua',
    notAllowedCharacters: 'Caracteres no permitidos',
  },
  startDateShouldBeBeforeEndDate: 'Start date should be before end date',
  allowedDataRangeIs6Months: 'Maximum allowed data range is 6 months',
  noDataInGivenPeriod: 'No data was available in the chosen period. Please select different time period.',
  timeThisMonth: 'Time this Month',
  todaysMedications: "Today's Medications",
  statusEnum: {
    pending: 'Pending',
    submitted: 'Submitted',
    onhold: 'On-Hold',
    enabled: 'Enabled',
    disabled: 'Disabled',
    active: 'Active',
    inactive: 'Inactive',
    forbidden: 'No access',
    invited: 'Invited',
    configured: 'Enabled',
  },
  copyToClipboard: 'Copy to clipboard',
  publicKeyHasBeenDeleted: 'Public key has been deleted, all tokens are now revoked',
  publicKeyIsInvalid: 'Public key is invalid',
  noticeSaveCredentials: 'Notice: Please save those credentials. It will not be possible to obtain them again.',
  credentialsCopiedToClipboard: 'Credentials copied to clipboard',
  applyFilters: 'Apply page filters',
  billableTime: 'Billable time',
  pm25: 'PM 2.5',
  healthCenter: 'Health Center',
  yourDevices: 'Your Devices',
  yourMedications: 'Your Medications',
  year: 'Year',
  unitsEnum: {
    lbs: 'lbs',
    kg: 'kg',
    minutes: 'mins',
  },
  dailyAverage: 'Daily Average',
  hourlyAverage: 'Hourly Average',
  dateRange: 'Date Range',
  portalAccess: 'Portal Access',
  editUser: 'Edit User',
  addUser: 'Add User',
  userName: 'User Name',
  resetPasswordMethodPrompt: 'How would you like to reset your password?',
  changePasswordWarning: 'Do you want to change your password? We will send the password reset link to ',
  userSaved: 'User Details for {0} have been saved',
  rolesEnum: {
    organization_manager: 'Organization Manager',
    capmanager: 'Cap Manager',
    doctor: 'Admin',
  },
  rolesPluralEnum: {
    organization_manager: 'Organization Managers',
    capmanager: 'Cap Managers',
    doctor: 'Admins',
  },
  confirmPasswordReset: 'Confirm Password Reset',
  confirmReinvite: 'Confirm Invite Resend',
  dateRangeAdjustedWarning:
    'The maximum date range allowed for download is 6 months. The start date of your selected date range has been adjusted to fit within this limit.',
  scheduleEnded: 'Schedule Ended',
  assignedDate: 'Assigned Date',
  unassignedDate: 'Unassigned Date',
  invalidTimezone: 'Invalid Timezone',
  dataRangeAberraviations: {
    day: 'D',
    week: 'W',
    twoWeeks: '2W',
    month: 'M',
    sixMonths: '6M',
    year: 'Y',
  },
  invalidTag: 'Invalid tag',
  insertTag: 'Insert tag',
  awaitingBleReset: 'Awaiting BLE Reset',
  resetBle: 'Reset BLE chip',
  enableDexcomIntegration: 'Enable Dexcom integration',
  resetDexcomIntegration: 'Re-enable Dexcom integration',
  disableDexcomIntegration: 'Disable Dexcom integration',
  enableDexcomIntegrationConfirm: 'Are you sure you want to enable Dexcom integration for patient {0}?',
  enableDexcomIntegrationSuccess: 'Dexcom integration enabled successfully',
  disableDexcomIntegrationConfirm: 'Are you sure you want to disable Dexcom integration for patient {0}?',
  disableDexcomIntegrationSuccess: 'Dexcom integration disabled successfully',
  authorizeRxcapForDexcom:
    'To configure your device, please authorize RxCap to access your Dexcom data. We will redirect you to Dexcom website, where you will need to log in and accept our authorization.',
  reAuthorizeRxcapForDexcom:
    'Our authorization to access your Dexcom data has expired or was revoked. Please authorize RxCap to access your Dexcom data. We will redirect you to Dexcom website, where you will need to log in and accept our authorization.',
  notNow: 'Not now',
  dexcomDeviceConfiguration: 'Dexcom Device Configuration',
  dexcomDeviceConfigured: 'Dexcom Device Configured',
  dexcomDeviceConfiguredSuccess:
    'Your Dexcom device has been configured successfully. Now, the data synced with your dexcom app on your phone will be available to your care provider',
  dexcomInvalidCode: 'Invalid code',
  dexcomCodeNotMatchingPatient:
    'The code obtained from Dexcom was not issued for this patient. Please contact your care provider for assistance.',
  dexcomConfigurationFailed: 'Dexcom configuration failed',
  dexcomConfigurationFailedText:
    'There was a problem configuring your Dexcom device. Please contact your care provider for assistance.',
  noReadingsSince: 'No readings since',
  newNotesSince: 'New notes since',
  numberOfDays: 'Number of days',
  allowedRangeWarning: 'Allowed range is {0}-{1}',
  coagulation: 'Coagulation',
  bulkAddTexts: {
    selectSheet: 'Select Excel sheet to upload',
    multipleSheets: 'Excel Sheet',
    noRows: 'No rows found in the selected sheet',
    columns: 'Columns',
    matchedColumns: 'Matched column headers for import:',
    unmatchedColumns: 'Unmatched column headers excluded from import:',
    newConditionsFound: 'New conditions found',
    unknownConditionsFound: 'Unknown conditions found',
    patientsWithNoCondition: 'Patients with no condition',
    conditionsToAdd: 'Following ICD10 conditions have been found that will be added to the organization',
    unrecognizedConditions:
      'The following conditions are not valid ICD10 codes and will be replaced as ‘Unknown’. Please adjust the conditions in the file or in patient record after import',
    unknownCondition: '{0} patient(s) have no conditions. They will be assigned to ‘{1}’ condition.',
    missingColumns: 'The following columns are missing from the selected sheet:',
    noFacility:
      '{0} patients have no facility or their facility is not added to organization. They will be assigned to ‘{1}’ facility',
    patientsWithNoFacility: 'Patients with no facility',
    unknownFacilitiesFound: 'Unknown facilities found',
    errorReadingFile: 'Error while reading file',
    noPatientsInFile: 'No patients found in the file',
  },
  lastLogin: 'Last login',
  validUntil: 'Valid until',
  changeDeviceState: 'Change device state',
  events: 'Eventos',
  changeOrgState: 'Change organization state',
  changeState: 'Change state',
  analytics: 'Analytics',
  maskPHIData: 'Mask PHI data',
};

export default spanish;
