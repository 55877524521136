import { now } from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import PatientList from './PatientList';
import { RPM_PATIENT_STATUS } from '../../../constants';
import { hasPermission, PERMISSIONS } from '../../../utils/userPermissions';
import { Tab, Tabs } from '../../../components/Tabs';
import Strings from '../../../Strings';

function PatientsTabs(props) {
  const tabArray = Object.keys(RPM_PATIENT_STATUS).map(status => (
    <Tab label={Strings.capPatient.patientStatus[status]} key={status}>
      <PatientList refreshTimestamp={props.refreshTimestamp} status={status} />
    </Tab>
  ));

  return (
    <React.Fragment>
      <Tabs defaultIndex={tabArray.findIndex(t => t.key === props.status)}>{tabArray}</Tabs>
    </React.Fragment>
  );
}

PatientsTabs.propTypes = {
  activeTab: PropTypes.any,
};

export default PatientsTabs;
