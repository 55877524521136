import { PAGE_LIMIT } from '../../../../constants';
import { API_CALL } from '../../../../middleware/api';
import { ORGANIZATION_LIST } from '../../../ShipperCaps/redux/constants';
import {
  ADD_ORGANIZATION_OUTBOUND_API,
  ASSIGN_CAPS_TO_THE_ORGANIZATION,
  CHANGE_ORG_ADMINISTRATIVE_STATE,
  CLEAR_DATA,
  CLEAR_ERRORS,
  CLEAR_SUGGESTED_DATA,
  DELETE_HUB_DEVICE,
  DELETE_ORGANIZATION,
  DELETE_ORGANIZATION_OUTBOUND_API,
  GET_ALL_PERMISSION,
  GET_ORAGANIZATION,
  GET_ORAGANIZATION_OUTBOUND_API,
  GET_ORAGANIZATION_REPORT,
  GET_ORG_CONFIG,
  GET_ORG_ROLES_DETAIL,
  GET_ORG_STATE_HISTORY,
  GET_ROLES_DETAIL,
  HUB_DEVICES,
  HUB_DEVICES_TYPES,
  LAUNCH_ORG,
  ORGANIZATION_DEVICES,
  UNSUSPEND_ORG,
  UPDATE_ORG,
  UPDATE_ORGANIZATION_OUTBOUND_API,
  UPDATE_ORG_ROLES_DETAIL,
} from './constants';
const qs = require('qs');

export const actions = {
  downloadReport: () => {
    return {
      [API_CALL]: {
        method: 'GET',
        endpoint: `/v1/organization/caps/report`,
      },
      type: GET_ORAGANIZATION_REPORT,
    };
  },
  getOrganizations: pageRequest => {
    return {
      [API_CALL]: {
        method: 'GET',
        endpoint: `/v1/organizations/`,
        params: { ...pageRequest, limit: PAGE_LIMIT },
      },
      type: GET_ORAGANIZATION,
    };
  },
  deleteOrg: id => ({
    [API_CALL]: {
      method: 'POST',
      endpoint: `/v1/organizations/${id}/state/administrative`,
      data: { state: 'Deleted' },
    },
    type: DELETE_ORGANIZATION,
  }),

  getOutboundAPIList: pageRequest => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: `/v1/outbounds/${pageRequest.organizationId}`,
      params: { ...pageRequest, limit: PAGE_LIMIT },
    },
    type: GET_ORAGANIZATION_OUTBOUND_API,
  }),

  addOrganizationOutboundAPI: (orgData, data) => ({
    [API_CALL]: {
      endpoint: `/v1/outbounds/${data.organization_id}`,
      method: 'POST',
      data: orgData,
    },
    type: ADD_ORGANIZATION_OUTBOUND_API,
  }),

  updateOrganizationOutboundAPI: (orgData, outboundAPI) => ({
    [API_CALL]: {
      endpoint: `/v1/outbounds/${outboundAPI.data.id}`,
      method: 'PUT',
      data: orgData,
    },
    type: UPDATE_ORGANIZATION_OUTBOUND_API,
  }),

  deleteOrganizationOutboundAPI: deleteData => ({
    [API_CALL]: {
      endpoint: `/v1/outbounds/${deleteData.id}`,
      method: 'DELETE',
      data: deleteData,
    },
    type: DELETE_ORGANIZATION_OUTBOUND_API,
  }),

  deleteHubDevice: (hubId, deviceId, callAfterSuccess = []) => ({
    [API_CALL]: {
      endpoint: `/v1/hubs/${hubId}/devices/${deviceId}`,
      method: 'DELETE',
      callAfterSuccess,
    },
    type: DELETE_HUB_DEVICE,
  }),

  getDevices: pageRequest => ({
    [API_CALL]: {
      endpoint: `/v1/devices/unassigned/`,
      method: 'get',
      params: { ...pageRequest, limit: PAGE_LIMIT },
    },
    type: ORGANIZATION_DEVICES,
  }),

  getHubDevices: (hubId, pageRequest) => ({
    [API_CALL]: {
      endpoint: `/v1/hubs/${hubId}/devices/`,
      method: 'get',
      params: { ...pageRequest, limit: PAGE_LIMIT },
    },
    type: HUB_DEVICES,
  }),

  getDeviceTypes: pageRequest => ({
    [API_CALL]: {
      endpoint: `/devices/event-types`,
      method: 'get',
      params: { ...pageRequest, limit: PAGE_LIMIT },
    },
    type: HUB_DEVICES_TYPES,
  }),

  getOrgs: request => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/v1/orgs/',
      params: request,
    },
    type: ORGANIZATION_LIST,
  }),

  assignCaps: data => ({
    [API_CALL]: {
      endpoint: `/v1/organizations/${data.organization_id}/devices/`,
      method: 'PUT',
      data: data,
    },
    type: ASSIGN_CAPS_TO_THE_ORGANIZATION,
  }),

  getOrgConfig: pageRequest => {
    return {
      [API_CALL]: {
        method: 'GET',
        endpoint: `/v1/organizations/${pageRequest.organizationId}/configs`,
      },
      type: GET_ORG_CONFIG,
    };
  },

  getOrgRole: pageRequest => {
    return {
      [API_CALL]: {
        method: 'GET',
        endpoint: `/v1/organizations/${pageRequest.organizationId}/roles`,
      },
      type: GET_ROLES_DETAIL,
    };
  },

  getTemplateOrgConfig: pageRequest => {
    return {
      [API_CALL]: {
        method: 'GET',
        endpoint: `/v1/organizations/template`,
      },
      type: GET_ALL_PERMISSION,
    };
  },

  getOrgRoleConfig: pageRequest => {
    return {
      [API_CALL]: {
        method: 'GET',
        endpoint: `/v1/organizations/${pageRequest.organizationId}/permissions/${pageRequest.roleUuid}`,
      },
      type: GET_ORG_ROLES_DETAIL,
    };
  },

  updateOrgRoleConfig: (data, pageRequest) => ({
    [API_CALL]: {
      endpoint: `/v1/organizations/${pageRequest.organizationId}/permissions/${pageRequest.roleUuid}`,
      method: 'PUT',
      data: data,
    },
    type: UPDATE_ORG_ROLES_DETAIL,
  }),

  updateOrg: (data, pageRequest) => ({
    [API_CALL]: {
      endpoint: `/v1/organizations/${pageRequest.organizationId}`,
      method: 'PUT',
      data: data,
    },
    type: UPDATE_ORG,
  }),

  getOrganizationStateHistory: id => {
    return {
      [API_CALL]: {
        method: 'GET',
        endpoint: `/v1/organizations/${id}/state/history`,
      },
      type: GET_ORG_STATE_HISTORY,
    };
  },

  changeOrganizationAdministrativeState: (id, data) => {
    return {
      [API_CALL]: {
        method: 'POST',
        endpoint: `/v1/organizations/${id}/state/administrative`,
        data,
      },
      type: CHANGE_ORG_ADMINISTRATIVE_STATE,
    };
  },

  launchOrganization: id => {
    return {
      [API_CALL]: {
        method: 'POST',
        endpoint: `/v1/organizations/${id}/state/business/launch`,
      },
      type: LAUNCH_ORG,
    };
  },

  unsuspendOrganization: (id, data) => {
    return {
      [API_CALL]: {
        method: 'POST',
        endpoint: `/v1/organizations/${id}/state/administrative/unsuspend`,
        data,
      },
      type: UNSUSPEND_ORG,
    };
  },

  clearData: () => ({
    type: CLEAR_DATA,
  }),

  clearErrors: () => ({
    type: CLEAR_ERRORS,
  }),

  clearAutoSuggestedDevice: () => ({
    type: CLEAR_SUGGESTED_DATA,
  }),
};
