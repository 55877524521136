import { ADD_NICK_NAME } from '../../../actions/action-types';
import { API_CALL } from '../../../middleware/api';
import {
  GET_FILTER_DATA,
  SHIPPER_CAPS_DETAILS,
  SHIPPER_CAPS_DETAILS_CLEAR,
  SHIPPER_CAPS_SCHEDULE_DETAILS,
  SHIPPER_CAPS_PLOT_DATA,
} from './constants';

export const actions = {
  getFillterData: type => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/v1/caps/event-types',
      params: { filterBy: type },
    },
    type: GET_FILTER_DATA,
  }),
  getCapsDetails: (id, pageRequest) => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/esi/v1/caps/' + id,
      params: pageRequest,
    },
    type: SHIPPER_CAPS_DETAILS,
  }),

  addNickName: data => ({
    [API_CALL]: {
      endpoint: `/esi/v1/caps`,
      method: 'put',
      data,
    },
    type: ADD_NICK_NAME,
  }),

  clearCapsDetails: () => ({
    type: SHIPPER_CAPS_DETAILS_CLEAR,
  }),

  getPlotScheduleDetail: (id, params) => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/devices/v1/cap/' + id + '/schedule_details',
      params,
    },
    type: SHIPPER_CAPS_SCHEDULE_DETAILS,
  }),

  getPlotData: (id, params) => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: `/devices/v1/cap/${id}/plot`,
      params,
    },
    type: SHIPPER_CAPS_PLOT_DATA,
  }),
};
