import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import _ from 'lodash';
import moment from 'moment-timezone';

import PatientList from './PatientList';
import { RPM_PATIENT_STATUS } from '../../../constants';
import { Tab, Tabs } from '../../../components/Tabs';
import Strings from '../../../Strings';
import { actions } from './redux/actions';
import { actions as cohortActions } from './Cohorts/redux/actions';
import { getFiltersForRequest, getRelevantFilters, urlInjected } from '../../../components/AdvancedFilters/helpers';
import { PageHeader } from '../../../components/PageHeader';
import AdvancedFiltersBar from '../../../components/AdvancedFilters/AdvancedFiltersBar';
import { FacilityFilter } from './Cohorts/Cohorts';
import { TextInput } from '../../../components/PageHeader/TextInput';
import AdvancedFilters, { Filter } from '../../../components/AdvancedFilters/AdvancedFilters';
import { getFiltersDefinition } from '../../../components/AdvancedFilters/FiltersDefinition';

export const PatientListMode = {
  list: 'list',
  tabsByStatus: 'tabsByStatus',
};

function PatientsTabs(props) {
  const urlParams = new URLSearchParams(window.location.search);
  const statusFromUrl = urlParams.get('status');
  const { cohortId, refreshTimestamp, filters } = props;
  const [status, setStatus] = React.useState(props.displayMode === PatientListMode.tabsByStatus ? (statusFromUrl || RPM_PATIENT_STATUS.activated) : undefined);
  const defaultPatientStatusCount = Object.keys(RPM_PATIENT_STATUS).map(status => ({status, count: '?'}));
  const [patientsCount, setPatientsCount] = React.useState(defaultPatientStatusCount);
  const searchTime = React.useRef(null);
  const pageRequest = React.useRef({
    offset: 0,
    search: '',
    lastReadings: true,
    missedToday: true,
  });

  const load = () => {
    const { getPatients, cohortId } = props;

    if (cohortId) pageRequest.current.cohortId = cohortId;
    else delete pageRequest.current.cohortId;

    getPatients(pageRequest.current);
  };

  const getPatientsCount = currentRequest => {
    if (props.displayMode !== PatientListMode.tabsByStatus) {
      return;
    }

    setPatientsCount(defaultPatientStatusCount);
    const request = {
      ...currentRequest,
      offset: 0,
      limit: 1,
    };
    delete request.lastReadings;
    delete request.missedToday;

    Object.keys(RPM_PATIENT_STATUS).forEach(status => {
      request.status = status;
      props.getPatientsForSuggestion(request).then(resp => {
        const count = resp.response?.pagination?.totalRecords || 0;
        setPatientsCount(prev => prev.map(p => (p.status === status ? { ...p, count } : p)));
      });
    });
  };

  React.useEffect(() => {
    props.getFacilities();
    if (!urlInjected(window.location.search)) {
      getPatientsCount();
    }
  }, []);

  const refreshData = () => {
    load();
    getPatientsCount(pageRequest.current);
  };

  React.useEffect(() => {
    if (!urlInjected(window.location.search)) {
      if (status) {
        pageRequest.current.status = status;
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('status', status);
        props.replacePath(`${window.location.pathname}?${urlParams}`);
      }
      load();
    }
    return () => {
      props.clearData();
    };
  }, [status, cohortId, refreshTimestamp]);

  React.useEffect(() => {
    const facilities = getRelevantFilters(filters.filter(f => f.group === 'facilityId'));
    const facilityQueryString = facilities?.length > 0 ? facilities.map(f => f.value).join(',') : undefined;

    const noReadingsSince = getRelevantFilters(filters.filter(f => f.group === 'NoReadingsSince'));
    const noReadingsSinceValue =
      noReadingsSince?.length > 0
        ? moment()
            .subtract(noReadingsSince[noReadingsSince.length - 1].state, 'days')
            .startOf('day')
            .format()
        : undefined;

    const newNotesSince = getRelevantFilters(filters.filter(f => f.group === 'NewNotesSince'));
    const newNotesStartTimestamp =
      newNotesSince?.length > 0
        ? moment()
            .subtract(newNotesSince[newNotesSince.length - 1].state, 'days')
            .format()
        : undefined;

    let outReachStatus = getFiltersForRequest(filters.filter(f => f.group === 'consentForProvider'));
    if (outReachStatus?.length === 0) {
      outReachStatus = undefined;
    }

    if (
      pageRequest.current.facilityId !== facilityQueryString ||
      pageRequest.current.noReadingsSince !== noReadingsSinceValue ||
      pageRequest.current.noteStartTimestamp !== newNotesStartTimestamp ||
      pageRequest.current.filterBy !== outReachStatus
    ) {
      onFiltersChange();
    }
  }, [filters]);

  const onSortClick = ({ sortKey, direction }) => {
    pageRequest.current.offset = 0;
    pageRequest.current.sortColumn = sortKey;
    pageRequest.current.sortType = direction;
    props.getPatients(pageRequest.current);
  };

  const onOffsetChange = offset => {
    pageRequest.current.offset = offset;
    props.getPatients(pageRequest.current);
  };

  const onFiltersChange = _.debounce(() => {
    pageRequest.current.offset = 0;
    const facilities = getRelevantFilters(filters.filter(f => f.group === 'facilityId'));
    if (facilities?.length > 0) {
      pageRequest.current.facilityId = facilities.map(f => f.value).join(',');
    } else {
      delete pageRequest.current.facilityId;
    }
    const noReadingsSince = getRelevantFilters(filters.filter(f => f.group === 'NoReadingsSince'));
    if (noReadingsSince?.length > 0) {
      pageRequest.current.noReadingsSince = moment()
        .subtract(noReadingsSince[noReadingsSince.length - 1].state, 'days')
        .startOf('day')
        .format();
    } else {
      delete pageRequest.current.noReadingsSince;
    }
    const newNotesSince = getRelevantFilters(filters.filter(f => f.group === 'NewNotesSince'));
    if (newNotesSince?.length > 0) {
      pageRequest.current.noteStartTimestamp = moment()
        .subtract(newNotesSince[newNotesSince.length - 1].state, 'days')
        .format();
    } else {
      delete pageRequest.current.noteStartTimestamp;
    }
    const outreachStatus = getFiltersForRequest(props.filters.filter(f => f.group === 'consentForProvider'));
    if (outreachStatus?.length > 0) {
      pageRequest.current.filterBy = outreachStatus;
    } else {
      delete pageRequest.current.filterBy;
    }
    pageRequest.current.offset = 0;
    load();
    getPatientsCount(pageRequest.current);
  }, 1000);

  const onSearchQueryChange = query => {
    pageRequest.current.offset = 0;
    pageRequest.current.search = query;
    clearTimeout(searchTime.current);
    searchTime.current = setTimeout(() => {
      props.getPatients(pageRequest.current);
      getPatientsCount(pageRequest.current);
    }, 1000);
  };

  const getHeaderComponents = () => {
    return (
      <React.Fragment>
        <TextInput class="" placeholder={Strings.search} onChange={e => onSearchQueryChange(e.target.value)} />
        {props.facilities?.length > 0 && (
          <AdvancedFilters>
            {status === RPM_PATIENT_STATUS.onboarded  && <Filter definition={getFiltersDefinition().consentForProvider} />}
            <Filter definition={FacilityFilter(props.facilities).facilityId} />
            <Filter definition={getFiltersDefinition().NoReadingsSince} />
            <Filter definition={getFiltersDefinition().NewNotesSince} />
          </AdvancedFilters>
        )}
      </React.Fragment>
    );
  };

  const tabArray = Object.keys(RPM_PATIENT_STATUS).map(status => (
    <Tab label={`${Strings.capPatient.patientStatus[status]} (${patientsCount.find(p => p.status === status)?.count})`} key={status}>
      <PatientList
        refreshTimestamp={refreshTimestamp}
        status={status}
        facilities={props.facilities}
        onSortClick={onSortClick}
        onOffsetChange={onOffsetChange}
        forceRefresh={refreshData}
      />
    </Tab>
  ));

  return (
    <React.Fragment>
      <PageHeader right={() => getHeaderComponents()} left={props.facilities ? <AdvancedFiltersBar customFilters={FacilityFilter(props.facilities)} /> : <></>} noLeftPadding />
      {props.displayMode === PatientListMode.tabsByStatus && (
        <Tabs
          defaultIndex={tabArray.findIndex(t => t.key === status)}
          onChange={i => { setStatus(Object.keys(RPM_PATIENT_STATUS)[i]); pageRequest.current.offset = 0}}>
            {tabArray}
        </Tabs>
      )}
      {props.displayMode === PatientListMode.list && (
        <PatientList
          refreshTimestamp={refreshTimestamp}
          onSortClick={onSortClick}
          onOffsetChange={onOffsetChange}
          forceRefresh={refreshData}
        />
      )}
    </React.Fragment>
  );
}

PatientsTabs.propTypes = {
  getPatients: PropTypes.func,
  isLoading: PropTypes.any,
  pagination: PropTypes.shape({
    offset: PropTypes.number,
    total: PropTypes.number,
  }),
  filters: PropTypes.array,
  facilities: PropTypes.array,
  clearData: PropTypes.func,
  getPatientsForSuggestion: PropTypes.func,
  getFacilities: PropTypes.func,
  filters: PropTypes.array.isRequired,
  displayMode: PropTypes.string,
};

PatientsTabs.defaultProps = { displayMode: PatientListMode.tabsByStatus };

const mapStateToProps = state => {
  const { patients } = state.superUser;
  return {
    isLoading: patients && patients?.isLoading,
    pagination: patients && patients?.data?.pagination,
    facilities: state.superUser.cohorts?.facilities,
    filters: state.entities.advancedFilters.filters.items,
  };
};

const mapDispatchToProps = dispatch => ({
  getPatients: pageRequest => dispatch(actions.getPatients(pageRequest)),
  getPatientsForSuggestion: pageRequest => dispatch(actions.getPatientsForSuggestion(pageRequest)),
  clearData: () => dispatch(actions.clearPatients()),
  getFacilities: () => dispatch(cohortActions.getFacilities()),
  replacePath: path => dispatch(replace(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientsTabs);
