import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { resetForm } from '../../actions/forms';
import { closeModal } from '../../actions/modal';
import { actions } from '../../pages/SuperUser/Schedules/redux/actions';
import { actions as kitActions } from '../../pages/Kits/redux/actions';
import { ASSIGN_DEVICE_TO_KIT_ERROR, ASSIGN_DEVICE_TO_KIT_RESULT } from '../../pages/Kits/redux/constants';
import {
  ASSIGN_CAPS_TO_THE_SCHEDULE_ERROR,
  ASSIGN_CAPS_TO_THE_SCHEDULE_RESULT,
} from '../../pages/SuperUser/Schedules/redux/constants';
import Strings from '../../Strings';
import './../editStudyModal.scss';
import './attachCap.scss';
import { SelectedOptions } from '../../components/Select/Multiselect';
import Select from '../../components/Select';
import AsyncSelect from '../../components/Select/AsyncSelect';
import Wizard from '../../containers/Modal/Wizard';
import { notificationActions } from '../../components/Notification/redux/actions';

const MAX_DEVICES_IN_SELECT = 500;

class AttachCapToSchedule extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.data.caption ? this.props.data.caption : Strings.attachCap,
      devices: [],
      unassignedDevices: [],
      asyncSelectNeeded: false,
    };
    this.searchTime = null;
  }

  componentDidMount = () => {
    const pageRequest = {
      offset: 0,
      filterBy: '',
      type: 'schedule',
      limit: MAX_DEVICES_IN_SELECT,
    };

    this.props.loadDevicesForAKit(pageRequest).then(resp => {
      const devices = resp.response.devices.map(e => {
        return {
          ...e,
          value: e.device_id,
          label: `${e.device_id}${e.device_name ? ` (${e.device_name})` : ''}`,
        };
      });
      this.setState({
        unassignedDevices: devices.filter(d => !this.state.devices.some(dv => dv === d.device_id)),
        asyncSelectNeeded: resp.response.pagination.totalRecords > MAX_DEVICES_IN_SELECT,
      });
    });
  };

  onSave = () => {
    const { devices } = this.state;
    const patientDevice = this.props.data?.patientDevice;
    if (patientDevice && this.props.data?.kit.id) {
      const results = [];
      devices.forEach(device => {
        const device_data = { device_id: device };
        this.props.attachDeviceToKit(device_data, this.props.data.kit.id).then(response => {
          if (response?.type === ASSIGN_DEVICE_TO_KIT_ERROR) {
            this.props.showNotification(response?.response?.data?.error?.message, 5000, true);
          } else if (response?.type === ASSIGN_DEVICE_TO_KIT_RESULT) {
            results.push(response?.response?.data?.result);
          }

          if (results.length === devices.length) {
            this.props.closeModalWithNextAction();
          }
        });
      });
    } else if (patientDevice) {
      const patientId = this.props.data?.patientId;

      const capData = { devicesIds: devices };
      this.props.assignPatientCaps(capData, patientId).then(response => {
        if (response?.type === ASSIGN_CAPS_TO_THE_SCHEDULE_ERROR) {
          this.props.showNotification(response?.response?.data?.error?.message, 5000, true);
        }
      });
    } else {
      const devicesData = devices.map(device => ({ device_id: device }));

      const data = {
        masked_id: decodeURIComponent(this.props.data.request.maskedId),
        devices: devicesData,
      };

      this.props.assignCaps(data).then(capData => {
        if (capData?.type === ASSIGN_CAPS_TO_THE_SCHEDULE_ERROR) {
          this.props.showNotification(response?.response?.data?.error?.message, 5000, true);
        }
      });
    }
  };

  addDeviceToList(device) {
    if (device) {
      const devices = this.state.devices;
      devices.push(device);
      this.setState({
        devices,
        unassignedDevices: this.state.unassignedDevices.filter(d => d.device_id !== device),
      });
      this.forceUpdate();
    }
  }

  removeDeviceFromList(device) {
    if (device) {
      this.setState({ devices: this.state.devices.filter(e => e !== device) });
      this.forceUpdate();
    }
  }

  validateDeviceId(device) {
    if (device) {
      this.addDeviceToList(device);
    }
  }

  loadOptions = (value, callback) => {
    const pageRequest = {
      offset: 0,
      search: value,
      filterBy: '',
      type: 'schedule',
    };

    clearTimeout(this.searchTime);
    this.searchTime = setTimeout(() => {
      this.props.loadDevicesForAKit(pageRequest).then(resp => {
        callback(() => {
          const devices = resp.response.devices.map(e => {
            return {
              ...e,
              value: e.device_id,
              label: `${e.device_id}${e.device_name ? ` (${e.device_name})` : ''}`,
            };
          });
          return devices.filter(d => !this.state.devices.some(dv => dv === d.device_id));
        });
      });
    }, 1000);
  };

  render() {
    const { organizations, onOpen, onCancel, AttachCaptoAdminModalLoading, ...props } = this.props;
    const { devices } = this.state;

    const data = [
      {
        value: '',
        text: Strings.patientDashboard.selectOrg,
      },
    ];

    if (organizations) {
      Object.values(organizations).forEach(organization => {
        data.push({
          value: organization.masked_id,
          text: organization.name,
        });
      });
    }

    const pages = [
      {
        id: 'attach-device',
        title: Strings.attachDevice,
        content: (
          <React.Fragment>
            <div>
              {this.state.asyncSelectNeeded ? (
                <AsyncSelect
                  key={`devices-select-${devices.length}`}
                  placeholder={Strings.search}
                  loadOptions={this.loadOptions}
                  defaultOptions={this.state.unassignedDevices}
                  onChange={e => {
                    this.validateDeviceId(e.value);
                  }}
                />
              ) : (
                <Select
                  key={`devices-select-${devices.length}`}
                  placeholder={Strings.search}
                  data={this.state.unassignedDevices}
                  onChange={e => {
                    this.validateDeviceId(e.value);
                  }}
                  isSearchable
                />
              )}
            </div>
            <SelectedOptions
              items={devices.map(d => {
                return { value: d, label: d };
              })}
              onRemove={e => this.removeDeviceFromList(e.value)}
              itemsPerRow={1}
            />
          </React.Fragment>
        ),
        nextButton: { text: Strings.review },
        emptyFieldsCount: 0,
        canGoNext: devices.length,
      },
      {
        id: 'attach-device-review',
        title: Strings.devicesToAttach,
        content: devices.map(device => {
          if (device) {
            return (
              <div>
                <label>{device}</label>
              </div>
            );
          }
          return '';
        }),
        nextButton: { text: Strings.save },
        emptyFieldsCount: 0,
        canGoNext: true,
      },
    ];

    return <Wizard name="attach-cap-to-schedule" pages={pages} onSubmit={this.onSave} />;
  }
}

AttachCapToSchedule.propTypes = {
  AttachCaptoAdminModalLoading: PropTypes.any,
  assignCaps: PropTypes.func,
  assignPatientCaps: PropTypes.func,
  data: PropTypes.shape({
    caption: PropTypes.any,
    patientDevice: PropTypes.any,
    patientId: PropTypes.any,
    request: PropTypes.shape({
      maskedId: PropTypes.any,
      scheduleName: PropTypes.any,
    }),
  }),
  onCancel: PropTypes.any,
  onOpen: PropTypes.any,
  organizations: PropTypes.any,
  loadDevicesForAKit: PropTypes.func,
  showNotification: PropTypes.func,
};

const mapStateToProps = state => ({
  AttachCaptoAdminModalLoading: state.entities.caps.loading,
  organizations: state.superUser?.organizations?.organizationAll,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => dispatch(closeModal('attach-cap-to-schedule')),
  assignPatientCaps: (data, patientId) =>
    dispatch(actions.assignPatientCaps(data, patientId)).then(response => {
      if (response.type === ASSIGN_CAPS_TO_THE_SCHEDULE_RESULT) {
        dispatch(ownProps.data.action(ownProps.data.request, patientId)).then(results => {
          if (results && results.type === `${ownProps.data.actionType}/result`) {
            dispatch(closeModal('attach-cap-to-schedule'));
          }
          return results;
        });
      }
    }),

  assignCaps: data =>
    dispatch(actions.assignCaps(data)).then(response => {
      if (response.type === ASSIGN_CAPS_TO_THE_SCHEDULE_RESULT) {
        dispatch(ownProps.data.action(ownProps.data.request)).then(results => {
          if (results && results.type === `${ownProps.data.actionType}/result`) {
            dispatch(closeModal('attach-cap-to-schedule'));
          }
          return results;
        });
      }
    }),

  attachDeviceToKit: (data, kitId) => dispatch(kitActions.assignDeviceToKit(kitId, data)),
  closeModalWithNextAction: () => {
    dispatch(closeModal('attach-cap-to-schedule'));
    if (ownProps.data.action) {
      dispatch(ownProps.data.action(ownProps.data.request, ownProps.data.patientId)).then(results => {
        if (results && results.type === `${ownProps.data.actionType}/result`) {
          dispatch(closeModal('attach-cap-to-schedule'));
        }
        return results;
      });
    }
  },
  onOpen: () => dispatch(resetForm('attach-cap-to-schedule', ownProps.data)),
  loadDevicesForAKit: pageRequest => dispatch(kitActions.getDevicesUnassignedToKit(pageRequest)),
  showNotification: (message, timeout, isError) => dispatch(notificationActions.show(message, timeout, isError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AttachCapToSchedule);
