import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { openModalAction } from '../../../actions/modal';
import { PageHeader } from '../../../components/PageHeader';
import { Button } from '../../../components/PageHeader/Button';
import { TextInput } from '../../../components/PageHeader/TextInput';
import { Switch2 } from '../../../components/Switch2/Switch2';
import { HEADER_BUTTON_BLUE, PAGE_LIMIT } from '../../../constants';
import Table, { Column } from '../../../containers/Table/TableWithPagination';
import Strings from '../../../Strings';
import { actions } from '../Provisioning/redux/actions';
import { GET_PROVISIONING_DEVICES } from './redux/constants';

class ProvisioningTab extends PureComponent {
  pageRequest = {
    totalRecords: 0,
    offset: 0,
    filterBy: this.props.type,
  };

  componentDidMount() {
    this.props.loadDevices(this.pageRequest);
  }

  componentWillUnmount() {
    this.props.clearData();
  }

  onSearchQueryChange(query) {
    this.pageRequest.offset = 0;
    this.pageRequest.search = query;
    this.props.loadDevices(this.pageRequest);
  }

  onDateRangeChanged = () => {
    this.pageRequest.offset = this.props.pagination.offset;
    this.props.loadDevices(this.pageRequest);
  };

  onPrevClick = () => {
    const { pagination } = this.props;
    this.pageRequest.offset = pagination.offset - PAGE_LIMIT;
    this.props.loadDevices(this.pageRequest);
  };

  onNextClick = () => {
    const { pagination } = this.props;
    this.pageRequest.offset = pagination.offset + PAGE_LIMIT;
    this.props.loadDevices(this.pageRequest);
  };

  onCustomPage = page => {
    this.pageRequest.offset = (page - 1) * PAGE_LIMIT;
    this.props.loadDevices(this.pageRequest);
  };

  onChange = event => {
    this.setState({ [event.target.getAttribute('name')]: event.target.value });
  };

  onTextInputChange = e => {
    this.onSearchQueryChange(e.target.value);
  };

  testDeviceRender = e => {
    return (
      <div style={{ position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 2,
          }}
        ></div>
        <Switch2 checked={e.TestDevice} name={`switch_${e.id}`} />
      </div>
    );
  };

  deleteDeviceConfirmation = (id, device) => {
    const data = {
      title: (
        <span>
          {Strings.deleteHubDeviceWarning} <b> {device.DeviceSN}</b>?
        </span>
      ),

      onConfirmAction: actions.deleteDevice(device, [actions.getDevices(this.pageRequest)]),
      onCancelAction: null,
    };
    this.props.openConfirmModal(data);
  };

  getHeaderComponents() {
    const { onEditDevice } = this.props;
    return (
      <React.Fragment>
        <TextInput class="search" placeholder={Strings.search} onChange={this.onTextInputChange} />
        <Button
          class={HEADER_BUTTON_BLUE}
          onClick={() => onEditDevice({}, this.props.type, this.pageRequest)}
          title={Strings.add}
        />
      </React.Fragment>
    );
  }

  render() {
    const { devices, isLoading, pagination, type } = this.props;

    const columns = [];

    columns.push(<Column key="DeviceSN" title={Strings.deviceId} value={e => e.DeviceSN} />);
    columns.push(<Column key="DeviceName" title={Strings.deviceName} value={e => e.DeviceName ?? '-'} />);
    columns.push(<Column key="DeviceImei" title={Strings.imei} value={e => e.DeviceImei ?? '-'} />);

    columns.push(<Column key="testdevice" title={Strings.testDevice} value={e => this.testDeviceRender(e)} />);
    columns.push(<Column key="apiurl" title={Strings.apiURL} value={e => e.ApiUrl} />);
    columns.push(<Column key="fotaurl" title={Strings.fotaURL} value={e => e.FotaUrl} />);
    columns.push(<Column key="features" title={Strings.features} value={e => e.Features} />);
    if (type === 'hub') columns.push(<Column key="devices" title={Strings.devices} value={e => e.Devices} />);

    const buttons = [
      {
        icon: 'edit',
        onClick: (id, device) => this.props.onEditDevice(device, type, this.pageRequest),
        text: Strings.edit,
      },
      {
        icon: 'delete',
        onClick: this.deleteDeviceConfirmation,
        text: Strings.delete,
      },
    ];

    return (
      <React.Fragment>
        <PageHeader left={Strings.provisioning} right={() => this.getHeaderComponents()} />
        {devices && (
          <Table
            name="cap-list"
            data={devices}
            onPrevClick={this.onPrevClick}
            onNextClick={this.onNextClick}
            onCustomPage={this.onCustomPage}
            pagination={
              pagination || {
                offset: 0,
                total: 0,
              }
            }
            isLoading={isLoading}
            buttons={buttons}
          >
            {columns}
          </Table>
        )}
      </React.Fragment>
    );
  }
}

ProvisioningTab.propTypes = {
  clearData: PropTypes.func,
  deleteDevice: PropTypes.func,
  devices: PropTypes.any,
  isLoading: PropTypes.any,
  loadDevices: PropTypes.func,
  onEditDevice: PropTypes.func,
  onNavigate: PropTypes.func,
  type: PropTypes.string,
  pagination: PropTypes.shape({
    offset: PropTypes.number,
    total: PropTypes.number,
  }),
};

const mapStateToProps = state => {
  const { provisioning } = state.superUser;

  return {
    role: state.auth.role,
    devices: provisioning && provisioning?.devices,
    isLoading: provisioning && provisioning?.isLoading,
    pagination: provisioning && provisioning?.pagination,
  };
};

const mapDispatchToProps = dispatch => ({
  onNavigate: path => dispatch(push(path)),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
  loadDevices: pageRequest => dispatch(actions.getDevices(pageRequest)),
  deleteDevice: pageRequest => dispatch(actions.deleteDevice(pageRequest)),
  clearData: () => dispatch(actions.clearData()),
  onEditDevice: (req, type, pageRequest) =>
    dispatch(
      openModalAction('add-edit-provisioning', {
        action: actions.getDevices,
        actionType: GET_PROVISIONING_DEVICES,
        type,
        request: req,
        pageRequest,
      }),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProvisioningTab);
