import { authActions } from 'actions/auth';

import {
  ESI_PATIENT_LOGIN_CHECK_CODE_RESULT,
  ESI_PATIENT_LOGIN_CONFIG_RESULT,
  SKIP_ON_BOARDING,
} from '../pages/ESI/PatientLogin/redux/constants';
import { authSet, authUnset } from '../utils';

const loginResult = `${authActions.login}/result`;
const loginError = `${authActions.login}/error`;
const oauthLoginResult = `${authActions.oauth2Login}/result`;
const logoutResult = `${authActions.logout}/result`;
const logoutError = `${authActions.logout}/error`;
const profileResult = `${authActions.profile}/result`;
const ssoLoginResult = `${authActions.ssoLogin}/result`;
const activateResult = 'user-activate/result';
const authStatus403 = 'auth/status/error';
const authStatus403UnSet = 'auth/status/unset';

const TwoFAResult = `${authActions.two_factor_auth}/result`;
const TwoFAError = `${authActions.two_factor_auth}/error`;

// this permisssion added only for the duke UI and it is a temporary fix
const DUKE_PERMISSSION = [
  'dashboard_adherence/adherence_chart',
  'dashboard_adherence/adherence_stats',
  'dashboard_adherence/cap_evolution',
  'settings/details',
  'support/chat',
  'support/email',
  'support/help_center',
  'support/smart_cap',
  'study/',
];

export const auth = (state = {}, action) => {
  if (action.type.startsWith('auth/')) {
    switch (action.type) {
      case authActions.login:
        return {
          ...state,
          isLoading: true,
          loginErrors: undefined,
        };
      case loginResult:
      case TwoFAResult: {
        if (action.response.authentication_token) {
          if (action.response.version) {
            sessionStorage.setItem('version', action.response.version);
            sessionStorage.setItem('org', action.response?.organization?.name);
          }
          return {
            ...authSet(action.response),
            isLoading: false,
            is_doctor: action.response.is_doctor,
            role: action.response.role,
            loginErrors: undefined,
            reset_password: action.response.reset_password,
            organization: action.response.organization,
            ui_config: action.response.ui_config,
            permissions: readPermissions(action.response),
            eula: action.response?.eula,
          };
        }
        if (action.response.two_factor_key) {
          return {
            ...state,
            isLoading: false,
            two_factor_key: action.response.two_factor_key,
            verified: action.response.verified,
            first_name: action.response.first_name,
            organization: action.response.organization,
          };
        }
        break;
      }
      case authActions.clear_two_factor_key: {
        return {
          ...state,
          twoFAErrors: null,
          two_factor_key: null,
        };
      }
      case authActions.two_factor_auth_resend_result: {
        return {
          ...state,
          isLoading: false,
          twoFAErrors: null,
          two_factor_key: action.response.two_factor_key,
        };
      }
      case authActions.two_factor_auth:
        return {
          ...state,
          isLoading: true,
          twoFAErrors: null,
        };
      case TwoFAError:
        return {
          ...state,
          twoFAErrors: action.response.data.error,
          isLoading: false,
        };

      case oauthLoginResult: {
        if (action.response.authentication_token) {
          return {
            ...authSet(action.response),
            loginErrors: undefined,
            origin: 'fhir',
          };
        }
        break;
      }
      case ssoLoginResult: {
        if (action.response.authentication_token) {
          return {
            ...authSet(action.response),
            loginErrors: undefined,
            context: action.response.context,
            origin: 'sso',
          };
        }
        break;
      }
      case authActions.profile:
        return {
          ...state,
          profileRequested: true,
        };

      case profileResult: {
        if (action.response.role) {
          sessionStorage.setItem('role', action.response.role);
        }

        return {
          ...state,
          is_doctor: action.response.is_doctor,
          role: action.response.role,
          organization: action.response.organization,
          permissions: readPermissions(action.response),
          ui_config: action.response.ui_config,
          profile: { ...action.response },
          eula: action.response?.eula,
        };
      }
      case loginError: {
        return {
          authenticated: false,
          loginErrors: action.response.data.error,
        };
      }
      case logoutResult: {
        return authUnset();
      }
      case logoutError: {
        return authUnset();
      }
      case authActions.clearLoginForm:
        return {
          ...state,
          loginErrors: undefined,
        };
      case authStatus403:
        return {
          ...state,
          errors: action.response.data,
        };

      case authStatus403UnSet:
        return {
          ...state,
          errors: {},
        };

      default:
        return state;
    }
  } else if (action.type === activateResult && action.response.authentication_token) {
    return {
      ...authSet(action.response),
      loginErrors: undefined,
    };
  } else if (action.type === ESI_PATIENT_LOGIN_CHECK_CODE_RESULT && action.response.authentication_token) {
    return {
      ...authSet(action.response),
      isLoading: false,
      is_doctor: action.response.is_doctor,
      role: action.response.role,
      loginErrors: undefined,
      reset_password: action.response.reset_password,
      config: action.response?.config,
      permissions: readPermissions(action.response),
      ui_config: action.response.ui_config,
      eula: action.response?.eula,
    };
  } else if (action.type === ESI_PATIENT_LOGIN_CONFIG_RESULT) {
    return {
      ...state,
      config: action.response?.config,
    };
  } else if (action.type === SKIP_ON_BOARDING) {
    return {
      ...state,
      esi_is_portal_onboarding_tutorial_viewed: 'skip',
    };
  }

  return state;
};

const readPermissions = response => {
  const ui_access = response.ui_accesss ? response.ui_accesss : response.ui_access ? response.ui_access : undefined;
  // this permisssion added only for the duke UI and it is a temporary fix
  if (response.version === 'v1' && response.role === 'doctor') {
    return DUKE_PERMISSSION;
  }
  // temporary solution for introducing permissions that are not in the backend yet
  return permissionsRecursiveSearch(ui_access);
};

const permissionsRecursiveSearch = (obj, path = '', results = []) => {
  let r = results;
  if (!obj) return r;

  const keys = Object.keys(obj);
  if (keys.length === 0) return [...r, path];

  keys.forEach(key => {
    const value = obj[key];
    if (typeof value === 'object') {
      r = permissionsRecursiveSearch(value, path + (path ? '/' : '') + key, r);
    }
  });

  return r;
};
