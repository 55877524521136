import { API_CALL } from '../../../../../middleware/api';
import { WIDGET_GET_PATIENTS_WITH_NO_READINGS } from './constants';

export const actions = {
  getPatientsWithNoReadings: days => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/patients/list',
      params: { limit: 1, noReadingsSince: days, status: 'activated' },
    },
    type: WIDGET_GET_PATIENTS_WITH_NO_READINGS,
  }),
};
