export const BLOOD_PRESSURE = `blood_pressure`;
export const SLEEP_REPORT = 'sleep_report';
export const HEART_RATE = 'heart_rate';
export const BATTERY = `battery`;
export const CONNECTION = `connection`;
export const WEIGHT = 'weight';
export const MOVEMENT_REPORT = 'movement_report';
export const STEPS_REPORT = 'steps_report';
export const SPIROMETER = 'spirometer';
export const HAILIE_INHALER = 'hailie_inhaler';
export const PTINR_MONITOR = 'pt_inr';
export const THERMOMETER = 'thermometer';
export const PULSEOX = 'pulseox';
export const AIR_QUALITY_MONITOR = 'atmocube';
