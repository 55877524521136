import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import Strings from '../../../Strings';
import TableWithLocalPagination, { Column } from '../../../containers/Table/TableWithLocalPagination';
import { DATE_MONTH_DAY_YEAR, TIME_FORMAT_12_UPPERCASE } from '../../../constants';
import { PageHeader } from '../../../components/PageHeader';
import { Button, HEADER_BUTTON_DARK_BLUE } from '../../../components/PageHeader/Button';
import { CHANGE_DEVICE_STATUS_HISTORY } from '../../ShipperCaps/redux/constants';
import { openModalAction } from '../../../actions/modal';
import { actions } from '../../Kits/redux/actions';
import { CHANGE_KIT_ADMINISTRATIVE_STATUS } from '../../Kits/redux/constants';
import { StateTypes } from '../../../modals/ChangeStateModal';

function KitStatusHistory(props) {
  const [history, setHistory] = React.useState([]);
  const deviceId = props.match.params.deviceId || props.match.params.id;

  React.useEffect(() => {
    getData();
  }, [deviceId]);

  const getData = () => {
    props.getKitStatusHistory(deviceId).then(resp => {
      if (resp?.response) {
        const ret = [];
        resp.response.forEach(e => {
          let statusChanged = '-';
          let old_status = '-';
          let new_status = '-';
          if (e.old_operational_state && e.new_operational_state) {
            statusChanged = Strings.operationalState;
            old_status = e.old_operational_state;
            new_status = e.new_operational_state;
          } else if (e.new_administrative_state && e.old_administrative_state) {
            statusChanged = Strings.administrativeState;
            old_status = e.old_administrative_state;
            new_status = e.new_administrative_state;
          } else if (e.new_usage_state && e.old_usage_state) {
            statusChanged = Strings.usageState;
            old_status = e.old_usage_state;
            new_status = e.new_usage_state;
          }
          ret.push({
            ...e,
            old_status,
            new_status,
            statusChanged,
          });
        });
        setHistory(ret);
      }
    });
  };

  const onStatusChange = () => {
    const data = {
      id: deviceId,
      propertyForApi: 'state',
      action: actions.changeKitAdministrativeState,
      actionType: CHANGE_KIT_ADMINISTRATIVE_STATUS,
      title: Strings.changeDeviceState,
      state: StateTypes.kit.id,
      onSuccess: getData,
    };
    props.changeDeviceStatus(data);
  };

  return (
    <React.Fragment>
      <PageHeader
        left={Strings.KitStatusHistory}
        right={<Button onClick={onStatusChange} class={HEADER_BUTTON_DARK_BLUE} title={Strings.changeDeviceState} />}
      />
      <TableWithLocalPagination
        name="device-status-history"
        data={history}
        columns={[
          <Column
            key="change_date"
            title={Strings.date}
            value={e => {
              return e.change_time ? moment(e.change_time).format(DATE_MONTH_DAY_YEAR) : '-';
            }}
          />,
          <Column
            key="change_time"
            title={Strings.time}
            value={e => {
              return e.change_time ? moment(e.change_time).format(TIME_FORMAT_12_UPPERCASE) : '-';
            }}
          />,
          <Column key="statusChanged" title={Strings.changedStatus} value={e => e.statusChanged || '-'} />,
          <Column key="old_status" title={Strings.oldStatus} value={e => e.old_status || '-'} />,
          <Column key="new_status" title={Strings.newStatus} value={e => e.new_status || '-'} />,
          <Column key="change_note" title={Strings.note} value={e => e.change_note || '-'} />,
          <Column
            key="valid_until"
            title={Strings.validUntil}
            value={e => (e.valid_until ? moment(e.valid_until).format(DATE_MONTH_DAY_YEAR) : '-')}
          />,
        ]}
      />
    </React.Fragment>
  );
}

KitStatusHistory.propTypes = {
  activeTab: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
  getKitStatusHistory: id => dispatch(actions.getKitStatusHistory(id)),
  changeDeviceStatus: data => dispatch(openModalAction('change-state', data)),
});

export default connect(null, mapDispatchToProps)(KitStatusHistory);
