import { PAGE_LIMIT } from '../../../../constants';
import { API_CALL } from '../../../../middleware/api';
import {
  ADD_SCHEDULE,
  ASSIGN_CAPS_TO_THE_SCHEDULE,
  DELETE_CAP_FROM_SCHEDULE,
  DELETE_SCHEDULE,
  EDIT_SCHEDULE,
  GET_CAPS,
  SCHEDULE_DETAILS,
  SCHEDULE_LIST,
  SCHEDULE_LIST_CLEAR,
} from './constants';

const qs = require('qs');

export const actions = {
  getSchedules: pageRequest => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/v1/grouped/schedule/list/',
      params: { ...pageRequest, limit: PAGE_LIMIT },
    },
    type: SCHEDULE_LIST,
  }),
  getScheduleDetails: pageRequest => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/v1/grouped/schedule/info/',
      params: { ...pageRequest, limit: PAGE_LIMIT },
    },
    type: SCHEDULE_DETAILS,
  }),

  deleteSchedule: (deleteData, id) => ({
    [API_CALL]: {
      endpoint: `/v1/grouped/schedule/delete`,
      method: 'POST',
      data: deleteData,
    },
    type: DELETE_SCHEDULE,
    id: id,
  }),

  deleteScheduleCaps: deleteData => ({
    [API_CALL]: {
      endpoint: `/v1/grouped/schedule/caps/delete`,
      method: 'POST',
      data: deleteData,
    },
    type: DELETE_CAP_FROM_SCHEDULE,
    capId: deleteData.device_id,
  }),

  assignCaps: data => ({
    [API_CALL]: {
      endpoint: `/v1/grouped/schedule/caps/`,
      method: 'PUT',
      data,
    },
    type: ASSIGN_CAPS_TO_THE_SCHEDULE,
  }),

  // this endpoint should not be used as it is removed from backend
  assignPatientCaps: (data, patientId) => ({
    [API_CALL]: {
      endpoint: `/patients/devices/${patientId}/attach`,
      method: 'PUT',
      data,
    },
    type: ASSIGN_CAPS_TO_THE_SCHEDULE,
  }),

  addSchedule: data => ({
    [API_CALL]: {
      method: 'POST',
      endpoint: '/v1/grouped/schedule/',
      data,
    },
    type: ADD_SCHEDULE,
  }),

  editSchedule: data => ({
    [API_CALL]: {
      method: 'PUT',
      endpoint: '/v1/grouped/schedule/',
      data,
    },
    type: EDIT_SCHEDULE,
  }),

  clearCapsDetails: () => ({
    type: SCHEDULE_LIST_CLEAR,
  }),
};
