import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { actions } from './redux/actions';
import '../Widgets.scss';
import Strings from '../../../../Strings';

class PendingCallsMessages extends PureComponent {
  componentDidMount() {
    this.props.getData();
  }

  render() {
    const { isLoading, pendingMessages } = this.props;
    return (
      <React.Fragment>
        {!isLoading && (
          <div className="boxWithVl-box">
            <div className="boxWithVl-valueBox black">{pendingMessages}</div>
            <div onClick={() => this.props.onClick('/messages/inbox')} className="boxWithVl-labelBox clickable">
              {Strings.widgets.unreadMessages}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

PendingCallsMessages.widgetId = 'PendingCallsMessagesWidget';

PendingCallsMessages.propTypes = {
  getData: PropTypes.func,
  pendingCalls: PropTypes.any,
  pendingMessages: PropTypes.any,
  isLoading: PropTypes.any,
  onClick: PropTypes.func,
};

const mapStateToProps = state => {
  const { widgetPendingCallsMessagesReducer } = state.dashboardWidgets;
  return {
    pendingCalls: widgetPendingCallsMessagesReducer && widgetPendingCallsMessagesReducer?.pendingCalls,
    pendingMessages: widgetPendingCallsMessagesReducer && widgetPendingCallsMessagesReducer?.pendingMessages,
    isLoading: widgetPendingCallsMessagesReducer && widgetPendingCallsMessagesReducer?.isLoading,
  };
};

const mapDispatchToProps = dispatch => ({
  getData: () => dispatch(actions.getPendingCallsMessages()),
  onClick: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PendingCallsMessages);
