import React, { PureComponent } from 'react';

import './Configuration.scss';
import OrgPermissions from './OrgPermissions';
import OrgRolePermissions from './OrgRolePermissions';
import { Tab, Tabs } from '../../../components/Tabs';

class Configuration extends PureComponent {
  render() {
    const { organizationId } = this.props;
    return (
      <div className="">
        <div className="config-tab-container">
          <Tabs defaultIndex={0}>
            <Tab label="Organization" key="org">
              <OrgPermissions organizationId={organizationId}></OrgPermissions>
            </Tab>
            <Tab label="Role" key="role">
              <OrgRolePermissions organizationId={organizationId}></OrgRolePermissions>
            </Tab>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default Configuration;
