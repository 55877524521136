import { API_CALL } from '../../../middleware/api';
import { GET_DEVICES } from './constants';

export const actions = {
  getDevices: pageRequest => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/devices',
      params: pageRequest,
    },
    type: GET_DEVICES,
  }),
};
