import { now } from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Wrapper, Button as MenuButton, MenuItem, Menu } from 'react-aria-menubutton';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import moment from 'moment-timezone';

import { closeModal, openModalAction } from '../../../actions/modal';
import { PageHeader } from '../../../components/PageHeader';
import { Button } from '../../../components/PageHeader/Button';
import TabView, { Tab } from '../../../components/TabView';
import Strings from '../../../Strings';
import Cohorts from './Cohorts/Cohorts';
import Readings from './Readings/Readings';
import { actions as cohortActions } from './Cohorts/redux/actions';
import { actions } from './redux/actions';
import {
  ADD_PATIENT,
  DOWNLOAD_NOTES_AND_ACTIONS_RESULT,
  DOWNLOAD_PATIENTS_EVENTS_RESULT,
  PATIENT_LIST,
  PATIENT_LIST_FOR_SUGGESTION,
} from './redux/constants';
import { CREATE_COHORT } from './Cohorts/redux/constants';
import { executePatientOnboarding } from '../../../modals/_Workflows/OnboardPatient';
import { downloadFileOnly } from '../../../utils';
import { executeRpmOrgSetup } from '../../../modals/_Workflows/SetupRpmOrg';
import { DATE_FORMAT_YEAR_MONTH_DAY, RPM_PATIENT_STATUS } from '../../../constants';
import { hasPermission, PERMISSIONS } from '../../../utils/userPermissions';
import CloudDocuments from '../../CloudDocuments/CloudDocuments';
import PatientsTabs from './PatientsTabs';

function Patients(props) {
  const [isListOpen, setIsListOpen] = useState(false);
  const [cohortsRefreshTimestamp, setCohortsRefreshTimestamp] = useState(now());
  const [patientsRefreshTimestamp, setPatientsRefreshTimestamp] = useState(now());
  const [refreshEnable, setRefreshEnable] = useState(true);

  const { activeTab } = props;

  const onDownloadBtnClickSuccessHandler = (req, name) => {
    const fileName = req?.response && req?.response?.name ? req?.response?.name : name ? name : moment().toString();
    downloadFileOnly(req, fileName);
  };

  const downloadNotesAndActions = () => {
    const onDateSubmited = dates => {
      const request = {
        includeSignature: true,
        startTimestamp: moment(dates.startDate * 1000).format(),
        endTimestamp: moment(dates.endDate * 1000).format(),
      };
      props.downloadNotesAndActions(request).then(resp => {
        if (resp.type === DOWNLOAD_NOTES_AND_ACTIONS_RESULT) {
          onDownloadBtnClickSuccessHandler(
            resp,
            `NoteAll__${moment(request.startTimestamp).format(DATE_FORMAT_YEAR_MONTH_DAY)}_${moment(
              request.endTimestamp,
            ).format(DATE_FORMAT_YEAR_MONTH_DAY)}`,
          );
        }
      });
    };
    props.openDateRangeSelectionModal({
      actionOnDone: onDateSubmited,
      title: Strings.capPatient.downloadNotesAndActions,
      submitButtonText: Strings.download,
    });
  };

  const downloadPatientEvents = () => {
    const onDateSubmited = dates => {
      const request = {
        startTimestamp: moment(dates.startDate * 1000).format(),
        endTimestamp: moment(dates.endDate * 1000).format(),
      };
      props.downloadPatientsEvents(request).then(resp => {
        if (resp.type === DOWNLOAD_PATIENTS_EVENTS_RESULT) {
          onDownloadBtnClickSuccessHandler(
            resp,
            `EventsAll__${moment(request.startTimestamp).format(DATE_FORMAT_YEAR_MONTH_DAY)}_${moment(
              request.endTimestamp,
            ).format(DATE_FORMAT_YEAR_MONTH_DAY)}`,
          );
        }
      });
    };
    props.openDateRangeSelectionModal({
      actionOnDone: onDateSubmited,
      title: Strings.downloadPatientsEvents,
      submitButtonText: Strings.download,
    });
  };

  const isRpmOrgSetup = async () => {
    const conditionsResp = await props.getConditions();
    const facilitiesResp = await props.getFacilities();
    if (conditionsResp?.response?.data?.length > 0 && facilitiesResp?.response?.data?.length) {
      return true;
    }
    return false;
  };

  const handleSelection = async (value, event) => {
    const {
      onBulkAddPatients,
      onCreateCohort,
      onEditConditions,
      onEditFacilities,
      onSendMessage,
      getPatientsForSuggestion,
      onUploadPatientList,
    } = props;
    switch (value) {
      case 'addPatient':
        props.addPatient(() => setPatientsRefreshTimestamp(now()));
        // if (!props.labRpmSetupEnabled || (await isRpmOrgSetup())) {
        //   executePatientOnboarding(() => setPatientsRefreshTimestamp(now()));
        // } else {
        //   const data = {
        //     caption: Strings.addPatient,
        //     title: <span>{Strings.capPatient.addConditionsFacilitiesCohorts}</span>,
        //     hideCaution: true,
        //     onConfirmAction: () => {
        //       executeRpmOrgSetup();
        //       props.closeConfirmationModal();
        //     },
        //     onCancelAction: null,
        //     yesButtonText: Strings.ok,
        //     noButtonText: Strings.cancel,
        //   };
        //   props.openConfirmModal(data);
        // }
        break;
      case 'bulkAddPatients':
        onBulkAddPatients(() => setPatientsRefreshTimestamp(now()));
        break;
      case 'createCohort':
        onCreateCohort(() => setCohortsRefreshTimestamp(now()));
        break;
      case 'editConditions':
        onEditConditions();
        break;
      case 'editFacilities':
        onEditFacilities();
        break;
      case 'downloadNotesAndActions':
        downloadNotesAndActions();
        break;
      case 'sendMessage':
        onSendMessage(getPatientsForSuggestion, PATIENT_LIST_FOR_SUGGESTION, now());
        break;
      case 'uploadPatientList':
        onUploadPatientList();
        break;
      case 'downloadPatientsEvents':
        downloadPatientEvents();
        break;
      default:
        break;
    }
  };

  const getHeaderComponents = () => {
    const actionsButton = (
      <Wrapper
        className="addMenu"
        onSelection={handleSelection}
        onMenuToggle={({ isOpen }) => {
          setIsListOpen(isOpen);
        }}
      >
        <MenuButton className={`addMenu-button ${isListOpen ? 'expanded' : ''}`}>{Strings.actions}</MenuButton>
        <Menu className="addMenu-menu">
          {activeTab === Strings.patients && (
            <MenuItem className="addMenu-menuItem" value="addPatient" id="addPatient">
              {Strings.addPatient}
            </MenuItem>
          )}
          {activeTab === Strings.patients && (
            <MenuItem className="addMenu-menuItem" value="bulkAddPatients" id="bulkAddPatients">
              {Strings.bulkAddPatients}
            </MenuItem>
          )}
          {activeTab === Strings.cohorts && (
            <MenuItem className="addMenu-menuItem" value="createCohort" id="createCohort">
              {Strings.createCohort}
            </MenuItem>
          )}
          {activeTab === Strings.cohorts || activeTab === Strings.patients && (
            <MenuItem className="addMenu-menuItem" value="editConditions" id="editConditions">
              Add / Edit Conditions
            </MenuItem>
          )}
          {activeTab === Strings.cohorts || activeTab === Strings.patients && (
            <MenuItem className="addMenu-menuItem" value="editFacilities" id="editFacilities">
              Providers / Facilities
            </MenuItem>
          )}
          {activeTab === Strings.patients && (
            <MenuItem className="addMenu-menuItem" value="downloadNotesAndActions" id="downloadNotesAndActions">
              {Strings.capPatient.downloadNotesAndActions}
            </MenuItem>
          )}
          {activeTab === Strings.patients && hasPermission(PERMISSIONS.PATIENTS_RPM_DOWNLOAD_PATIENTS_VITALS_CSV) && (
            <MenuItem className="addMenu-menuItem" value="downloadPatientsEvents" id="downloadPatientsEvents">
              {Strings.downloadPatientsEvents}
            </MenuItem>
          )}
          {activeTab === Strings.patients && (
            <MenuItem className="addMenu-menuItem" value="sendMessage" id="sendMessage">
              {Strings.sendSMS}
            </MenuItem>
          )}
          {activeTab === Strings.patients && hasPermission(PERMISSIONS.PATIENTS_RPM_UPLOAD_PATIENT_LIST) && (
            <MenuItem className="addMenu-menuItem" value="uploadPatientList" id="uploadPatientList">
              {Strings.uploadPatientList}
            </MenuItem>
          )}
        </Menu>
      </Wrapper>
    );
    return (
      <React.Fragment>
        {(activeTab === Strings.patients || activeTab === Strings.cohorts) && actionsButton}
        <Button class="refresh" disabled={!refreshEnable} onClick={onRefresh} />
      </React.Fragment>
    );
  };

  const onRefresh = () => {
    setCohortsRefreshTimestamp(now());
    setPatientsRefreshTimestamp(now());
    setRefreshEnable(false);
    setTimeout(() => {
      setRefreshEnable(true);
    }, 10000);
  };

  const urlParams = new URLSearchParams(window.location.search);
  const status = urlParams.get('status') || RPM_PATIENT_STATUS.activated;
  const tabArray = [];
  const patientList = (
    <Tab name={Strings.patients} key={Strings.patients} path="/cap-patients/list">
      <PatientsTabs refreshTimestamp={patientsRefreshTimestamp} status={status} />
    </Tab>
  );

  const cohorts = (
    <Tab name={Strings.cohorts} key={Strings.cohorts} path="/cap-patients/cohorts">
      <Cohorts refreshTimestamp={cohortsRefreshTimestamp} />
    </Tab>
  );

  const readings = (
    <Tab name={Strings.monitoring} key={Strings.monitoring} path="/cap-patients/readings">
      <Readings refreshTimestamp={cohortsRefreshTimestamp} />
    </Tab>
  );
  const patientFiles = (
    <Tab name={Strings.patientListFiles} key={Strings.patientListFiles} path="/cap-patients/patient-list-files">
      <CloudDocuments patientFiles />
    </Tab>
  );

  tabArray.push(patientList);
  tabArray.push(readings);
  tabArray.push(cohorts);
  tabArray.push(patientFiles);

  return (
    <React.Fragment>
      <PageHeader left={Strings.enrollAndMonitor} right={getHeaderComponents()} />
      <TabView key="tabs" activeTab={activeTab} routeMode className="" noSwiping>
        {tabArray}
      </TabView>
    </React.Fragment>
  );
}

Patients.propTypes = {
  activeTab: PropTypes.any,
  onNavigate: PropTypes.func,
  onBulkAddPatients: PropTypes.func,
  onCreateCohort: PropTypes.func,
  onEditConditions: PropTypes.func,
  onEditFacilities: PropTypes.func,
  downloadNotesAndActions: PropTypes.func,
  downloadPatientsEvents: PropTypes.func,
  onSendMessage: PropTypes.func,
  getPatientsForSuggestion: PropTypes.func,
  getConditions: PropTypes.func,
  getFacilities: PropTypes.func,
  openConfirmModal: PropTypes.func,
  openDateRangeSelectionModal: PropTypes.func,
  onUploadPatientList: PropTypes.func,
  addPatient: PropTypes.func,
  closeConfirmationModal: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  onNavigate: path => dispatch(push(path)),
  onBulkAddPatients: onSuccess =>
    dispatch(
      openModalAction('bulk-add-patients', {
        actionType: PATIENT_LIST,
        onSuccess,
      }),
    ),
  onCreateCohort: nextAction =>
    dispatch(
      openModalAction('edit-cohort', {
        action: cohortActions.createCohort,
        actionType: CREATE_COHORT,
        nextAction,
      }),
    ),
  onUploadPatientList: () =>
    dispatch(
      openModalAction('bulk-add-patients', {
        uploadPatientListMode: true,
      }),
    ),
  onEditConditions: () => dispatch(openModalAction('edit-conditions')),
  onEditFacilities: () => dispatch(openModalAction('edit-facilities')),
  downloadNotesAndActions: request => dispatch(actions.downloadNotesAndActions(request)),
  downloadPatientsEvents: request => dispatch(actions.downloadPatientsEvents(request)),
  onSendMessage: (loadOptionsAction, loadOptionsActionType, id) =>
    dispatch(
      openModalAction(
        'send-message',
        {
          loadRecipients: loadOptionsAction,
          loadRecipientsActionType: loadOptionsActionType,
        },
        id,
      ),
    ),
  getPatientsForSuggestion: pageRequest => dispatch(actions.getPatientsForSuggestion(pageRequest)),
  getConditions: () => dispatch(cohortActions.getConditions()),
  getFacilities: () => dispatch(cohortActions.getFacilities()),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
  closeConfirmationModal: () => dispatch(closeModal('confirmation-modal')),
  openDateRangeSelectionModal: data => dispatch(openModalAction('data-range-selection-modalion', data)),
  addPatient: nextAction =>
    dispatch(
      openModalAction('edit-rpm-patient', {
        action: actions.addPatient,
        actionType: ADD_PATIENT,
        nextAction,
        stage: 'onboarding',
      }),
    ),
});

const mapStateToProps = state => {
  return {
    labRpmSetupEnabled: state.labState.features.find(f => f.name === 'RPM Setup Workflow')?.selected,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Patients);
