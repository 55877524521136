import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import Wizard from '../containers/Modal/Wizard';
import Strings from '../Strings';
import { notificationActions } from '../components/Notification/redux/actions';
import { closeModal } from '../actions/modal';
import { actions } from '../pages/SuperUser/Patients/redux/actions';
import { actions as kitActions } from '../pages/Kits/redux/actions';
import { Input } from '../containers/Form';
import { getProgramsFromPatient } from '../utils/cmsPrograms';
import { EDIT_PATIENT_ERROR } from '../pages/SuperUser/Patients/redux/constants';
import { getScheduleFromMed } from './EditRpmSchedule/EditRpmScheduleModal';
import { patientAction } from '../actions/patient';
import { UPDATE_PATIENT_SCHEDULE_ERROR } from '../actions/action-types';
import { DELETE_KIT_ERROR } from '../pages/Kits/redux/constants';
import './ResetEnrollmentAndUnattachDevicesModal.scss';
import LoadingRenderer from '../components/LoadingRenderer';

function ResetEnrollmentAndUnattachDevicesModal(props) {
  const [schedules, setSchedules] = React.useState([]);
  const [selectedSchedules, setSelectedSchedules] = React.useState([]);
  const [removeKit, setRemoveKit] = React.useState(false);
  const [saveBtnClicked, setSaveBtnClicked] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const alerts = props.data?.patient?.lastReadings?.alerts?.schedule_missed_doses_alerts;

  useEffect(() => {
    setLoading(true);
    props.getMedications().then(resp => {
      const medications = resp?.response?.medications || [];
      const meds = medications?.filter(m => m.capId) || [];
      setSchedules(meds);
      const medsWithoutAlert = meds.filter(m => !alerts?.some(a => a.schedule_id === m.id));
      setSelectedSchedules(medsWithoutAlert.map(m => m.id));
      if (medsWithoutAlert.length === meds.length) {
        setRemoveKit(true);
      } else {
        setRemoveKit(false);
      }
      setLoading(false);
    });
  }, []);

  const onSubmit = async () => {
    if (saveBtnClicked) {
      return;
    }
    setSaveBtnClicked(true);

    let fail = false;

    const editSchedule = async medication => {
      const medData = getScheduleFromMed(medication);
      delete medData.cap_id;

      const response = await props.editSchedule(medData, props.data?.patient?.id);
      if (response && response.type === UPDATE_PATIENT_SCHEDULE_ERROR) {
        props.showNotification(response.response?.data?.error?.message, 5000, true);
        return false;
      } else {
        props.showNotification(Strings.success.medicationSaved);
        return true;
      }
    };

    for (const schedule of selectedSchedules) {
      const medication = schedules?.find(m => m.id === schedule);
      if (medication) {
        const res = await editSchedule(medication);
        if (!res) {
          fail = true;
        }
      }
    }
    if (fail) {
      setSaveBtnClicked(false);
      return;
    }

    if (props.data?.patient?.kitId && removeKit) {
      const kitResp = await props.deleteKit(props.data.patient.kitId);
      if (kitResp.type === DELETE_KIT_ERROR) {
        const error = kitResp.response?.data?.error?.message;
        props.showNotification(error, 5000, true);
        fail = true;
      }
    }
    if (fail) {
      setSaveBtnClicked(false);
      return;
    }

    const response = await props.editPatient({ needsNewEnrollment: true }, props.data?.patient?.id);
    if (response) {
      if (response.type === EDIT_PATIENT_ERROR) {
        const error = response.response?.data?.standard_error?.message;
        props.showNotification(error, 5000, true);
      } else {
        props.showNotification(Strings.formatString(Strings.capPatient.patientSaved, props.data?.patient?.patientName));
        props.closeModalWithNextAction();
      }
    }
    setSaveBtnClicked(false);
  };

  const onSelectedScheduleChange = (e, id) => {
    if (e.target.checked) {
      setSelectedSchedules([...selectedSchedules, id]);
    } else {
      setSelectedSchedules(selectedSchedules.filter(s => s !== id));
      setRemoveKit(false);
    }
  };

  const patientHasScheduleAlert = alerts?.some(a => schedules.some(m => m.id === a.schedule_id));
  const pages = [
    {
      id: 'reset-patient-status-page',
      title: Strings.resetPatient,
      content: (
        <LoadingRenderer loading={loading}>
          <div>{Strings.formatString(Strings.resetPatientStatusWarning, props.data?.patient?.patientName)}</div>
          {schedules?.length > 0 && <div>{Strings.resetPatientStatusSchedulesWarning}</div>}
          {patientHasScheduleAlert && (
            <div className="horizontal-flex reminders-row">
              <span className="row">
                <div>
                  {Strings.resetPatientStatusActiveAlertsWarning}{' '}
                  <a
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      props.onCancel();
                      props.onNavigate(`/cap-patients/${props.data?.patient?.id}`);
                    }}
                  >
                    {Strings.patientDashboardTxt}
                  </a>
                </div>
                <div className="signal-alert info" />
              </span>
            </div>
          )}
          {schedules?.map((schedule, i) => {
            const hasAlert = alerts?.some(a => a.schedule_id === schedule.id);
            return (
              <React.Fragment key={`schedule_${schedule.id}`}>
                <div className={`horizontal-flex reminders-row ${hasAlert ? 'disabled' : ''}`}>
                  <Input
                    type="checkbox"
                    name={`schedule_check_${i}`}
                    id={`schedule_check_${i}`}
                    label={schedule.medicationName}
                    checked={selectedSchedules.includes(schedule.id)}
                    onChange={e => onSelectedScheduleChange(e, schedule.id)}
                    disabled={hasAlert}
                  />
                </div>
              </React.Fragment>
            );
          })}
          <div>{Strings.resetPatientStatusKitWarning}</div>
          <div
            className={`horizontal-flex reminders-row ${
              selectedSchedules.length !== schedules.length ? 'disabled' : ''
            }`}
          >
            <Input
              type="checkbox"
              name="remove-kit"
              id="remove-kit"
              label={Strings.removeKit}
              checked={removeKit}
              onChange={e => setRemoveKit(e.target.checked)}
              disabled={selectedSchedules.length !== schedules.length}
            />
          </div>
        </LoadingRenderer>
      ),
      emptyFieldsCount: 0,
      canGoNext: true,
    },
  ];

  return (
    <Wizard name="reset-enrollment-and-unattach-devices" pages={pages} onSubmit={onSubmit} showPagesFilter={false} />
  );
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  showNotification: (message, timeout, isError) => dispatch(notificationActions.show(message, timeout, isError)),
  onCancel: () => dispatch(closeModal(ownProps.name)),
  deleteKit: kitId => dispatch(kitActions.deleteKit(kitId)),
  closeModalWithNextAction: () => {
    dispatch(closeModal('reset-enrollment-and-unattach-devices'));
    if (ownProps.data.onSuccess) ownProps.data.onSuccess();
  },
  getMedications: () => dispatch(actions.getMedications(ownProps.data?.patient?.id)),
  editSchedule: (data, patientId) => dispatch(patientAction.actionUpdateSchedule(data, patientId)),
  editPatient: (data, patientId) => dispatch(actions.editPatient(data, patientId)),
  onNavigate: path => dispatch(push(path)),
});

export default connect(null, mapDispatchToProps)(ResetEnrollmentAndUnattachDevicesModal);
