import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { PageHeader } from '../../../components/PageHeader';
import Strings from '../../../Strings';
import { Button, HEADER_BUTTON_DARK_BLUE } from '../../../components/PageHeader/Button';
import TableWithLocalPagination, { Column } from '../../../containers/Table/TableWithLocalPagination';
import { actions } from './redux/actions';
import { openModalAction } from '../../../actions/modal';
import { DATE_MONTH_DAY_YEAR, TIME_FORMAT_12_UPPERCASE } from '../../../constants';
import { CHANGE_ORG_ADMINISTRATIVE_STATE } from './redux/constants';
import { StateTypes } from '../../../modals/ChangeStateModal';

function OrgStatusHistory(props) {
  const [history, setHistory] = React.useState([]);
  const { organizationId } = props;

  React.useEffect(() => {
    getData();
  }, [organizationId]);

  const getData = () => {
    props.getOrgStatusHistory(organizationId).then(resp => {
      if (resp?.response) {
        setHistory(resp.response);
      }
    });
  };

  const onStatusChange = () => {
    const data = {
      id: organizationId,
      propertyForApi: 'state',
      action: actions.changeOrganizationAdministrativeState,
      actionType: CHANGE_ORG_ADMINISTRATIVE_STATE,
      title: Strings.changeOrgState,
      state: StateTypes.org.id,
      onSuccess: getData,
    };
    props.changeOrganizationAdministrativeState(data);
  };

  const launchOrg = () => {
    const data = {
      caption: `${Strings.launch} organization`,
      title: (
        <span>
          {Strings.launchOrgWarning}?
        </span>
      ),
      onConfirmAction: actions.launchOrganization(organizationId),
      confirmPostAction: getData,
      onCancelAction: null,
      hideCaution: true,
    };
    props.openConfirmModal(data);
  };

  const unsuspendOrg = () => {
    const data = {
      caption: `${Strings.unsuspend} organization`,
      title: (
        <span>
          {Strings.unsuspendOrgWarning}?
        </span>
      ),
      onConfirmAction: actions.unsuspendOrganization(organizationId, {}),
      confirmPostAction: getData,
      onCancelAction: null,
      hideCaution: true,
    };
    props.openConfirmModal(data);
  };

  return (
    <React.Fragment>
      <PageHeader
        right={(
          <React.Fragment>
            <Button onClick={launchOrg} class={HEADER_BUTTON_DARK_BLUE} title={Strings.launch} />
            <Button onClick={unsuspendOrg} class={HEADER_BUTTON_DARK_BLUE} title={Strings.unsuspend} />
            <Button onClick={onStatusChange} class={HEADER_BUTTON_DARK_BLUE} title={Strings.changeOrgState} />
          </React.Fragment>
        )}
      />
      <TableWithLocalPagination
        name="org-status-history"
        data={history}
        columns={[
          <Column
            key="change_date"
            title={Strings.date}
            value={e => {
              return e.change_time ? moment(e.change_time).format(DATE_MONTH_DAY_YEAR) : '-';
            }}
          />,
          <Column
            key="change_time"
            title={Strings.time}
            value={e => {
              return e.change_time ? moment(e.change_time).format(TIME_FORMAT_12_UPPERCASE) : '-';
            }}
          />,
          <Column
            key="old_administrative_state"
            title={`${Strings.old} ${Strings.administrativeState}`}
            value={e => e.old_administrative_state || '-'}
          />,
          <Column
            key="new_administrative_state"
            title={`${Strings.new} ${Strings.administrativeState}`}
            value={e => e.new_administrative_state || '-'}
          />,
          <Column
            key="old_business_state"
            title={`${Strings.old} ${Strings.businessState}`}
            value={e => e.old_business_state || '-'}
          />,
          <Column
            key="new_business_state"
            title={`${Strings.new} ${Strings.businessState}`}
            value={e => e.new_business_state || '-'}
          />,
          <Column
            key="old_operational_state"
            title={`${Strings.old} ${Strings.operationalState}`}
            value={e => e.old_operational_state || '-'}
          />,
          <Column
            key="new_operational_state"
            title={`${Strings.new} ${Strings.operationalState}`}
            value={e => e.new_operational_state || '-'}
          />,
          <Column key="change_note" title={Strings.note} value={e => e.change_note || '-'} />,
          <Column
            key="valid_until"
            title={Strings.validUntil}
            value={e => (e.valid_until ? moment(e.valid_until).format(DATE_MONTH_DAY_YEAR) : '-')}
          />,
        ]}
      />
    </React.Fragment>
  );
}

OrgStatusHistory.propTypes = {
  activeTab: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
  getOrgStatusHistory: id => dispatch(actions.getOrganizationStateHistory(id)),
  changeOrganizationAdministrativeState: data => dispatch(openModalAction('change-state', data)),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
});

export default connect(null, mapDispatchToProps)(OrgStatusHistory);
