import Strings from '../../Strings';
import { getBatteryStatus, signalRender } from '../../utils';
import { isSuperUser } from '../../utils/userRoles';

const statusFilterBasic = {
  group: 'Status', // interal name
  text: Strings.status, // text to display
  values: [
    {
      text: Strings.all,
      value: 'all',
    },
    {
      text: Strings.new, // text to display
      value: 'New', // internal value
    },
    {
      text: Strings.online,
      value: 'Online',
    },
    {
      text: Strings.offline,
      value: 'Offline',
    },
    {
      text: Strings.active,
      value: 'Active',
    },
  ],
};
const statusFilterExtended = {
  group: 'Status', // interal name
  text: Strings.status, // text to display
  values: [
    {
      text: Strings.all,
      value: 'all',
    },
    {
      text: Strings.new, // text to display
      value: 'New', // internal value
    },
    {
      text: Strings.online,
      value: 'Online',
    },
    {
      text: Strings.offline,
      value: 'Offline',
    },
    {
      text: Strings.active,
      value: 'Active',
    },
    {
      text: Strings.blocked,
      value: 'Blocked',
    },
    {
      text: Strings.suspended,
      value: 'Suspended',
    },
    {
      text: Strings.unassigned,
      value: 'Unassigned',
    },
    {
      text: Strings.manufactured,
      value: 'Manufactured',
    },
    {
      text: 'RMA',
      value: 'RMA',
    }
  ],
};

const getStatusFilter = () => {
  return isSuperUser() ? statusFilterExtended : statusFilterBasic;
};

export const getFiltersDefinition = () => {
  return {
    billingStatus: {
      group: 'billingStatus', // interal name
      text: Strings.status, // text to display
      values: [
        {
          text: Strings.all,
          value: 'all',
        },
        {
          text: Strings.billingStatus.submitted, // text to display
          value: 'submitted', // internal value
        },
        {
          text: Strings.billingStatus.onhold,
          value: 'onhold',
        },
        {
          text: Strings.billingStatus.pending,
          value: 'pending',
        },
      ],
    },

    Status: getStatusFilter(),

    ReadingStatus: {
      group: 'ReadingStatus',
      text: Strings.status,
      values: [
        {
          text: Strings.all,
          value: 'all',
        },
        {
          text: Strings.normal,
          value: 'Normal',
        },
        // {
        //   text: Strings.abnormal,
        //   value: 'Abnormal',
        // },
        {
          text: Strings.alert,
          value: 'Alert',
        },
      ],
    },
    Reminder: {
      group: 'Reminder',
      text: Strings.reminder,
      values: [
        {
          text: Strings.all,
          value: 'all',
        },
        {
          text: `${Strings.on}`,
          value: 'On',
        },
        {
          text: `${Strings.off}`,
          value: 'Off',
        },
      ],
    },
    Signal: {
      group: 'Signal',
      text: Strings.signal,
      itemRender: e => (e.value !== 'all' ? signalRender({ signal_overall: e.value }) : null),
      values: [
        {
          text: Strings.all,
          value: 'all',
        },
        {
          text: '',
          value: 'Excellent',
        },
        {
          text: '',
          value: 'Good',
        },
        {
          text: '',
          value: 'Medium',
        },
        {
          text: '',
          value: 'Weak',
        },
        {
          text: '',
          value: 'Not available',
        },
      ],
    },
    Faults: {
      group: 'Faults',
      text: Strings.faults,
      values: [
        {
          text: Strings.all,
          value: 'all',
        },
        {
          text: Strings.none,
          value: 'no faults',
        },
        {
          text: 'Connectivity',
          value: 'Connectivity',
        },
      ],
    },
    Battery: {
      group: 'Battery',
      text: Strings.battery.battery,
      itemRender: e => (e.value === 'all' ? null : e.value === 'Null' ? null : getBatteryStatus(e.value)),
      values: [
        {
          text: Strings.all,
          value: 'all',
        },
        {
          text: '',
          value: 'Good',
        },
        {
          text: '',
          value: 'Medium',
        },
        {
          text: '',
          value: 'Poor',
        },
        {
          text: '-',
          value: 'Null',
        },
      ],
    },
    ActionStatus: {
      group: 'ActionStatus',
      text: Strings.actionStatus,
      values: [
        {
          text: Strings.all,
          value: 'all',
        },
        {
          text: Strings.open,
          value: 'Open',
        },
        {
          text: Strings.completed,
          value: 'Completed',
        },
      ],
    },
    LogItemType: {
      group: 'LogItemType',
      text: Strings.logitemType,
      values: [
        {
          text: Strings.all,
          value: 'all',
        },
        {
          text: Strings.note,
          value: 'Note',
        },
        {
          text: Strings.action,
          value: 'Action',
        },
      ],
    },

    usage_state: {
      group: 'usage_state',
      text: Strings.usageState,
      propertyForApi: 'usage_state',
      values: [
        {
          text: Strings.all,
          value: 'all',
        },
        {
          text: 'Created',
          value: 'Created',
        },
        {
          text: 'Assigned',
          value: 'Assigned',
        },
        {
          text: 'Active',
          value: 'Active',
        },
        {
          text: 'Inactive',
          value: 'Inactive',
        },
        {
          text: 'Archived',
          value: 'Archived',
        },
      ],
    },
    administrative_state: {
      group: 'administrative_state',
      text: Strings.administrativeState,
      values: [
        {
          text: Strings.all,
          value: 'all',
        },
        {
          text: 'Normal',
          value: 'Normal',
        },
        {
          text: 'Blocked',
          value: 'Blocked',
        },
        {
          text: 'RMA',
          value: 'RMA',
        },
        {
          text: 'Suspended',
          value: 'Suspended',
        },
        {
          text: 'Deleted',
          value: 'Deleted',
        },
      ],
    },
    operational_state: {
      group: 'operational_state',
      text: Strings.operationalState,
      propertyForApi: 'operational_state',
      values: [
        {
          text: Strings.all,
          value: 'all',
        },
        {
          text: 'Functional',
          value: 'Functional',
        },
        {
          text: 'Degraded',
          value: 'Degraded',
        },
        {
          text: 'Disabled',
          value: 'Disabled',
        },
      ],
    },

    Gender: {
      group: 'Gender',
      text: Strings.gender,
      values: [
        {
          text: Strings.all,
          value: 'all',
        },
        {
          text: Strings.genders.male,
          value: 'male',
        },
        {
          text: Strings.genders.female,
          value: 'female',
        },
        {
          text: Strings.genders.other,
          value: 'other',
        },
      ],
    },

    DeviceType: {
      group: 'DeviceType',
      text: Strings.deviceType,
      values: [
        {
          text: Strings.all,
          value: 'all',
        },
        {
          text: Strings.deviceTypes.bpm,
          value: 'Blood Pressure Monitor',
        },
        {
          text: Strings.deviceTypes.scales,
          value: 'Weighing Machine',
        },
        {
          text: Strings.deviceTypes.glucometer,
          value: 'Glucometer',
        },
        {
          text: Strings.deviceTypes.bluetoothCap,
          value: 'Bluetooth cap',
        },
        {
          text: Strings.deviceTypes.wristBand,
          value: 'Wrist Band',
        },
        {
          text: Strings.deviceTypes.spirometer,
          value: 'Spirometer',
        },
        {
          text: Strings.deviceTypes.thermometers,
          value: 'Thermometer',
        },
        {
          text: Strings.deviceTypes.pulseOximeter,
          value: 'Pulse Oximeter',
        },
        {
          text: Strings.deviceTypes.lte,
          value: 'lte',
        },
        {
          text: Strings.deviceTypes.lsc,
          value: 'lsc',
        },
      ],
    },

    PrefabMessageType: {
      group: 'PrefabMessageType',
      text: Strings.type,
      values: [
        {
          text: Strings.all,
          value: 'all',
        },
        {
          text: Strings.messages.shared,
          value: 'shared_with_org',
        },
        {
          text: Strings.messages.private,
          value: 'private',
        },
      ],
    },

    DeviceStatus: {
      group: 'DeviceStatus',
      text: Strings.status,
      values: [
        {
          text: Strings.deleted,
          value: 'deleted',
        },
      ],
    },
    NoReadingsSince: {
      group: 'NoReadingsSince',
      text: Strings.noReadingsSince,
      type: 'number',
      unit: Strings.days,
      max: 366,
    },
    NewNotesSince: {
      group: 'NewNotesSince',
      text: Strings.newNotesSince,
      type: 'number',
      unit: Strings.days,
      max: 366,
    },

    org_business_state: {
      group: 'org_business_state',
      text: Strings.businessState,
      propertyForApi: 'business_state',
      values: [
        {
          text: Strings.all,
          value: 'all',
        },
        {
          text: 'Created',
          value: 'Created',
        },
        {
          text: 'Proof-of-Service',
          value: 'ProofOfService',
        },
        {
          text: 'Launched',
          value: 'Launched',
        },
        {
          text: 'Disengaged',
          value: 'Disengaged',
        },
      ],
    },
    org_administrative_state: {
      group: 'org_administrative_state',
      text: Strings.administrativeState,
      propertyForApi: 'administrative_state',
      values: [
        {
          text: Strings.all,
          value: 'all',
        },
        {
          text: 'New',
          value: 'New',
        },
        {
          text: 'Trial',
          value: 'Trial',
        },
        {
          text: 'Customer',
          value: 'Customer',
        },
        {
          text: 'Demo',
          value: 'Demo',
        },
        {
          text: 'Engineering/Test',
          value: 'EngineeringTest',
        },
        {
          text: 'Suspended',
          value: 'Suspended',
        },
        {
          text: 'Deleted',
          value: 'Deleted',
        },
      ],
    },
    org_operational_state: {
      group: 'org_operational_state',
      text: Strings.operationalState,
      propertyForApi: 'operational_state',
      values: [
        {
          text: Strings.all,
          value: 'all',
        },
        {
          text: 'Dormant',
          value: 'Dormant',
        },
        {
          text: 'Active',
          value: 'Active',
        },
        {
          text: 'Kit Activity',
          value: 'KitsOnlyActive',
        },
        {
          text: 'In-Use',
          value: 'In-Use',
        },
        {
          text: 'Revenue Active',
          value: 'RevenueActive',
        },
        {
          text: 'Inactive',
          value: 'Inactive',
        },
      ],
    },
  };
};
