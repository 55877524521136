import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import _ from 'lodash';

import { graphContainer } from './graphContainers';
import { actions } from '../../SuperUser/Patients/redux/actions';
import { ColorPalette } from '../DashboardColorPalettes';
import Strings from '../../../Strings';
import GenericCategoryGraph from '../../../components/Graphs/GenericCategoryGraph';

const getAgeRangeForPatient = dob => {
  const age = moment().diff(moment(dob), 'years');
  const floor = Math.floor(age / 10) * 10;
  return floor > 100 ? '100+' : `${floor}-${floor + 9}`;
};

function PatientsAgeGender(props) {
  const [data, setData] = useState();
  const { colorPalette, graphType } = props;

  React.useEffect(() => {
    let request = {
      offset: 0,
      search: '',
    };
    props.getPatients(request).then(resp => {
      setData(resp.response?.data);
    });
  }, []);

  const patientsGroupedByAge = ['0-9', '10-19', '20-29', '30-39', '40-49', '50-59', '60-69', '70-79', '80-89', '90-99', '100+'].map(ageRange => ({
    age: ageRange,
    patients: data?.filter(p => getAgeRangeForPatient(p.dateOfBirth) === ageRange),
  }));

  const dataSeries = 
    Object.values(Strings.genders).map((g, i) => ({
      name: g,
      color: colorPalette?.interpolate((i+1) / (Object.values(Strings.genders).length + 1)),
      selector: item => item[g],
    }));

  return graphContainer(
    <GenericCategoryGraph
      graphData={
        data
          ? patientsGroupedByAge.map(group => ({
              age: group.age,
              Female: group.patients?.filter(p => p.gender === "Female")?.length || 0,
              Male: group.patients?.filter(p => p.gender === "Male")?.length || 0,
              Other: group.patients?.filter(p => p.gender === "Other")?.length || 0,
            }))

          : []
      }
        hasBorder={true}
        forceCondensed
        xAxisTopPosition={false}
        yAxisWidth={40}
        yAxisUnit=""
        tooltipTitle="Patients age and gender"
        dataSeries={dataSeries}
        categorySelector={item => item.age}
        hasReferenceAreas={true}
        graphType={graphType}
      />,
    'Patients Age and Gender',
  );
}

PatientsAgeGender.widgetId = 'PatientsAgeGenderWidget';

PatientsAgeGender.propTypes = {
  getPatientsAgeGender: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    colorPalette:
      ColorPalette[state.dashboardState.configuration?.allDashboards?.colorPalette] || Object.values(ColorPalette)[0],
  };
};

const mapDispatchToProps = dispatch => ({
  getPatients: request => dispatch(actions.getPatientsForSuggestion(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientsAgeGender);
