import React from 'react';

const english = {
  yes: 'Yes',
  no: 'No',
  none: 'None',
  all: 'All',
  writePrivileges: 'Write Privileges',
  orderList: 'Order List',
  appEmail: 'support@rxcap.com',
  downloadData: 'Download Data',
  downloadAllData: 'Download All Data',
  downloadResultsData: 'Download Results Data',
  downloadHistory: 'Download History',
  downloadAllMeds: 'Download Med List',
  downloadEvents: 'Download Events',
  downloadSummary: 'Download Summary',
  addPatient: 'Add Patient',
  removePatient: 'Remove Patient',
  resetPatient: 'Reset Patient',
  bulkAddPatients: 'Bulk Add',
  addEnrollAssignMed: 'Add & Enroll Patient, Assign Medication',
  chooseFileToUpload: 'Choose File to Upload',
  noFileSelected: 'No file selected',
  patientsFoundInFile: 'Patients found in the file',
  addNickName: 'Edit Name',
  sessionTimeout: 'Extend Session',
  nickName: 'Cap name',
  editName: 'Edit Name',
  editCapName: 'Edit Cap Name',
  addPatients: 'Add Patients',
  addRemovePatients: 'Add / Remove Patients',
  fullName: 'Full Name',
  date: 'Date',
  time: 'Time',
  editInfo: 'Edit Info',
  reloadGraph: 'Reload graph',
  showSelectedItems: 'Show selected fields',
  showSelectedWidgets: 'Show selected widgets',
  resetToDefaults: 'Reset to Defaults',
  deleteWarning: 'Are you sure you want to delete {0}?',
  deletePatientWarning: 'Are you sure you want to delete patient',
  updateStatus: 'Are you sure you want to update status to',
  deleteCapManagerWarning: 'Are you sure you want to delete cap manager',
  deleteDoctorWarning: 'Are you sure you want to delete doctor',
  deleteOrderWarning: 'Are you sure you want to delete order',
  deleteOrgWarning: 'Are you sure you want to delete organization',
  launchOrgWarning: 'Are you sure you want to launch organization',
  deleteScheduleWarning: 'Are you sure you want to delete schedule',
  deleteTaksHistoryWarning: 'Are you sure you want to delete task history',
  deleteMedicationWarning: 'Are you sure you want to delete medicine',
  deleteScheduleCapWarning: 'Are you sure you want to delete cap',
  deleteOrgManagerWarning: 'Are you sure you want to delete organization manager',
  deleteOrgAdminWarning: 'Are you sure you want to delete organization admin',
  deleteOrgCapsManagerWarning: 'Are you sure you want to delete organization caps manager',
  deleteOrgOutboundAPIWarning: 'Are you sure you want to delete organization outbound API',
  deleteCohortWarning: 'Are you sure you want to delete cohort',
  unassignPatientWarning: 'Are you sure you want to unassign patient from cohort',
  deleteCapWarning: 'Are you sure you want to remove device',
  capSendSecurityCode: 'Send Device Security Code',
  capSendSecurityCodeSent: ' Device Security Code sent',
  deleteHubDeviceWarning: 'Are you sure you want to delete device',
  assignCapToScheduleCaption: 'Cap {0} will be assigned to schedule {1}',
  unassignCapToScheduleCaption: 'Cap {0} will be unassigned from schedule {1}',
  deletePrefabReply: 'Are you sure you want to delete prefab response',
  cancelEnrollmentWarning: 'Are you sure you want to cancel enrollment?',
  invitePatientWarning: 'Are you sure you want to invite patient {0} to portal?',
  resetBleWarning: 'Are you sure you want to reset BLE chip for selected hub(s)?',
  startEnrollmentWarning: 'Are you sure you want to activate patient {0}?',
  startEnrollmentMedsWarning:
    'The following schedules are linked to the program and have start date in the future. Please select the schedules you want to start now.',
  resetPatientStatusWarning:
    'Are you sure you want to reset patient {0} status? Patient will be moved to Onboarded/Registered state',
  resetPatientStatusSchedulesWarning:
    'The following schedules have cap assigned. Please select the schedules from which you want to unassign cap.',
  resetPatientStatusActiveAlertsWarning:
    'Some schedules have active alerts. You have to clear them to be able to unassign cap from them and to remove kit from patient. To clear alerts go to',
  patientDashboardTxt: 'Patient Dashboard',
  resetPatientStatusKitWarning:
    'Do you want to remove the kit from the patient? We recommend removing the kit only if the patient will be getting new one.',
  cancelEnrollmentWarning: 'Are you sure you want to cancel enrollment for patient {0}?',
  remindersChangeRequiredCaption: 'Reminders change required',
  remindersChangeRequired:
    'Patient has reminders defined. They will NOT be automatically translated to the newly set language. To change the reminders, please navigate to Medications page and edit each medication that has remiders set to change them. Would you like to be navigated to Medications page?',
  previous: 'Previous',
  nextWeek: 'Next week',
  downloadPatientData: 'Download Patient Data',
  downloadPatientsEvents: 'Download Patients Events',
  caregiver: 'Caregiver',
  caregivers: 'Caregivers',
  addCaregiver: 'Add Caregiver',
  addACaregiver: 'Add a Caregiver',
  editCaregiverList: 'Edit Caregiver List',
  numberOfAdministrators: 'Number of administrators',
  addAdmin: 'Invite Admin',
  rxcapCopyRightText: 'RXCap 2020 - All copyrights reserved',
  showingDataFrom: 'Showing data from ',
  trend: 'Trend',
  missed: 'Missed',
  miss: 'Miss',
  complied: 'Complied',
  skipped: 'Skipped',
  noData: 'No Data',
  last30days: 'Last 30 Days',
  last7days: 'Last 7 Days',
  thisMonth: 'This Month',
  lastMonth: 'Last Month',
  last6Months: 'Last 6 Months',
  thisYear: 'This Year',
  last12Months: 'Last 12 Months',
  selectedDate: 'Selected Date',
  last7DaysAdherence: 'Adherence Last 7 Days',
  noDescription: 'No description',
  noDataAvailable: 'No data available',
  totalAdherence: 'Total Adherence',
  weeklyTotalAdherence: 'Total | Weekly Adherence',
  totalAccuracy: 'Total Accuracy',
  accuracy: 'Accuracy',
  appOnly: 'App Only',
  smartCap: 'SmartCap',
  pillbox: 'Pillbox',
  smartCapUsage: 'Smart Cap Usage',
  accepted: 'Accepted',
  accept: 'Accept',
  pending: 'Pending',
  createOrder: 'Create Order',
  acceptedPendingInvites: 'Accepted / Pending Invites',
  medication: 'Medication',
  medication: 'Medication',
  noMedicationsTaken: 'No medications taken',
  averageTimedelta: 'Average timedelta',
  taken: 'Taken',
  takes: 'Takes',
  nextMed: 'Next Med',
  unscheduled: 'unscheduled',
  takeTypes: {
    scheduled: 'Scheduled take',
    unscheduled: 'Unscheduled take',
    missed: 'Missed take',
  },
  delta: 'delta',
  na: 'N/A',
  name: 'Name',
  verificationCode: 'Enter Verification Code',
  send: 'Send',
  sendVerificationCode: 'Send Verification Code',
  reSendVerificationCode: 'Resend Verification Code',
  sendVerificationLink: 'Send Verification Link',
  reSendVerificationLink: 'Resend Verification Link',
  total: 'Total',
  verified: 'Verified',
  invalidType: 'Invalid Type',
  dashboard: 'Dashboard',
  loading: 'Loading',
  notificationSettings: 'Notification Settings',
  mobilePhone: 'Mobile Phone',
  phoneNo: 'Phone No.',
  contactNo: 'Contact No.',
  hardwareId: 'Hardware ID',
  notVerified: 'Not verified',
  emailAddress: 'Email Address',
  inProcess: 'In process...',
  saveChanges: 'Save Changes',
  save: 'Save',
  update: 'Update',
  firstName: 'First Name',
  yourName: 'Your Name',
  lastName: 'Last Name',
  userName: 'Username',
  patient: 'Patient',
  selectGender: 'Please select gender',
  gender: 'Gender',
  genders: {
    male: 'Male',
    female: 'Female',
    other: 'Other',
  },
  userNameEmail: 'Username or Email',
  usingSmartCap: 'Using Smart Cap',
  studies: 'Studies',
  patients: 'Patients',
  myPatients: 'My Patients',
  enrollAndMonitor: 'Enroll & Monitor',
  alerts: 'Alerts',
  admin: 'Admins',
  caps: 'Caps',
  signOut: 'Sign Out',
  today: 'Today',
  daily: 'Daily',
  monthly: 'Monthly',
  medications: 'Medications',
  Info: 'Info',
  timeDelta: 'Time Delta',
  personalSettings: 'Personal Settings',
  settings: 'Settings',
  support: 'Support',
  systemConfiguration: 'System Configuration',
  supportHeader: 'How can we help? (Technical Support Only)',
  howToUseYourSmartCap: 'How to use your smart cap',
  chatWithUs: 'Chat with us',
  callUs: 'Call us',
  chatText: `In a hurry and would prefer to speak with someone directly? Then, we're here to help, just give us a call on the number below and we'll see what we can do.`,
  helpCenter: 'Help center',
  helpCenterText:
    'Before contacting customer support have a look around our help center to see if the answer you are looking for is there.',
  smartCapTitle: 'How to use your smart cap',
  emailUs: 'Email',
  emailText: `If you have a query or specific question, we'd be happy to hear from you. Our customer support team are here to help and can be reached at:`,
  supportText:
    "If you have a query or specific question, we'd be happy to hear from you. Our customer support team are here to help and can be reached at ",
  supportChatWithUsText:
    'Have your queries answered without leaving the site, drop us a message on our customer support chat using the chat icon in the bottom right hand cormer of the screen, and someone will get back to you as soon as possible.',
  supportHelpCenterText:
    'Before contacting customer support have a look around our help center to see if the answer you are looking for is there.',
  supportEmail: 'contact@rxcap.com',
  supportLidSyncEmail: 'support@lidsync.com',
  supportButton: 'Email Us',
  technicalSupport: 'Technical Support Only',
  supportTechnicalModal:
    'Please note our support page is only for technical support for the hardware or software. Do not share any medical information with customer support representatives.',
  callUsButton: 'Or call us on:',
  phone: '+1(818) 618 6375',
  phoneLidSync: '+1(888)-618-0463',
  emailSubject: 'RxCap Web Portal assistance',
  emailLidSyncSubject: 'Lid Sync Web Portal assistance',
  calendar: 'Calendar',
  findOutMore: 'Find Out More',
  cancel: 'Cancel',
  done: 'Done',
  confirm: 'Confirm',
  careGiverInvalidLink: 'Link is invalid. please generate a new link.',
  takeSimulation: 'Take simulation',
  careGiverHeading: 'Become an RxCap Caregiver',
  careGiverSubHeading:
    'By entering your details you are accepting to receive txt message notifications from RxCap for the purposes of the CareGiver feature only…',
  invite: 'Invite',
  inviteToPortal: 'Invite to Potrtal',
  reInviteToPortal: 'Resend Potrtal Invitation',
  invitationSuccess: 'Invitation sent successfully',
  upload: 'Upload',
  uploading: 'Uploading',
  uploadSignedForm: 'Upload Signed Form',
  submit: 'Submit',
  close: 'Close',
  skipAndClose: 'Skip & Close',
  studyName: 'Study Name',
  daysOfWeek: 'Days of Week',
  description: 'Description',
  defaultEnabled: 'Default enabled',
  enabled: 'Enabled',
  disabled: 'Disabled',
  blacklisted: 'Blacklisted',
  masterConfiguration: 'Master Configuration',
  organizationConfiguration: 'Organization Configuration',
  backToHours: 'Back to Hours',
  add: 'Add',
  addPills: 'Add (Pills)',
  totalPills: 'Total (Pills)',
  back: 'Back',
  status: 'Status',
  oldStatus: 'Old Status',
  newStatus: 'New Status',
  usageState: 'Usage State',
  administrativeState: 'Administrative State',
  operationalState: 'Operational State',
  businessState: 'Business State',
  old: 'Old',
  changedStatus: 'Changed Status',
  patientUserName: 'Patient Username',
  alertBySMS: 'Alert by SMS',
  alertByEmail: 'Alert by Email',
  alertByPhone: 'Alert by Phone',
  showAll: 'Show All',
  login: 'Login',
  loginTitle: 'Login to RxCap',
  loginTo: 'Login to',
  securityCode: 'Security Code',
  securityCodeIsRequired: 'Security Code is required, check your email or sms message',
  show: 'Show',
  reset: 'Reset',
  inviteFirstAdmin: 'Invite your first administrator',
  inviteFirstPatient: 'Invite your first patient',
  inviteAnAdmin: 'Invite an Admin',
  sendInvite: 'Send Invite',
  inviteAdmin: 'Invite Admin',
  personalDetails: 'Personal Details',
  userDetails: 'User Details',
  phoneNumber: 'Phone Number',
  phone: 'Phone',
  company: 'Company',
  email: 'Email',
  dob: 'D.O.B.',
  monthYear: 'Month-Year',
  age: 'Age',
  startDate: 'Start Date',
  endDate: 'End Date',
  endsAt: 'Ends at',
  regimenDetails: 'Regimen Details',
  regimenStartDate: 'Regimen Start Date',
  regimenEndDate: 'Regimen End Date',
  regimenLength: 'Regimen Length',
  activeFor: 'Active for',
  usesSmartCap: 'Uses Smart Cap',
  notAvailable: 'not available',
  adminAppPrevileges: 'Admin App Privileges',
  adminWritePrevileges: 'Write Privileges',
  adminTimerRights: 'Triggers Timer',
  adminAccess: 'Admin access',
  manageMultipleStudiesText: 'You can create and manage multiple studies at the same time.',
  invitePatientMessage: 'You can invite patients to use RXCap to track their medication adherence.',
  medicationAdherenceMessage: 'Medication adherence data are automatically added to your Provider Portal.',
  showingDataFromAllPatients: 'Showing data from all of your patients and studies',
  downloadApp: 'Download the App',
  createYourFirstStudy_p1: 'You can ',
  createYourFirstStudy_p2:
    ', view or edit any of your studies from the studies tab. With quick access to patient counts adherence and trends. Or delve deeper to view patient data and medication time stamps.',
  addAPatient_p1: 'You can ',
  addAPatient_p2:
    ', view and manage patients from the patients tab. Once you have created a ‘study’ you will be able to assign patients directly to a study when you are adding them to the portal.',
  addAPatient_2_p1: 'You have added a study, but have not assigned any ',
  addAPatient_2_p2: '. You can ',
  addAPatient_2_p3:
    ' and manage all your patients via the patients tab. This dashboard will be updated with analytics and graphs once sufficient data has been gathered from assigned patients.',
  inviteAnAdmin_p1: 'In the admin tab you can ',
  inviteAnAdmin_p2: ' and manage administration privileges, and access rights for doctors and researchers.',

  dashboardNoDataYet: 'Dashboard features will unlock after more data becomes available',

  medicationsMap: 'Medications Map',
  signIn: 'Sign In',
  signUp: 'Sign Up',
  patientAddedSuccessfully: 'Patient added successfully',
  patientSavedSuccessfully: 'Patient saved successfully',
  welcomeToRxCap: 'Welcome to RxCap',
  welcomeToApp: 'Welcome to RXCap Provider Portal',
  welcome: 'Welcome',
  loadingFhirSessions: 'Loading FHIR session...',
  password: 'Password',
  forgotPassword: 'Forgot password?',
  createAccount: 'Create Account',
  createRxCapAccount: 'Create RXCap Account',
  create: 'Create',
  addAPatient: 'Add a Patient',
  appTitle: 'The RXCap Platform',
  loginToRxCap: 'Login to RX-Cap',
  loginToLidSync: 'Login to Lid Sync',
  lidSync: 'Lid Sync',
  setPasswordToRxCap: 'Set password',
  invitePatient: 'Invite Patient',
  addMedicine: 'Add Medicine',
  editMedicine: 'Edit Medicine',
  summary: 'Summary',
  youAreAllDone: "You're All Done",
  youAreAllDoneName: 'Add next patient(s)/medication',
  youFinishedSetup: "You've finished the set up for {0}",
  finishedRpmSetup: "You've finished the set up for your organization",
  mobileVersionNotSupported: 'Mobile version not supported',
  editProfile: 'Edit Profile',
  editPatientInfo: 'Edit Patient Info',
  changePassword: 'Change Password',
  adherence: 'Adherence',
  adherenceForPeriod: 'Adherence for period',
  study: 'Study',
  search: 'Search',
  searchLog: 'Search Log',
  leave: 'Leave',
  patientCount: 'Patient Count',
  createStudy: 'Create a Study',
  editStudy: 'Edit a Study',
  country: 'Country',
  introduction: 'Introduction',
  editPersonalSettings: 'Edit Your personal settings',
  dateOfBirth: 'Date of birth',
  city: 'City',
  mobileNumber: 'Mobile Number',
  confirmPassword: 'Confirm Password',
  passwordChangeFailedCheckYourSecurityCode: 'Password change failed, check your security code.',
  confirmDelete: 'Confirm Delete',
  confirmAssignment: 'Confirm Assignment',
  newPassword: 'New Password',
  oldPassword: 'Old Password',
  showPassword: 'Show Password',
  hidePassword: 'Hide Password',
  resetPassword: 'Reset Password',
  byEmail: 'By Email',
  bySMS: 'By SMS',
  next: 'Next',
  later: 'Later',
  watchNow: 'Watch Now',
  allDay: 'All Day',
  yourAllDone: 'Your All Done!',
  videoInst:
    'Great, your device is all set up and ready to use. Before you start using it, we recomend you view a quick tutorial on how to use it.',
  esiDoctorInviteText:
    'You have been invited to be an admin on RxCap. To complete the verification process, please enter your mobile number, then enter the verification code.',
  esiDoctorCodeText:
    'We have sent you a 6-Digit verification code. Enter it below to verify your account. This code will expire in: ',
  esiDoctorAlmostDone:
    'Almost done, please use the credentials that have been sent to you via email to login to your account.',
  resetByEmail: 'Reset By Email',
  resetBySMS: 'Reset By SMS',
  resend: 'Resend',
  withYourAccount: 'with your account',
  weSentYouEmail: 'We have sent you Email',
  ok: 'Ok',
  enterNewPasswordMessage: 'Enter the new password you wish to use for',
  weSentVerificationCode: 'We have sent a verification code',
  yourRxCapAccountBelow: 'your RXCap account below',
  congratulations: 'Congratulations',
  enterBelowToContinue: 'Enter it below to continue',
  thisOptionIsrequired: 'This option is required',
  notFound: 'Not found',
  hi: 'Hi',
  lblResetPassword: 'Enter the email address associated with',
  lblResetPasswordRx: 'your RX-CAP account below:',
  enterEmailAddressAssociated: 'Enter the email address associated',
  enterPhoneNumberAssociated: 'Enter the mobile number associated with your account.',
  enterPhoneNumber2nd: ' In case of problems, contacts:',
  resetPasswordMessage: ' If you forgot your password, please choose one of the reset methods below:',
  appSubtitle:
    'Keep track of your medication usage, or if you are a doctor gain valuable, \n  real time insights on your patient medicine adherence \n through our online, mobile app and SmartCap solution.',
  requestDeleteText: 'You have requested to delete your RXCap account. ',
  requestConfirmText: () =>
    'Please confirm that you understand that this operation is <span class="color-brand-red"><strong>irreversible</strong></span> ',
  requestToContinue: 'and press the button to continue.',
  iAmAwareText: 'I am aware that continuing will ',
  irreversiblyText: 'irreversibly delete all data',
  aboutMeText: 'about me currently stored by RXCap.',
  deleteBtn: 'Delete Account',
  verificationCompleteText: 'Verification Complete',
  reviewThePatientText:
    'Review the patients profile information, then press the next button to review their current medications.',
  nextBtnText: 'Next',
  weAreSorryText:
    'We&apos;re sorry, but your FHIR endpoint doesn&apos;t seem to support the MedicationOrder endpoint. Please contact your EHR administrator for more information.',
  reviewYourPatient:
    'Review your patients medication, then select the ones you want to have linked to their RXCap account.',
  sendInviteText: 'Send Invite',
  patientNameText: 'Patient Name',
  inviteBySMSText: 'Invite by SMS',
  toFinishTheSetupText: 'To finish the set up process, add the patients phone number and click',
  invitationSentText: 'Invitation sent',
  adminInviteSent: 'Admin invite sent',
  youHaveBeenInvitedText: 'You have been invited to use RXCap by your Physician to keep',
  trackOfYourMedicineText: ' track of your medicine adherence. Input the required information by',
  toVerifyYourAccountText: ' to verify your account.',
  verifyAccountText: 'Verify your Account',
  yourDSAccountActivatedText:
    'Your RXCap account is now active, and you can now log into it on the RXCap mobile app. Check your mail box on your phone for the Download link to get the app on iPhone or Android',
  yourDSAccActivatedText: 'Your RXCap account is now active.',
  checkYourInbox: 'Check your inbox, or click the relevant link below to download the app.',
  loadingFHIRSession: 'Loading FHIR session...',
  enterPhoneNumberThatCanBeUsedToVerifyYourIdentity:
    'Enter a phone number that can be used to verify your identity with a text message.',
  noSMSReminderText: 'No SMS reminders?',
  weHaveSentCodeText: 'We have sent a temporary verification code to:',
  enterTheCodeText: '. Enter the code below to verify the number.',
  didnText: 'Didn',
  tReceiveACode: 't receive a code?',
  digitVerificationCode: '6 Digit Verification code',
  yourText: 'Your ',
  verifiedText: ' has been successfully verified.',
  editNotifcationSettingsText: 'Edit Notification Settings',
  chagneSettingForText: 'Change Settings for',
  dsUsesSmartText:
    'RXCap uses smart escalating notifications to send you medication reminders, via either email, SMS text messages, or by phone calls. We may also send information about important updates.',
  selectWhichNotificationText: 'Select which notifications you would like to receive from RXCap',
  emailsText: 'Emails',
  smsText: 'SMS',
  voiceText: 'Voice',
  takenAt: 'Taken At',
  dailyDataText: 'Daily data begins at 6 am',
  clickToTakeText: 'Click to Take Now!',
  dateText: 'Date',
  timeDueText: 'Time Due',
  medicationNameText: 'Medication Name',
  frequencyText: 'Frequency',
  areYouSure: 'Are You Sure?',
  areYouSureText: 'Are you sure you want to delete ',
  addMedicationText: 'Add Medication',
  dosageText: 'Dosage - ',
  daysText: 'Days - ',
  timesText: 'Times -',
  dosage: 'Dosage',
  times: 'Times',
  delete: 'Delete',
  edit: 'Edit',
  launch: 'Launch',
  createYourFirstStudy: 'Create Your First Study',
  whenToTakeMedicine: 'When to take Medicine?',
  frequency: 'Frequency',
  asNeeded: 'As Needed',
  everyDay: 'Every Day',
  specificDays: 'Specific Days',
  interval: 'Interval',
  howLongToTakeMedicine: 'How long to take Medicine?',
  days: 'Days',
  weeks: 'Weeks',
  daysOfweek: 'Days of Week',
  afterHowManyDays: 'After how many days?',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',
  timeForTakingMedicine: 'Time for taking Medicine',
  administrationWindow: 'Administration Window',
  medicineName: 'Medicine Name',
  dose: 'Dose',
  attach: 'Attach',
  review: 'Review',
  capName: 'Cap Name',
  capId: 'Cap ID',
  hubId: 'Hub ID',
  devicesPlural: {
    hub: 'Hubs',
    ble: 'BLE Caps',
    lte: 'LTE Caps',
    lsc: 'LSC Caps',
  },
  devicesEnum: {
    Kit: 'Kit',
    Datahub: 'Datahub',
    'Bluetooth Cap': 'Bluetooth Cap',
    'LTE Pillbox': 'LTE Pillbox',
    'LTE Smart Cap': 'LTE Smart Cap',
    'Injectable Medication Tracker': 'Injectable Medication Tracker',
    'MDP Dispenser': 'MDP Dispenser',
    'Blood Pressure Monitor': 'Blood Pressure Monitor',
    'Weighing Machine': 'Weighing Machine',
    'Wrist Band': 'Wrist Band',
    Glucometer: 'Glucometer',
    Thermometer: 'Thermometer',
    Inhaler: 'Inhaler',
    Spirometer: 'Spirometer',
    'Pulse Oximeter': 'Pulse Oximeter',
    'Air Quality Monitor': 'Air Quality Monitor',
    'Hailie Inhaler': 'Hailie Inhaler',
    'PT/INR Monitor': 'PT/INR Monitor',
  },
  devicePluralsEnum: {
    Kit: 'Kits',
    Datahub: 'Datahubs',
    'Bluetooth Cap': 'Bluetooth Caps',
    'LTE Pillbox': 'LTE Pillboxes',
    'LTE Smart Cap': 'LTE Smart Caps',
    'Injectable Medication Tracker': 'Injectable Medication Trackers',
    'MDP Dispenser': 'MDP Dispensers',
    'Blood Pressure Monitor': 'Blood Pressure Monitors',
    'Weighing Machine': 'Weighing Machines',
    'Wrist Band': 'Wrist Bands',
    Glucometer: 'Glucometers',
    Thermometer: 'Thermometers',
    Inhaler: 'Inhalers',
    Spirometer: 'Spirometers',
    'Pulse Oximeter': 'Pulse Oximeters',
    'Air Quality Monitor': 'Air Quality Monitors',
    'Hailie Inhaler': 'Hailie Inhalers',
    'PT/INR Monitor': 'PT/INR Monitors',
  },
  id: 'ID',
  hubName: 'Hub Name',
  or: 'Or',
  capsAdmin: 'Caps Manager',
  searchNewCap: 'Search New Cap',
  addCapsAdmin: 'Add Cap Manager',
  addDoctor: 'Add Doctor',
  doctors: 'Doctors',
  attachCapsToAdmin: 'Attach Caps to Manager',
  attachCap: 'Attach Device',
  attachDevice: 'Attach Device',
  devicesToAttach: 'Devices to attach',
  SendDeviceSecurityCode: 'Send Device Security Code',
  AddEditHardware: 'Enter Device ID',
  enter6DigitCode: 'Enter 6 character code or hardware ID printed on the cap',
  eventTimestamp: 'Event Date',
  lastEventTimestamp: 'Last Event Date',
  lastEventTime: 'Last Event Time',
  eventDate: 'Event Date',
  eventType: 'Event Type',
  physioParams: 'Physio Params',
  max: 'Max',
  min: 'Min',
  condition: 'Condition',
  conditions: 'Conditions',
  attachCapToAdmin: 'Attach Cap to Admin',
  locations: 'Location',
  location: 'Location',
  state: 'State',
  organizationName: 'Organization Name',
  devices: 'Devices',
  deletedDevices: 'Deleted Devices',
  temperature: 'Temperature',
  charge: 'Charge',
  action: 'Action',
  eventList: 'Event list',
  version: 'Version ',
  timezone: 'Time zone',
  selectStartAdministrativeWindow: 'Please select an start administrative window',
  selectEndAdministrativeWindow: 'Please select an end administrative window',
  idleSessionMessage: 'Your session is about to expire. Please press the "Extend" button, to prevent the logout.',
  extend: 'Extend',
  recoverPassword: 'Recover Password',
  showingXOf: 'Showing {0} of {1} {2}',
  showingX: 'Showing {0} {1}',
  showingXHubs: {
    showing: 'Showing',
    of: 'of',
    hubs: 'Hubs',
  },
  showingXKits: 'Showing {0} of {1} Kits',
  showingXDevices: 'Showing {0} of {1} Devices',
  showingXPatients: 'Showing {0} of {1} Patients',
  showingXCohorts: 'Showing {0} of {1} Cohorts',
  devicesInThisArea: 'Devices in this area',
  activeHubs: 'Active Hubs',
  pendingHubs: 'Pending Hubs',
  hubs: 'Hubs',
  scales: 'Scales',
  bpMonitors: 'BP Monitors',
  bpm: 'BPM',
  bpm_unit: 'bpm',
  inLast: 'In last',
  noReadings: 'No Readings',
  showingWait: 'Showing...',
  deviceReadings: 'Device Readings',
  yourKits: 'Your Kits',
  kits: 'Kits',
  pendingCalls: 'Pending Calls',
  pendingMessages: 'Pending Messages',
  enrolledPatients: 'Enrolled Patients',
  pendingEnrollment: 'Pending Enrollment',
  totalKits: 'Total Kits',
  widgets: {
    yourKitsChart: 'Your Kits Chart',
    deviceReadingsChart: 'Device Readings Chart',
    numberOfDevices: 'Number of Devices',
    numberOfHubsRealTime: 'Number of Hubs (real-time)',
    numberOfHubsDaily: 'Number of Hubs (daily)',
    numberOfHubsMonthly: 'Number of Hubs (monthly)',
    locationsOfYourKits: 'Locations of Your Kits',
    hubsWithoutReadings: 'Hubs without Readings',
    hubsActivedLastMonth: 'Hubs Activated Last Month',
    monthlyActiveHubs: 'Monthly Active Hubs',
    monthlyOnlineHubs: 'Monthly Online Hubs',
    dailyOnlineHubs: 'Daily Online Hubs',
    dailyActiveHubs: 'Daily Active Hubs',
    monthlyOperatingRate: 'Monthly Operating Rate',
    hubsActivated24h: 'Hubs activated in last 24h',
    totalHubs: 'Total Hubs',
    patientActions: 'Patient Actions',
    unreadMessages: 'Unread Messages',
    patientsEnrollment: 'Patients Enrollment',
    totalKits: 'Total Kits',
    patientsWithNoReadings: 'Activated Patients with no readings',
    patientsWithNewNotes: 'Patients with new notes',
    medicalHistory: 'Medical History',
    patientDetails: 'Patient Details',
    insurance: 'Insurance',
    centerDetails: 'Center Details',
    transplantDetails: 'Transplant Details',
    patientsToCall: 'Patients to Call',
    details: 'Details',
    people: 'People',
    hubsActivatedToday: 'Hubs Activated Today',
    daysComplied: 'Days Complied',
    devicesActivatedToday: '{0} Activated Today',
    numberOfBleRealTime: 'Number of BLE caps (real-time)',
    numberOfLteRealTime: 'Number of LTE caps (real-time)',
    numberOfLscRealTime: 'Number of LSC caps (real-time)',
    patientStatus: 'Patient Status',
    programDistribution: 'Program Distribution',
  },
  widgetsTooltips: {
    active_number_real_time: 'Number of {0} which sent at least one measurement in the last 24 hours',
    new_number_real_time: 'Number of {0} that have never sent a reading since they were assigned to the organization',
    offline_number_real_time: "Number of {0} which didn't connect to the network in the last 7 days",
    online_number_real_time: 'Number of {0} connected to the network in the last 7 days',
    total_number_real_time: 'Number of all {0} assigned to the organization',
    activated_number_real_time: 'Number of {0} activated today',
    active_number_daily: 'Number of {0} which sent at least one measurement yesterday',
    new_number_daily:
      'Number of {0} that have never sent a reading since they were assigned to the organization as of yesterday',
    offline_number_daily: "Number of {0} which didn't connect to the network yesterday",
    online_number_daily: 'Number of {0} connected to the network yesterday',
    total_number_daily: 'Number of all {0} assigned to the organization as of yesterday',
    activated_number_daily: 'Number of {0} activated yesterday',
    active_number_monthly: 'Number of {0} which sent at least one measurement in the previous month',
    new_number_monthly:
      'Number of {0} that have never sent a reading since they were assigned to the organization as of previous month',
    offline_number_monthly: "Number of {0} which didn't connect to the network in the previous month",
    online_number_monthly: 'Number of {0} connected to the network in the previous month',
    total_number_monthly: 'Number of all {0} assigned to the organization as of previous month',
    activated_number_monthly: 'Number of {0} activated in the previous month',
    monthlyOperatingRate:
      'Average of connected rate (Days having a connection at least once / eligible days in the previous month) of all activated hubs',
    activated_number_24h: 'Number of {0} activated in the last 24 hours',
    numberOfDaysComplied: 'Number of days complied since {0} (for code {1})',
    inTheLastXDays: 'In the last {0} days',
  },
  errors: {
    requiredStudyNameAndDescription: 'Your study needs a name and description',
    longStudyName:
      'Long study names may not be fully displayed, depending on the screen resolution at which the page is viewed.',
    invalidCapId: 'Invalid Cap id',
    invalidDeviceId: 'Invalid Device id',
    enterAnyOne: 'Enter any one cap id',
    endTime: 'End time should be greater than a start time',
    timeInTheFuture: 'Start time and end time should not be a future date',
    dateInTheFuture: 'Date should not be a future date',
    dateInThePast: 'Date should be a future date',
    emptyOrgId: 'Please select organization',
    emptyCapId: 'Valid cap id is required',
    emptyDeviceId: 'Valid device id is required',
    emptyMedicineName: 'Medicine name is required',
    emptyHowLongToTakeMedicine: 'Number of days is required',
    emptyHowLongToTakeMedicineWeek: 'Number of weeks is required',
    validHowLongToTakeMedicine: 'Please enter a valid number of days/weeks',
    validHowLongToTakeMedicineDays: 'Please enter valid number of days (below 999 days)',
    validHowLongToTakeMedicineWeeks: 'Please enter valid number of weeks (below 999 weeks)',
    scheduleTypeRequired: 'Please select when to take medicine',
    invalidDays: 'please enter valid days',
    duplicateTimeForTake: 'Time for taking medicine is duplicated',
    administrativeWindowRequiredForMultipleTakes: 'Administrative window is required for multiple takes',
    timeBetweenMedicineTooShort: 'Time between medicine is too short',
    emptyValidHowManydays: 'How many days is required',
    emptyTakenTime: 'Time for taking Medicine is required',
    emptyDaysOfWeek: 'Please select days of Week',
    connectionError: 'Please check your internet connection and try again',
    internalError: 'Something went wrong, please try again',
    unknownError: 'Unknown error',
    invalidEmail: 'Please enter a valid email address',
    emptyEmailAddress: 'Email Address is required',
    emptyNickName: 'Nickname is required',
    emptyFirstName: 'First Name is required',
    emptyOrgName: 'Organization name is required',
    emptyIsRequired: 'is required',
    emptyOldPassword: 'Old password is required',
    emptyConfirmPassword: 'Confirm password is required',
    emptyNewPassword: 'New password is required',
    passwordDoesNotMatched: "Password doesn't matched",
    emptyLastName: 'Last Name is required',
    emptyCode: 'Code is required',
    emptyPhoneNumber: 'Phone number is required',
    emptyName: 'Name is required',
    emptyDosage: 'Dosage is required.',
    emptyPatient: 'Patient is required.',
    selectOneDay: 'Please select at least one day.',
    addMedicationTime: 'Please add at least one medication time.',
    invalidPhoneNumber: 'Invalid phone number',
    passwordNotMatched: 'Incorrect username or password',
    pleaseTryAgain: 'Please, try again.',
    invalidDOB: 'Please enter valid Date of birth.',
    enterValidText: 'Please enter a valid',
    invalidPassword:
      'Your password should be at least 8 characters long and must have 1 number, 1 symbol (~!*^@#$%&), 1 upper case and 1 lower case character.',
    invalidActivationCode: 'Activation code is not valid.',
    noPhoneNoEmail: 'You should enter your email or phone number',
    phone: {
      notValid: 'Phone is not valid',
      invalidCountryCode: 'Invalid country code',
      notANumber: 'Not a number',
      tooLong: 'Number is too long',
      tooShort: 'Number is too short',
    },
    unrecognisedNumber: 'Unrecognised Number',
    unrecognisedNumberText: 'The phone number you entered does not match the one we have for you on our system.',
    settingLimitsError: 'Setting limits error',
    unrecognisedNumberEsiPatientText: () => [
      'Please re-enter your phone number or contact us for help. ',
      <a href="mailto:support@lidsync.com">support@lidsync.com</a>,
      ' or ',
      <a href="tel:+1 888-618-0463">+1 888-618-0463</a>,
    ],
    invalidCode: 'Invalid Code',
    invalidCodeText: 'The code you entered was not valid, try again, or press resend to receive a new code.',
    invalidCodeEsiPatientText: () => [
      'The code you entered was not valid, try again, or get help at ',
      <a href="mailto:support@lidsync.com">support@lidsync.com</a>,
      ' or ',
      <a href="tel:+1 888-618-0463">+1 888-618-0463</a>,
    ],
    invalidName: 'You entered invalid name',
    invalidFirstName: 'You entered invalid first name',
    invalidLastName: 'You entered invalid last name',
    invalidOrgName: 'You entered invalid Organization name',
    nothingToChange: 'There is nothing to change',
    deviceIdError: 'Device id shall be shorter than 100 characters',
    maxValueExceeded: 'Maximum allowed value is {0}',
    startDateCantBeInThePast: 'Start date can not be in the past',
    endDateMustBeAfterStartDate: 'End date must be after start date',
    endDateMustBeTomorrow: 'End date must be tomorrow or later',
    endDateMustBeBeforeEnrollmentEndDate: 'End date must be before enrollment end date',
  },
  warnigs: {
    cantBeUndone: "Caution: This action can't be undone.",
    deleteCaregiver: 'Are you sure you want to delete caregiver',
    adminAppPrevilegesWarning: 'Are you sure you want to change Admin App Privileges for this doctor?',
    adminDelete: 'This action cannot be undone. Are you sure you want to delete the following administrator?',
    adminResetPassword: 'Are you sure you want to reset password for the following user:',
    resendInvite: 'Are you sure you want to resend the invitation to the following user:',
    inviteFirstAdminWarning: ' To invite your first administrator please enter Email Address.',
    inviteFirstPatientWarning: ' To invite your first patient please enter Email Address.',
    longStudyName:
      'Long study names may not be fully displayed, depending on the screen resolution at which the page is viewed.',
    deletePatientWarning:
      'When patient is deleted, all their Medication schedules and notifications will be stopped and all active alerts will be cleared.',
  },
  success: {
    deleteAccountSuccess: 'The account has been deleted successfully.',
    dataSavedSuccessfully: 'Data saved successfully!',
    passwordResetSuccess:
      'Your RXCap password has now been reset, press login below to login to your account using your new credentials',
    invitationConfirmedSuccessfully: 'Your invitation has been successfully confirmed.',
    medicationSaved: 'Medication saved successfully',
  },
  placeholder: {
    phoneNumber: 'Enter phone number',
    emailAddress: 'Enter Email Address',
    medicationName: 'Enter Medication Name',
    dosageName: 'Enter Dosage Here',
    newTimeZone: 'Add a New Time',
    userName: 'Username',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    code: 'Code',
    cgPhoneNumber: 'Phone number',
    notesArea: 'Notes',
  },
  battery: {
    level: 'Battery level',
    battery: 'Battery',
    high: 'Good',
    medium: 'Medium',
    low: 'Poor',
    noData: 'Not available',
  },
  esiPatientStatus: {
    capInUse: 'Cap in use',
    pending: 'Pending',
    shipping: 'Shipping',
    inProgress: 'In progress',
    ready: 'Ready',
    active: 'Active',
  },
  patientDashboard: {
    upcomingReminders: 'Upcoming Reminders',
    activeMedications: 'Active Medications',
    stockManagement: 'Pills in Hand',
    seeAll: 'See All',
    seeDetails: 'See Details',
    calendar: 'Calendar',
    viewHistory: 'View History',
    lastWeek: 'Last Week',
    lastMonth: 'Last Month',
    allMeds: 'All Meds',
    all: 'All',
    allOrg: 'All Organizations',
    noOrg: 'No Organization',
    selectOrg: 'Select Organization',
    doses: 'Pills',
    dose: 'Pill',
  },
  esiPatientInfo: {
    street: 'Street',
    appartment: 'Appartment/Suite',
    zip: 'ZIP',
    city: 'City',
    state: 'State',
    country: 'Country',
    schedule: 'Schedule',
    device: 'Device',
    time: 'Time',
    activeFrom: 'Active from',
  },
  patient_medications: {
    medication: 'Medication',
    activeMedications: ' Active medications',
    stockDoses: 'Stock (doses)',
    active: 'Active',
    inActive: 'Inactive',
    schedule: 'Schedule',
    name: 'Name',
    frequency: 'Frequency',
    timesPerDay: 'Times Per Day',
    startDate: 'Start Date',
    endDate: 'End Date',
    lastTaken: 'Last Taken',
    nextReminder: 'Next Reminder',
    stock: 'Stock',
    numberOfPill: 'Number of Pills',
    dosesInStock: 'Doses in Stock',
    pillsInHand: 'Pills in Hand',
    tooMayPillsWaring: 'Too many pills added. Pills number reduced to ',
    willLastFor: 'Will last for',
    expiresOn: 'Expires on',
    edit: 'Edit',
    hardwareId: 'Hardware ID',
    device: 'Device',
    battery: 'Battery',
    activationDate: 'Activation Date',
    learnMore: 'Learn more',
    refill: 'Refill',
    time: 'Time ',
    times: 'Time(s)',
    am: 'AM',
    pm: 'PM',
    addPills: 'Add (Pills)',
    totalPills: 'Total (Pills)',
    zero: '0',
    doseRemaining: 'Pills Remaining',
    thisWillLastFor: 'This will last until',
    downloadFullMedlist: 'Download Full Med List',
    downloadFilteredMedList: 'Download Filtered Med List',
    last2Months: 'Last 2 Months',
    last6Months: 'Last 6 Months',
    lastWeek: 'Last Week',
    lastMonth: 'Last month',
    assignedCap: 'Assigned cap',
    reminders: {
      early: 'Early Reminder',
      on_time: 'On-Time Reminder',
      late: 'Late Reminder',
      missed_doses: 'Missed Doses Reminder',
    },
    remindersTypes: {
      early: 'Early',
      on_time: 'On-Time',
      late: 'Late',
      missed_doses: 'Missed doses',
    },
    numberOfDoses: '# of doses',
    defaultDoses: '2 doses',
    tagsForReminders: 'Tags to be used in reminders messages:',
    reminderCharLimit: 'Messages must be less than 320 charecters long',
    enter3Chars: 'Enter at least 3 characters to search',
  },
  endUserLicenseAgreement: 'End User License Agreement',
  privacyPolicy: 'INTERNET PRIVACY POLICY',
  privacyPolicyTrust: 'Privacy policy',
  termsOfUse: 'Terms of use',
  termsAndConditions: 'Terms and Conditions',
  esiPatientLoginStep1: 'To login to your Lid Sync account, please enter your mobile phone number below.',
  esiPatientLoginStep2:
    'We have sent you a 6-Digit verification code. Enter it below to verify your account. This code will expire in: ',
  yourTrustIsImportantToUs: 'Your trust is important to us',

  toProceedYouNeedToAcceptTheFollowing: 'To proceed you need to accept the following',
  capsRequiredList: {
    '38mm': 'LS002 Caps Required',
    '42mm': 'LS001 Caps Required',
  },
  capsTitles: {
    '38': '38 mm Caps',
    '42': '42 mm Caps',
  },
  updateOrder: 'Update Order',
  updateCap: 'Update Device',
  registerCap: 'Register Cap',
  hardwareIdUpdatedSuccessfully: 'Hardware ID updated successfully',
  registerYourSmartCap: 'Register your Smart Cap',
  activateYourDeviceTitle: 'Activate Your Device',
  changeYourDeviceTitle: 'Change your cap',
  activateYourDevice:
    'Activate your device by SMS. Your unique 6 digit activation code can be found printed on the label attached to your device. Text the code to:',
  changeYourDevice:
    'Update your device by SMS. Your unique 6 digit activation code can be found printed on the label attached to your device. Text the code to:',
  setYourTimezone: 'Set your Timezone',
  setTimezoneInst:
    'Finally, select your timezone so we can make sure your medications schedules are correct for you. You can change this in the future via settings.',
  smartCapInst:
    'Find the 6 digit Cap ID printed on the top of your cap’s packaging (and the back of the instruction booklet), and enter the number below to register it.',
  findTheSmartCapID:
    'Find the Smart Cap ID printed on the outside of your cap’s packaging, and enter the number below to register it.',
  packageTrackingID: 'Package Tracking ID',
  trackingStatus: 'Tracking Status',
  fieldIsRequired: 'Field is required',
  fieldIsBelowZero: 'The field must be greater than zero',
  orderStatuses: {
    created: 'Created',
    inTransit: 'In transit',
    shipped: 'Shipped',
    delivered: 'Delivered',
  },
  orderID: 'Order ID',
  orderDate: 'Order Date',
  trackingID: 'Tracking ID',
  capsRequired: 'Caps Required',
  twoFAFormCodeText: 'The one password code was sent to your mobile phone. This code will expire in: ',
  editSchedule: 'Edit schedule',
  editStock: 'Edit Pills in Hand',
  myDetails: 'My Details',
  myAddress: 'My Address',
  myMedications: 'My Medications',
  editAddress: 'Edit Address',
  apartmentSuiteOptional: 'Apartment/Suite (optional)',
  streetAddress: 'Street Address',
  editDetails: 'Edit Details',
  scheduledRemindersAreTurningOff:
    'This action is not recommended and will stop you all reminders for all of your currently active meds.',
  scheduledRemindersAreTurningOn: 'You are going to receive all reminders for all of your currently active meds.',
  medicationReminders: 'Medication Reminders',
  receiveWeeklyReports: 'Receive weekly reports',
  addressUpdatedSuccessfully: 'Address Updated Successfully',
  codeHasBeenResent: 'Code has been resent. It will expire in: ',
  profileUpdated: 'Profile Updated',
  selectTimezone: 'Select Timezone',
  history: 'History',
  stateHistory: 'State History',
  timeTaken: 'Time Taken',
  scheduledDate: 'Scheduled Date',
  downloadLast24Months: 'Download Last 24 Months',
  downloadFilteredHistory: 'Download Filtered History',
  addFilter: 'Add Filter',
  refresh: 'Refresh',
  imei: 'IMEI',
  deviceImei: 'Device IMEI',
  capShortName: 'Cap Short Name',
  lastActivity: 'Last Activity',
  lastReading: 'Last Reading',
  pairingStatus: 'Pairing Status',
  pairingTime: 'Pairing Time',
  updated_at: 'Updated At',
  reminders: 'Reminders',
  reminder: 'Reminder',
  showReminders: 'Show Reminders',
  factoryMode: 'Factory mode',
  factory: 'Factory',
  customerMode: 'Customer mode',
  customer: 'Customer',
  sleepMode: 'Sleep mode',
  pairingMode: 'Pairing mode',
  on: 'On',
  off: 'Off',
  fota: 'FOTA',
  pins: 'Pins',
  readings: 'Readings',
  signalStrength: 'Signal strength',
  signal: 'Signal',
  activity: 'Activity',
  activityType: 'Activity type',
  value: 'Value',
  qrCode: 'QR Code',
  faults: 'Faults',
  passwordInfo:
    'Your password should be at least 8 characters long and must have 1 number, 1 symbol (~!*^@#$%&), 1 upper case and 1 lower case character.',
  AGN: 'AGN',
  enrollmentDate: 'Enrollment Date',
  registeredDate: 'Registered Date',
  lastKnownBattery: 'Last known battery',
  lastKnownTemperature: 'Last known temperature',
  lastKnownSignalStrength: 'Last known signal strength',
  lowSignal: 'Low Signal',
  moderateSignal: 'Moderate Signal',
  highSignal: 'High Signal',
  locationOfDevices: 'Location of Devices',
  mapTooltipText: 'Need text for this...',
  lastKnownLocation: 'Last known location',
  valueHwCode: 'Value (HW Code)',
  current: 'Current',
  remove: 'Remove',
  dosesRemaining: 'Pills Remaining',
  thisWillLastUntil: 'This Will Last Until',
  great: 'Great ',
  youAreAllDone: 'You’re All Done',
  requestDownloadData: 'You have requested to download your data. ',
  pleasePressTheButton: 'Please press the button on the right and save the file on your hard drive.',
  scheduled: 'Scheduled',
  allTimes: 'All Times',
  allMedications: 'All Medications',
  statusAll: 'Status - All',
  statusMissed: 'Status - Missed',
  statusUpcoming: 'Status - Upcoming',
  statusTaken: 'Status - Taken',
  readyOnly: 'Read only',
  readAndWrite: 'Read & Write',
  enterPhoneNumber: 'Enter phone number',
  learnMoreAboutInfo:
    'Learn more about this medication, including instructions for use, side effect, drug interactions, precautions and storage. Powered by ',
  medicationC: 'Medication©',
  day: ' Day',
  daysStreak: ' Streak',
  errorEnterCapId: 'Enter Cap Id',
  errorEnterDeviceId: 'Enter Device Id',
  errorPaitentMedication: 'Error while saving patient medication data',
  errorSavingPatient: 'Error while saving patient data',
  allDays: ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'],
  orderUpdatedSuccessfully: 'Order updated successfully',
  downloadCalendar: 'Download Calendar',
  download: 'Download',
  left: 'left',
  yourSuccessPreviousMonth: 'Your accuracy previous Month',
  yourSuccessMonthly: 'Your accuracy this Month',
  yourSuccessWeekly: 'Your accuracy this Week',
  good: 'Good',
  average: 'Average',
  bad: 'Bad',
  getTheApp: 'Get the App',
  tutorialTexts: {
    skipBtn: 'Skip',
    dashTitle: 'Dashboard',
    dashboardInfo:
      'The dashboard provides a quick summary of how well you are sticking to your schedule, upcoming reminders, active medications and stock alerts.',
    medicationsTitle: 'Medications',
    medicationsInfo:
      'Here you can view and edit your active and inactive medications, see your schedules and quickly discover many meds you have missed or taken.',
    calendarTitle: 'Calendar',
    calendarInfo:
      'The calender is the fastest way to see all the meds you have due on any given day, and to see what days you have scheduled throughout the entire month.',
    historyTitle: 'History',
    historyInfo:
      'If you need to confirm if you took or missed a medication on an previous day, or review or download any period of time head to History. ',
    settingsTitle: 'Settings',
    settignsInfo:
      'Settings lets you review (and edit some) of your personal details, and allows you to change your language or timezone,  and toggle reminders.',
    supportTitle: 'Support',
    supportInfo:
      'If you need any technical assistance with your cap, or the app or website, we are here to help via chat support, help center, or email or phone.',
  },
  adminDashboard: {
    numberOfParticipants: 'Participants',
    numberOfActivatedCaps: 'Activated Caps',
    missed2ConsecutiveDoses: 'Missed 2 consecutive doses',
    missed5ConsecutiveDoses: 'Missed 5 consecutive doses',
    medicationTaken: 'Taken',
    medicationMissed: 'Missed',
    top5LocationByEvents: 'Top 5 Locations (by Events)',
    evolutionOfCapActivations: 'Evolution of Cap Activations',
    adherenceStats: 'Adherence Statistics',
  },
  organization: 'Organization',
  reason: 'Reason',
  organizations: 'Organizations',
  provisioning: 'Provisioning',
  testDevice: 'Test Device',
  apiURL: 'API URL',
  fotaURL: 'FOTA URL',
  features: 'Features',
  deviceManagement: 'Devices',
  bluetooth: 'BLE Smart Cap',
  weightScales: 'Weight Scales',
  wristBand: 'Wrist Band',
  glucometer: 'Glucometer',
  spirometer: 'Spirometer',
  inhaler: 'Inhaler',
  sleepReport: 'Sleep report',
  heartRate: 'Heart rate',
  airQuality: 'Air Quality',
  movementReport: 'Movement report',
  stepsLowerCase: 'steps',
  steps: 'Steps',
  lte: 'Pillbox LTE',
  lsc: 'LTE Smart Cap',
  injectableTracker: 'Injectable Medication Tracker',
  mdp: 'Smart MDP Dispenser',
  bloodPressureMonitor: 'Blood Pressure Monitor',
  hub: 'Datahub',
  otherDevices: 'Other devices',
  report: 'Report',
  addOrganization: 'Add Organization',
  editOrganization: 'Edit Organization',
  enterOrganizationName: 'Enter organization name',
  organizationManagers: 'Organization Managers',
  capManagers: 'Cap Managers',
  configuration: 'Configuration',
  dates: 'Dates',
  startImmediately: 'Start Immediately',
  date: 'Date',
  twoFAEnabled: '2FA',
  twoFAEnabledStatus: '2FA Enabled',
  twoFADisabledStatus: '2FA Disabled',
  capManager: 'Cap Manager',
  adminTab: 'Admin',
  admins: 'Admins',
  devicesTab: 'Devices',
  outboundAPITab: 'Outbound API',
  externalApiOauthTab: 'External API OAuth',
  externalApiAccessControlTab: 'External API Access Control',
  externalApiLogsTab: 'External API Logs',
  masterLog: 'Master Log',
  addOrganizationManager: 'Add Organization Manager',
  editOrganizationManager: 'Edit Organization Manager',
  active: 'Active',
  inactive: 'Inactive',
  online: 'Online',
  offline: 'Offline',
  blocked: 'Blocked',
  suspended: 'Suspended',
  unassigned: 'Unassigned',
  manufactured: 'Manufactured',
  deleted: 'Deleted',
  urls: 'URLs',
  key: 'Key',
  dateOfCreation: 'Date of Creation (Days)',
  addOutboundAPI: 'Add Outbound API',
  editOutboundAPI: 'Edit Outbound API',
  secretKey: 'Secret Key',
  host: 'Host',
  url: 'URL',
  ip: 'IP',
  capActivityDetails: 'Cap Activity Details',
  hubActivityDetails: 'Hub Activity Details',
  selectOrganization: 'Select',
  deviceId: 'Device ID',
  deviceName: 'Device Name',
  systolicPressure: 'Systolic Pressure',
  diastolicPressure: 'Diastolic Pressure',
  pulseRate: 'Pulse Rate',
  meanArterialPressure: 'Mean Arterial Pressure',
  deviceType: 'Device Type',
  deviceModel: `Device Model`,
  weight: 'Weight',
  glucose: 'Glucose',
  glucoseConcentration: 'Glucose concentration',
  sampleLocation: 'Sample location',
  pulse: 'Pulse',
  saturation: 'Saturation',
  oxygenSaturation: 'Oxygen Saturation',
  viewDetails: 'View details',
  hubDetails: 'Hub Details',
  bloodPressure: 'Blood Pressure',
  weeklyAvgBloodPressure: 'Weekly Avg. Blood Pressure',
  avgBloodPressure: 'Avg. Blood Pressure',
  pulseRateAndBloodPressure: 'Pulse Rate and Blood Pressure',
  schedules: {
    schedules: 'Schedules',
    scheduleName: 'Schedule Name',
    patient: 'Patient',
    scheduleType: 'Schedule Type',
    time: 'Time',
    accuracy: 'Accuracy',
    medication: 'Medication',
    startDate: 'Start Date',
    endDate: 'End Date',
    addSchedule: 'Add Schedule',
    addCap: 'Add Cap',
    capId: 'Cap ID',
    capName: 'Cap Name',
    timeFor: 'Time From Last Take',
    lastActivityDate: 'Last Activity Date',
    lastActivityTime: 'Last Activity Time',
    battery: 'Battery',
    addName: 'Add Name',
    enterName: 'Enter name',
    search: 'Search',
    title1: 'Add Schedule to:',
    title2: 'Select Medication',
    title3: 'Set Schedule',
    title4: 'Dates',
    title5: 'Review',
    enableEndDate: 'Enable End Date',
    disableEndDate: 'Disable End Date',
    errorScheduleName: 'Please enter schedule name',
    errorMedicationName: 'Please select medication name',
    errorSelectTime: 'Please select time',
    errorSelectTimezone: 'Please select timezone',
    errorSelectStartDate: 'Please select start date',
    timezone: 'Timezone',
  },
  bpMonitor: 'BP Monitor',
  scale: 'Scale',
  ble: 'Bluetooth',
  billing: {
    billing: 'Billing',
    lte: 'LTE',
    ble: 'Bluetooth',
    hub: 'HUB',
    ID: 'ID',
    nickname: 'Nickname',
    costMonth: 'Cost / month',
    added: 'Added',
    device: 'Type',
    tasks: 'Tasks',
    deviceEvents: 'Device Events',
  },
  capPatient: {
    watchedBy: 'Watched by',
    dob: 'D.O.B',
    patientName: 'Patient Name',
    enterPatientName: 'Enter Patient Name',
    enterFirstName: 'Enter Firstname',
    enterLastName: 'Enter Lastname',
    enrollment: 'Enrollment',
    organType: 'Organ Type',
    downloadPatientData: 'Download Patient Data',
    downloadNotesAndActions: 'Download Notes and Actions',
    mobileNumber: 'Mobile Number',
    phoneNumber: 'Phone Number',
    email: 'Email',
    address: 'Address',
    insurance: 'Insurance',
    insuranceName: 'Insurance Name',
    enterInsuranceName: 'Enter Insurance Name',
    policyId: 'Policy ID',
    enterPolicyId: 'Enter Policy ID',
    groupNumber: 'Group Number',
    enterGroupNumber: 'Enter Group Number',
    centerDetails: 'Center Details',
    providerCenterDetails: 'Provider/Center Details',
    centerPracticeName: 'Center/Practice Name',
    enterCenterPracticeName: 'Enter Center/Practice Name',
    orderingProvider: 'Ordering Provider',
    enterOrderingProvider: 'Enter Ordering Provided',
    clinicContact: 'Clinic Contact',
    enterClinicContact: 'Enter Clinic Contact',
    medicalHistory: 'Medical History',
    primaryCondition: 'Primary Condition',
    enterPrimaryCondition: 'Enter Primary Condition',
    secondaryCondition: 'Secondary Condition',
    enterSecondaryCondition: 'Enter Secondary Condition',
    noConditionsSelected: 'No conditions selected',
    addMedicalHistory: 'Add Medical History',
    transplantDetails: 'Transplant Details',
    transplantOrganType: 'Transplant Organ Type',
    enterTransplantOrganType: 'Enter Transplant Organ Type',
    task: 'Task',
    addOrgan: 'Add Organ',
    transplantDate: 'Transplant Date',
    taskName: 'Task Name',
    taskNotes: 'Notes',
    status: 'Status',
    taskTime: 'Task Time',
    startTime: 'Start Time',
    endTime: 'End Time',
    deviceId: 'Device ID',
    deviceName: 'Device Name',
    deviceType: 'Device Type',
    registeredDate: 'Registered Date',
    activationDate: 'Activation Date',
    battery: 'Battery',
    startDate: 'Start Date',
    endDate: 'End Date',
    dose: 'Dose',
    logTime: 'Log Time',
    viewLogs: 'View Logs',
    loggedThisMonth: 'Logged this month',
    goal: 'Goal',
    goalThisMonth: 'Goal this month',
    startTimer: 'Start Timer',
    stopTimer: 'Stop Timer',
    patientId: 'Patient ID',
    patientDetails: 'Patient Details',
    summary: 'Summary',
    dateOfBirth: 'Date of birth',
    firstName: 'Firstname',
    lastName: 'Lastname',
    errorReadingFile: 'Error while reading file',
    errorInvalidFile: 'Invalid file selected, valid file format:',
    errorPatientName: 'Please enter patient name',
    errorFirstName: 'Please enter first name',
    errorLastName: 'Please enter last name',
    errorDOB: 'Please select date of birth',
    errorTextPhoneNumber: 'Please enter text phone number',
    errorPrimaryPhoneNumber: 'Please enter primary phone number',
    errorEmail: 'Please enter email',
    errorTimezone: 'Please select Timezone',
    errorAddress: 'Please enter address',
    errorInsuranceName: 'Please enter insurance name',
    errorPolicyId: 'Please enter policy id',
    errorGroupNumber: 'Please enter group number',
    errorCenterName: 'Please enter center name',
    errorFacility: 'Please select Facility',
    errorProvider: 'Please enter provider',
    errorClinicContact: 'Please enter clinic contact',
    errorPrimaryCondition: 'Please enter primary condition',
    errorSecondaryCondition: 'Please enter secondary condition',
    errorOrganType: 'Please enter organ type',
    errorTransplantDate: 'Please enter transplant date',
    addInsurance: 'Add Insurance',
    addCenterDetails: 'Add Center Details',
    month: 'Month',
    numberOfTask: 'Number of Tasks',
    numberOfDays: 'Days with event',
    tcClaims: 'TC Claims',
    date: 'Date',
    dateOfService: 'Date of Service',
    reviewerName: 'Reviewer Name',
    type: 'Type',
    note: 'Note',
    tcId: 'TC ID',
    billableDate: 'Billable Date:',
    billableTime: 'Billable time',
    updateTask: 'Update Task',
    totalTime: 'Total time',
    cptCode: 'CPT Codes',
    diagnosticsCode: 'Diagnostics code',
    data: 'Data',
    defaultTimeZone: 'America/New_York',
    cycleStartDate: 'Start date',
    cycleEndDate: 'End date',
    fileWithXRecords: '{0} with {1} records(s)',
    errorRequiredFields: 'Required fields missing',
    errorFile: 'Unable to read file ',
    errorFiles: 'File errors encountered',
    missingFieldFormat: 'Field missing: {0}',
    incorrectFieldFormat: 'Incorrect format',
    wrongFieldLengthFormat: 'Field too long. Maximum length is {0}',
    incorrectFieldValue: 'Incorrect value: {0}',
    missingTransplantDetails: 'No transplant details',
    missingInsurance: 'No insurance',
    missingCenterDetails: 'No center details',
    missingMedicalHistory: 'No medical history',
    invalidRecords: 'invalid records(s)',
    patientNotAdded: 'patient(s) not added',
    uploadSucceeded: 'Upload succeeded',
    readyForUpload: 'Ready for upload',
    noNewPatients: 'No patients found to be assigned to cohort.',
    patientStatusInCohort: {
      assigned: 'Already in cohort',
      toAssign: 'Will be assigned to cohort',
      toRemove: 'Will be removed from cohort',
      nothingToDo: '-',
      successAdd: 'Sucessfully added to cohort',
      successRemove: 'Sucessfully removed from cohort',
      error: 'Error',
    },
    assignCohort: 'Assign Cohort',
    unassignFromCohort: 'Unassign from Cohort',
    patientStatus: {
      enrolled: 'Enrolled',
      onboarded: 'Onboarded',
      verified: 'Registered',
      activated: 'Activated',
      ended: 'Disenrolled',
    },
    contactInformation: 'Contact Information',
    patientSaved: 'Patient Details for {0} have been saved',
    assignSmartCap: 'Assign Smart Cap',
    linkWithProgram: 'Link with Program',
    noProgram: 'No Program',
    whereIsKitId: "Where's the kit Id?",
    enableMessage: 'Enable Message',
    welcomeMessage: 'Welcome Message',
    addConditionsFacilitiesCohorts:
      'Please setup your organization (add conditions, facilities and cohorts) before adding your first patient',
    medicationsTooltip:
      'Please enter the medications the patient is currently taking. Each medication should be entered in new line.',
    patientAgreed: 'Patient agreed',
    patientNotAgreed: 'Patient not agreed',
    patientNotConsented: 'Patient not consented',
    patientConsentIsRequired: 'Patient consent is required',
  },
  fieldsRequired: 'fields required',
  messages: {
    archived: 'Archived',
    messages: 'Messages',
    new: 'New',
    inbox: 'Inbox',
    number: 'Number',
    name: 'Name',
    conversation: 'Conversation',
    date: 'Date',
    time: 'Time',
    newMessage: 'New Message',
    displaySystemMessages: 'Display system messages',
    typeMessage: 'Type Message',
    archive: 'Archive',
    unarchive: 'Unarchive',
    read: 'Read',
    unread: 'Unread',
    markAs: 'Mark as',
    prefabReplies: 'Prefab Responses',
    prefabReply: 'Prefab Response',
    shortcut: 'Shortcut',
    author: 'Author',
    shared: 'Shared',
    private: 'Private',
    shareWithCollegues: 'Share prefab with colleagues',
    addToPrefabs: 'Add to prefab responses',
    addAsPrefab: 'Add reply as a prefab',
    addReply: 'Add Reply',
    createNewPrefab: 'Create new prefab response',
    editPrefab: 'Edit prefab response',
    recipients: 'Recipients',
  },
  esi: {
    active: 'Active',
    pending: 'Pending',
    termed: 'Termed',
    activatedDate: 'Activated Date',
    termedDate: 'Termed Date',
  },
  textDays: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
  },
  settingPage: {
    details: 'Details',
  },
  timer: 'Timer:',
  alltime: 'All-time',
  selectTimezone: 'Please select timezone',
  patientInfo: 'Patient Info',
  frequecyType: {
    asNeeded: 'As Needed',
    everyday: 'Every day',
    specificDays: 'Specific days',
    interval: 'Interval',
    qd: 'q.d. - Every day',
    bid: 'b.i.d. - Twice a day',
    tid: 't.i.d. - Three times a day',
    qid: 'q.i.d. - Four times a day',
    q4h: 'q.4h - Every 4 hours',
  },
  searchConversation: 'Search Conversation ',
  editCapManager: 'Edit Cap Manager',
  capManagerUpdated: 'Cap manager updated',
  activateYourDeviceBySMSText: `Activate your device by SMS. Your unique 6 digit activation code can be found printed on the label attached to  your device. Text the code to:`,
  pleaseEnterCodeAL: 'Please enter code ',
  charachterAL: ' character',
  selectMedicineAL: 'Please select medicine from list box',
  timepickerAL: 'Please select timezone from timepicker',
  medicationScreenAL: 'navigate to the medications screen',
  refreshDataAL: 'Refresh the data',
  navigateToScreenAL: {
    navigateTo: 'navigate to the',
    screen: 'screen',
  },
  refreshAL: 'Refresh the data',
  chooseStartDateAL: 'Choose start Date',
  chooseEndDateAL: 'Choose end Date',
  clear: 'Clear',
  unsupportedDevice: 'This device type is not fully supported yet. Please contact customer support.',
  rem: `REM`,
  deepSleep: 'Deep',
  lightSleep: 'Light',
  awake: 'Awake',
  opportunity: 'Opportunity',
  percentChange: '% Change',
  ended: 'Ended',
  length: 'Lenght',
  sleepAt: 'Sleep at',
  wakeUpAt: 'Wake-up at',
  selectedTimezone: ' selected timezone',
  billingStatus: {
    pending: 'Pending',
    submitted: 'Submitted',
    onhold: 'On-Hold',
  },
  reading: 'Reading',
  custom: 'Custom',
  calories: 'Calories',
  distance: 'Distance',
  exerciseTime: 'Exercise time',
  exerciseIntensity: 'Exercise intensity',
  restHR: 'Rest HR',
  alertsEnum: {
    bloodPressure: 'Blood Pressure Alerts',
    oxygenSaturation: 'Saturation Alerts',
    weight: 'Weight Alerts',
    glucose: 'Glucose Concentration Alerts',
    spirometry: 'Spirometry Alerts',
    temperature: 'Temperature Alerts',
    heartRate: 'Heart rate Alerts',
    pm25: 'PM 2.5 Alerts',
    coagulation: 'Coagulation Alerts',
  },
  unit: 'Unit',
  lowWeight: 'Low Weight',
  highWeight: 'High Weight',
  lowSystolic: 'Low Systolic',
  highSystolic: 'High Systolic',
  lowDiastolic: 'Low Diastolic',
  highDiastolic: 'High Diastolic',
  lowGlucose: 'Low Glucose',
  highGlucose: 'High Glucose',
  lowFev1: 'Low FEV1',
  highFev1: 'High FEV1',
  lowFvc: 'Low FVC',
  highFvc: 'High FVC',
  change: 'Change',
  changeSinceLastReading: 'Change since last reading',
  latestReadings: 'Latest Readings',
  patientLog: 'Patient Log',
  time: 'Time',
  pulseOx: 'Pulse Ox',
  language: 'Language',
  new: 'New',
  documents: 'Documents',
  pulseMeasures: 'Pulse measures',
  stepsBy: 'Accumulated steps by',
  dropOr: 'Drag and drop file, or',
  fileUploadedSuccessfully: 'File uploaded successfully',
  manualUploadProcessInfo:
    'As this is a manual patient upload process, please communicate to the to the coordinator that data has been uploaded.',
  fileUploadFail: 'File upload failed',
  fileExceedMaximumSizeOf: 'File exceed maximum size of',
  documentsGeneralProblem: 'Problem with getting access to documents',
  rxcap: 'RxCap',
  rxcapRights: 'onwards. All Rights Reserved.',
  privacyPolicyText: 'Privacy Policy',
  termText: 'Terms & Conditions',
  and: 'and',
  dragFile: 'Drag and drop file, or',
  chooseFile: 'Choose File',
  uploadingPatients: 'Uploading patients...',
  allFacilities: 'All Facilities',
  facility: 'Facility',
  providerFacility: 'Provider / Facility',
  selectFacility: 'Select Provider /Facility',
  selectManager: 'Select Manager',
  selectCohort: 'Select Cohort',
  select: 'Select',
  noOptionSelected: 'No option selected',
  english: 'English',
  spanish: 'Spanish',
  languagesEnum: {
    en: 'English',
    es: 'Spanish',
    ar: 'Arabic',
  },
  actions: 'Actions',
  addNote: 'Add Note',
  editNote: 'Edit Note',
  addOpenAction: 'Add Open Action',
  editAction: 'Edit Action',
  emailPatient: 'Email Patient',
  title: 'Title',
  note: 'Note',
  completedAction: 'Completed Action',
  openAction: 'Open Action',
  created: 'Created',
  edited: 'Edited',
  complete: 'Complete',
  open: 'Open',
  completed: 'Completed',
  reOpen: 'Re-Open',
  actionStatus: 'Action Status',
  logitemType: 'Log Item Type',
  downloadTemplateCsv: 'Download template cvs file',
  downloadTemplateXslx: 'Download template xlsx file',
  andXmore: '... and {0} more',
  createKit: 'Create Kit',
  kit: 'Kit',
  kitId: 'Kit ID',
  kitStatus: 'Kit Status',
  selectKit: 'Select Kit',
  attachKit: 'Attach Kit',
  reassignKit: 'Reassign Kit',
  removeKit: 'Remove Kit',
  attachDeviceToKit: 'Attach Device to a Kit',
  attachDevicesToKit: 'Attach Devices to a Kit',
  kitName: 'Kit Name',
  activeSince: 'Active Since',
  deleteKit: 'Delete Kit',
  deleteKitWarning: 'Are you sure you want to delete this kit',
  editKit: 'Edit Kit',
  myAlerts: 'My Alerts',
  showingXPendingAlerts: 'Showing {0} Pending Alerts',
  showingXofXPendingAlerts: 'Showing {0} of {1} Pending Alerts',
  showingXAlerts: 'Showing {0} Alerts',
  showingXofXAlerts: 'Showing {0} of {1} Alerts',
  priority: 'Priority',
  low: 'Low',
  medium: 'Medium',
  high: 'High',
  type: 'Type',
  measurments: 'Measurements',
  characters: 'characters',
  vitals: 'Vitals',
  cohorts: 'Cohorts',
  cohort: 'Cohort',
  activePatients: 'Active Patients',
  inactivePatients: 'Inactive Patients',
  assignedTo: 'Assigned to',
  facilities: 'Facilities',
  createCohort: 'Create Cohort',
  editCohort: 'Edit Cohort',
  cohortName: 'Cohort Name',
  cohortId: 'Cohort Id',
  primaryCohortManager: 'Primary Cohort Manager',
  primaryManager: 'Primary Manager',
  secondaryManager: 'Secondary Manager',
  numberOfPatients: 'Number of Patients',
  createdBy: 'Created by',
  skip: 'Skip',
  flowVolumeCurve: 'Flow Volume Curve',
  volumeTimeCurve: 'Volume Time Curve',
  clearAlert: 'Clear alert',
  clearAlertError: 'Clear alert error',
  setReminder: 'Set reminder',
  spirometry: 'Spirometry',
  program: 'Program',
  confirmations: 'Confirmations',
  programs: {
    rtm: 'RTM',
    ccm: 'CCM',
    rpm: 'RPM',
    pcm: 'PCM',
  },
  subprograms: {
    rtm: {
      musculoskeletal: 'Musculoskeletal',
      respiratory: 'Respiratory',
      behavioralHealth: 'Behavioral Health',
    },
    ccm: {
      basic: 'Basic',
      complex: 'Complex',
    },
  },
  defaultProgram: 'Default Program',
  otherPrograms: 'Other Programs',
  writtenConsentGiven: 'Written consent given',
  verbalConsentGiven: 'Verbal consent given',
  selectCondition: 'Select Condition',
  selectProgram: 'Select Program',
  selectPrograms: 'Select Programs',
  patientWillBeAssigned: 'Patient will be assigned to:',
  searchKit: 'Search Kit',
  scanBarcodeOrEnterId: 'Scan barcode on the box, or enter id',
  redings: 'Redings',
  deviceTypes: {
    bpm: 'BP Monitor',
    scales: 'Scales',
    glucometer: 'Glucometer',
    wristBand: 'Wrist Band',
    spirometer: 'Spirometer',
    hailieInhaler: 'Hailie Inhaler',
    thermometers: 'Thermometers',
    pulseOximeter: 'Pulse Oximeter',
    bluetoothCap: 'Bluetooth cap',
    hub: 'Hub',
    lte: 'LTE',
    lsc: 'LSC',
    inhaler: 'Inhaler',
    airQualityMonitor: 'Air Quality Monitor',
    ptInrMonitor: 'PT/INR Monitor',
  },
  readingStatus: {
    allReadings: 'All Readings',
    newReadings: 'New Readings',
    archivedReadings: 'Archived Readings',
  },
  normal: 'Normal',
  abnormal: 'Abnormal',
  alert: 'Alert',
  batteryReading: 'Battery reading',
  localization: 'Localization',
  firmwareVersion: 'Firmware version',
  signalQuality: 'Signal quality',
  signalQualityEnum: {
    Excellent: 'Excellent',
    Good: 'Good',
    Medium: 'Medium',
    Weak: 'Weak',
  },
  problems: 'Problem(s)',
  sleep: 'Sleep',
  pillsTaken: 'Pills taken',
  inhalationTaken: 'Inhalation taken',
  zephyRxDashboard: 'ZephyRx Dashboard',
  editConditionList: 'Edit Condition List',
  editFacilityList: 'Edit Provider / Facility List',
  patientAlerts: 'Patient Alerts',
  thermometer: 'Thermometer',
  lowTemperature: 'Low Temperature',
  highTemperature: 'High Temperature',
  lowSaturation: 'Low Saturation',
  lowHR: 'Low Heart rate',
  highHR: 'High Heart rate',
  highPm25: 'High PM 2.5',
  lowPt: 'Low PT',
  highPt: 'High PT',
  lowInr: 'Low INR',
  highInr: 'High INR',
  missedDosesAlerts: 'Missed doses Alerts',
  reviewBy: 'Review By',
  last2days: 'Last 48 hours',
  hour: 'hour',
  hours: 'hours',
  saveWithNoKit: 'Save with no kit',
  xObjectsAssignedtoY: '{0} {1} assigned to {2}',
  xObjectsUnassignedfromY: '{0} {1} unassigned from {2}',
  enroll: 'Enroll',
  continueEnrollment: 'Continue Enrollment',
  cancelEnrollment: 'Cancel Enrollment',
  setEnrollmentEndDate: 'Set enrollment end date',
  disenroll: 'Disenroll',
  activateNow: 'Activate now',
  changeEnrollmentEndDate: 'Change enrollment end date',
  register: 'Register',
  pharmacyNotesToProvider: 'Pharmacy notes to Provider',
  enrollmentFor: 'Enrollment for',
  enrollmentCondition: 'Enrollment Condition',
  enrollmentProgram: 'Enrollment Program',
  enrollmentConsent: 'Enrollment Consent',
  agreementToSpeakWithProvider: 'Agreement to speak with Provider',
  consent: 'Consent',
  activate: 'Activate',
  assignKit: 'Assign Kit',
  consentUploadInstruction: 'Signed consent can be uploaded via "Documents"',
  patientEnrolledInfo: '{0} has been enrolled to the system',
  patientActivatedInfo: 'Patient {0} has been activated',
  welcomeSmsSent: 'Welcome SMS was scheduled to be sent to patient {0}',
  kitAssignedInfo: 'Kit {0} has been assigned to patient {1}',
  wheresKitIdText:
    'You will find a 20 digit alphanumeric code printed here on the kit packaging. You should only need to enter the first few letters.',
  enrollmentKit: 'Enrollment Kit',
  noCapAvailable: 'No cap available',
  avgTimeDelta: 'Avg. Time Delta',
  tutorial: 'Tutorial',
  onboarding: 'Onboarding',
  enrollment: 'Enrollment',
  preview: 'Preview',
  monitoring: 'Monitoring',
  sendTo: 'Send to',
  from: 'From',
  message: 'Message',
  SMSSent: 'SMS sent successfully',
  SMSFailed: 'Unable to send SMS',
  sendSMS: 'Send SMS',
  uploadPatientList: 'Upload Patient List',
  patientListFiles: 'Patient List Files',
  perfusionIndex: 'Perfusion Index',
  maxAndRemainingChars: 'Max characters: {0} ({1} remaining)',
  maxAndOverflowChars: 'Max characters: {0} (too long by {1})',
  remainingChar: '{0} character remaining',
  remainingChars: '{0} characters remaining',
  selectTheme: 'Please select theme',
  theme: {
    blue: 'Blue',
    red: 'Red',
    petrol: 'Petrol',
    white: 'White (esi)',
  },
  selectBrand: 'Please select brand',
  lteUserInstructions: 'LTE User Instructions',
  rpmQuickGuide: 'RPM Quick Guide',
  rxcapPortal: 'RxCap Portal',
  smartCapPatientInstVideo: 'Smart Cap Patient Instruction Video',
  role: 'Select role',
  permissionsUpdated: 'Permissions updated',
  contactUs: 'Contact Us',
  videos: 'Videos',
  gettingStart: 'Getting started using the portal',
  gettingStartDesc:
    'Just joined? Watch here to cover all the key areas you will need to learn to successfully manage your patients.',
  onboardingDesc: 'Watch this if you need a quick review of how to onboard new patients to the portal.',
  enrollementDesc: 'Watch this if you need a quick review of how to enroll new patients to the portal.',
  monitoringDesc: 'Watch this if you need a quick review of how to monitor your patients metrics.',
  billingDesc: 'Watch this if you need a quick review of how to manage your billing for your billing clinician.',
  smartCapRTMDesc:
    'You can send this video to your patients, so they can quickly learn about how to use their smart cap',
  SmartCapRTM: 'Smart Cap for RTM',
  network: 'Network',
  networkTime: 'Last network time',
  rxcapLaunchOutlineExternal: 'RxCap Launch Outline',
  setDefault: 'Set default',
  authenticationHeader: 'Authentication Header',
  authHeader: 'Auth Header',
  pauseAPI: 'Pause API',
  successfieldRequired: 'Success Field Required',
  maxTimeWithoutEvents: 'Maximum Time Without Event',
  maxBatchSize: 'Max Batch Size',
  errorMaxBatchSize: 'Must be greater than 0',
  successFieldRequired: 'Success Field',
  required: 'Required',
  notRequired: 'Not Required',
  apiStatus: {
    active: 'Active',
    paused: 'Paused',
  },
  missedDoses: 'Missed doses',
  alertCleared: 'Alert successfully cleared',
  size: 'Size',
  itemSaved: '{0} saved successfully',
  manage: 'Manage',
  noDataAvailableChooseNavigation:
    'No data to display for the chosen time period. Please use navigation options to view data from a different time period',
  atmocubeValues: {
    tvoc: 'TVOC',
    vocIndex: 'VOC Index',
    noxIndex: 'NOx Index',
    relativeHumidity: 'Relative Humidity',
    atmosphericPressure: 'Atmospheric Pressure',
    noiseLevels: 'Noise Levels',
    lightIntensity: 'Light Intensity',
    formaldehyde: 'Formaldehyde',
    occupancy: 'Occupancy',
  },
  hailieEventSubTypes: {
    inhalation: 'Inhalation',
    shakeDetection: 'Shake Detection',
    respirationFlow: 'Respiration Flow',
    respirationTiming: 'Respiration Timing',
  },
  passwordTexts: {
    yourPasswordShouldBe: 'Your password should be',
    eightTo32Characters: '8 to 32 characters long',
    atLeastOneDigit: 'at least have one digit',
    atLeastOneUpperCase: 'at least have one upper case letter',
    atLeastOneLowerCase: 'at least have one lower case letter',
    atLeastOneSpecialCharacter: 'at least have one (~!*^@#$%&) special character',
    matchWithConfirmField: 'match with confirm field',
    oldPasswordIsRequired: 'Old password is required',
    notAllowedCharacters: 'Not allowed characters',
  },
  startDateShouldBeBeforeEndDate: 'Start date should be before end date',
  allowedDataRangeIs6Months: 'Maximum allowed data range is 6 months',
  noDataInGivenPeriod: 'No data was available in the chosen period. Please select different time period.',
  timeThisMonth: 'Time this Month',
  todaysMedications: "Today's Medications",
  statusEnum: {
    pending: 'Pending',
    submitted: 'Submitted',
    onhold: 'On-Hold',
    enabled: 'Enabled',
    disabled: 'Disabled',
    active: 'Active',
    inactive: 'Inactive',
    forbidden: 'No access',
    invited: 'Invited',
    configured: 'Enabled',
  },
  copyToClipboard: 'Copy to clipboard',
  publicKeyHasBeenDeleted: 'Public key has been deleted, all tokens are now revoked',
  publicKeyIsInvalid: 'Public key is invalid',
  noticeSaveCredentials: 'Notice: Please save those credentials. It will not be possible to obtain them again.',
  credentialsCopiedToClipboard: 'Credentials copied to clipboard',
  applyFilters: 'Apply page filters',
  billableTime: 'Billable time',
  pm25: 'PM 2.5',
  healthCenter: 'Health Center',
  yourDevices: 'Your Devices',
  yourMedications: 'Your Medications',
  year: 'Year',
  unitsEnum: {
    lbs: 'lbs',
    kg: 'kg',
    minutes: 'mins',
  },
  dailyAverage: 'Daily Average',
  hourlyAverage: 'Hourly Average',
  dateRange: 'Date Range',
  portalAccess: 'Portal Access',
  editUser: 'Edit User',
  addUser: 'Add User',
  userName: 'User Name',
  resetPasswordMethodPrompt: 'How would you like to reset your password?',
  changePasswordWarning: 'Do you want to change your password? We will send the password reset link to ',
  userSaved: 'User Details for {0} have been saved',
  rolesEnum: {
    organization_manager: 'Organization Manager',
    capmanager: 'Cap Manager',
    doctor: 'Admin',
  },
  rolesPluralEnum: {
    organization_manager: 'Organization Managers',
    capmanager: 'Cap Managers',
    doctor: 'Admins',
  },
  confirmPasswordReset: 'Confirm Password Reset',
  confirmReinvite: 'Confirm Invite Resend',
  dateRangeAdjustedWarning:
    'The maximum date range allowed for download is 6 months. The start date of your selected date range has been adjusted to fit within this limit.',
  scheduleEnded: 'Schedule Ended',
  assignedDate: 'Assigned Date',
  unassignedDate: 'Unassigned Date',
  invalidTimezone: 'Invalid timezone',
  dataRangeAberraviations: {
    day: 'D',
    week: 'W',
    twoWeeks: '2W',
    month: 'M',
    sixMonths: '6M',
    year: 'Y',
  },
  invalidTag: 'Invalid tag',
  insertTag: 'Insert tag',
  awaitingBleReset: 'Awaiting BLE Reset',
  resetBle: 'Reset BLE chip',
  enableDexcomIntegration: 'Enable Dexcom integration',
  resetDexcomIntegration: 'Re-enable Dexcom integration',
  disableDexcomIntegration: 'Disable Dexcom integration',
  enableDexcomIntegrationConfirm: 'Are you sure you want to enable Dexcom integration for patient {0}?',
  enableDexcomIntegrationSuccess: 'Dexcom integration enabled successfully',
  disableDexcomIntegrationConfirm: 'Are you sure you want to disable Dexcom integration for patient {0}?',
  disableDexcomIntegrationSuccess: 'Dexcom integration disabled successfully',
  authorizeRxcapForDexcom:
    'To configure your device, please authorize RxCap to access your Dexcom data. We will redirect you to Dexcom website, where you will need to log in and accept our authorization.',
  reAuthorizeRxcapForDexcom:
    'Our authorization to access your Dexcom data has expired or was revoked. Please authorize RxCap to access your Dexcom data. We will redirect you to Dexcom website, where you will need to log in and accept our authorization.',
  notNow: 'Not now',
  dexcomDeviceConfiguration: 'Dexcom Device Configuration',
  dexcomDeviceConfigured: 'Dexcom Device Configured',
  dexcomDeviceConfiguredSuccess:
    'Your Dexcom device has been configured successfully. Now, the data synced with your dexcom app on your phone will be available to your care provider',
  dexcomInvalidCode: 'Invalid code',
  dexcomCodeNotMatchingPatient:
    'The code obtained from Dexcom was not issued for this patient. Please contact your care provider for assistance.',
  dexcomConfigurationFailed: 'Dexcom configuration failed',
  dexcomConfigurationFailedText:
    'There was a problem configuring your Dexcom device. Please contact your care provider for assistance.',
  noReadingsSince: 'No readings since',
  newNotesSince: 'New notes since',
  numberOfDays: 'Number of days',
  allowedRangeWarning: 'Allowed range is {0}-{1}',
  coagulation: 'Coagulation',
  bulkAddTexts: {
    selectSheet: 'Select Excel sheet to upload',
    multipleSheets: 'Excel Sheet',
    noRows: 'No rows found in the selected sheet',
    columns: 'Columns',
    matchedColumns: 'Matched column headers for import:',
    unmatchedColumns: 'Unmatched column headers excluded from import:',
    newConditionsFound: 'New conditions found',
    unknownConditionsFound: 'Unknown conditions found',
    patientsWithNoCondition: 'Patients with no condition',
    conditionsToAdd: 'Following ICD10 conditions have been found that will be added to the organization',
    unrecognizedConditions:
      'The following conditions are not valid ICD10 codes and will be replaced as ‘Unknown’. Please adjust the conditions in the file or in patient record after import',
    unknownCondition: '{0} patient(s) have no conditions. They will be assigned to ‘{1}’ condition.',
    missingColumns: 'The following columns are missing from the selected sheet:',
    noFacility:
      '{0} patients have no facility or their facility is not added to organization. They will be assigned to ‘{1}’ facility',
    patientsWithNoFacility: 'Patients with no facility',
    unknownFacilitiesFound: 'Unknown facilities found',
    errorReadingFile: 'Error while reading file',
    noPatientsInFile: 'No patients found in the file',
  },
  lastLogin: 'Last login',
  validUntil: 'Valid until',
  changeDeviceState: 'Change device state',
  events: 'Events',
  changeOrgState: 'Change administrative state',
  changeState: 'Change state',
  analytics: 'Analytics',
  maskPHIData: 'Mask PHI data',
};

export default english;
