import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Wrapper, Button as MenuButton, MenuItem, Menu } from 'react-aria-menubutton';
import { now } from 'moment';

import Strings from '../../../../Strings';
import { actions } from './redux/actions';
import { EDIT_COHORT } from './redux/constants';
import { PageHeader } from '../../../../components/PageHeader';
import { Button } from '../../../../components/PageHeader/Button';
import CohortDetails from './CohortDetails';
import DashboardLayout from '../../../Dashboards/DashboardLayout';
import CohortPeople from './CohortPeople';
import { openModalAction } from '../../../../actions/modal';
import PatientsTabs, { PatientListMode } from '../PatientsTabs';

export const DASHBOARD_ID = 'CohortDetails';

const DetailsMetadata = {
  id: CohortDetails.widgetId,
  name: Strings.widgets.details,
  permission: '',
  defProps: { i: CohortDetails.widgetId, isResizable: false, w: 4, h: 1, minW: 4, minH: 1 },
};

const PeopleMetadata = {
  id: 'CohortPeopleWidget',
  name: Strings.widgets.people,
  permission: '',
  defProps: { i: 'CohortPeopleWidget', isResizable: false, w: 4, h: 1, minW: 4, minH: 1 },
};

export const WidgetMetadata = [DetailsMetadata, PeopleMetadata];

const l = [
  { ...DetailsMetadata.defProps, x: 0, y: 0, w: 6, h: 1 },
  { ...PeopleMetadata.defProps, x: 6, y: 0, w: 6, h: 1 },
];

const m = [
  { ...DetailsMetadata.defProps, x: 0, y: 0, w: 4, h: 1 },
  { ...PeopleMetadata.defProps, x: 4, y: 0, w: 4, h: 1 },
];

const s = [
  { ...DetailsMetadata.defProps, x: 0, y: 0, w: 12, h: 1 },
  { ...PeopleMetadata.defProps, x: 0, y: 1, w: 12, h: 1 },
];

export const DefaultLayouts = { l, m, s };

function Cohort(props) {
  const [refreshEnable, setRefreshEnable] = useState(true);
  const [refreshTimestamp, setRefreshTimestamp] = useState(now());
  const [isListOpen, setIsListOpen] = useState(false);

  const { cohort } = props;
  const id = props.match.params.id;

  useEffect(() => {
    if (id) props.getCohortDetails(id);
  }, [props.match.params.id]);

  const CohortDetailsWidget = {
    ...DetailsMetadata,
    render: <CohortDetails cohort={cohort} cohortId={id} />,
    noPadding: true,
  };
  const CohortPeopleWidget = {
    ...PeopleMetadata,
    render: <CohortPeople cohort={cohort} />,
    noPadding: true,
  };

  const widgets = [CohortDetailsWidget, CohortPeopleWidget];

  const handleSelection = (value, event) => {
    const { onEditCohort } = props;
    switch (value) {
      case 'editCohort':
        onEditCohort({ ...cohort, id }, onRefresh);
        break;
      default:
        break;
    }
  };

  const getHeaderComponents = () => {
    const actionsButton = (
      <Wrapper
        className="addMenu"
        onSelection={handleSelection}
        onMenuToggle={({ isOpen }) => {
          setIsListOpen(isOpen);
        }}
      >
        <MenuButton className={`addMenu-button ${isListOpen ? 'expanded' : ''}`}>{Strings.actions}</MenuButton>
        <Menu className="addMenu-menu">
          <MenuItem className="addMenu-menuItem" value="editCohort" id="editCohort">
            {Strings.editCohort}
          </MenuItem>
        </Menu>
      </Wrapper>
    );
    return (
      <React.Fragment>
        {actionsButton}
        <Button class="refresh" disabled={!refreshEnable} onClick={onRefresh} />
      </React.Fragment>
    );
  };

  const onRefresh = () => {
    setRefreshTimestamp(now());
    if (id) props.getCohortDetails(id);
    setRefreshEnable(false);
    setTimeout(() => {
      setRefreshEnable(true);
    }, 10000);
  };

  return (
    <React.Fragment>
      <PageHeader onBackClick={() => history.back()} left={cohort?.title} right={getHeaderComponents()} />
      {cohort && widgets.length > 0 && !_.isEmpty(DefaultLayouts) && (
        <DashboardLayout
          dashboardId={DASHBOARD_ID}
          widgets={widgets}
          defaultLayouts={DefaultLayouts}
          breakpoints={{ l: 1400, m: 1151, s: 0 }}
          rowHeight={362}
          isReadOnly
        />
      )}
      <PatientsTabs cohortId={props.match.params.id} refreshTimestamp={refreshTimestamp} displayMode={PatientListMode.list} />
    </React.Fragment>
  );
}

Cohort.propTypes = {
  cohort: PropTypes.any,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.any }),
  }),
  history: PropTypes.shape({
    location: PropTypes.shape({ state: PropTypes.shape({ params: PropTypes.shape({ offset: PropTypes.any }) }) }),
  }),
  getCohortDetails: PropTypes.func,
  onEditCohort: PropTypes.func,
};

const mapStateToProps = state => {
  const { cohorts } = state.superUser;
  return {
    cohort: cohorts && cohorts?.cohort,
    isLoading: cohorts && cohorts?.isLoading,
  };
};

const mapDispatchToProps = dispatch => ({
  onNavigate: path => dispatch(push(path)),
  getCohortDetails: id => dispatch(actions.getCohortDetails(id)),
  onEditCohort: (cohort, nextAction) =>
    dispatch(
      openModalAction('edit-cohort', {
        action: actions.editCohort,
        actionType: EDIT_COHORT,
        cohort,
        nextAction,
        caption: Strings.editCohort,
      }),
    ),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cohort);
