export const PATIENT_LIST = 'patients';
export const PATIENT_LIST_RESULT = `${PATIENT_LIST}/result`;
export const PATIENT_LIST_CLEAR = `${PATIENT_LIST}/clear`;

export const lOAD_PATIENTS = 'load-patients';
export const lOAD_PATIENTS_RESULT = `${lOAD_PATIENTS}/result`;
export const lOAD_PATIENTS_CLEAR = `${lOAD_PATIENTS}/clear`;

export const EVENT_LIST = 'events';
export const EVENT_LIST_RESULT = `${EVENT_LIST}/result`;
export const EVENT_LIST_ERROR = `${EVENT_LIST}/error`;

export const GET_DEVICES = 'get-devices';
export const GET_DEVICES_RESULT = `${GET_DEVICES}/result`;
export const GET_DEVICES_ERROR = `${GET_DEVICES}/error`;

export const GET_PATIENT_DEVICES = 'get-patient-devices';
export const GET_PATIENT_DEVICES_RESULT = `${GET_PATIENT_DEVICES}/result`;
export const GET_PATIENT_DEVICES_ERROR = `${GET_PATIENT_DEVICES}/error`;

export const GET_DEVICES_DETAILS = 'get-devices-details';
export const GET_DEVICES_DETAILS_RESULT = `${GET_DEVICES_DETAILS}/result`;
export const GET_DEVICES_DETAILS_ERROR = `${GET_DEVICES_DETAILS}/error`;

export const GET_CAP_DETAILS = 'get-cap-details';
export const GET_CAP_DETAILS_RESULT = `${GET_CAP_DETAILS}/result`;
export const GET_CAP_DETAILS_ERROR = `${GET_CAP_DETAILS}/error`;

export const TASK_HISTORY = 'task-history';
export const TASK_HISTORY_RESULT = `${TASK_HISTORY}/result`;
export const TASK_HISTORY_ERROR = `${TASK_HISTORY}/error`;

export const PATIENT_DETAILS = 'patient-details';
export const PATIENT_DETAILS_RESULT = `${PATIENT_DETAILS}/result`;
export const PATIENT_DETAILS_ERROR = `${PATIENT_DETAILS}/error`;
export const PATIENT_DETAILS_CLEAR = `${PATIENT_DETAILS}/clear`;

export const PATIENT_DASHBOARD_INFO = 'patient-dashboard-info';
export const PATIENT_DASHBOARD_INFO_RESULT = `${PATIENT_DASHBOARD_INFO}/result`;
export const PATIENT_DASHBOARD_INFO_ERROR = `${PATIENT_DASHBOARD_INFO}/error`;

export const PATIENT_BILLABLE_TIME = 'patient-billable-time';
export const PATIENT_BILLABLE_TIME_RESULT = `${PATIENT_BILLABLE_TIME}/result`;
export const PATIENT_BILLABLE_TIME_ERROR = `${PATIENT_BILLABLE_TIME}/error`;

export const ADD_MEDICINE = 'add-medicine';
export const ADD_MEDICINE_RESULT = `${ADD_MEDICINE}/result`;
export const ADD_MEDICINE_ERROR = `${ADD_MEDICINE}/error`;

export const ADD_PATIENT = 'add-patient';
export const ADD_PATIENT_RESULT = `${ADD_PATIENT}/result`;
export const ADD_PATIENT_ERROR = `${ADD_PATIENT}/error`;

export const EDIT_PATIENT = 'edit-patient';
export const EDIT_PATIENT_RESULT = `${EDIT_PATIENT}/result`;
export const EDIT_PATIENT_ERROR = `${EDIT_PATIENT}/error`;

export const GET_MEDICATIONS = 'get-medications';
export const GET_MEDICATIONS_RESULT = `${GET_MEDICATIONS}/result`;
export const GET_MEDICATIONS_ERROR = `${GET_MEDICATIONS}/error`;

export const ASSIGN_CAPS_TO_THE_SCHEDULE = 'assign-caps-to-the-schedule';
export const ASSIGN_CAPS_TO_THE_SCHEDULE_RESULT = `${ASSIGN_CAPS_TO_THE_SCHEDULE}/result`;
export const ASSIGN_CAPS_TO_THE_SCHEDULE_ERROR = `${ASSIGN_CAPS_TO_THE_SCHEDULE}/error`;

export const DELETE_CAP_FROM_SCHEDULE = 'delete-cap-from-schedule';
export const DELETE_CAP_FROM_SCHEDULE_RESULT = `${DELETE_CAP_FROM_SCHEDULE}/result`;
export const DELETE_CAP_FROM_SCHEDULE_ERROR = `${DELETE_CAP_FROM_SCHEDULE}/error`;

export const ADD_SCHEDULE = 'add-schedule';
export const ADD_SCHEDULE_RESULT = `${ADD_SCHEDULE}/result`;
export const ADD_SCHEDULE_ERROR = `${ADD_SCHEDULE}/error`;

export const START_TASK = 'start-task';
export const START_TASK_RESULT = `${START_TASK}/result`;
export const START_TASK_ERROR = `${START_TASK}/error`;

export const CONTINUE_TASK = 'continue-task';
export const CONTINUE_TASK_RESULT = `${CONTINUE_TASK}/result`;
export const CONTINUE_TASK_ERROR = `${CONTINUE_TASK}/error`;

export const END_TASK = 'end-task';
export const END_TASK_RESULT = `${END_TASK}/result`;
export const END_TASK_ERROR = `${END_TASK}/error`;

export const EDIT_SCHEDULE = 'add-schedule';
export const EDIT_SCHEDULE_RESULT = `${EDIT_SCHEDULE}/result`;
export const EDIT_SCHEDULE_ERROR = `${EDIT_SCHEDULE}/error`;

export const DELETE_SCHEDULE = 'delete-schedule';
export const DELETE_SCHEDULE_RESULT = `${DELETE_SCHEDULE}/result`;
export const DELETE_SCHEDULE_ERROR = `${DELETE_SCHEDULE}/error`;

export const GET_TASKS_LIST = 'get-tasks-list';
export const GET_TASKS_LIST_RESULT = `${GET_TASKS_LIST}/result`;
export const GET_TASKS_LIST_ERROR = `${GET_TASKS_LIST}/error`;

export const DELETE_PATIENT = 'delete-patient';
export const DELETE_PATIENT_RESULT = `${DELETE_PATIENT}/result`;
export const DELETE_PATIENT_ERROR = `${DELETE_PATIENT}/error`;

export const DELETE_MEDICATION = 'delete-medication';
export const DELETE_MEDICATION_RESULT = `${DELETE_MEDICATION}/result`;
export const DELETE_MEDICATION_ERROR = `${DELETE_MEDICATION}/error`;

export const DELETE_TASK_HISTORY = 'delete-task-history';
export const DELETE_TASK_HISTORY_RESULT = `${DELETE_TASK_HISTORY}/result`;
export const DELETE_TASK_HISTORY_ERROR = `${DELETE_TASK_HISTORY}/error`;

export const DELETE_CAP = 'delete-cap';
export const DELETE_CAP_RESULT = `${DELETE_CAP}/result`;
export const DELETE_CAP_ERROR = `${DELETE_CAP}/error`;

export const UPDATE_TASKS = 'update-tasks';
export const UPDATE_TASKS_RESULT = `${UPDATE_TASKS}/result`;
export const UPDATE_TASKS_ERROR = `${UPDATE_TASKS}/error`;

export const BLOOD_PRESSURE = `blood_pressure`;
export const BATTERY = `battery`;
export const CONNECTION = `connection`;
export const WEIGHT = 'weight';
export const PULSE_OXIMETER = 'pulse_oximeter';
export const BLUETOOTH = 'bluetooth';
export const BLUETOOTH_CAP = 'Bluetooth cap';
export const WEIGHING_MACHINE = 'Weighing Machine';
export const BLOOD_PRESSURE_MONITOR = 'Blood Pressure Monitor';

export const CLEAR_DEVICE_DATA = 'clear-device-data';
export const SCHEDULE_LIST_CLEAR = 'clear-schedule-list';
export const SCHEDULE_LIST = 'clear-schedules';

export const GET_NOTES = 'get-notes';
export const GET_NOTES_RESULT = `${GET_NOTES}/result`;
export const GET_NOTES_ERROR = `${GET_NOTES}/error`;

export const SEARCH_FOR_NOTE_RECIPIENTS = 'search-for-note-recipients';
export const SEARCH_FOR_NOTE_RECIPIENTS_RESULT = `${SEARCH_FOR_NOTE_RECIPIENTS}/result`;
export const SEARCH_FOR_NOTE_RECIPIENTS_ERROR = `${SEARCH_FOR_NOTE_RECIPIENTS}/error`;

export const ADD_NOTE = 'add-note';
export const ADD_NOTE_RESULT = `${ADD_NOTE}/result`;
export const ADD_NOTE_ERROR = `${ADD_NOTE}/error`;

export const EDIT_NOTE = 'edit-note';
export const EDIT_NOTE_RESULT = `${EDIT_NOTE}/result`;
export const EDIT_NOTE_ERROR = `${EDIT_NOTE}/error`;

export const GET_NOTE = 'get-note';
export const GET_NOTE_RESULT = `${GET_NOTE}/result`;
export const GET_NOTE_ERROR = `${GET_NOTE}/error`;

export const PATIENT_LIST_FOR_SUGGESTION = 'patients-for-suggestion';
export const PATIENT_LIST_FOR_SUGGESTION_RESULT = `${PATIENT_LIST_FOR_SUGGESTION}/result`;
export const PATIENT_LIST_FOR_SUGGESTION_CLEAR = `${PATIENT_LIST_FOR_SUGGESTION}/clear`;

export const GET_CONDITION_LIST = 'get-condition-list';
export const GET_CONDITION_LIST_RESULT = `${GET_CONDITION_LIST}/result`;
export const GET_CONDITION_LIST_ERROR = `${GET_CONDITION_LIST}/error`;

export const ENROLL_PATIENT = 'enroll-patient';
export const ENROLL_PATIENT_RESULT = `${ENROLL_PATIENT}/result`;
export const ENROLL_PATIENT_ERROR = `${ENROLL_PATIENT}/error`;

export const END_ENROLLMENT = 'end-enrollment';
export const END_ENROLLMENT_RESULT = `${END_ENROLLMENT}/result`;
export const END_ENROLLMENT_ERROR = `${END_ENROLLMENT}/error`;

export const GET_READINGS = 'get-readings';
export const GET_READINGS_RESULT = `${GET_READINGS}/result`;
export const GET_READINGS_ERROR = `${GET_READINGS}/error`;

export const ARCHIVE_PATIENT = 'archive-patient';
export const ARCHIVE_PATIENT_RESULT = `${ARCHIVE_PATIENT}/result`;
export const ARCHIVE_PATIENT_ERROR = `${ARCHIVE_PATIENT}/error`;

export const GET_SCHEDULE = 'get-schedule';
export const GET_SCHEDULE_RESULT = `${GET_SCHEDULE}/result`;
export const GET_SCHEDULE_ERROR = `${GET_SCHEDULE}/error`;

export const GET_CAP_PLOT_DATA = 'get-cap-plot-data';
export const GET_CAP_PLOT_DATA_RESULT = `${GET_CAP_PLOT_DATA}/result`;
export const GET_CAP_PLOT_DATA_ERROR = `${GET_CAP_PLOT_DATA}/error`;

export const GET_CAPS_WITHOUT_SCHEDULE = 'get-caps-without-schedule';
export const GET_CAPS_WITHOUT_SCHEDULE_RESULT = `${GET_CAPS_WITHOUT_SCHEDULE}/result`;
export const GET_CAPS_WITHOUT_SCHEDULE_ERROR = `${GET_CAPS_WITHOUT_SCHEDULE}/error`;

export const GET_REMINDERS_DEFAULT_MESSAGES = 'get-reminders-default-messages';
export const GET_REMINDERS_DEFAULT_MESSAGES_RESULT = `${GET_REMINDERS_DEFAULT_MESSAGES}/result`;
export const GET_REMINDERS_DEFAULT_MESSAGES_ERROR = `${GET_REMINDERS_DEFAULT_MESSAGES}/error`;

export const SEND_WELCOME_MESSAGE = 'send-welcome-messages';
export const SEND_WELCOME_MESSAGE_RESULT = `${SEND_WELCOME_MESSAGE}/result`;
export const SEND_WELCOME_MESSAGE_ERROR = `${SEND_WELCOME_MESSAGE}/error`;

export const EDIT_WELCOME_MESSAGE = 'edit-welcome-messages';
export const EDIT_WELCOME_MESSAGE_RESULT = `${EDIT_WELCOME_MESSAGE}/result`;
export const EDIT_WELCOME_MESSAGE_ERROR = `${EDIT_WELCOME_MESSAGE}/error`;

export const DOWNLOAD_NOTES_AND_ACTIONS = 'download-notes-and-actions';
export const DOWNLOAD_NOTES_AND_ACTIONS_RESULT = `${DOWNLOAD_NOTES_AND_ACTIONS}/result`;
export const DOWNLOAD_NOTES_AND_ACTIONS_ERROR = `${DOWNLOAD_NOTES_AND_ACTIONS}/error`;

export const DOWNLOAD_PATIENTS_EVENTS = 'download-patients-events';
export const DOWNLOAD_PATIENTS_EVENTS_RESULT = `${DOWNLOAD_PATIENTS_EVENTS}/result`;
export const DOWNLOAD_PATIENTS_EVENTS_ERROR = `${DOWNLOAD_PATIENTS_EVENTS}/error`;

export const GET_SCHEDULE_NOTIFICATIONS = 'get-scheule-notifications';
export const GET_SCHEDULE_NOTIFICATIONS_RESULT = `${GET_SCHEDULE_NOTIFICATIONS}/result`;
export const GET_SCHEDULE_NOTIFICATIONS_ERROR = `${GET_SCHEDULE_NOTIFICATIONS}/error`;

export const DOWNLOAD_KIT_DATA = 'download-kit-data';
export const DOWNLOAD_KIT_DATA_RESULT = `${DOWNLOAD_KIT_DATA}/result`;
export const DOWNLOAD_KIT_DATA_ERROR = `${DOWNLOAD_KIT_DATA}/error`;

export const GET_AVERAGE_READINGS = 'get-average-readings';
export const GET_AVERAGE_READINGS_RESULT = `${GET_AVERAGE_READINGS}/result`;
export const GET_AVERAGE_READINGS_ERROR = `${GET_AVERAGE_READINGS}/error`;

export const INVITE_PATIENT_TO_PORTAL = 'invite-patient-to-portal';
export const INVITE_PATIENT_TO_PORTAL_RESULT = `${INVITE_PATIENT_TO_PORTAL}/result`;
export const INVITE_PATIENT_TO_PORTAL_ERROR = `${INVITE_PATIENT_TO_PORTAL}/error`;

export const GET_PATIENT_ADHERENCE = 'get-patient-adherence';
export const GET_PATIENT_ADHERENCE_RESULT = `${GET_PATIENT_ADHERENCE}/result`;
export const GET_PATIENT_ADHERENCE_ERROR = `${GET_PATIENT_ADHERENCE}/error`;

export const GET_UNBILLED_EVIDENCES = 'get-unbilled-evidences';
export const GET_UNBILLED_EVIDENCES_RESULT = `${GET_UNBILLED_EVIDENCES}/result`;
export const GET_UNBILLED_EVIDENCES_ERROR = `${GET_UNBILLED_EVIDENCES}/error`;
