import PropTypes from 'prop-types';
import React from 'react';

import { MenuItem } from '../../../components/MenuItem';
import { SideMenu } from '../../../components/SideMenu';
import Strings from '../../../Strings';
import { isCapManager, isDoctor, isSuperUser, isShipper, isPatient } from '../../../utils/userRoles';
import { hasAnyPermissionInGroup, hasPermission, PERMISSIONS, PERMISSION_GROUPS } from '../../../utils/userPermissions';
import { root as devicesRoot } from '../../../pages/ShipperCaps';
import { root as provisioningRoot } from '../../../pages/SuperUser/Provisioning/Provisionings';
import { isEsi } from '../../../utils/portalType';
import { DeviceClass, DeviceTypes } from '../../../utils/deviceTypes';
import { hasFeature } from '../../../pages/Settings/Lab';

const addOrganisation = items => {
  items.push({
    tag: 'organization',
    label: Strings.organization,
    to: '/organization',
    isActive: path => path.startsWith(`organization`),
  });
};
const addProvisioning = items => {
  const children = [];
  let firstAvailableUrl = '';
  Object.values(DeviceTypes)
    .filter(d => d.deviceClass === DeviceClass.cap || d.deviceClass === DeviceClass.hub)
    .forEach(deviceType => {
      if (hasPermission(deviceType.permission)) {
        children.push({
          tag: `${provisioningRoot}-${deviceType.key}`,
          label: deviceType.displayNamePlural,
          to: `/${provisioningRoot}/${deviceType.id}`,
          isActive: path => path.startsWith(`/${provisioningRoot}/${deviceType.id}`),
        });
        if (firstAvailableUrl == '') firstAvailableUrl = `/${provisioningRoot}/${deviceType.id}`;
      }
    });
  children.length > 0 &&
    items.push({
      tag: 'provisioning',
      label: Strings.provisioning,
      to: firstAvailableUrl,
      isActive: path => path.startsWith(`/${provisioningRoot}`),
      expanded: false,
      expandable: true,
      children,
    });
};

const addReport = items => {
  items.push({
    tag: 'report',
    label: Strings.report,
    to: '/report',
    isActive: path => path.startsWith(`/report`),
  });
};
const addDashboardDoctor = items => {
  items.push({
    tag: 'dashboard',
    isActive: path => path.startsWith('/dashboard'),
    label: Strings.dashboard,
    to: '/dashboard',
  });
};
const addDashboardPatient = (items, patientId) => {
  items.push({
    tag: 'dashboard',
    isActive: path => path.startsWith(`/dashboard/${patientId}`),
    label: Strings.dashboard,
    to: '/user-dashboard',
  });
};
const addDashboardGeneral = items => {
  items.push({
    tag: 'dashboard',
    isActive: path => path.startsWith('/dashboard-devices'),
    label: Strings.dashboard,
    to: '/dashboard-devices',
  });
};
const addDashboardRpm = items => {
  items.push({
    tag: 'dashboard',
    isActive: path => path.startsWith('/dashboard-rpm'),
    label: Strings.dashboard,
    to: '/dashboard-rpm',
  });
};
const addStudies = items => {
  items.push({
    tag: 'studies',
    isActive: path => path.startsWith('/studies') || path.startsWith('/study'),
    label: Strings.studies,
    to: '/studies',
  });
};
const addPatientsEsi = items => {
  const children = [];
  let firstAvailableUrl = '';
  if (hasPermission(PERMISSIONS.PATIENTS_ADHERENCE_ACTIVE)) {
    const url = '/esi_patients/active';
    children.push({
      tag: 'patients-active',
      label: Strings.esi.active,
      to: url,
      isActive: path => path.startsWith(url),
    });
    if (firstAvailableUrl == '') firstAvailableUrl = url;
  }
  if (hasPermission(PERMISSIONS.PATIENTS_ADHERENCE_PENDING)) {
    const url = '/esi_patients/pending';
    children.push({
      tag: 'patients-pending',
      label: Strings.esi.pending,
      to: url,
      isActive: path => path.startsWith(url),
    });
    if (firstAvailableUrl == '') firstAvailableUrl = url;
  }
  if (hasPermission(PERMISSIONS.PATIENTS_ADHERENCE_TERMED)) {
    const url = '/esi_patients/termed';
    children.push({
      tag: 'patients-termed',
      label: Strings.esi.termed,
      to: url,
      isActive: path => path.startsWith(url),
    });
    if (firstAvailableUrl == '') firstAvailableUrl = url;
  }
  children.length > 0 &&
    items.push({
      tag: 'patients',
      label: Strings.patients,
      to: firstAvailableUrl,
      isActive: path => path.startsWith('/esi_patients/'),
      expanded: false,
      expandable: true,
      children,
    });
};
const addMedications = (items, patientId) => {
  items.push({
    tag: 'medications',
    label: Strings.medications,
    to: `/patient/${patientId}/medications`,
    isActive: path => path.startsWith(`/patient/${patientId}/medications`),
  });
};
const addCalendar = items => {
  items.push({
    tag: 'calendar',
    label: Strings.calendar,
    to: '/calendar',
  });
};
const addHistory = items => {
  items.push({
    tag: 'history',
    label: Strings.history,
    to: '/history',
    path: path => path.startsWith(`/history`),
  });
};
const addCapPatients = items => {
  const children = [];
  let firstAvailableUrl = '';
  if (hasPermission(PERMISSIONS.PATIENTS_RPM_PATIENT_LIST)) {
    const url = '/cap-patients/list';
    children.push({
      tag: 'patient-list',
      label: Strings.patients,
      to: url,
      isActive: path => path.startsWith(url),
    });
    if (firstAvailableUrl == '') firstAvailableUrl = url;
  }
  if (hasPermission(PERMISSIONS.PATIENTS_RPM_PATIENT_LIST)) {
    const url = '/cap-patients/readings';
    children.push({
      tag: 'readings',
      label: Strings.monitoring,
      to: url,
      isActive: path => path.startsWith(url),
    });
    if (firstAvailableUrl == '') firstAvailableUrl = url;
  }
  if (hasPermission(PERMISSIONS.PATIENTS_RPM_PATIENT_LIST)) {
    const url = '/cap-patients/cohorts';
    children.push({
      tag: 'cohorts',
      label: Strings.cohorts,
      to: url,
      isActive: path => path.startsWith(url),
    });
    if (firstAvailableUrl == '') firstAvailableUrl = url;
  }
  if (hasPermission(PERMISSIONS.PATIENTS_RPM_PATIENT_LIST)) {
    const url = '/cap-patients/patient-list-files';
    children.push({
      tag: 'pateint-files',
      label: Strings.patientListFiles,
      to: url,
      isActive: path => path.startsWith(url),
    });
    if (firstAvailableUrl == '') firstAvailableUrl = url;
  }

  children.length > 0 &&
    items.push({
      tag: 'patients',
      label: Strings.enrollAndMonitor,
      to: firstAvailableUrl,
      isActive: path => path.startsWith('/cap-patients/'),
      expanded: false,
      expandable: true,
      children,
    });
};
const addAlerts = items => {
  const children = [];
  true &&
    children.push({
      tag: 'alerts-current',
      label: Strings.alerts,
      to: '/alerts/current',
      isActive: path => path.startsWith('/alerts/current'),
    });
  true &&
    children.push({
      tag: 'alerts-configurations',
      label: Strings.configuration,
      to: '/alerts/configuration',
      isActive: path => path.startsWith('/alerts/configuration'),
    });
  children.length > 0 &&
    items.push({
      tag: 'alerts',
      label: Strings.alerts,
      to: '/alerts/current',
      isActive: path => path.startsWith('/alerts/'),
      expanded: false,
      expandable: true,
      children,
    });
};
const addBilling = items => {
  items.push({
    tag: 'billing',
    label: Strings.billing.billing,
    to: '/billings',
    isActive: path => path.startsWith('/billings'),
  });
};
const addMessages = items => {
  const children = [];
  let firstAvailableUrl = '';
  if (hasPermission(PERMISSIONS.MESSAGES_INBOX)) {
    const url = '/messages/inbox';
    children.push({
      tag: 'messages-inbox',
      label: Strings.messages.inbox,
      to: url,
      isActive: path => path.startsWith(url),
    });
    if (firstAvailableUrl == '') firstAvailableUrl = url;
  }
  if (hasPermission(PERMISSIONS.MESSAGES_ARCHIVED)) {
    const url = '/messages/archived';
    children.push({
      tag: 'messages-archived',
      label: Strings.messages.archived,
      to: url,
      isActive: path => path.startsWith(url),
    });
    if (firstAvailableUrl == '') firstAvailableUrl = url;
  }
  if (hasPermission(PERMISSIONS.MESSAGES_SEND_MESSAGES)) {
    const url = '/messages/new';
    children.push({
      tag: 'messages-new',
      label: Strings.messages.new,
      to: url,
      isActive: path => path.startsWith(url),
    });
    if (firstAvailableUrl == '') firstAvailableUrl = url;
  }
  if (hasPermission(PERMISSIONS.MESSAGES_PREFABS)) {
    const url = '/messages/prefabs';
    children.push({
      tag: 'messages-prefabs',
      label: Strings.messages.prefabReplies,
      to: url,
      isActive: path => path.startsWith(url),
    });
    if (firstAvailableUrl == '') firstAvailableUrl = url;
  }

  children.length > 0 &&
    items.push({
      tag: 'messages',
      label: Strings.messages.messages,
      to: firstAvailableUrl,
      isActive: path => path.startsWith('/messages/'),
      expanded: false,
      expandable: true,
      children,
    });
};
const addAdmin = items => {
  items.push({
    tag: 'OrganizationManagerAdmin',
    label: Strings.adminTab,
    to: '/admins',
    isActive: path => path.startsWith('/admins'),
  });
};
const addCapManager = items => {
  items.push({
    tag: 'OrganizationManagerCapManager',
    label: Strings.capManager,
    to: '/cap-managers',
    isActive: path => path.startsWith('/cap-managers'),
  });
};

const addDevices = items => {
  const children = [];
  let firstAvailableUrl = '';

  Object.values(DeviceTypes).forEach(deviceType => {
    if (hasPermission(deviceType.permission)) {
      children.push({
        tag: `devices-${deviceType.key}`,
        label: deviceType.displayNamePlural,
        to: `/devices/${deviceType.id}`,
        isActive: path => path.startsWith(`/devices/${deviceType.id}`),
      });
      if (firstAvailableUrl == '') firstAvailableUrl = `/devices/${deviceType.id}`;
    }
  });

  children.length > 0 &&
    items.push({
      tag: 'devices',
      label: Strings.deviceManagement,
      to: firstAvailableUrl,
      isActive: path => path.startsWith(`/${devicesRoot}/`),
      expanded: false,
      expandable: true,
      children,
    });
};

const addHealthCenter = items => {
  items.push({
    tag: 'health-center',
    key: 'health-center',
    label: Strings.healthCenter,
    isActive: path => path.startsWith('/health-center'),
    to: '/health-center',
  });
};

const addRpmMedications = items => {
  items.push({
    tag: 'medications',
    key: 'medications',
    label: Strings.medications,
    isActive: path => path.startsWith('/patient-medications'),
    to: '/patient-medications',
  });
};
const addRpmDevices = items => {
  items.push({
    tag: 'devices',
    key: 'devices',
    label: Strings.devices,
    isActive: path => path.startsWith('/patient-devices'),
    to: '/patient-devices',
  });
};

const addSettingsX = items => {
  items.push({
    tag: 'settings',
    key: 'right-menu-settings',
    label: Strings.settings,
    isActive: path =>
      path.startsWith('/esi-patient-settings') || path.startsWith('/user-settings') || path.startsWith('/settings'),
    to:
      // TODO, isPatinet is undefined, seems that /esi-patient-settings route is unavailable via sidebar
      isPatient() && isEsi()
        ? '/esi-patient-settings'
        : isDoctor() || isCapManager() || isSuperUser() || isShipper()
        ? '/user-settings'
        : '/settings',
  });
};
const addSupportX = items => {
  items.push({
    tag: 'support',
    key: 'support',
    label: Strings.support,
    isActive: path => path.startsWith('/support'),
    to: '/support',
  });
};

const addSystemConfiguration = items => {
  items.push({
    tag: 'system-configuration',
    key: 'system-configuration',
    label: Strings.systemConfiguration,
    isActive: path => path.startsWith('/system-configuration'),
    to: '/system-configuration/external-api-logs',
  });
};

export const getSidebarItems = userId => {
  const items = [];

  hasAnyPermissionInGroup(PERMISSION_GROUPS.DASHBOARD_ADHERENCE) && isDoctor() && addDashboardDoctor(items);
  hasPermission(PERMISSIONS.DASHBOARD_PATIENT) && addDashboardPatient(items, userId);
  hasAnyPermissionInGroup(PERMISSION_GROUPS.DASHBOARD_GENERAL) && addDashboardGeneral(items);
  hasAnyPermissionInGroup(PERMISSION_GROUPS.DASHBOARD_RPM) && addDashboardRpm(items);

  hasPermission(PERMISSION_GROUPS.STUDY) && addStudies(items);

  hasAnyPermissionInGroup(PERMISSION_GROUPS.PATIENTS_RPM) && addCapPatients(items);
  hasAnyPermissionInGroup(PERMISSION_GROUPS.BILLING) && addBilling(items);

  hasAnyPermissionInGroup(PERMISSION_GROUPS.ORGANIZATIONS) && addOrganisation(items);
  hasAnyPermissionInGroup(PERMISSION_GROUPS.PROVISIONING) && addProvisioning(items);

  hasPermission(PERMISSIONS.ADMIN) && addAdmin(items);
  hasPermission(PERMISSIONS.CAP_MANAGER) && addCapManager(items);

  hasAnyPermissionInGroup(PERMISSION_GROUPS.DEVICE_MANAGEMENT) && addDevices(items);

  hasPermission(PERMISSIONS.REPORT) && addReport(items);

  hasAnyPermissionInGroup(PERMISSION_GROUPS.PATIENTS_ADHERENCE) && addPatientsEsi(items);

  hasPermission(PERMISSIONS.MEDICATIONS) && addMedications(items, userId);
  hasPermission(PERMISSIONS.CALENDAR) && addCalendar(items);
  hasPermission(PERMISSIONS.HISTORY) && addHistory(items);
  hasAnyPermissionInGroup(PERMISSION_GROUPS.MESSAGES) && addMessages(items);
  hasPermission(PERMISSIONS.ALERTS) && addAlerts(items);

  hasPermission(PERMISSIONS.RPM_PATIENT_PAGE_HEALTH_CENTER) && addHealthCenter(items);
  hasPermission(PERMISSIONS.RPM_PATIENT_PAGE_MEDICATIONS) && addRpmMedications(items);
  hasPermission(PERMISSIONS.RPM_PATIENT_PAGE_DEVICES) && addRpmDevices(items);

  hasAnyPermissionInGroup(PERMISSION_GROUPS.SETTINGS) && addSettingsX(items);
  hasAnyPermissionInGroup(PERMISSION_GROUPS.SUPPORT) && addSupportX(items);

  hasPermission(PERMISSIONS.SYSTEM_CONFIGURATION) && addSystemConfiguration(items);
  hasFeature('Analytics dashboard') && !isSuperUser() && !isShipper() && items.push({ tag: 'analytics', label: Strings.analytics, to: '/analytics' });
  return items;
};
export function Sidebar(props) {
  const addLogout = () => {
    const { onLogOut } = props;
    return {
      tag: 'logout',
      label: Strings.signOut,
      onClick: onLogOut,
    };
  };

  const [items, setItems] = React.useState(getSidebarItems(props.patientId).concat([addLogout()]));

  const onExpand = params => {
    const newItems = [...items];
    const item = newItems.find(i => i.tag === params.tag);
    if (item !== undefined) {
      item.expanded = params.isExpanding;
      setItems(newItems);
    }
  };

  const { leftMenuVisible, location, onChange } = props;

  const list = [];
  items.forEach((data, index) => {
    list.push(<MenuItem key={data.tag} visible={leftMenuVisible} {...data} isChildNode={false} onExpand={onExpand} />);
    if (data.children !== undefined)
      data.children.forEach((chData, chIndex) => {
        data.expanded &&
          list.push(
            <MenuItem
              key={chData.tag}
              visible={leftMenuVisible}
              {...chData}
              isChildNode
              isParentExpanded={data.expanded}
              expandable={false}
              expanded={false}
            />,
          );
      });
  });

  return (
    <SideMenu visible={leftMenuVisible} path={location.pathname} onChange={onChange}>
      {list}
    </SideMenu>
  );
}

export default Sidebar;

Sidebar.propTypes = {
  visible: PropTypes.bool,
  location: PropTypes.object,
  onChange: PropTypes.func,
  onLogOut: PropTypes.func,
  patientId: PropTypes.any,
};
