import { CHANGE_LAB_FEATURE_STATE } from './actions';
// 'Full-height Table' - not yet ready
export const allFeatures = ['Infinite scroll Table', 'RPM Setup Workflow', 'Dashboard configuration', 'Analytics dashboard'];

export const LabReducer = (
  state = {
    features: allFeatures.map(f => {
      return { name: f, selected: false };
    }),
  },
  action,
) => {
  switch (action.type) {
    case CHANGE_LAB_FEATURE_STATE:
      return {
        ...state,
        features: state.features.map(f => {
          if (f.name === action.feature) {
            return { name: f.name, selected: !f.selected };
          }
          return f;
        }),
      };
    default:
      return state;
  }
};
