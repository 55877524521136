import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Wizard, { wizardTypes } from '../../containers/Modal/Wizard';
import Strings from '../../Strings';
import { AsyncSelectField, TextArea } from '../../containers/Form';
import { closeModal } from '../../actions/modal';
import { actions } from '../../pages/SuperUser/Patients/redux/actions';
import {
  ADD_NOTE_RESULT,
  EDIT_NOTE_RESULT,
  GET_NOTE_RESULT,
  SEARCH_FOR_NOTE_RECIPIENTS_ERROR,
} from '../../pages/SuperUser/Patients/redux/constants';
import { SelectedOptions, SelectedOptionsType } from '../../components/Select/Multiselect';
import { notificationActions } from '../../components/Notification/redux/actions';
import PropTypes from 'prop-types';

const AddEditNoteModal = props => {
  const [recipients, setRecipients] = useState([]);
  const [originalRecipients, setOriginalRecipients] = useState([]);
  const [originalNote, setOriginalNote] = useState('');
  const [note, setNote] = useState('');
  const [canSend, setCanSend] = useState(false);
  const [actionCompleted, setActionCompleted] = useState(props.data.actionMode ? false : undefined);

  const editMode = props.data?.noteId !== undefined;

  useEffect(() => {
    if (editMode) {
      props.getNote(props.data.noteId).then(response => {
        if (response && response.type === GET_NOTE_RESULT) {
          setNote(response.response.content);
          setOriginalNote(response.response.content);

          if (response.response.action_completed !== undefined) {
            setActionCompleted(response.response.action_completed);
          }

          if (response.response.viewable_by) {
            const newRecipients = response.response.viewable_by.map(r => ({
              ...r,
              value: r.uuid,
              label: `${r.first_name} ${r.last_name}`,
            }));

            setOriginalRecipients(newRecipients);
            setRecipients(newRecipients);
          }
        } else {
          props.showNotification(response.response?.data?.error?.message);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!editMode) {
      setCanSend(note.length > 0);
    } else {
      setCanSend(
        note.length > 0 && (note !== originalNote || JSON.stringify(recipients) !== JSON.stringify(originalRecipients)),
      );
    }
  }, [note, recipients]);

  const loadOptions = _.debounce((value, callback) => {
    if (value?.length < 3) {
      return;
    }

    props.searchForRecipients(value).then(resp => {
      callback(() => {
        if (resp.type === SEARCH_FOR_NOTE_RECIPIENTS_ERROR) {
          return [];
        }

        const people = resp.response?.data
          ? resp.response.data.map(e => {
              return {
                ...e,
                value: e.uuid,
                label: `${e.first_name} ${e.last_name}`,
              };
            })
          : [];

        return people.filter(d => !recipients.some(p => p.uuid === d.uuid));
      });
    });
  }, 1000);

  const notePage = (
    <>
      <AsyncSelectField
        id="recipients"
        name="recipients"
        placeholder={Strings.patient_medications.enter3Chars}
        loadOptions={loadOptions}
        onChange={e => setRecipients(recipients.concat(e))}
        cacheOptions={false}
        clearValueAfterSelection
        menuPositionFixed={false}
        isRequired
      />

      {recipients && recipients.length > 0 && (
        <SelectedOptions
          items={recipients}
          onRemove={option => setRecipients(recipients.filter(r => r.uuid !== option.uuid))}
          onClear={() => setRecipients([])}
          type={SelectedOptionsType.cloud}
        />
      )}
      <TextArea name="note" id="note" type="text" value={note} onChange={e => setNote(e.target.value)} rows={10} />
    </>
  );

  let title = '';

  if (props.data.actionMode && editMode) {
    title = Strings.editAction;
  } else if (props.data.actionMode && !editMode) {
    title = Strings.addOpenAction;
  } else if (!props.data.actionMode && editMode) {
    title = Strings.editNote;
  } else {
    title = Strings.addNote;
  }

  const pages = [
    {
      id: 'note',
      title: title,
      content: notePage,
      canGoNext: canSend,
      nextButton: { text: Strings.send },
    },
  ];

  const onSend = () => {
    const request = {
      content: note,
      viewable_by: recipients.map(r => r.uuid),
    };

    if (props.data.actionMode) {
      request.action_completed = actionCompleted;
    }

    if (editMode) {
      props.editNote(props.data.noteId, request).then(response => {
        if (response && response.type === EDIT_NOTE_RESULT) {
          props.getNotes();
          props.closeModal();
        } else {
          props.showNotification(response.response?.data?.error?.message);
        }
      });
    } else {
      props.addNote(props.data.patientId, request).then(response => {
        if (response && response.type === ADD_NOTE_RESULT) {
          props.getNotes();
          props.closeModal();
        } else {
          props.showNotification(response.response?.data?.error?.message);
        }
      });
    }
  };

  return (
    <Wizard
      name="add-note"
      id={props.modalId}
      pages={pages}
      isTopModal={props.isTopModal}
      type={wizardTypes.floatingModal}
      onSubmit={onSend}
    />
  );
};

PropTypes.AddEditNoteModal = {
  data: PropTypes.shape({
    noteId: PropTypes.string,
    patientId: PropTypes.string.isRequired,
  }),
  isTopModal: PropTypes.bool,
  modalId: PropTypes.any,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  searchForRecipients: search => dispatch(actions.searchForNoteRecipients(search)),
  closeModal: () => dispatch(closeModal('add-note'), {}, ownProps.modalId),
  addNote: (patientId, data) => dispatch(actions.addNote(patientId, data)),
  editNote: (noteId, data) => dispatch(actions.editNote(noteId, data)),
  getNote: noteId => dispatch(actions.getNote(noteId)),
  showNotification: message => dispatch(notificationActions.show(message)),
  getNotes: () => dispatch(actions.getNotes(ownProps.data.patientId)),
});

export default connect(null, mapDispatchToProps)(AddEditNoteModal);
