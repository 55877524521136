import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { PageHeader } from '../../../components/PageHeader';
import Strings from '../../../Strings';
import { Button, HEADER_BUTTON_DARK_BLUE } from '../../../components/PageHeader/Button';
import { openModalAction } from '../../../actions/modal';
import { actions } from './redux/actions';
import { ADD_PREFAB_REPLY } from './redux/constants';
import { Tab, Tabs } from '../../../components/Tabs';
import { prefabType } from './PrefabReplies';
import { TextInput } from '../../../components/PageHeader/TextInput';

function PrefabWindow(props) {
  const tabs = [
    { label: Strings.all },
    { label: Strings.messages.shared, type: prefabType.shared },
    { label: Strings.messages.private, type: prefabType.private },
  ];
  const [searchString, setSearchString] = useState();

  const blurHandler = event => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      props.onBlur();
    }
  };

  const onKeyDown = e => {
    if (e.key === 'Escape') {
      props.onBlur();
    }
  };

  return (
    <div className="prefab-window" onBlur={blurHandler} onKeyDown={onKeyDown} tabIndex={1}>
      <PageHeader
        left={Strings.messages.prefabReplies}
        right={
          <React.Fragment>
            <Button
              class={HEADER_BUTTON_DARK_BLUE}
              onClick={() => props.onNavigate('/messages/prefabs')}
              title={Strings.manage}
            />
            <div className="button-container">
              <Button class="add-prefab-button plus-button" onClick={props.addPrefabReply} />
            </div>
          </React.Fragment>
        }
      />
      <Tabs>
        {tabs.map(t => (
          <Tab label={t.label} key={t.label}>
            <div className="message-textbox__suggestions">
              <TextInput
                className="search"
                placeholder={Strings.search}
                onChange={e => setSearchString(e.target.value?.toLowerCase())}
              />
              <OverlayScrollbarsComponent
                defer
                className="suggestions-scrolled-container"
                options={{ scrollbars: { autoHide: 'leave', autoHideDelay: '100' } }}
              >
                <div className="suggestion-flex">
                  {props.prefabReplies &&
                    props.prefabReplies
                      .filter(pr => pr.type === t.type || !t.type)
                      .filter(
                        r =>
                          !searchString ||
                          r.title.toLowerCase().includes(searchString) ||
                          r.full_txt.toLowerCase().includes(searchString) ||
                          r.shortcut.toLowerCase().includes(searchString),
                      )
                      .map(p => (
                        <div
                          key={`prefab-${p.shortcut}`}
                          className="message-textbox__suggestions__item"
                          onClick={() => props.onPrefabChosen(p.full_txt)}
                        >
                          <div
                            className="tag-suggestion"
                            data-tooltip-content={Strings.messages.addReply}
                            data-tooltip-id="tooltip"
                          >
                            <div className="tag-suggestion-header">
                              <div className="tag-suggestion-header-title">{p.title}</div>
                              <div className="tag-suggestion-header-shortcut">#{p.shortcut}</div>
                            </div>
                            {p.full_txt}
                          </div>
                        </div>
                      ))}
                </div>
              </OverlayScrollbarsComponent>
            </div>
          </Tab>
        ))}
      </Tabs>
    </div>
  );
}

PrefabWindow.PropTypes = {
  prefabs: PropTypes.arrayOf({
    shortcut: PropTypes.string,
    full_txt: PropTypes.string,
    title: PropTypes.string,
  }),
  addPrefabReply: PropTypes.func,
  onPrefabChosen: PropTypes.func,
  onBlur: PropTypes.func,
  onNavigate: PropTypes.func,
};

const mapStateToProps = state => {
  const { messages } = state.superUser;
  return {
    prefabReplies: messages?.prefabReplies,
  };
};

const mapDispatchToProps = dispatch => ({
  onNavigate: path => dispatch(push(path)),
  addPrefabReply: () =>
    dispatch(
      openModalAction('edit-prefab-reply', {
        action: actions.addPrefabReply,
        actionType: ADD_PREFAB_REPLY,
      }),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrefabWindow);
