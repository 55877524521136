import { API_CALL } from '../../../middleware/api';
import {
  GET_DEVICES_UNASSIGNED_TO_KIT,
  KIT_DEVICES,
  KITS_LIST,
  CREATE_KIT,
  ASSIGN_DEVICE_TO_KIT,
  CLEAR_DATA,
  UNASSIGN_DEVICE_FROM_KIT,
  DELETE_KIT,
  UPDATE_KIT,
  GET_PATIENT_KIT_INFO,
  ATTACH_PATIENT_TO_KIT,
  GET_KIT_DETAILS,
  CHANGE_KIT_ADMINISTRATIVE_STATUS,
  GET_KIT_STATUS_HISTORY,
} from './constants';

export const actions = {
  getDevicesUnassignedToKit: pageRequest => {
    return {
      [API_CALL]: {
        method: 'GET',
        endpoint: `/kits/devices/unassigned`,
        params: pageRequest,
      },
      type: GET_DEVICES_UNASSIGNED_TO_KIT,
    };
  },
  getKitDevices: (kitId, pageRequest) => ({
    [API_CALL]: {
      endpoint: `/kits/kit/${kitId}/devices/list`,
      method: 'get',
      params: { ...pageRequest },
    },
    type: KIT_DEVICES,
  }),
  getKits: request => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/kits/list',
      params: request,
    },
    type: KITS_LIST,
  }),
  createKit: data => ({
    [API_CALL]: {
      method: 'POST',
      endpoint: '/kits/kit',
      data,
    },
    type: CREATE_KIT,
  }),
  assignDeviceToKit: (kitId, data) => ({
    [API_CALL]: {
      method: 'PUT',
      endpoint: `/kits/kit/${kitId}/devices/assign`,
      data,
    },
    type: ASSIGN_DEVICE_TO_KIT,
  }),
  unassignDeviceFromKit: (kitId, deviceId, callAfterSuccess = []) => ({
    [API_CALL]: {
      endpoint: `/kits/kit/${kitId}/devices/unassign`,
      method: 'PUT',
      data: { device_id: deviceId },
      callAfterSuccess,
    },
    type: UNASSIGN_DEVICE_FROM_KIT,
  }),
  deleteKit: (kitId, callAfterSuccess = []) => ({
    [API_CALL]: {
      endpoint: `/kits/kit/${kitId}/delete`,
      method: 'DELETE',
      callAfterSuccess,
    },
    type: DELETE_KIT,
  }),
  updateKit: data => ({
    [API_CALL]: {
      method: 'PUT',
      endpoint: `/kits/kit`,
      data,
    },
    type: UPDATE_KIT,
  }),
  getPatientKitInfo: patientId => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: `/kits/patient/${patientId}/instance`,
    },
    type: GET_PATIENT_KIT_INFO,
  }),
  attachKitToPatient: (kitId, patientId) => ({
    [API_CALL]: {
      method: 'PUT',
      endpoint: `/kits/kit/${kitId}/assign`,
      data: { patient_id: patientId },
    },
    type: ATTACH_PATIENT_TO_KIT,
  }),

  getKitDetails: kitId => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: `/kits/kit/${kitId}/details`,
    },
    type: GET_KIT_DETAILS,
  }),

  getKitStatusHistory: kitId => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: `/kits/kit/${kitId}/state/history`,
    },
    type: GET_KIT_STATUS_HISTORY,
  }),

  changeKitAdministrativeState: (kitId, data) => ({
    [API_CALL]: {
      method: 'POST',
      endpoint: `/kits/kit/${kitId}/state/administrative`,
      data,
    },
    type: CHANGE_KIT_ADMINISTRATIVE_STATUS,
  }),

  clearData: () => ({
    type: CLEAR_DATA,
  }),
};
