import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import Strings from '../../../Strings';
import { actions } from './redux/actions';
import { GET_CAP_PLOT_DATA_RESULT, GET_SCHEDULE_RESULT } from './redux/constants';
import { PageHeader } from '../../../components/PageHeader';
import Select from '../../../components/Select';
import CapTakesPlot from '../../ShipperCapsDetails/CapTakesPlot';
import { getCapChargeText, signalRender } from '../../../utils';
import MedicationStats from './Dashboard/MedicationStats';
import { isBrowser, isDesktop } from 'react-device-detect';
import { PatientDashboardAction } from '../../../actions/patient-dashboard';

function AdherenceGraph(props) {
  const { patientId } = props;

  const [medications, setMedications] = useState();
  const [plotScheduleData, setPlotScheduleData] = useState();
  const [plotData, setPlotData] = useState();
  const [plotDateRange, setPlotDateRange] = useState({});
  const [selectedMedId, setSelectedMedId] = useState();
  const [reminders, setReminders] = useState([]);

  useEffect(() => {
    props.getMedications(props.patientId);
    props.loadDashboard(props.patientId).then(r => {
      setReminders(r.response?.reminders);
    });
  }, [patientId]);

  useEffect(() => {
    const { patientMedications } = props;
    if (_.isEqual(patientMedications, medications) && props.selectedMed === selectedMedId) {
      return;
    }

    setMedications(patientMedications);
    if (!patientMedications) {
      return;
    }
    const meds = patientMedications;
    const medIdFromProps = props.selectedMed || props.selectedMedId;
    if (meds && meds.length > 0) {
      if (!meds.some(m => m.id === medIdFromProps)) {
        setSelectedMedId(props.includeSchedulesWithNoCap ? meds[0].id : meds.find(m => m.capId)?.id);
      } else {
        setSelectedMedId(medIdFromProps);
      }
    } else {
      setSelectedMedId(null);
    }
  }, [props.patientMedications, props.selectedMed]);

  useEffect(() => {
    if (!selectedMedId || !medications) {
      return;
    }
    getScheduleDetails();
  }, [selectedMedId]);

  const getScheduleDetails = () => {
    setPlotScheduleData(null);

    //props.replacePath(`${window.location.pathname}?medId=${selectedMedId}`);

    props.getSchedule(props.patientId, selectedMedId).then(r => {
      if (r.type === GET_SCHEDULE_RESULT) {
        const scheduleData = r.response;
        if (scheduleData && scheduleData.schedules) {
          scheduleData.schedules = scheduleData.schedules.filter(s => s.hours);
        }
        setPlotScheduleData(scheduleData);
        if (props.onScheduleDataLoaded) {
          props.onScheduleDataLoaded(scheduleData);
        }
        const end = r.response.end_date ? moment(r.response.end_date * 1000) : moment();
        const start = moment(r.response.start_date * 1000);
        const dateRange = {
          start,
          end: end < start ? start.clone().add(1, 'month') : end > moment() ? moment() : end,
        };
        setPlotDateRange(dateRange);
        loadPlot(dateRange);

        if (scheduleData) {
          const request = {
            offset: 0,
            search: '',
            limit: 1,
            activityType: 'all',
          };

          if (scheduleData.cap_id && !props.patientVersion) {
            props.getCapDetails(scheduleData.cap_id, request);
          }
        }
      }
      return r;
    });
  };

  const loadPlot = selectedDateRange => {
    const request = {
      startDate: selectedDateRange.start.format(),
      endDate: selectedDateRange.end.format(),
    };

    setPlotData(null);

    props.getCapPlotData(props.patientId, selectedMedId, request).then(r => {
      if (r.type === GET_CAP_PLOT_DATA_RESULT) {
        setPlotData(r.response);
      }
    });
  };

  const onMedChosen = option => {
    if (option.value !== selectedMedId) {
      setPlotData();
      setSelectedMedId(option.value);
    }
  };

  return medications?.length > 0 && (medications.some(m => m.capId) || props.includeSchedulesWithNoCap) ? (
    <div className="medication-details-page" style={{ paddingTop: 0 }}>
      {!props.patientVersion && isDesktop && (
        <PageHeader
          right={
            <React.Fragment>
              {medications && medications.length > 0 && !props.hideMedSelect && (
                <Select
                  data={medications
                    .filter(med => med.capId || props.includeSchedulesWithNoCap)
                    .map(m => ({ value: m.id, label: m.medicationName.substring(0, 50) }))}
                  onChange={onMedChosen}
                  value={selectedMedId}
                />
              )}
              {/* {plotDateRanges && plotDateRanges.length > 0 && (
              <Select data={plotDateRanges} onChange={onDateRangeChanged} value={selectedPlotDateRange?.value} />
            )} */}
            </React.Fragment>
          }
          left={
            <div className="legendContainer">
              {plotScheduleData?.cap_id && (
                <React.Fragment>
                  <div className="label greyLabel">{`${Strings.capId}: ${plotScheduleData?.cap_id}`}</div>
                  <div className="hr20"></div>
                  <div className="label greyLabel">
                    {Strings.battery.battery}: {getCapChargeText(props.patientCapDetails?.battery)}
                  </div>
                  <div className="hr20"></div>
                  {props.patientCapDetails?.version !== 'ble' && (
                    <React.Fragment>
                      <div className="label greyLabel">
                        {Strings.signal}: {signalRender(props.patientCapDetails?.signal_strength)}
                      </div>
                      <div className="hr20"></div>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
              <div className="label greyLabel">{`${Strings.avgTimeDelta}: ${plotData?.avg_time_delta >= 0 ? '+' : '-'}${
                plotData?.avg_time_delta || plotData?.avg_time_delta === 0
                  ? `${plotData?.avg_time_delta} ${Strings.min}`
                  : '-'
              }`}</div>
              <div className="hr20"></div>
              <div className="label greyLabel">{`${Strings.adherenceForPeriod}: ${
                plotData?.adherence || plotData?.adherence === 0 ? `${plotData?.adherence}%` : '-'
              }`}</div>
            </div>
          }
        />
      )}
      {props.patientVersion && isBrowser && (
        <PageHeader left={medications.find(m => m.id === selectedMedId)?.medicationName} />
      )}
      {plotData && props.patientVersion && (
        <div className="mini-adherence-dashboard">
          <MedicationStats
            data={plotData}
            nextTake={reminders.find(r => r.id === selectedMedId)}
            medication={medications.find(m => m.id === selectedMedId)}
          />
        </div>
      )}
      {plotData ? (
        <div className="capEventsContainer">
          <CapTakesPlot
            plotData={plotData}
            plotScheduleData={plotScheduleData}
            graphMinDate={plotDateRange.start}
            graphMaxDate={plotDateRange.end}
            plotResizeTimestamp={props.plotResizeTimestamp}
          />
        </div>
      ) : (
        <div className="boxStatsWithGraph-graph noStats">
          <div className="empty-row-container">
            <div className="empty-row-inner">{Strings.noDataAvailable}</div>
          </div>
        </div>
      )}
    </div>
  ) : (
    <React.Fragment />
  );
}

AdherenceGraph.propTypes = {
  getMedications: PropTypes.func,
  patientMedications: PropTypes.array,
  onAddMedicine: PropTypes.func,
  patientId: PropTypes.any,
  getSchedule: PropTypes.func,
  timezone: PropTypes.string,
  getCapPlotData: PropTypes.func,
  selectedMedId: PropTypes.number,
  replacePath: PropTypes.func,
  patientCapDetails: PropTypes.any,
  getCapDetails: PropTypes.func,
  loadDashboard: PropTypes.func,
  getDevices: PropTypes.func,
  includeSchedulesWithNoCap: PropTypes.bool,
  patientVersion: PropTypes.bool,
  hideMedSelect: PropTypes.bool,
};

AdherenceGraph.defaultProps = { includeSchedulesWithNoCap: false, patientVersion: false, hideMedSelect: false };

const mapStateToProps = state => {
  const urlParams = new URLSearchParams(window.location.search);
  const { patients } = state.superUser;
  return {
    patientMedications: patients?.patientMedications?.medications,
    timezone: state.auth?.profile?.preferences?.timezone,
    selectedMedId: parseInt(urlParams.get('medId'), 10),
    patientCapDetails: patients?.patientCapDetails,
  };
};

const mapDispatchToProps = dispatch => ({
  getMedications: patientId => dispatch(actions.getMedications(patientId)),
  getSchedule: (patientId, scheduleId) => dispatch(actions.getSchedule(patientId, scheduleId)),
  getCapPlotData: (patientId, scheduleId, request) => dispatch(actions.getCapPlotData(patientId, scheduleId, request)),
  getCapDetails: (id, pageRequest) => dispatch(actions.getCapDetails(id, pageRequest)),
  replacePath: path => dispatch(replace(path)),
  loadDashboard: id => dispatch(PatientDashboardAction.getDashboard(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdherenceGraph);
