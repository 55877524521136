import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { actions } from './redux/actions';
import '../Widgets.scss';
import Strings from '../../../../Strings';

class TotalKits extends PureComponent {
  componentDidMount() {
    this.props.getData();
  }

  render() {
    const { isLoading, totalKits } = this.props;
    return (
      <React.Fragment>
        {!isLoading && (
          <div className="boxWithVl-box">
            <div className="boxWithVl-valueBox black">{totalKits}</div>
            <div onClick={() => this.props.onClick('/devices/Kit')} className="boxWithVl-labelBox clickable">
              {Strings.totalKits}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

TotalKits.widgetId = 'TotalKitsWidget';

TotalKits.propTypes = {
  getData: PropTypes.func,
  totalKits: PropTypes.any,
  isLoading: PropTypes.any,
  onClick: PropTypes.func,
};

const mapStateToProps = state => {
  const { widgetTotalKitsReducer } = state.dashboardWidgets;
  return {
    totalKits: widgetTotalKitsReducer && widgetTotalKitsReducer?.totalKits,
    isLoading: widgetTotalKitsReducer && widgetTotalKitsReducer?.isLoading,
  };
};

const mapDispatchToProps = dispatch => ({
  getData: () => dispatch(actions.getTotalKits()),
  onClick: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TotalKits);
