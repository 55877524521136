import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { now } from 'moment-timezone';

import DashboardLayout from './DashboardLayout';
import { PageHeader } from '../../components/PageHeader';
import { Button } from '../../components/PageHeader/Button';
import Strings from '../../Strings';
import PatientStatus, { PatientStatusMetadata } from './Widgets/PatientStatus';
import { actionsDashboard as actions } from './redux/actions';
import DashboardFilter from './DashboardFilter';
import DashboardEditModeButton from './DashboardEditModeButton';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { chartTypeEnum } from '../../components/Graphs/GenericCategoryGraph';
import ProgramDistribution from './Widgets/ProgramDistribution';
import PatientsAgeGender from './Widgets/PatientsAgeGender';
import { stackedChartTypeConfig } from './Widgets/WidgetConfigs';
import ConditionsDistribution from './Widgets/ConditionsDistribution';

export const DASHBOARD_ID = 'Analytics';

const ProgramDistributionMetadata = {
  id: ProgramDistribution.widgetId,
  name: Strings.widgets.programDistribution,
  permission: '',
  defProps: { i: ProgramDistribution.widgetId, w: 5, h: 4, minW: 4, minH: 3 },
  configuration: {
    chartType: {
      type: 'select',
      name: 'chartType',
      label: 'Chart Type',
      defaultValue: chartTypeEnum.pie,
      options: [
        { label: 'Bar chart', value: chartTypeEnum.bar },
        { label: 'Area chart', value: chartTypeEnum.area },
        { label: 'Line chart', value: chartTypeEnum.line },
        { label: 'Pie chart', value: chartTypeEnum.pie },
      ],
      canBeSetByDashboard: false,
    },
  },
};

const PatientsAgeGenderMetadata = {
  id: PatientsAgeGender.widgetId,
  name: 'Patients age and gender',
  permission: '',
  defProps: { i: PatientsAgeGender.widgetId, w: 6, h: 4, minW: 4, minH: 3 },
  configuration: stackedChartTypeConfig,
};

const ConditionsDistributionMetadata = {
  id: ConditionsDistribution.widgetId,
  name: 'Conditions Distribution',
  permission: '',
  defProps: { i: ConditionsDistribution.widgetId, w: 6, h: 4, minW: 4, minH: 3 },
};

export const WidgetMetadata = [
  PatientStatusMetadata,
  ProgramDistributionMetadata,
  PatientsAgeGenderMetadata,
  ConditionsDistributionMetadata,
];

const l = [
  { ...PatientStatusMetadata.defProps, x: 0, y: 0, w: 4, h: 5 },
  { ...ProgramDistributionMetadata.defProps, x: 0, y: 5, w: 4, h: 6 },
  { ...PatientsAgeGenderMetadata.defProps, x: 4, y: 0, w: 8, h: 5 },
  { ...ConditionsDistributionMetadata.defProps, x: 4, y: 5, w: 8, h: 6 },
];

const m = [
  { ...PatientStatusMetadata.defProps, x: 0, y: 0, w: 4, h: 4 },
  { ...ProgramDistributionMetadata.defProps, x: 0, y: 4, w: 4, h: 6 },
  { ...PatientsAgeGenderMetadata.defProps, x: 4, y: 0, w: 4, h: 4 },
  { ...ConditionsDistributionMetadata.defProps, x: 4, y: 4, w: 4, h: 6 },
];

const s = [
  { ...PatientStatusMetadata.defProps, x: 0, y: 2, w: 4, h: 2 },
  { ...ProgramDistributionMetadata.defProps, x: 0, y: 12, w: 4, h: 2 },
  { ...PatientsAgeGenderMetadata.defProps, x: 0, y: 12, w: 4, h: 2 },
  { ...ConditionsDistributionMetadata.defProps, x: 4, y: 6, w: 4, h: 6 },
];

export const DefaultLayouts = { l, m, s };

function Analytics(props) {
  const [refreshEnable, setRefreshEnable] = useState(true);
  const [widgets, setWidgets] = useState([]);
  const [refreshTimestamp, setRefreshTimestamp] = useState(now());

  const initializeDashboard = () => {
    const widgetPE = {
      ...PatientStatusMetadata,
      render: <PatientStatus
      graphType={
        props.configuration?.[PatientStatusMetadata.id]?.chartType?.value ||
        PatientStatusMetadata.configuration.chartType.defaultValue
      }
      refreshTimestamp={refreshTimestamp}
      />,
      noPadding: true,
    };

    const widgetPD = {
      ...ProgramDistributionMetadata,
      render: <ProgramDistribution
      graphType={
        props.configuration?.[ProgramDistributionMetadata.id]?.chartType?.value ||
        ProgramDistributionMetadata.configuration.chartType.defaultValue
      }
      refreshTimestamp={refreshTimestamp}
      />,
      noPadding: true,
    };

    const widgetPAG = {
      ...PatientsAgeGenderMetadata,
      render: <PatientsAgeGender
      graphType={
        props.configuration?.[PatientsAgeGenderMetadata.id]?.stackedChartType?.value ||
        PatientsAgeGenderMetadata.configuration.stackedChartType.defaultValue
      }
      refreshTimestamp={refreshTimestamp}
      />,
      noPadding: true,
    };

    const widgetCD = {
      ...ConditionsDistributionMetadata,
      render: <ConditionsDistribution
      refreshTimestamp={refreshTimestamp}
      />,
      noPadding: true,
    };

    setWidgets([widgetPE, widgetPD, widgetPAG, widgetCD]);
  };

  useEffect(() => {
    initializeDashboard();
  }, [refreshTimestamp, props.configuration]);

  const onRefresh = () => {
    setRefreshEnable(false);
    setTimeout(() => {
      setRefreshEnable(true);
    }, 10000);
    setRefreshTimestamp(now());
  };

  return (
    <React.Fragment>
      <PageHeader
        isBlack
        left={`${Strings.welcome} ${props.userName}`}
        right={
          <React.Fragment>
            <DashboardEditModeButton dashboardId={DASHBOARD_ID} />
            <DashboardFilter dashboardId={DASHBOARD_ID} />
            <Button class="refresh" disabled={!refreshEnable} onClick={onRefresh} />
          </React.Fragment>
        }
      />
      {widgets.length > 0 && !_.isEmpty(DefaultLayouts) && (
        <OverlayScrollbarsComponent
          defer
          className="scrollbar-right-margin"
          options={{ scrollbars: { autoHide: 'leave', autoHideDelay: '100' } }}
        >
          <DashboardLayout
            dashboardId={DASHBOARD_ID}
            widgets={widgets}
            defaultLayouts={DefaultLayouts}
            rowHeight={45}
          />
        </OverlayScrollbarsComponent>
      )}
    </React.Fragment>
  );
}

Analytics.propTypes = {
  userName: PropTypes.string,
  filters: PropTypes.array,
  configuration: PropTypes.any,
  initializeDashboard: PropTypes.func,
};

const mapStateToProps = state => {
  const { profile } = state.auth;
  const { dashboardFilters } = state.dashboardState;
  const filters = dashboardFilters[DASHBOARD_ID];
  const configuration = state.dashboardState.configuration[DASHBOARD_ID];
  return {
    userName: profile ? (profile.first_name ? `${profile.first_name} ${profile.last_name}` : profile.username) : '',
    filters,
    configuration,
  };
};

const mapDispatchToProps = dispatch => ({
  initializeDashboard: (dashboardId, defaultLayouts, widgets, force) =>
    dispatch(actions.initializeDashboard(dashboardId, defaultLayouts, widgets, force)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
