import React from 'react';
import PropTypes from 'prop-types';
import AsyncReactSelect from 'react-select/async';
import { createFilter } from 'react-select';

import './style.scss';

function AsyncMultiselect(props) {
  const { data, label, id, placeholder, value, className, checkboxFirst, menuPositionFixed, onChange, searchByLabelOnly, 
    loadOptions,
    defaultOptions,
    cacheOptions,
    ...otherProps } = props;

  const getLabel = option => (
    <React.Fragment key={`option_${option.value}_${value.includes(option.value)}`}>
      {option.label}
      <input type="checkbox" defaultChecked={value.includes(option.value)} disabled={option.isDisabled} />
    </React.Fragment>
  );

  const defaultFilter = {
    ignoreCase: true,
    ignoreAccents: true,
    matchFrom: 'any',
    stringify: option => `${option.data.label} ${option.data.value}`,
    trim: true,
  };
    
      
  return (
    <>
      <label htmlFor={id} className="visuallyhidden">
        {label}
      </label>
      <AsyncReactSelect
        id={id}
        className={`${className || ''} ${checkboxFirst ? 'checkboxFirst' : ''} react-select-container multi-select`}
        classNamePrefix="react-select"
        value={value}
        onChange={onChange}
        options={data}
        unstyled
        placeholder={placeholder}
        menuPosition={menuPositionFixed ? 'fixed' : 'absolute'}
        isMulti
        controlShouldRenderValue={false}
        isClearable={false}
        hideSelectedOptions={false}
        getOptionLabel={props.getLabel || getLabel}
        filterOption={searchByLabelOnly ? createFilter({ stringify: option => option.data.label }) : createFilter(defaultFilter)}
        loadOptions={loadOptions}
        cacheOptions={cacheOptions}
        defaultOptions={defaultOptions || undefined}
        backspaceRemovesValue={false}
        {...otherProps}
      />
    </>
  );
}

AsyncMultiselect.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.array,
  data: PropTypes.array.isRequired,
  closeMenuOnSelect: PropTypes.bool,
  menuPositionFixed: PropTypes.bool,
};

AsyncMultiselect.defaultProps = { menuPositionFixed: true };

export default AsyncMultiselect;
