import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Strings from '../../../Strings';
import { isShipper, isSuperUser } from '../../../utils/userRoles';
import TabView, { Tab } from '../../../components/TabView';
import HubDevices from './HubDevices';
import DeviceStatusHistory from './DeviceStatusHistory';
import ShipperCapsDetails, { getCapHeader } from '../../ShipperCapsDetails';
import { actions } from '../../ShipperCapsDetails/redux/actions';
import { openModalAction } from '../../../actions/modal';
import { ADD_NICK_NAME } from '../../../actions/action-types';
import { DeviceTypes } from '../../../utils/deviceTypes';

export const CAP_EVENTS_TAB = Strings.events;
export const CAP_STATUS_HISTORY_TAB = Strings.history;
export const HUB_DEVICES_TAB = Strings.devices;

function CapDetails(props) {
  const capId = encodeURIComponent(props.match.params.id);
  const type = props.match.params.type;
  const { activeTab } = props;

  const pageRequest = {
    offset: 0,
    search: '',
    filterBy: '',
    limit: 1,
  };

  React.useEffect(() => {
    props.getCapsDetails(capId, pageRequest);
    return props.clearCapsDetails;
  }, [capId, activeTab]);

  const rootPath = `/devices/${type}/${capId}`;
  const tabs = [];
  if (type === DeviceTypes.Datahub.id) {
    tabs.push(
      <Tab name={HUB_DEVICES_TAB} path={`${rootPath}/devices`} key={HUB_DEVICES_TAB}>
        <HubDevices {...props} noHeader />
      </Tab>,
    );
  }
  tabs.push(
    <Tab name={CAP_EVENTS_TAB} path={`${rootPath}/events`} key={CAP_EVENTS_TAB}>
      <ShipperCapsDetails {...props} noHeader />
    </Tab>,
  );

  if (isSuperUser() || isShipper()) {
    tabs.push(
      <Tab name={CAP_STATUS_HISTORY_TAB} path={`${rootPath}/history`} key={CAP_STATUS_HISTORY_TAB}>
        <DeviceStatusHistory {...props} />
      </Tab>,
    );
  }

  return (
    <React.Fragment>
      {getCapHeader(
        props.capInfo,
        type,
        () => props.editCapName(pageRequest, props.capInfo.cap_name, capId),
        true,
        'white',
      )}
      <TabView activeTab={activeTab} routeMode>
        {tabs}
      </TabView>
    </React.Fragment>
  );
}

CapDetails.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({ state: PropTypes.shape({ params: PropTypes.shape({ offset: PropTypes.any }) }) }),
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.any }),
    url: PropTypes.shape({ replace: PropTypes.func }),
  }),
  capInfo: PropTypes.shape({
    signal_strength: PropTypes.string,
    battery: PropTypes.number,
  }),
  activeTab: PropTypes.string,
  getCapsDetails: PropTypes.func,
  clearCapsDetails: PropTypes.func,
  editCapName: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    capInfo: state.shipper.capsDetails.data,
  };
};
const mapDispatchToProps = dispatch => ({
  getCapsDetails: (id, pageRequest) => dispatch(actions.getCapsDetails(id, pageRequest)),
  clearCapsDetails: () => dispatch(actions.clearCapsDetails()),
  editCapName: (pageRequest, capName, id) =>
    dispatch(
      openModalAction('add-nick-name', {
        action: actions.addNickName,
        actionType: ADD_NICK_NAME,
        id,
        nextAction: actions.getCapsDetails,
        pageRequest,
        capName,
        allowEmpty: true,
      }),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CapDetails);
