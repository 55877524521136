import {
  ADD_ORGANIZATION_OUTBOUND_API,
  ADD_ORGANIZATION_OUTBOUND_API_ERROR,
  CLEAR_DATA,
  CLEAR_ERRORS,
  CLEAR_SUGGESTED_DATA,
  DELETE_HUB_DEVICE_RESULT,
  DELETE_ORGANIZATION,
  DELETE_ORGANIZATION_OUTBOUND_API,
  DELETE_ORGANIZATION_OUTBOUND_API_RESULT,
  DELETE_ORGANIZATION_RESULT,
  GET_ALL_PERMISSION,
  GET_ALL_PERMISSION_RESULT,
  GET_ORAGANIZATION,
  GET_ORAGANIZATION_ERROR,
  GET_ORAGANIZATION_OUTBOUND_API,
  GET_ORAGANIZATION_OUTBOUND_API_ERROR,
  GET_ORAGANIZATION_OUTBOUND_API_RESULT,
  GET_ORAGANIZATION_RESULT,
  GET_ORG_CONFIG,
  GET_ORG_CONFIG_RESULT,
  GET_ORG_ROLES_DETAIL,
  GET_ORG_ROLES_DETAIL_RESULT,
  GET_ROLES_DETAIL,
  GET_ROLES_DETAIL_RESULT,
  HUB_DEVICES,
  HUB_DEVICES_BPMONITOR_GRAPH,
  HUB_DEVICES_BPMONITOR_GRAPH_ERROR,
  HUB_DEVICES_BPMONITOR_GRAPH_RESULT,
  HUB_DEVICES_ERROR,
  HUB_DEVICES_RESULT,
  HUB_DEVICES_SCALE_GRAPH,
  HUB_DEVICES_SCALE_GRAPH_ERROR,
  HUB_DEVICES_SCALE_GRAPH_RESULT,
  HUB_DEVICES_TYPES,
  HUB_DEVICES_TYPES_ERROR,
  HUB_DEVICES_TYPES_RESULT,
  ORGANIZATION_DEVICES,
  ORGANIZATION_DEVICES_ERROR,
  ORGANIZATION_DEVICES_RESULT,
  ORGANIZATION_LIST,
  ORGANIZATION_LIST_ERROR,
  ORGANIZATION_LIST_RESULT,
  UPDATE_ORG,
  UPDATE_ORGANIZATION_OUTBOUND_API,
  UPDATE_ORGANIZATION_OUTBOUND_API_ERROR,
  UPDATE_ORG_RESULT,
  UPDATE_ORG_ROLES_DETAIL,
  UPDATE_ORG_ROLES_DETAIL_RESULT,
} from './constants';

function getErrorMessage(response) {
  return response.data && response.data.error && response.data.error.message ? response.data.error.message : null;
}

export const reducer = (state = null, action) => {
  switch (action.type) {
    case GET_ORAGANIZATION:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ORAGANIZATION_ERROR:
      return {
        ...state,
        isLoading: false,
        organization: [],
      };
    case GET_ORAGANIZATION_RESULT:
      return {
        ...state,
        organization: action.response.data,
        pagination: action.response.pagination,
        isLoading: false,
      };

    case DELETE_ORGANIZATION:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_ORGANIZATION_RESULT:
      if (action.response) {
        const { name } = action.response;
        const { organization } = state;

        const updatedOrg = organization.filter(org => {
          return org.name !== name;
        });
        return {
          ...state,
          organization: updatedOrg,
          isLoading: false,
        };
      }
      return state;

    case CLEAR_ERRORS: {
      return {
        ...state,
        isLoading: false,
        error: [],
      };
    }

    case GET_ORAGANIZATION_OUTBOUND_API:
      return {
        ...state,
        isLoading: true,
      };

    case GET_ORAGANIZATION_OUTBOUND_API_ERROR:
      return {
        ...state,
        isLoading: false,
        organizationOutboundAPI: [],
      };
    case GET_ORAGANIZATION_OUTBOUND_API_RESULT:
      return {
        ...state,
        organizationOutboundAPI: action.response.data,
        pagination: action.response.pagination,
        isLoading: false,
      };

    case ADD_ORGANIZATION_OUTBOUND_API:
      return {
        ...state,
        isLoading: false,
      };
    case ADD_ORGANIZATION_OUTBOUND_API_ERROR: {
      const apiErrorMessage = getErrorMessage(action.response);
      const errorMessage = [
        {
          title: apiErrorMessage,
          text: '',
        },
      ];
      return {
        ...state,
        isLoading: false,
        error: errorMessage,
      };
    }

    case UPDATE_ORGANIZATION_OUTBOUND_API:
      return {
        ...state,
        isLoading: false,
      };

    case UPDATE_ORGANIZATION_OUTBOUND_API_ERROR: {
      const apiErrorMessage = getErrorMessage(action.response);
      const errorMessage = [
        {
          title: apiErrorMessage,
          text: '',
        },
      ];
      return {
        ...state,
        isLoading: false,
        error: errorMessage,
      };
    }

    case DELETE_ORGANIZATION_OUTBOUND_API:
      return {
        ...state,
        isLoading: true,
      };

    case DELETE_ORGANIZATION_OUTBOUND_API_RESULT:
      if (action.response) {
        const { auth_key } = action.response;
        const { organizationOutboundAPI } = state;
        const updatedOrg = organizationOutboundAPI.filter(org => {
          return org.auth_key !== auth_key;
        });
        return {
          ...state,
          organizationOutboundAPI: updatedOrg,
          isLoading: false,
        };
      }
      return state;

    case ORGANIZATION_DEVICES:
      return {
        ...state,
        isLoading: true,
      };

    case ORGANIZATION_DEVICES_ERROR:
      return {
        ...state,
        isLoading: false,
        devices: [],
      };
    case ORGANIZATION_DEVICES_RESULT:
      return {
        ...state,
        devices: action.response.data,
        pagination: action.response.pagination,
        isLoading: false,
      };

    case HUB_DEVICES:
      return {
        ...state,
        isLoading: true,
        hubDevices: [],
        pagination: {},
        capInfo: undefined,
      };

    case HUB_DEVICES_RESULT:
      return {
        ...state,
        isLoading: false,
        hubDevices: action.response.data,
        pagination: action.response.pagination,
        capInfo: action.response.capInfo,
      };

    case HUB_DEVICES_ERROR:
      return {
        ...state,
        isLoading: false,
        hubDevices: [],
        pagination: {},
        capInfo: undefined,
      };

    case ORGANIZATION_LIST:
      return {
        ...state,
        isLoading: true,
      };

    case ORGANIZATION_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        organizationAll: [],
      };
    case ORGANIZATION_LIST_RESULT:
      return {
        ...state,
        organizationAll: action.response.data,
        isLoading: false,
      };

    case HUB_DEVICES_TYPES:
      return {
        ...state,
        isLoading: true,
      };

    case HUB_DEVICES_TYPES_ERROR:
      return {
        ...state,
        isLoading: false,
        deviceTypes: [],
      };
    case HUB_DEVICES_TYPES_RESULT:
      return {
        ...state,
        deviceTypes: action.response.data,
        isLoading: false,
      };

    case CLEAR_DATA:
      return {
        ...state,
        devices: [],
        organizationDevices: [],
        deviceTypes: [],
        graphDataBP: [],
        graphDataWeightScale: [],
      };

    case HUB_DEVICES_BPMONITOR_GRAPH:
      return {
        ...state,
        graphDataBP: [],
        isLoading: true,
      };

    case HUB_DEVICES_BPMONITOR_GRAPH_RESULT:
      return {
        ...state,
        graphDataBP: action.response,
        isLoading: false,
      };

    case HUB_DEVICES_BPMONITOR_GRAPH_ERROR:
      return {
        ...state,
        graphDataBP: [],
        isLoading: false,
      };

    case HUB_DEVICES_SCALE_GRAPH:
      return {
        ...state,
        graphDataWeightScale: [],
        isLoading: true,
      };

    case HUB_DEVICES_SCALE_GRAPH_RESULT:
      return {
        ...state,
        graphDataWeightScale: action.response,
        isLoading: false,
      };

    case HUB_DEVICES_SCALE_GRAPH_ERROR:
      return {
        ...state,
        graphDataWeightScale: [],
        isLoading: false,
      };

    case CLEAR_SUGGESTED_DATA:
      return {
        ...state,
        suggestion: [],
      };

    case DELETE_HUB_DEVICE_RESULT: {
      if (action.response && action.request) {
        const { device_id } = action.request;
        const { data } = state;

        const filteredData = data.filter(user => {
          return user.cap_id !== device_id;
        });
        return {
          ...state,
          data: filteredData,
          isLoading: false,
        };
      }
    }

    case GET_ALL_PERMISSION:
    case GET_ROLES_DETAIL:
    case GET_ORG_ROLES_DETAIL:
    case UPDATE_ORG_ROLES_DETAIL:
    case UPDATE_ORG:
    case GET_ORG_CONFIG: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case GET_ROLES_DETAIL_RESULT: {
      return {
        ...state,
        isLoading: false,
        roles: action.response,
      };
    }

    case GET_ORG_ROLES_DETAIL_RESULT: {
      return {
        ...state,
        isLoading: false,
        orgRoleConfig: action.response,
      };
    }

    case UPDATE_ORG_ROLES_DETAIL_RESULT: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case GET_ORG_CONFIG_RESULT: {
      return {
        ...state,
        isLoading: false,
        orgConfig: action.response,
      };
    }

    case GET_ALL_PERMISSION_RESULT: {
      return {
        ...state,
        isLoading: false,
        template: action.response,
      };
    }

    case UPDATE_ORG_RESULT: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};
