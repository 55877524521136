import React from 'react';
import PropTypes from 'prop-types';

import Strings from '../../../../Strings';
import { makeValid } from '../../../../utils';
import TableWidget from '../component/TableWidget';
import { PERMISSIONS, hasPermission } from '../../../../utils/userPermissions';

function Insurance(props) {
  const { insurance, isLoading } = props;
  let data = [];
  if (insurance && insurance.length > 0) {
    insurance.forEach(ins => {
      data.push([Strings.capPatient.insuranceName, makeValid(ins.insuranceName)]);
      data.push([Strings.capPatient.policyId, makeValid(ins.policyId)]);
      data.push([Strings.capPatient.groupNumber, makeValid(ins.groupNumber)]);
    });
  }
  const canEdit = hasPermission(PERMISSIONS.PATIENTS_RPM_PATIENT_INFO_EDIT);

  return (
    <TableWidget
      title={Strings.capPatient.insurance}
      buttons={props.onEdit && canEdit ? [{ icon: 'edit', onClick: props.onEdit }] : undefined}
      data={data}
      isLoading={isLoading}
      firstColumnClassName="insurance-col"
    />
  );
}

Insurance.propTypes = {
  isLoading: PropTypes.any,
  insurance: PropTypes.arrayOf(
    PropTypes.shape({
      insuranceName: PropTypes.string,
      policyId: PropTypes.string,
      groupNumber: PropTypes.string,
    }),
  ),
  patientId: PropTypes.any,
  onEdit: PropTypes.func,
};
Insurance.widgetId = 'InsuranceWidget';

export default Insurance;
