import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { cloneDeep, debounce, set } from 'lodash';
import axios from 'axios';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { closeModal } from '../../actions/modal';
import {
  AsyncSelectField,
  DateInput,
  Input,
  SelectField,
  TextAreaWithHashtagsField,
  TimeInput,
} from '../../containers/Form';
import Strings from '../../Strings';
import Select from '../../components/Select';
import Wizard from '../../containers/Modal/Wizard';
import { actions } from '../../pages/SuperUser/Patients/redux/actions';
import { notificationActions } from '../../components/Notification/redux/actions';
import {
  GET_CAPS_WITHOUT_SCHEDULE_RESULT,
  GET_REMINDERS_DEFAULT_MESSAGES_RESULT,
  PATIENT_DETAILS_RESULT,
  START_TASK_RESULT,
} from '../../pages/SuperUser/Patients/redux/constants';
import { GetTimezoneTextMapping } from '../../constants';
import { scheduleTypes, validateSchedule } from '../../utils/validators/schedule';
import './EditRpmScheduleModal.scss';
import { getTzOffset } from '../../utils';
import { EditRpmScheduleModalPages } from '../_ModalsMetadata/ModalsMetadata';
import { getProgramDisplayName, getProgramsFromPatient } from '../../utils/cmsPrograms';
import HashtagsWindow from '../../components/Inputs/TextAreaWithHashtags/HashTagsWindow';

//#region Definitions and exports
const TakePerDaysIds = {
  QD: 'QD', // q.daily - every day
  BID: 'BID', // b.i.d - twice a day
  TID: 'TID', // t.i.d - three times a day
  QID: 'QID', // q.i.d - four times a day
  Q4H: 'Q4H', // q.4.h - every 4 hours
};

const timePerDaysTemplates = {
  [TakePerDaysIds.QD]: {
    text: Strings.frequecyType.qd,
    offsets: [43200], // 12 PM
    type: TakePerDaysIds.QD,
  },
  [TakePerDaysIds.BID]: {
    text: Strings.frequecyType.bid,
    offsets: [28800, 64800], // 8 AM, 6 PM
    type: TakePerDaysIds.BID,
  },
  [TakePerDaysIds.TID]: {
    text: Strings.frequecyType.tid,
    offsets: [28800, 46800, 64800], // 8 AM, 1 PM, 6 PM
    type: TakePerDaysIds.TID,
  },
  [TakePerDaysIds.QID]: {
    text: Strings.frequecyType.qid,
    offsets: [21600, 43200, 57600, 79200], // 6 AM, 12 PM, 4 PM, 10 PM
    type: TakePerDaysIds.QID,
  },
  [TakePerDaysIds.Q4H]: {
    text: Strings.frequecyType.q4h,
    offsets: [7200, 21600, 36000, 50400, 64800, 79200], // 2 AM, 6 AM, 10 AM, 2 PM, 6 PM, 10 PM
    type: TakePerDaysIds.Q4H,
  },
};

export const getWindowOffsetsWithPrefix = prefix => {
  return [
    {
      value: undefined,
      label: Strings.allDay,
    },
    {
      value: 3600,
      label: `${prefix}1 ${Strings.hour}`,
    },
    {
      value: 7200,
      label: `${prefix}2 ${Strings.hours}`,
    },
    {
      value: 10800,
      label: `${prefix}3 ${Strings.hours}`,
    },
    {
      value: 14400,
      label: `${prefix}4 ${Strings.hours}`,
    },
  ];
};

export const remindersTypes = {
  early: 'early',
  onTime: 'on_time',
  late: 'late',
  missedDoses: 'missed_doses',
};

const reminderOffsets = [
  { value: 10 * 60, label: '10 min' },
  { value: 20 * 60, label: '20 min' },
  { value: 40 * 60, label: '40 min' },
  { value: 60 * 60, label: '1 hour' },
];

const defaultReminderOffsets = {
  early: { offset: reminderOffsets[0].value },
  late: { offset: reminderOffsets[0].value },
};

export const getScheduleFromMed = med => {
  return {
    id: med.id,
    offset: med.format?.offset,
    name: med.medicationName,
    type: med.scheduleType,
    end_time: med.format?.end_dt
      ? new Date(
          moment
            .unix(med.format?.end_dt)
            .tz(med.scheduleTimezone)
            .format(),
        )
      : null,
    start_time:
      med.format?.start_dt && moment(med.format?.start_dt * 1000).isAfter(moment().endOf('day'))
        ? new Date(
            moment
              .unix(med.format?.start_dt)
              .tz(med.scheduleTimezone)
              .format(),
          )
        : null,
    schedule_timezone: med.scheduleTimezone,
    cap_id: med.capId,
    take_window: med.takeWindow,
    sms_reminder: med.sms_reminder
      ? {
          early: med.sms_reminder?.early
            ? {
                checked: true,
                offset: med.sms_reminder.early.offset || reminderOffsets[0].value,
                text: med.sms_reminder.early.text || null,
              }
            : defaultReminderOffsets.early,
          late: med.sms_reminder?.late
            ? {
                checked: true,
                offset: med.sms_reminder.late.offset || reminderOffsets[0].value,
                text: med.sms_reminder.late.text || null,
              }
            : defaultReminderOffsets.late,
          on_time: med.sms_reminder?.on_time
            ? {
                checked: true,
                text: med.sms_reminder.on_time.text || null,
              }
            : null,
          missed_doses: med.sms_reminder?.missed_doses
            ? {
                checked: true,
                text: med.sms_reminder.missed_doses.text || null,
                offset: med.sms_reminder.missed_doses.offset || null,
              }
            : null,
        }
      : defaultReminderOffsets,
    enrollment_id: med.enrollmentId,
  };
};

const MEDICATION_RECONCILIATION_TASK_ID = 16;

export const calculateDateInNewTimezone = (date, oldTimezone, newTimezone) => {
  return new Date(moment(date.getTime() + getTzOffset(date, oldTimezone) - getTzOffset(date, newTimezone)).format());
};

//#endregion

function EditRpmScheduleModal(props) {
  const [schedule, setSchedule] = useState(
    props.data.schedule
      ? getScheduleFromMed(props.data.schedule)
      : { sms_reminder: defaultReminderOffsets, schedule_timezone: props.data?.patient?.timezone, type: scheduleTypes.everyDay },
  );
  const [caps, setCaps] = useState([]);
  const [defaultReminders, setDefaultReminders] = useState([]);
  const [forceNextPage, setForceNextPage] = useState(0);
  const [highlightInvalidFields, sethighlightInvalidFields] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [saveBtnClicked, setSaveBtnClicked] = useState(false);
  const [programs, setPrograms] = useState(getProgramsFromPatient(props.data.patient, true));
  const [startDateFromEnrollment, setStartDateFromEnrollment] = useState();
  const [endDateFromEnrollment, setEndDateFromEnrollment] = useState();

  const timezones = GetTimezoneTextMapping();
  const editMode = props.data?.schedule?.id;

  // allow to edit start_time only if it's in the future and editMode is true
  const allowStartTimeEdit =
    (editMode &&
      schedule &&
      moment(getScheduleFromMed(props.data.schedule).start_time).isAfter(moment().endOf('day'))) ||
    !editMode;

  const remindersRef = useRef(Object.values(remindersTypes).reduce((acc, r) => ({ ...acc, [r]: React.useRef() }), {}));
  const tooltipsRef = useRef(Object.values(remindersTypes).reduce((acc, r) => ({ ...acc, [r]: React.useRef() }), {}));
  const taskLogTimer = useRef();
  const taskLogData = {
    patientId: props.data.patient?.id,
    taskMetadataID: MEDICATION_RECONCILIATION_TASK_ID,
    enrollmentId: props.data.enrollmentId,
  };

  const startTask = () => {
    props.startTask(taskLogData).then(resp => {
      if (resp.type === START_TASK_RESULT) {
        taskLogTimer.current = setInterval(() => {
          props.continueTask(taskLogData);
        }, 30000);
      }
    });
  };

  const endTask = () => {
    props.endTask(taskLogData);
    clearInterval(taskLogTimer.current);
  };

  useEffect(() => {
    if (props.data.logTask && props.data.enrollmentId) {
      startTask();
    }

    props.getCaps().then(r => {
      if (r.type === GET_CAPS_WITHOUT_SCHEDULE_RESULT) {
        if (r.response) {
          const data = r.response;
          if (schedule.cap_id) {
            data.push(schedule.cap_id);
          }
          setCaps(data);
          if (data?.length === 1) {
            setSchedule(s => ({
              ...s,
              cap_id: data[0],
            }));
          }
        }
      }
    });

    props.getReminders({ language: props.data.patient.language }).then(r => {
      if (r.type === GET_REMINDERS_DEFAULT_MESSAGES_RESULT) {
        const early = r.response?.data?.find(m => m.type === 'early_sms');
        const onTime = r.response?.data?.find(m => m.type === 'on_time_sms');
        const late = r.response?.data?.find(m => m.type === 'late_sms');
        const missedDoses = r.response?.data?.find(m => m.type === 'missed_doses_sms');
        setDefaultReminders({
          early,
          on_time: onTime,
          late,
          missed_doses: missedDoses,
        });
        if (Object.values(schedule.sms_reminder).some(re => !re?.text)) {
          setSchedule(s => ({
            ...s,
            sms_reminder: {
              ...s.sms_reminder,
              early: {
                ...s.sms_reminder?.early,
                text: s.sms_reminder?.early?.text || early.message,
              },
              on_time: {
                ...s.sms_reminder?.on_time,
                text: s.sms_reminder?.on_time?.text || onTime.message,
              },
              late: {
                ...s.sms_reminder?.late,
                text: s.sms_reminder?.late?.text || late.message,
              },
              missed_doses: {
                ...s.sms_reminder?.missed_doses,
                text: s.sms_reminder?.missed_doses?.text || missedDoses.message,
              },
            },
          }));
        }
      }
    });

    props.getPatientInfo(props.data.patient?.id).then(r => {
      if (r.type === PATIENT_DETAILS_RESULT) {
        if (r.response) {
          const p = r.response;
          const enrollments = getProgramsFromPatient(p, true);
          const defaultEnrollment = enrollments?.find(e => e.isDefault)?.enrollmentId;
          setPrograms(enrollments);
          if (!schedule.id && defaultEnrollment) {
            setSchedule(s => ({
              ...s,
              enrollment_id: defaultEnrollment,
            }));
          }
        }
      }
    });

    return () => {
      if (taskLogTimer?.current) {
        clearInterval(taskLogTimer.current);
      }
    };
  }, []);

  const isTextDefault = type => {
    return schedule.sms_reminder?.[type]?.text === defaultReminders?.[type]?.message;
  };

  useEffect(() => {
    const allowedTagsForReminders = Object.entries(defaultReminders).reduce(
      (acc, r) => ({ ...acc, [r[0]]: r[1].actions }),
      {},
    );
    setValidationErrors(validateSchedule(schedule, allowedTagsForReminders));
  }, [schedule]);

  useEffect(() => {
    if (schedule.enrollment_id) {
      const programDetails = programs.find(p => p.enrollmentId === schedule.enrollment_id);
      if (programDetails) {
        if (programDetails.enrollmentStart && !editMode) {
          const startMoment = moment(programDetails.enrollmentStart);
          const proposedStart = startMoment.isBefore(moment().startOf('day')) ? moment().startOf('day') : startMoment;
          setStartDateFromEnrollment(proposedStart.toDate());
        }
        if (
          programDetails.enrollmentEnd &&
          !editMode &&
          moment(programDetails.enrollmentStart).isAfter(moment().endOf('day'))
        ) {
          setEndDateFromEnrollment(moment(programDetails.enrollmentEnd).toDate());
        }
      }
    } else {
      setStartDateFromEnrollment(null);
      setEndDateFromEnrollment(null);
    }
  }, [schedule.enrollment_id]);

  useEffect(() => {
    // if schedule start_time is set in the past, error
    if (
      allowStartTimeEdit &&
      schedule.start_time &&
      moment(schedule.start_time).isBefore(moment.tz(schedule.schedule_timezone).startOf('day'))
    ) {
      setValidationErrors(v => [
        ...v,
        {
          property: 'start_time',
          errors: [Strings.errors.startDateCantBeInThePast],
        },
      ]);
    }

    // if start_time is after end_time, error
    if (schedule.start_time && schedule.end_time && moment(schedule.start_time).isAfter(schedule.end_time)) {
      setValidationErrors(v => [
        ...v,
        {
          property: 'end_time',
          errors: [Strings.errors.endDateMustBeAfterStartDate],
        },
      ]);
    }

    // if end_time is after enrollment end, error
    const enrollmentEndDate = programs.find(p => p.enrollmentId === schedule.enrollment_id)?.enrollmentEnd;
    if (schedule.end_time && enrollmentEndDate && moment(schedule.end_time).isAfter(moment(enrollmentEndDate))) {
      setValidationErrors(v => [
        ...v,
        {
          property: 'end_time',
          errors: [Strings.errors.endDateMustBeBeforeEnrollmentEndDate],
        },
      ]);
    }
  }, [schedule]);

  const onDateChange = (date, field) => {
    if (field === 'start_time' && date === '') {
      setStartDateFromEnrollment(null);
    }
    if (field === 'end_time' && date === '') {
      setEndDateFromEnrollment(null);
    }
    setSchedule(p => ({
      ...p,
      [field]: date,
    }));
  };

  const onSelectedOption = (option, field) => {
    setSchedule(p => ({
      ...p,
      [field]: option.value,
    }));
  };

  const round = (date, duration, method) => {
    return moment(Math[method](+date / +duration) * +duration);
  };

  const getCurrentTimeRoundToMinutes = () => {
    return round(moment(), moment.duration(5, 'minutes'), 'ceil');
  };

  const onTypeSelected = option => {
    const offset = moment.duration(getCurrentTimeRoundToMinutes().diff(moment().startOf('day'))).asSeconds();

    setSchedule(s => ({
      ...s,
      type: option.value,
      offset: option.value === scheduleTypes.everyDay ? [offset] : [],
    }));
  };

  const onMedicationSelected = value => {
    if (value) {
      setSchedule({
        ...schedule,
        name: value,
      });
      setForceNextPage(prev => prev + 1);
    }
  };

  const onTakesPerDayChanged = value => {
    setSchedule(s => ({
      ...s,
      offset: value.offsets,
    }));
  };

  const onWindowSelected = (field, value) => {
    setSchedule(s => ({
      ...s,
      take_window: [
        field === 'start' ? value : s.take_window?.[0] || null,
        field === 'end' ? value : s.take_window?.[1] || null,
      ],
    }));
  };

  const onTimezoneChange = option => {
    setSchedule(s => ({
      ...s,
      schedule_timezone: option.value,
      end_time: s.end_time ? calculateDateInNewTimezone(s.end_time, s.schedule_timezone, option.value) : null,
    }));
  };

  const onOffsetChange = (value, index, amPm) => {
    const newTime = moment(`${value} ${amPm}`, 'HH:mm A');
    const startOfDay = moment().startOf('day');
    const newOffset = newTime.isValid() ? moment.duration(newTime.diff(startOfDay)).asSeconds() : '';

    setSchedule(s => ({
      ...s,
      offset: s.offset.map((o, i) => {
        if (i === index) {
          return newOffset;
        }
        return o;
      }),
      invalidFields: !s.invalidFields ? [] : s.invalidFields.filter(f => !(f.field === 'offset' && f.index === index)),
    }));
  };

  const onAmPmSelected = (prevValue, value, index) => {
    if (prevValue === value) {
      return;
    }
    setSchedule(s => ({
      ...s,
      offset: s.offset.map((o, i) => {
        if (i === index) {
          return value === 'PM' ? o + 12 * 60 * 60 : o - 12 * 60 * 60;
        }
        return o;
      }),
    }));
  };

  const getTimesPerDayFromScheduleOffsets = offsets => {
    if (!offsets || offsets.length === 0) {
      return undefined;
    } else if (offsets.length === 1) {
      return timePerDaysTemplates[TakePerDaysIds.QD].text;
    } else if (offsets.length === 2) {
      return timePerDaysTemplates[TakePerDaysIds.BID].text;
    } else if (offsets.length === 3) {
      return timePerDaysTemplates[TakePerDaysIds.TID].text;
    } else if (offsets.length === 4) {
      return timePerDaysTemplates[TakePerDaysIds.QID].text;
    } else if (offsets.length === 6) {
      return timePerDaysTemplates[TakePerDaysIds.Q4H].text;
    }

    return undefined;
  };

  const getDataForSelect = res => {
    return res.map(d => {
      return { label: d.rxName, value: d.code };
    });
  };
  const _loadOptions = (inputValue, callback) => {
    if (inputValue.length < 3) {
      return;
    }
    axios.get(`https://mobileapidev.meducation.com/medsearch?qs=${inputValue}`).then(response => {
      callback(getDataForSelect(response.data));
    });
  };
  const loadOptions = debounce(_loadOptions, 500);

  const onReminderChange = (type, field, value) => {
    setSchedule(s => ({
      ...s,
      sms_reminder: {
        ...s.sms_reminder,
        [type]: {
          ...s.sms_reminder?.[type],
          [field]: value,
        },
      },
    }));
  };

  const onTagChosen = (type, text) => {
    const { selectionStart, selectionEnd, value } = remindersRef.current[type].current;
    let textBeforeCursorPosition = value.substring(0, selectionStart);
    let textAfterCursorPosition = value.substring(selectionEnd, value.length);
    onReminderChange(type, 'text', `${textBeforeCursorPosition}${text}${textAfterCursorPosition}`);
    tooltipsRef.current[type].current?.close();
    setTimeout(() => {
      remindersRef.current[type].current.focus();
      remindersRef.current[type].current.selectionStart = selectionStart + text.length;
      remindersRef.current[type].current.selectionEnd = selectionStart + text.length;
    }, 300);
  };

  const setInvalidFields = (field, index) => {
    setSchedule(s => ({
      ...s,
      invalidFields: !s.invalidFields
        ? [{ field, index }]
        : s.invalidFields.some(f => f.field === field && f.index === index)
        ? s.invalidFields
        : s.invalidFields.concat({ field, index }),
    }));
  };

  const onSave = () => {
    if (saveBtnClicked) {
      return;
    }
    setSaveBtnClicked(true);

    const patientId = props.data.patient.id;
    const data = cloneDeep(schedule);

    if (data.end_time) {
      data.end_time = moment
        .tz(schedule.end_time, schedule.schedule_timezone)
        .endOf('day')
        .format();
    } else if (endDateFromEnrollment) {
      data.end_time = moment(endDateFromEnrollment).format();
    }

    if (data.start_time) {
      data.start_time = moment
        .tz(schedule.start_time, schedule.schedule_timezone)
        .startOf('day')
        .format();
    } else if (startDateFromEnrollment) {
      data.start_time = moment(startDateFromEnrollment).format();
    }

    Object.entries(schedule.sms_reminder).forEach(([key, value]) => {
      if (!value || !value.checked) {
        delete data.sms_reminder[key];
      } else {
        delete data.sms_reminder[key].checked;
        if (value.text === defaultReminders[key]?.message) {
          delete data.sms_reminder[key].text;
        }
      }
    });

    props.onSubmit(data, patientId).then(response => {
      if (response && response.type === `${props.data.actionType}/error`) {
        props.showNotification(response.response?.data?.error?.message, 5000, true);
      } else {
        if (props.data.logTask && props.data.enrollmentId) {
          endTask();
        }
        props.showNotification(Strings.success.medicationSaved);
        props.closeModalWithNextAction();
      }
      setSaveBtnClicked(false);
    });
  };

  const onCancel = () => {
    if (props.data.logTask && props.data.enrollmentId) {
      endTask();
    }
  };

  const medicationPage = (
    <div>
      <AsyncSelectField
        id="medication_name"
        name="medication_name"
        label={Strings.medicationNameText}
        value={schedule.name}
        placeholder={schedule.name || Strings.patient_medications.enter3Chars}
        loadOptions={loadOptions}
        onChange={o => onMedicationSelected(o.label)}
        isRequired
        highlightInvalid={highlightInvalidFields}
      />
    </div>
  );

  const programDetails = programs.find(p => p.enrollmentId === schedule.enrollment_id);
  const schedulePage = (
    <React.Fragment>
      <div className="page-info-header">{schedule.name}</div>
      <SelectField
        name="type"
        id="type"
        value={schedule.type}
        label={Strings.frequency}
        placeholder={Strings.select}
        onChange={onTypeSelected}
        data={Object.keys(scheduleTypes).map(key => ({ value: scheduleTypes[key], label: Strings[key] }))}
        isRequired
        highlightInvalid={highlightInvalidFields}
        disabled={editMode}
      />
      {schedule.type && (
        <React.Fragment>
          {allowStartTimeEdit && (
            <DateInput
              name="start_time"
              id="start_time"
              label={Strings.startDate}
              placeholder="MM / DD / YYYY"
              value={schedule.start_time || startDateFromEnrollment || ''}
              timezone={schedule.schedule_timezone}
              format="MM / dd / yyyy"
              onChange={e => onDateChange(e, 'start_time')}
              highlightInvalid={highlightInvalidFields}
              errorsForTooltip={validationErrors.find(v => v.property === 'start_time')?.errors || []}
              disabled
            />
          )}
          <div className="horizontal-flex">
            <DateInput
              name="end_time"
              id="end_time"
              label={Strings.endDate}
              placeholder="MM / DD / YYYY"
              value={schedule.end_time || endDateFromEnrollment || ''}
              timezone={schedule.schedule_timezone}
              format="MM / dd / yyyy"
              onChange={e => onDateChange(e, 'end_time')}
              highlightInvalid={highlightInvalidFields}
              errorsForTooltip={validationErrors.find(v => v.property === 'end_time')?.errors || []}
            />
            <SelectField
              name="schedule_timezone"
              id="schedule_timezone"
              label={Strings.timezone}
              value={schedule.schedule_timezone}
              placeholder={Strings.select}
              onChange={onTimezoneChange}
              data={Object.keys(timezones).map(e => ({
                label: timezones[e],
                value: e,
              }))}
              isRequired
              highlightInvalid={highlightInvalidFields}
            />
          </div>
          {schedule.type === scheduleTypes.everyDay && (
            <React.Fragment>
              <div className="horizontal-flex">
                <SelectField
                  name="administration_window_start"
                  id="administration_window_start"
                  label={Strings.administrationWindow}
                  value={schedule.take_window?.[0]}
                  placeholder={Strings.select}
                  onChange={e => onWindowSelected('start', e.value)}
                  data={getWindowOffsetsWithPrefix('-')}
                  isRequired={schedule.offset?.length > 1 || schedule.take_window?.[1]}
                  highlightInvalid={highlightInvalidFields}
                  errorsForTooltip={
                    validationErrors.find(v => v.property === 'take_window' && v.field === 'start')?.errors || []
                  }
                />
                <SelectField
                  name="administration_window_end"
                  id="administration_window_end"
                  value={schedule.take_window?.[1]}
                  placeholder={Strings.select}
                  onChange={e => onWindowSelected('end', e.value)}
                  data={getWindowOffsetsWithPrefix('+')}
                  isRequired={schedule.offset?.length > 1 || schedule.take_window?.[0]}
                  highlightInvalid={highlightInvalidFields}
                  errorsForTooltip={
                    validationErrors.find(v => v.property === 'take_window' && v.field === 'end')?.errors || []
                  }
                />
              </div>
              <div className="horizontal-flex">
                <div className="vertical-flex">
                  <SelectField
                    name="times_per_day"
                    id="times_per_day"
                    label={Strings.patient_medications.timesPerDay}
                    placeholder={getTimesPerDayFromScheduleOffsets(schedule?.offset)}
                    value={schedule.offset}
                    data={Object.keys(timePerDaysTemplates).map(key => ({
                      value: timePerDaysTemplates[key],
                      label: timePerDaysTemplates[key].text,
                    }))}
                    onChange={option => onTakesPerDayChanged(option.value)}
                    isRequired
                    highlightInvalid={highlightInvalidFields}
                    errorsForTooltip={validationErrors.find(v => v.property === 'timesPerDay')?.errors || []}
                    disabled={editMode}
                  />
                  {schedule.offset &&
                    schedule.offset.length > 0 &&
                    schedule.offset.map((o, i) => (
                      <div key={`offset_${i}`} className="horizontal-flex">
                        <TimeInput
                          id={`offset_${i}`}
                          name={`offset_${i}`}
                          label={`${Strings.time} ${i + 1}`}
                          format="hh:mm"
                          value={
                            o || o === 0
                              ? moment
                                  .unix(o)
                                  .utc()
                                  .format('hh:mm')
                              : ''
                          }
                          onChange={e =>
                            onOffsetChange(
                              e,
                              i,
                              moment
                                .unix(o)
                                .utc()
                                .format('A'),
                            )
                          }
                          onInvalidValue={() => setInvalidFields('offset', i)}
                          isRequired
                          highlightInvalid={highlightInvalidFields}
                          errorsForTooltip={
                            validationErrors.find(v => v.property === 'offset' && v.index === i)?.errors || []
                          }
                        />
                        <SelectField
                          name={`offset_am_switch${i}`}
                          id={`offset_am_switch${i}`}
                          label=" "
                          value={moment
                            .unix(o)
                            .utc()
                            .format('A')}
                          onChange={e =>
                            onAmPmSelected(
                              moment
                                .unix(o)
                                .utc()
                                .format('A'),
                              e.value,
                              i,
                            )
                          }
                          data={[
                            { value: 'AM', label: Strings.patient_medications.am },
                            { value: 'PM', label: Strings.patient_medications.pm },
                          ]}
                          highlightInvalid={highlightInvalidFields}
                        />
                      </div>
                    ))}
                </div>
                <div />
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );

  const remindersPage = (
    <React.Fragment>
      <div className="page-info-header">{schedule.name}</div>
      {Object.values(remindersTypes).map((r, i) => (
        <React.Fragment key={`reminder_${r}`}>
          <div className={`horizontal-flex reminders-row reminder-${r}`}>
            <Input
              type="checkbox"
              name={`reminder_check_${i}`}
              id={`reminder_check_${i}`}
              label={Strings.patient_medications.reminders[r]}
              defaultChecked={schedule.sms_reminder?.[r]?.checked}
              onChange={e => onReminderChange(r, 'checked', e.target.checked)}
            />
            {r === remindersTypes.onTime ? (
              <></>
            ) : r === remindersTypes.missedDoses ? (
              <Input
                type="number"
                id={`reminder_doses_${i}`}
                label=""
                placeholder={Strings.patient_medications.defaultDoses}
                disabled={!schedule.sms_reminder?.[r]?.checked}
                value={schedule.sms_reminder?.[r]?.offset}
                errorsForTooltip={
                  validationErrors.find(v => v.property === 'sms_reminder' && v.type === r && v.field === 'offset')
                    ?.errors || []
                }
                highlightInvalid={highlightInvalidFields && schedule.sms_reminder?.[r]?.checked}
                onChange={e => onReminderChange(r, 'offset', parseInt(e.target.value, 10))}
              />
            ) : (
              <Select
                name={`reminder_time_${i}`}
                id={`reminder_time_${i}`}
                value={schedule.sms_reminder?.[r]?.offset}
                placeholder={Strings.select}
                onChange={e => onReminderChange(r, 'offset', e.value)}
                data={reminderOffsets}
                disabled={!schedule.sms_reminder?.[r]?.checked}
              />
            )}
          </div>
          {schedule.sms_reminder?.[r]?.checked && (
            <div className="reminder-text">
              <TextAreaWithHashtagsField
                value={schedule.sms_reminder?.[r]?.text}
                onChange={e => onReminderChange(r, 'text', e)}
                label=""
                rows={5}
                hashtags={
                  defaultReminders?.[r]?.actions?.length > 0
                    ? defaultReminders?.[r]?.actions.map(a => {
                        return {
                          id: a,
                          display: a,
                          shortcut: a,
                          title: a,
                        };
                      })
                    : []
                }
                trigger={/(\[(\w*))$/}
                markup="[__id__]"
                displayTransform={(_, display) => {
                  return `[${display}]`;
                }}
                regex={/\[(\S+?)\]/}
                simpleMenu
                tooltipForEntries={Strings.insertTag}
                inputRef={remindersRef.current[r]}
                forceSuggestionsAboveCursor={false}
                isRequired
                errorsForTooltip={
                  validationErrors.find(v => v.property === 'sms_reminder' && v.type === r && v.field === 'text')
                    ?.errors || []
                }
                highlightInvalid={highlightInvalidFields}
              />
              {!isTextDefault(r) && (
                <button
                  onClick={() => onReminderChange(r, 'text', defaultReminders?.[r]?.message)}
                  className="reset-reminder-text brand-white-gray"
                >
                  {Strings.setDefault}
                </button>
              )}
              {defaultReminders?.[r]?.actions?.length > 0 && (
                <React.Fragment>
                  <div className="help" data-tooltip-id={`tooltip-tags-${r}`}>
                    ?
                  </div>
                  <ReactTooltip
                    id={`tooltip-tags-${r}`}
                    effect="solid"
                    clickable
                    variant="light"
                    place="top-start"
                    className="message-textbox__suggestions"
                    border="1px solid #bababa"
                    ref={tooltipsRef.current[r]}
                  >
                    <h3>{Strings.patient_medications.tagsForReminders}</h3>

                    <HashtagsWindow
                      onTagChosen={text => onTagChosen(r, text)}
                      hashtags={defaultReminders?.[r]?.actions?.length > 0 ? defaultReminders?.[r]?.actions : []}
                    />
                  </ReactTooltip>
                </React.Fragment>
              )}
            </div>
          )}
          <div className="hr" />
        </React.Fragment>
      ))}
      <div>{Strings.patient_medications.reminderCharLimit}</div>
    </React.Fragment>
  );

  const assignCapPage = (
    <React.Fragment>
      <div className="page-info-header">{schedule.name}</div>
      <SelectField
        name="cap_id"
        id="cap_id"
        label={Strings.capId}
        defaultValue={schedule.cap_id}
        placeholder={Strings.select}
        onChange={e => onSelectedOption(e, 'cap_id')}
        data={caps.map(c => ({
          label: c,
          value: c,
        }))}
      />
    </React.Fragment>
  );

  const linkProgramPage = (
    <React.Fragment>
      <div className="page-info-header">{schedule.name}</div>
      <SelectField
        name="program"
        id="program"
        label={Strings.program}
        defaultValue={schedule?.enrollment_id || null}
        placeholder={Strings.select}
        onChange={e => onSelectedOption(e, 'enrollment_id')}
        data={[{ label: Strings.capPatient.noProgram, value: null }].concat(
          programs?.map(c => ({
            label: getProgramDisplayName(c.name, c.subprogram),
            value: c.enrollmentId,
          })),
        )}
      />
    </React.Fragment>
  );

  const medicationFields = ['name'];
  const scheduleFields = ['type', 'offset', 'take_window', 'end_time', 'schedule_timezone', 'start_time'];
  const remindersFields = ['sms_reminder'];

  const pages = [
    {
      id: EditRpmScheduleModalPages.medication.id,
      title: EditRpmScheduleModalPages.medication.name,
      content: medicationPage,
      emptyFieldsCount: validationErrors.filter(v => medicationFields.includes(v.property) && v.missing).length,
      canGoNext: validationErrors.filter(v => medicationFields.includes(v.property)).length === 0,
      closeButton: { text: props.data.workflow ? Strings.skipAndClose : Strings.close },
    },
    {
      id: EditRpmScheduleModalPages.linkProgram.id,
      title: EditRpmScheduleModalPages.linkProgram.name,
      content: linkProgramPage,
      validations: [],
      canGoNext: !saveBtnClicked,
    },
    {
      id: EditRpmScheduleModalPages.schedule.id,
      title: EditRpmScheduleModalPages.schedule.name,
      content: schedulePage,
      emptyFieldsCount: validationErrors.filter(v => scheduleFields.includes(v.property) && v.missing).length,
      canGoNext: validationErrors.filter(v => scheduleFields.includes(v.property)).length === 0,
    },
    {
      id: EditRpmScheduleModalPages.reminders.id,
      title: EditRpmScheduleModalPages.reminders.name,
      content: remindersPage,
      emptyFieldsCount: validationErrors.filter(v => remindersFields.includes(v.property) && v.missing).length,
      canGoNext: validationErrors.filter(v => remindersFields.includes(v.property)).length === 0,
    },
    {
      id: EditRpmScheduleModalPages.assignCap.id,
      title: EditRpmScheduleModalPages.assignCap.name,
      content: assignCapPage,
      validations: [],
      canGoNext: !saveBtnClicked,
    },
  ];

  const getpagesToShow = () => {
    let ret =
      props.data.pages && props.data.pages.length > 0
        ? props.data.pages.map(p => pages.find(pg => pg.id === p.id))
        : pages;
    if (schedule.type === scheduleTypes.asNeeded) {
      ret = ret.filter(p => p.id !== EditRpmScheduleModalPages.reminders.id);
    }
    if (programs.length === 0) {
      ret = ret.filter(p => p.id !== EditRpmScheduleModalPages.linkProgram.id);
    }
    if (props.data.ignoreFilters) {
      return ret;
    }
    return ret.filter(p => props.pagesVisibility?.some(pg => pg.id === p.id && pg.visible));
  };

  return (
    <Wizard
      name="edit-rpm-schedule"
      pages={getpagesToShow()}
      onNextButtonHover={e => sethighlightInvalidFields(e)}
      forceNextPage={forceNextPage}
      onSubmit={onSave}
      onCancelAction={onCancel}
      showPagesFilter={!props.data.ignoreFilters}
    />
  );
}

EditRpmScheduleModal.propTypes = {
  data: PropTypes.shape({
    pages: PropTypes.array,
    schedule: PropTypes.any,
    patient: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    actionType: PropTypes.string,
    workflow: PropTypes.string,
    ignoreFilters: PropTypes.bool,
    logTask: PropTypes.bool,
    enrollmentId: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  getCaps: PropTypes.func,
  getReminders: PropTypes.func,
  showNotification: PropTypes.func,
  pagesVisibility: PropTypes.array,
  closeModalWithNextAction: PropTypes.func,
  startTask: PropTypes.func,
  continueTask: PropTypes.func,
  endTask: PropTypes.func,
  getPatientInfo: PropTypes.func,
};

EditRpmScheduleModal.defaultProps = { data: { ignoreFilters: false, logTask: false } };

const mapStateToProps = state => {
  return {
    conditions: state.superUser.cohorts?.conditions,
    facilities: state.superUser.cohorts?.facilities,
    cohorts: state.superUser.cohorts?.cohorts,
    pagesVisibility: state.modalsVisibility.pagesVisibility.find(m => m.id === 'edit-rpm-schedule').pages,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: (data, patientId) => dispatch(ownProps.data.action(data, patientId)),
  closeModalWithNextAction: () => {
    dispatch(closeModal('edit-rpm-schedule'));
    if (ownProps.data.onSuccess) {
      ownProps.data.onSuccess(ownProps.data.patient.id);
    }
  },
  getCaps: () => dispatch(actions.getCapsWithoutSchedule(ownProps.data?.patient?.id)),
  getReminders: request => dispatch(actions.getRemindersDefaultMessages(request)),
  showNotification: (message, timeout, isError) => dispatch(notificationActions.show(message, timeout, isError)),
  startTask: data => dispatch(actions.startTask(data)),
  endTask: data => dispatch(actions.endTask(data)),
  continueTask: data => dispatch(actions.continueTask(data)),
  getPatientInfo: patientId => dispatch(actions.getPatientInfo(patientId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditRpmScheduleModal);
