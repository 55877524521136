import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { now } from 'moment-timezone';

import { openModalAction } from '../../../../actions/modal';
import Strings from '../../../../Strings';
import { actions } from '../redux/actions';
import { EDIT_PATIENT } from '../redux/constants';
import MedicalHistory from './MedicalHistory';
import PatientDetails from './PatientDetails';
import DashboardLayout from '../../../Dashboards/DashboardLayout';
import Insurance from './Insurance';
import CenterDetails from './CenterDetails';
import TransplantDetails from './TransplantDetails';
import { RpmPatientModalPages } from '../../../../modals/_ModalsMetadata/ModalsMetadata';
import Caregivers from './Caregivers';
import { hasPermission, PERMISSIONS } from '../../../../utils/userPermissions';
import { RPM_PATIENT_STATUS } from '../../../../constants';

export const DASHBOARD_ID = 'DashboardPatientInfo2';

const medicalHistoryMetadata = {
  id: MedicalHistory.widgetId,
  name: Strings.widgets.medicalHistory,
  permission: '',
  defProps: { i: MedicalHistory.widgetId, w: 4, h: 1, minW: 2, minH: 3 },
};
const patientDetailsMetadata = {
  id: PatientDetails.widgetId,
  name: Strings.widgets.patientDetails,
  permission: '',
  defProps: { i: PatientDetails.widgetId, w: 4, h: 1, minW: 2, minH: 3 },
};
const insuranceMetadata = {
  id: Insurance.widgetId,
  name: Strings.widgets.insurance,
  permission: '',
  defProps: { i: Insurance.widgetId, w: 4, h: 2, minW: 2, minH: 3 },
};
const centerDetailsMetadata = {
  id: CenterDetails.widgetId,
  name: Strings.widgets.centerDetails,
  permission: '',
  defProps: { i: CenterDetails.widgetId, w: 4, h: 1, minW: 2, minH: 3 },
};
const transplantDetailsMetadata = {
  id: TransplantDetails.widgetId,
  name: Strings.widgets.transplantDetails,
  permission: '',
  defProps: { i: TransplantDetails.widgetId, w: 4, h: 1, minW: 2, minH: 3 },
};
const caregiversMetadata = {
  id: Caregivers.widgetId,
  name: Strings.caregivers,
  permission: '',
  defProps: { i: Caregivers.widgetId, w: 4, h: 1, minW: 2, minH: 3 },
};

export const WidgetMetadata = [
  medicalHistoryMetadata,
  patientDetailsMetadata,
  insuranceMetadata,
  centerDetailsMetadata,
  transplantDetailsMetadata,
  caregiversMetadata,
];

const l = [
  { ...medicalHistoryMetadata.defProps, x: 0, y: 4, w: 6, h: 5 },
  { ...patientDetailsMetadata.defProps, x: 0, y: 0, w: 6, h: 10 },
  { ...insuranceMetadata.defProps, x: 6, y: 0, w: 6, h: 4 },
  { ...centerDetailsMetadata.defProps, x: 6, y: 2, w: 6, h: 5 },
  { ...transplantDetailsMetadata.defProps, x: 6, y: 5, w: 6, h: 3 },
  { ...caregiversMetadata.defProps, x: 6, y: 7, w: 6, h: 3 },
];

const m = [
  { ...medicalHistoryMetadata.defProps, x: 0, y: 4, w: 4, h: 5 },
  { ...patientDetailsMetadata.defProps, x: 0, y: 0, w: 4, h: 10 },
  { ...insuranceMetadata.defProps, x: 4, y: 0, w: 4, h: 4 },
  { ...centerDetailsMetadata.defProps, x: 6, y: 2, w: 4, h: 5 },
  { ...transplantDetailsMetadata.defProps, x: 4, y: 5, w: 4, h: 3 },
  { ...caregiversMetadata.defProps, x: 6, y: 7, w: 4, h: 3 },
];

const s = [
  { ...medicalHistoryMetadata.defProps, x: 0, y: 4, w: 4, h: 5 },
  { ...patientDetailsMetadata.defProps, x: 0, y: 0, w: 4, h: 10 },
  { ...insuranceMetadata.defProps, x: 0, y: 2, w: 4, h: 4 },
  { ...centerDetailsMetadata.defProps, x: 0, y: 3, w: 4, h: 5 },
  { ...transplantDetailsMetadata.defProps, x: 0, y: 4, w: 4, h: 3 },
  { ...caregiversMetadata.defProps, x: 6, y: 7, w: 4, h: 3 },
];

export const DefaultLayouts = { l, m, s };

function PatientInfo(props) {
  const [widgets, setWidgets] = useState([]);
  const [refreshTimestamp, setRefreshTimestamp] = useState(now());
  const scrollbarRef = React.useRef();

  const onEditPatient = (event, patient, pageNo) => {
    event.preventDefault();
    event.stopPropagation();
    props.onEditPatient(patient, pageNo, () => setRefreshTimestamp(now()));
  };

  useEffect(() => {
    props.getPatientInfo(props.patientId);
  }, [props.patientId, refreshTimestamp]);

  useEffect(() => {
    initializeDashboard();
  }, [props.patientDetails, props.refreshTimestamp]);

  const initializeDashboard = () => {
    const { patientDetails } = props;
    const canEdit =
      hasPermission(PERMISSIONS.PATIENTS_RPM_PATIENT_INFO_EDIT) &&
      props.patientDetails?.status !== RPM_PATIENT_STATUS.ended;

    const widgetMedHist = {
      ...medicalHistoryMetadata,
      render: (
        <MedicalHistory
          conditions={patientDetails?.conditions}
          enrollment={patientDetails?.patientEnrollment}
          medications={patientDetails?.medications}
          onEdit={canEdit ? e => onEditPatient(e, patientDetails, [RpmPatientModalPages.medicalHistory]) : undefined}
        />
      ),
      noPadding: true,
    };
    const widgetPatDetails = {
      ...patientDetailsMetadata,
      render: (
        <PatientDetails
          patientDetails={patientDetails}
          onEdit={
            canEdit
              ? e =>
                  onEditPatient(e, patientDetails, [
                    RpmPatientModalPages.patientDetails,
                    RpmPatientModalPages.contactInformation,
                  ])
              : undefined
          }
        />
      ),
      noPadding: true,
    };
    const widgetIns = {
      ...insuranceMetadata,
      render: (
        <Insurance
          insurance={patientDetails?.insurance}
          onEdit={canEdit ? e => onEditPatient(e, patientDetails, [RpmPatientModalPages.insurance]) : undefined}
        />
      ),
      noPadding: true,
    };
    const widgetCentDet = {
      ...centerDetailsMetadata,
      render: (
        <CenterDetails
          centerDetails={patientDetails?.centerDetails}
          facility={patientDetails?.facilityName}
          cohort={patientDetails?.cohortName}
          onEdit={canEdit ? e => onEditPatient(e, patientDetails, [RpmPatientModalPages.centerDetails]) : undefined}
        />
      ),
      noPadding: true,
    };
    const widgetTransplDet = {
      ...transplantDetailsMetadata,
      render: (
        <TransplantDetails
          transplantDetails={patientDetails?.transplantDetails}
          onEdit={canEdit ? e => onEditPatient(e, patientDetails, [RpmPatientModalPages.transplantDetails]) : undefined}
        />
      ),
      noPadding: true,
    };
    const widgetCaregivers = {
      ...caregiversMetadata,
      render: <Caregivers patientId={patientDetails?.id} refreshTimestamp={props.refreshTimestamp} canEdit={canEdit} />,
      noPadding: true,
    };

    setWidgets([widgetMedHist, widgetPatDetails, widgetIns, widgetCentDet, widgetTransplDet, widgetCaregivers]);
  };

  return (
    widgets.length > 0 &&
    !_.isEmpty(DefaultLayouts) && (
      <OverlayScrollbarsComponent
        defer
        className="patient-info-dashboard scrollbar-right-margin"
        options={{ scrollbars: { autoHide: 'leave', autoHideDelay: '100' } }}
        ref={scrollbarRef}
      >
        <DashboardLayout
          dashboardId={DASHBOARD_ID}
          widgets={widgets}
          defaultLayouts={DefaultLayouts}
          breakpoints={{ l: 1400, m: 1151, s: 0 }}
          rowHeight={40}
          scrollbarRef={scrollbarRef}
        />
      </OverlayScrollbarsComponent>
    )
  );
}

PatientInfo.propTypes = {
  getPatientInfo: PropTypes.func,
  isLoading: PropTypes.any,
  onEditPatient: PropTypes.func,
  patientDetails: PropTypes.shape({
    centerDetails: PropTypes.arrayOf(
      PropTypes.shape({
        centerName: PropTypes.string,
        provider: PropTypes.string,
        clinicContact: PropTypes.string,
      }),
    ),
    dateOfBirth: PropTypes.any,
    email: PropTypes.any,
    homeAddress: PropTypes.any,
    insurance: PropTypes.arrayOf(
      PropTypes.shape({
        insuranceName: PropTypes.string,
        policyId: PropTypes.string,
        groupNumber: PropTypes.string,
      }),
    ),
    medicalHistory: PropTypes.arrayOf(
      PropTypes.shape({
        primaryCondition: PropTypes.string,
        secondaryCondition: PropTypes.string,
      }),
    ),
    mrn: PropTypes.any,
    patientName: PropTypes.any,
    primaryPhoneNo: PropTypes.any,
    textPhoneNo: PropTypes.any,
    transplantDetails: PropTypes.arrayOf(
      PropTypes.shape({
        organType: PropTypes.string,
        transplantDate: PropTypes.string,
      }),
    ),
  }),
  patientId: PropTypes.any,
};

const mapStateToProps = state => {
  const { patients } = state.superUser;
  return {
    patientDetails: patients?.patientDetails,
    isLoading: patients?.isLoading,
  };
};

const mapDispatchToProps = dispatch => ({
  onNavigate: path => dispatch(push(path)),
  getPatientInfo: patientId => dispatch(actions.getPatientInfo(patientId)),
  onEditPatient: (patient, pages, nextAction) =>
    dispatch(
      openModalAction('edit-rpm-patient', {
        action: actions.editPatient,
        actionType: EDIT_PATIENT,
        nextAction,
        patient,
        pages,
        ignoreFilters: true,
      }),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientInfo);
