import React from 'react';
import { connect } from 'react-redux';
import ReactDOMServer from 'react-dom/server';

import { getOrgnization, getRole } from '../../utils/userRoles';

import './SideMenu.module.scss';

function DiagnosticInfo (props) {
  const { profile, visible } = props;
  const isDev = process.env.NODE_ENV === 'development';
  let userName = '';
  if (profile) {
    userName = profile.first_name ? `${profile.first_name} ${profile.last_name}` : profile.username;
  }

  const devInfo = (
    <div
      className={`left-bar-diagnostic-info ${visible ? '' : 'collapsed'}`}
      data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
        <div>
          {' '}
          {getRole()}
          <br /> {getOrgnization()}
        </div>,
      )}
      data-tooltip-id="tooltip"
    >
      {userName}
    </div>
  );

  const prodInfo = (
    <div className={`left-bar-diagnostic-info ${visible ? '' : 'collapsed'}`} >
    {getOrgnization()}
  </div>
  );
  
  return isDev ? devInfo : prodInfo;
}

const mapStateToProps = state => {
  return {
    profile: state.auth.profile,
  };
};

export default connect(mapStateToProps)(DiagnosticInfo);
