import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import Wizard from '../containers/Modal/Wizard';
import Strings from '../Strings';
import { notificationActions } from '../components/Notification/redux/actions';
import { closeModal } from '../actions/modal';
import { actions } from '../pages/SuperUser/Patients/redux/actions';
import { Input } from '../containers/Form';
import { getProgramsFromPatient } from '../utils/cmsPrograms';
import { EDIT_WELCOME_MESSAGE_ERROR, ENROLL_PATIENT_ERROR } from '../pages/SuperUser/Patients/redux/constants';
import { getScheduleFromMed } from './EditRpmSchedule/EditRpmScheduleModal';
import { patientAction } from '../actions/patient';
import { UPDATE_PATIENT_SCHEDULE_ERROR } from '../actions/action-types';

function StartEnrollmentAndSchedulesModal(props) {
  const [medsToStart, setMedsToStart] = React.useState([]);
  const [selectedSchedules, setSelectedSchedules] = React.useState([]);
  const [saveBtnClicked, setSaveBtnClicked] = React.useState(false);

  const enrollments = getProgramsFromPatient(props.data?.patient, true);

  useEffect(() => {
    props.getMedications();
  }, []);

  useEffect(() => {
    const meds =
      props.medications?.filter(
        m => enrollments?.some(e => e.enrollmentId === m.enrollmentId) && m.format?.start_dt > moment().unix(),
      ) || [];
    setMedsToStart(meds);
    if (meds?.length === 1) {
      setSelectedSchedules([meds[0].id]);
    }
  }, [props.medications]);

  const onSubmit = async () => {
    if (saveBtnClicked) {
      return;
    }
    setSaveBtnClicked(true);

    const data = {
      patient_id: props.data?.patient?.id,
      data: Object.values(props.data?.patient.patientEnrollment.enrollments).map(e => ({
        subprogram: e.subprogram,
        start_date: moment().format(),
        condition_id: e.conditionId,
      })),
    };

    const response = await props.enrollPatient(data);
    if (response) {
      if (response.type === ENROLL_PATIENT_ERROR) {
        const error = response.response?.data?.standard_error?.message;
        props.showNotification(error, 5000, true);
      } else {
        props.showNotification(Strings.formatString(Strings.patientActivatedInfo, props.data?.patient?.patientName));

        props.sendWelcomeMessageNow({ user_id: props.data?.patient?.id }).then(welcomeMsgResp => {
          if (welcomeMsgResp.type === EDIT_WELCOME_MESSAGE_ERROR) {
            props.showNotification(welcomeMsgResp.response?.data?.error?.message, 5000, true);
          }
        });
        selectedSchedules.forEach(scheduleId => {
          const medication = props.medications.find(m => m.id === scheduleId);
          if (medication) {
            const medData = getScheduleFromMed(medication);
            if (medication.format?.end_dt) {
              medData.end_time = moment()
                .unix(medication.format?.end_dt)
                .format();
            }
            medData.start_time = moment().format();

            props.editSchedule(medData, props.data?.patient?.id).then(response => {
              if (response && response.type === UPDATE_PATIENT_SCHEDULE_ERROR) {
                props.showNotification(response.response?.data?.error?.message, 5000, true);
              } else {
                props.showNotification(Strings.success.medicationSaved);
              }
            });
          }
        });
        props.closeModalWithNextAction();
      }
    }
    setSaveBtnClicked(false);
  };

  const onSelectedScheduleChange = (e, id) => {
    if (e.target.checked) {
      setSelectedSchedules([...selectedSchedules, id]);
    } else {
      setSelectedSchedules(selectedSchedules.filter(s => s !== id));
    }
  };

  const pages = [
    {
      id: 'start-enrollment-page',
      title: Strings.activateNow,
      content: (
        <React.Fragment>
          <div>{Strings.formatString(Strings.startEnrollmentWarning, props.data?.patient?.patientName)}</div>
          {medsToStart?.length > 0 && <div>{Strings.startEnrollmentMedsWarning}</div>}
          {medsToStart?.map((schedule, i) => (
            <React.Fragment key={`schedule_${schedule.id}`}>
              <div className="horizontal-flex reminders-row ">
                <Input
                  type="checkbox"
                  name={`schedule_check_${i}`}
                  id={`schedule_check_${i}`}
                  label={schedule.medicationName}
                  defaultChecked={selectedSchedules.includes(schedule.id)}
                  onChange={e => onSelectedScheduleChange(e, schedule.id)}
                />
              </div>
            </React.Fragment>
          ))}
        </React.Fragment>
      ),
      emptyFieldsCount: 0,
      canGoNext: true,
    },
  ];

  return <Wizard name="start-enrollmend-and-schedules" pages={pages} onSubmit={onSubmit} showPagesFilter={false} />;
}

const mapStateToProps = state => {
  return {
    medications: state.superUser?.patients?.patientMedications?.medications,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  showNotification: (message, timeout) => dispatch(notificationActions.show(message, timeout)),
  onCancel: () => dispatch(closeModal(ownProps.name)),
  enrollPatient: data => dispatch(actions.enrollPatient(data)),
  closeModalWithNextAction: () => {
    dispatch(closeModal('start-enrollmend-and-schedules'));
    if (ownProps.data.onSuccess) ownProps.data.onSuccess();
  },
  getMedications: () => dispatch(actions.getMedications(ownProps.data?.patient?.id)),
  editSchedule: (data, patientId) => dispatch(patientAction.actionUpdateSchedule(data, patientId)),
  sendWelcomeMessageNow: data => dispatch(actions.editWelcomeMessage(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StartEnrollmentAndSchedulesModal);
