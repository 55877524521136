import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import Wizard from '../containers/Modal/Wizard';
import Strings from '../Strings';
import { DateInput, SelectField, TextArea } from '../containers/Form';
import { notificationActions } from '../components/Notification/redux/actions';
import { closeModal } from '../actions/modal';

const deviceStates = [
  { value: 'New', label: 'New' },
  { value: 'Blocked', label: 'Blocked' },
  { value: 'Suspended', label: 'Suspended' },
  { value: 'RMA', label: 'RMA' },
  { value: 'Unassigned', label: 'Unassigned' },
  { value: 'Offline', label: 'Offline ' },
  { value: 'Online', label: 'Online' },
  { value: 'Active', label: 'Active' },
];

const kitAdministrativeStates = [
  { value: 'Normal', label: 'Normal' },
  { value: 'Blocked', label: 'Blocked' },
  { value: 'RMA', label: 'RMA' },
  { value: 'Suspended', label: 'Suspended' },
  { value: 'Deleted', label: 'Deleted' },
];

const orgAdministrativeStates = [
  { value: 'New', label: 'New' },
  { value: 'Trial', label: 'Trial' },
  { value: 'Customer', label: 'Customer' },
  { value: 'Demo', label: 'Demo' },
  { value: 'EngineeringTest', label: 'Engineering/Test' },
  { value: 'Suspended', label: 'Suspended' },
];

export const StateTypes = {
  device: {
    id: 'device',
    states: deviceStates,
  },
  kit: {
    id: 'kit',
    states: kitAdministrativeStates,
  },
  org: {
    id: 'org',
    states: orgAdministrativeStates,
  },
}

function ChangeStateModal(props) {
  const [status, setStatus] = React.useState();
  const [note, setNote] = React.useState('');
  const [validUntil, setValidUntil] = React.useState();
  const [highlightInvalidFields, setHighlightMissingFields] = React.useState(false);
  const [saveBtnClicked, setSaveBtnClicked] = React.useState(false);

  const onSubmit = () => {
    if (saveBtnClicked) {
      return;
    }
    setSaveBtnClicked(true);

    const data = {[props.data?.propertyForApi]: status};

    if (note) {
      data.change_note = note;
    }
    if (validUntil) {
      data.valid_until = moment(validUntil)
        .endOf('day')
        .format();
    }

    props.onSubmit(data).then(response => {
      if (response) {
        if (response.type === `${props.data.actionType}/error`) {
          const error = response.response?.data?.standard_error?.message;
          props.showNotification(error, 5000, true);
        } else {
          props.showNotification(Strings.formatString(Strings.itemSaved, Strings.status));
          props.closeModalWithNextAction();
        }
      }
      return response;
    });
    setSaveBtnClicked(false);
  };

  const pages = [
    {
      id: 'change-state-page',
      title: props.data?.title || Strings.changeState,
      content: (
        <React.Fragment>
          <SelectField
            name="status"
            id="status"
            label={Strings.status}
            value={status}
            onChange={option => setStatus(option.value)}
            data={StateTypes[props.data?.state].states}
            highlightInvalid={highlightInvalidFields}
            isRequired
          />
          <TextArea
            name="note"
            id="note"
            label={Strings.note}
            type="text"
            value={note}
            onChange={e => setNote(e.target.value)}
          />
          <DateInput
            name="valid_until"
            id="valid_until"
            label={Strings.validUntil}
            placeholder="MM / DD / YYYY"
            value={validUntil || ''}
            format="MM / dd / yyyy"
            onChange={setValidUntil}
            highlightInvalid={highlightInvalidFields}
            // errorsForTooltip={validationErrors.find(v => v.property === 'end_time')?.errors || []}
          />
        </React.Fragment>
      ),
      emptyFieldsCount: status ? 0 : 1,
      canGoNext: status,
    },
  ];

  return (
    <Wizard
      name="change-state"
      pages={pages}
      onNextButtonHover={e => setHighlightMissingFields(e)}
      onSubmit={onSubmit}
      showPagesFilter={false}
    />
  );
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  showNotification: (message, timeout, isError) => dispatch(notificationActions.show(message, timeout, isError)),
  onCancel: () => dispatch(closeModal(ownProps.name)),
  onSubmit: data => dispatch(ownProps.data.action(ownProps.data.id, data)),
  closeModalWithNextAction: () => {
    dispatch(closeModal('change-state'));
    if (ownProps.data.onSuccess) ownProps.data.onSuccess();
  },
});

export default connect(null, mapDispatchToProps)(ChangeStateModal);
